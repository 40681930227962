import { Logout } from './authTypes';

export const GET_DOC_TYPES_REQUEST = 'GET_DOC_TYPES_REQUEST';
export const GET_DOC_TYPES_SUCCESS = 'GET_DOC_TYPES_SUCCESS';
export const GET_DOC_TYPES_FAIL = 'GET_DOC_TYPES_FAIL';
export const SEND_DOCS_DATA_REQUEST = 'SEND_DOCS_DATA_REQUEST';
export const SEND_DOCS_DATA_SUCCESS = 'SEND_DOCS_DATA_SUCCESS';
export const SEND_DOCS_DATA_FAIL = 'SEND_DOCS_DATA_FAIL';
export const GET_DOCS_DATA_REQUEST = 'GET_DOCS_DATA_REQUEST';
export const GET_DOCS_DATA_SUCCESS = 'GET_DOCS_DATA_SUCCESS';
export const GET_DOCS_DATA_FAIL = 'GET_DOCS_DATA_FAIL';
export const UPLOAD_NATIONAL_ID_REQUEST = 'UPLOAD_NATIONAL_ID_REQUEST';
export const UPLOAD_NATIONAL_ID_SUCCESS = 'UPLOAD_NATIONAL_ID_SUCCESS';
export const UPLOAD_NATIONAL_ID_FAIL = 'UPLOAD_NATIONAL_ID_FAIL';
export const UPLOAD_SELFIE_PICTURE_REQUEST = 'UPLOAD_SELFIE_PICTURE_REQUEST';
export const UPLOAD_SELFIE_PICTURE_SUCCESS = 'UPLOAD_SELFIE_PICTURE_SUCCESS';
export const UPLOAD_SELFIE_PICTURE_FAIL = 'UPLOAD_SELFIE_PICTURE_FAIL';

export type DocType = {
  id: number;
  type: string;
};

export type DocTypes = {
  types: DocType[];
  isLoading: boolean;
  isErrorExist: boolean;
};

export type NationalId = {
  isLoading: boolean;
  isErrorExist: boolean;
};

export type SelfiePicture = {
  isLoading: boolean;
  isErrorExist: boolean;
};

export type SendDocsDataState = {
  isLoading: boolean;
  isErrorExist: boolean;
};

export enum DocumentTypes {
  SELFIE = 'Selfie',
  VERIFICATION_DOCUMENT = 'Verification Document',
  ACCOUNT_STATEMENT = 'Account Statement',
  TRADE_CONFIRMATION = 'Trade Confirmation',
  OTHER = 'Other',
}

export type Attachment = {
  id: number;
  createdAt: Date;
  documentLink: string;
  userId: number;
  documentType: {
    id: number;
    type: DocumentTypes;
  };
};

export type DocsDataState = {
  data: Attachment[];
  isLoading: boolean;
  isErrorExist: boolean;
  isDataLoaded: boolean;
};

export type AttachedDoc = {
  documentTypeId: number;
  link: string;
};

export type FetchUploadedDocumentURLPayload =
  | string
  | {
      fileId: string;
      access: 'private' | 'public';
    };

export interface RequestUploadNationalId {
  type: typeof UPLOAD_NATIONAL_ID_REQUEST;
}

export interface RequestUploadNationalIdSuccess {
  type: typeof UPLOAD_NATIONAL_ID_SUCCESS;
}

export interface RequestUploadNationalIdFail {
  type: typeof UPLOAD_NATIONAL_ID_FAIL;
}

export interface RequestUploadSelfiePicture {
  type: typeof UPLOAD_SELFIE_PICTURE_REQUEST;
}

export interface RequestUploadSelfiePictureSuccess {
  type: typeof UPLOAD_SELFIE_PICTURE_SUCCESS;
}

export interface RequestUploadSelfiePictureFail {
  type: typeof UPLOAD_SELFIE_PICTURE_FAIL;
}

export interface GetDocTypes {
  type: typeof GET_DOC_TYPES_REQUEST;
}

export interface GetDocTypesSuccess {
  type: typeof GET_DOC_TYPES_SUCCESS;
  payload: DocType[];
}

export interface GetDocTypesFail {
  type: typeof GET_DOC_TYPES_FAIL;
}

export interface SendDocsData {
  type: typeof SEND_DOCS_DATA_REQUEST;
}

export interface SendDocsDataSuccess {
  type: typeof SEND_DOCS_DATA_SUCCESS;
}

export interface SendDocsDataFail {
  type: typeof SEND_DOCS_DATA_FAIL;
}

export interface GetDocsData {
  type: typeof GET_DOCS_DATA_REQUEST;
}

export interface GetDocsDataSuccess {
  type: typeof GET_DOCS_DATA_SUCCESS;
  payload: Attachment[];
}

export interface GetDocsDataFail {
  type: typeof GET_DOCS_DATA_FAIL;
}

export type DocsAction =
  | RequestUploadNationalId
  | RequestUploadNationalIdSuccess
  | RequestUploadNationalIdFail
  | RequestUploadSelfiePicture
  | RequestUploadSelfiePictureSuccess
  | RequestUploadSelfiePictureFail
  | GetDocTypes
  | GetDocTypesSuccess
  | GetDocTypesFail
  | SendDocsData
  | SendDocsDataSuccess
  | SendDocsDataFail
  | GetDocsData
  | GetDocsDataSuccess
  | GetDocsDataFail
  | Logout;

export type DocsState = Readonly<{
  docTypes: DocTypes;
  nationalId: NationalId;
  selfiePicture: SelfiePicture;
  sendDocsData: SendDocsDataState;
  docsData: DocsDataState;
}>;
