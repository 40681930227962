import * as APIs from '../../constants/APIs';
import { UpdateProfileDataPayload, UploadDocumentPayload } from '../../store_deprecated/types/authTypes';
import { AttachedDoc, FetchUploadedDocumentURLPayload } from '../../store_deprecated/types/docsTypes';
import { ApiService } from '../http';

const HTTP = new ApiService();

export * from './bank';
export * from './survey';
export * from './portfolio';
export * from './userSignAgreement';

export async function getFaqs() {
  try {
    return await HTTP.get(`${APIs.GET_FAQS}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function uploadDocument(payload: UploadDocumentPayload) {
  try {
    const response = await HTTP.post(`${APIs.UPLOAD_DOC}/${payload.entity}/private`, payload.formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response?.data;
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getUploadedDocumentURL(payload: FetchUploadedDocumentURLPayload) {
  let fileName;
  let access = 'private';

  if (typeof payload !== 'string') {
    fileName = payload.fileId;
    access = payload.access || 'private';
  } else {
    fileName = payload;
  }

  try {
    const response = await HTTP.get(`${APIs.GET_DOC_URL}/${access}/${fileName}`);

    return response?.data;
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getProfileData() {
  try {
    return await HTTP.get(`${APIs.GET_PROFILE_DATA}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function updateProfileData(data: UpdateProfileDataPayload) {
  try {
    return await HTTP.put(`${APIs.PUT_PROFILE_DATA}`, data);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function contactUs(payload: object) {
  try {
    const response = await HTTP.post(APIs.CONTACT_US, payload);
    return response?.data;
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function sendAttachedDoc(payload: AttachedDoc[]) {
  try {
    const response = await HTTP.post(APIs.SAVE_ATTACHED_DOCS, { items: payload });

    return response?.data;
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getAttachedDocs() {
  try {
    const response = await HTTP.get(APIs.GET_ATTACHED_DOCS);

    return response?.data;
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getDocumentsType() {
  try {
    const response = await HTTP.get(`${APIs.GET_DOCS_TYPE}`);
    return response?.data;
  } catch (error) {
    HTTP.handleError(error);
  }
}
