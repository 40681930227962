import { apiSlice } from '../../../api/common/apiSlice';
import { ApiTagType } from '../../../constants/apiTagType';
import { AppConfig, ConfigKey } from '../../../types/config';

export const configApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppConfig: builder.query<AppConfig, ConfigKey>({
      query: (key) => ({
        url: `/app/config/${key}`,
        method: 'GET',
      }),
      providesTags: (response) => (response ? [{ type: ApiTagType.Config, id: response.key }] : []),
       // Disable caching for this endpoint
       keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetAppConfigQuery } = configApiSlice;
