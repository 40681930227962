import { captureException } from '@sentry/react';
import axios, { Axios, AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';
import { CONTACT_US } from '../../constants/APIs';
import { getAccessToken, removeAccessToken, removeLoggedIn } from '../../helpers/LocalStorage';

const controller = new AbortController();

export class ApiService {
  private readonly instance: Axios;

  constructor(baseURL = process.env.REACT_APP_BASE_API_URL) {
    this.instance = axios.create({
      headers: { 'Content-Type': 'application/json' },
      responseType: 'json',
      baseURL: baseURL || process.env.REACT_APP_BASE_API_URL,
    });
    this.instance.defaults.headers.common.accept = 'application/json'; // for all requests
    this.instance.defaults.headers.common['Content-Type'] = 'application/json'; // for all requests

    this.instance.interceptors.response.use(
      (res) => res.data,
      (error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response?.status === 401) {
            const { location } = window;
            const { origin, pathname } = location;

            if (pathname !== '/login') sessionStorage.setItem('lastPage', pathname);

            controller.abort();
            removeAccessToken();
            removeLoggedIn();

            location.replace(`${origin}/login`);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log('[ApiService error.request]', error.request);
        }

        captureException(error);

        return Promise.reject(error.response ? error.response.data : error);
      },
    );

    this.instance.interceptors.request.use(
      (req) => {
        const accessToken = getAccessToken();

        if (accessToken) {
          const payload = jwtDecode<object & { exp: number }>(accessToken);
          const isExpired = Date.now() >= payload.exp * 1000;
          if (!isExpired) {
            req.headers.setAuthorization(`Bearer ${accessToken}`);
          } else if (req.url !== CONTACT_US) {
            window.postMessage('NotAuthorized');
          }
        } else if (req.url !== CONTACT_US) {
          window.postMessage('NotAuthorized');
        }

        req.headers.set('x-anonymous-id', localStorage.getItem('ajs_anonymous_id') || '');

        return req;
      },
      (error: unknown) => {
        console.error('[ApiService API request error]', error);
        return Promise.reject(error);
      },
    );
  }

  get = <R = any>(url: string, config?: AxiosRequestConfig) =>
    this.instance.get<any, R>(url, { ...config, signal: controller.signal });

  post = <R = any, B = any>(url: string, body: B, config?: AxiosRequestConfig): Promise<R> =>
    this.instance.post<any, R, B>(url, body, config);

  put = <R = any, B = any>(url: string, body: B): Promise<R> => this.instance.put<any, R, B>(url, body);

  delete = <R>(url: string, config?: AxiosRequestConfig): Promise<R> => this.instance.delete<unknown, R>(url, config);
}
