/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unsafe-optional-chaining */
import './BetaDashboardView.scss';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Fade, useMediaQuery } from '@mui/material';
import debounce from 'lodash.debounce';
import COLORS from '../../../constants/colors';
import {
  useFindProfileDataQuery,
  useLazyGetIamAuthorizationUrlQuery,
} from '../../../redux/features/auth/auth.apiSlice';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { locations } from '../../../routes/locations';
import { ReduxState, useTypedDispatch } from '../../../redux/store';
import {
  fetchAvailablePortfolios,
  findPortfolioLatestStatistics,
  findRecommendedPortfolioByProduct,
  resetSubscribedToPortfolio,
  subscribedToPortfolio,
} from '../../../store_deprecated/actions/portfolioActions';
import { fetchLastSurveyResult } from '../../../store_deprecated/actions/surveyActions';
import {
  clearWallet,
  fetchAnbMasterardConfigAction,
  fetchBankInfo,
} from '../../../store_deprecated/actions/walletActions';
import {
  getAvailablePortfoliosSelector,
  getPortfolioStatisticsSelector,
  getRecommendedPortfolioSelector,
  getSubscribeToPortfolioSelector,
} from '../../../store_deprecated/selectors/portfolioSelectors';
import { getSurveyResult } from '../../../store_deprecated/selectors/surveySelectors';
import Widgets from '../BetaDashboard/Widgets';
import MButton from '../../../shared/MButton';
import { ProductCard } from '../BetaDashboard/ProductCard';
import { useFetchSubscribedProductsQuery } from '../../../redux/features/products/products.apiSlice';
import {
  deleteUserPreference,
  fetchUserPreferenceByType,
  cleanUserPreferences,
  deleteRecommendedPortfolio,
  fetchRecommendedPortfolios,
} from '../../../redux/modules/userPreference/userPreference.actions';
import { UserPreferenceTypeEnum } from '../../../types/userPreference';
import {
  userPreferenceByTypeSelector,
  userPreferencePortfoliosSelector,
} from '../../../redux/modules/userPreference/userPreference.selectors';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { Product } from '../../../types/product';
import SubscribePortfolioDialog from '../Invest/SubscribePortfolioDialog';
import AddMoreFundDialog from '../../../shared/AddMoreFundDialog';
import Deposit, { DepositProps } from '../MyWallet/Deposit';
import { madkhoulBankInfoSelector } from '../../../store_deprecated/selectors/walletSelectors';
import * as LocalStorage from '../../../helpers/LocalStorage';
import { UserAgreementName } from '../../../store_deprecated/types/userAgreementTypes';
import { agreementSignedSelector } from '../../../store_deprecated/selectors/agreementSelectors';
import Notification from '../BetaDashboard/Notification';
import { UserStatus } from '../../../constants/user';
import { toggleEmailUsPopup } from '../../../store_deprecated/actions/appActions';
import { getEmailUpVisibilityStatus } from '../../../store_deprecated/selectors/appSelectors';
import { findUserSignedAgreement } from '../../../store_deprecated/actions/userAgreementActions';
import CongratulationModal from './CongratulationModal';
import { round2Format } from '../../../utils/number';
import TutorialView from '../Tutorials/TutorialView';
import NotificationType from '../../../constants/NotificationType';
import { FetchProductItemsResponse, NewProduct, PortfoliotName } from '../../../store_deprecated/types/portfolioTypes';
import { productService, userPreferenceService } from '../../../api';
import useGetUserProducts from '../../../helpers/hooks/useGetUserProducts';
import { resetProductToInvest, setProductToInvest } from '../../../redux/features/products/selectedCardToInvest';
import SuccessAndFailurePaymentModals from '../../../shared/SuccessAndFailurePaymentModals';
import { setProducts } from '../../../redux/features/products/getProductsSlice';

const Loader = React.lazy(() => import('../../../shared/Loader'));
const Error = React.lazy(() => import('../../../shared/Error'));
const LOADER_DEBOUNCE_MS = 800;

const dashboardView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const isEnglish = i18n.language === 'en';
  const [recommendedPortfolio, setRecommendedPortfolio] = useState<any>(null);
  const [product, setProduct] = useState<any>(null);
  const [isProductAlreadySubscribed, setIsProductAlreadySubscribed] = useState<any>(false);
  const [currentSelectedProduct, setCurrentSelectedProduct] = useState<any>(null);
  const [depositConfig, setDepositConfig] = useState<DepositProps | null>(null);
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);
  const [openAddMoreFundDialog, setOpenAddMoreFundDialog] = useState(false);
  const [showSuccessfulScreen, setShowSuccessfulScreen] = useState(false);
  const [render, setRender] = useState(false);
  const [isNewInvest, setIsNewInvest] = useState(false);
  const [isNotCompleted, setIsNotCompleted] = useState(false);
  const [showUserWalletHasNewAmountNotification, setShowUserWalletHasNewAmountNotification] = useState(true);
  const [isNotification, setIsNotification] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  const [getIamAuthorizationUrl] = useLazyGetIamAuthorizationUrlQuery();
  const {
    data: subscribedProducts,
    isLoading: isFetchSubscribedProductsLoading,
    isError: isFetchSubscribedProductsFailed,
    refetch,
  } = useFetchSubscribedProductsQuery({});
  const { user } = useSelector(authSelector);
  const bankItem = useSelector(madkhoulBankInfoSelector);
  const availablePortfolios = useSelector(getAvailablePortfoliosSelector);

  const { data, isLoading: isFindProfileLoading, isError: isFindProfileFailed } = useFindProfileDataQuery();

  const showCongratulationModal = LocalStorage.getCongratulationModalAppearBefore();

  const [openCongratulationModal, setOpenCongratulationModal] = useState<boolean>(false);
  const [availablePortfoliosnew, setAllAvailablePortfoliosNew] = useState<any>();

  const { latest: latestStatistics } = useSelector(getPortfolioStatisticsSelector);
  const rawRecommendedPortfolio = useSelector(getRecommendedPortfolioSelector);

  const subscribeToPortfolio = useSelector(getSubscribeToPortfolioSelector);

  const { userPreference: productTypePreference, fetchStatus: userPreferenceFetchStatus } = useSelector(
    (state: ReduxState) => userPreferenceByTypeSelector(state, UserPreferenceTypeEnum.PRODUCT_TYPE),
  );

  const { userPreference: savedRecommendedPortfolio } = useSelector((state: ReduxState) =>
    userPreferenceByTypeSelector(state, UserPreferenceTypeEnum.RECOMMENDED_PORTFOLIO),
  );

  const recommendedPortfolios = useSelector(userPreferencePortfoliosSelector);

  const surveyResult = useSelector(getSurveyResult);
  const surveyPassed = Boolean(surveyResult?.category?.id);
  const signAccountOpeningAgreementDate = useSelector((state: ReduxState) =>
    agreementSignedSelector(state, UserAgreementName.ACCOUNT_OPENING_AGREEMENT),
  );

  const lastUpdated = moment(latestStatistics?.updatedAt || user?.updatedAt)
    .locale(i18n.language)
    .format('hh:mm A - MMM Do YYYY');

  const completePercent =
    [data?.id, signAccountOpeningAgreementDate && data?.confirmDate, surveyPassed].filter(Boolean).length * 25 + 25;

  const profileInReview =
    user?.status?.name && [UserStatus.PENDING, UserStatus.MANUAL_CHECK_REQUIRED].includes(user.status.name);

  const profileInactive = user?.status?.name === UserStatus.INACTIVE;

  const isJustActivated = user?.status?.name === UserStatus.ACTIVE && bankItem && !bankItem?.userBankDetails?.amount;

  const { userPreference } = useSelector((state: ReduxState) =>
    userPreferenceByTypeSelector(state, UserPreferenceTypeEnum.AUTO_INVEST),
  );

  const isReadyToStartInvesting =
    user?.status?.name === UserStatus.ACTIVE &&
    bankItem &&
    bankItem?.userBankDetails?.amount > 0 &&
    showUserWalletHasNewAmountNotification;

  // ==========================================================
  const isEmailUpVisible = useSelector(getEmailUpVisibilityStatus);
  const contactUs = () => {
    if (!isEmailUpVisible) dispatch(toggleEmailUsPopup());
  };

  const handleLoadingDebounce = debounce((flag: boolean) => {
    setIsNotification(flag);
  }, LOADER_DEBOUNCE_MS);

  useEffect(() => {
    if (!isFindProfileLoading) {
      setIsNotCompleted(!data?.confirmDate || !signAccountOpeningAgreementDate || !surveyPassed);
    }
  }, [data, signAccountOpeningAgreementDate, surveyPassed, isFindProfileLoading]);

  useEffect(() => {
    if (product?.id) {
      productService.fetchProductPortfolio(product?.id).then(({ data }: { data: FetchProductItemsResponse }) => {
        setAllAvailablePortfoliosNew(data?.items);
      });
    }
  }, [product]);

  useEffect(() => {
    dispatch(fetchBankInfo());
    dispatch(findUserSignedAgreement(UserAgreementName.ACCOUNT_OPENING_AGREEMENT));
    dispatch(findPortfolioLatestStatistics());
    if (!surveyResult?.category?.id) {
      dispatch(fetchLastSurveyResult());
    }
    const fetchInitialData = async () => {
      try {
        await dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.PRODUCT_TYPE));
        await dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.RECOMMENDED_PORTFOLIO));
        await dispatch(fetchRecommendedPortfolios());
        await dispatch(fetchAvailablePortfolios());
        const anbMastercardConfig = await dispatch(fetchAnbMasterardConfigAction());

        if (anbMastercardConfig?.url && anbMastercardConfig?.version) {
          setDepositConfig({
            anbMastercardCheckoutBaseUrl: anbMastercardConfig.url,
            anbMastercardCheckoutVersion: anbMastercardConfig.version,
            // openCongratulationModal,
          });
        }
      } catch (e) {
        console.log('Some error');
      }
    };

    fetchInitialData();

    return () => {
      dispatch(clearWallet());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userPreferenceFetchStatus === fetchStatuses.pending) {
      return;
    }

    if (!productTypePreference && !fetchStatuses.success) {
      dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.PRODUCT_TYPE));
      // dispatch(fetchRecommendedPortfolios());
    }
  }, [productTypePreference, userPreferenceFetchStatus]);

  useEffect(() => {
    const isAnyLoading = !isFetchSubscribedProductsLoading && !isFindProfileLoading;
    handleLoadingDebounce(isAnyLoading);
    // Cleanup function to cancel any pending debounced function calls
    return () => {
      handleLoadingDebounce.cancel();
    };
  }, [isFetchSubscribedProductsLoading, isFindProfileLoading]);

  useEffect(() => {
    if (productTypePreference) {
      setProduct(JSON.parse(productTypePreference));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productTypePreference]);

  useEffect(() => {
    if (data && isJustActivated && (!subscribedProducts || !subscribedProducts?.items.length)) {
      setOpenCongratulationModal(!showCongratulationModal || showCongratulationModal !== data?.userId.toString());
    }
  }, [data]);

  useEffect(() => {
    if (product && !isNewInvest) {
      const fetchRecommendedPortfolio = async (productTypeId: number, category?: string) => {
        await dispatch(findRecommendedPortfolioByProduct({ productTypeId, category }));
      };
      const category =
        product?.scoreDependent && surveyResult?.category?.id ? surveyResult?.category?.nameEn : undefined;

      fetchRecommendedPortfolio(product.id, category);
    }
  }, [surveyResult?.category, product]);

  useEffect(() => {
    if (isNewInvest) return;
    let portfolio;

    if (product && product?.isCustom) {
      portfolio = { ...rawRecommendedPortfolio, associatedIndexFunds: product.indexFunds };
    } else portfolio = { ...rawRecommendedPortfolio };

    setRecommendedPortfolio(portfolio);
  }, [product, rawRecommendedPortfolio.id]);

  const products = useGetUserProducts();

  const firstSubscribedProductId = products.find((p) => p.isSubscribed);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (products) {
      dispatch(setProducts(products));
      setRender(true);
    }
  }, [products.length, user]);

  useEffect(() => {
    if (isProductAlreadySubscribed) {
      setTimeout(() => {
        setIsProductAlreadySubscribed(false);
      }, 3000);
    }
  }, [isProductAlreadySubscribed]);

  const onDeleteProduct = async (productId: number) => {
    await dispatch(deleteUserPreference(UserPreferenceTypeEnum.PRODUCT_TYPE));
    await dispatch(deleteRecommendedPortfolio(productId));
    // dispatch(cleanUserPreferences());
    LocalStorage.clearPreselectedProduct(user!.id);
    setRecommendedPortfolio(null);
  };

  const onCloseSubscribeDialog = async (fundAmount?: number) => {
    if (fundAmount) {
      const result = (await dispatch(
        subscribedToPortfolio({
          associatedIndexFunds: currentSelectedProduct?.associatedIndexFunds,
          portfolioId: currentSelectedProduct?.id,
          fundAmount,
        }),
      )) as any;

      if (result) {
        setShowSuccessfulScreen(true);
        LocalStorage.clearPreselectedProduct(user!.id);
      }
    } else {
      setOpenSubscribeDialog(false);
      dispatch(resetSubscribedToPortfolio());
    }
  };

  const closeDialog = (success?: boolean) => {
    dispatch(resetSubscribedToPortfolio());

    if (success) {
      setShowSuccessfulScreen(true);
    } else {
      setOpenSubscribeDialog(false);
      setOpenAddMoreFundDialog(false);
    }
  };

  const onSuccessButtonClick = (id?: number) => {
    if (openSubscribeDialog) {
      setIsNewInvest(true);
      dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.PRODUCT_TYPE));
      dispatch(fetchRecommendedPortfolios());
      if (id) dispatch(deleteRecommendedPortfolio(id));
    }
    setOpenSubscribeDialog(false);
    setOpenAddMoreFundDialog(false);
    setShowSuccessfulScreen(false);
    closeDialog();
    refetch();
  };

  if (
    isFetchSubscribedProductsLoading &&
    !isFetchSubscribedProductsFailed &&
    isFindProfileLoading &&
    !isFindProfileFailed &&
    !user &&
    !surveyResult
  ) {
    return <Loader />;
  }

  if (
    !isFetchSubscribedProductsLoading &&
    isFetchSubscribedProductsFailed &&
    !isFindProfileLoading &&
    isFindProfileFailed
  ) {
    return <Error />;
  }

  const onClickCompleteProfile = async () => {
    if (!data?.id) {
      const { url } = await getIamAuthorizationUrl('en' || i18n.language).unwrap();

      if (url) {
        window.open(url, '_self');
        return;
      }

      return alert("Nafaz is disabled! Please update the data data in admin and change the user's status");
    }

    if (data?.id && !data?.confirmDate) {
      return navigate(locations.nafazData(), {
        state: {
          from: `${locations.riskSurvey()}?page=recommendedPortfolios`,
        },
      });
    }

    if (!surveyPassed) {
      return navigate(locations.survey());
    }

    if (!signAccountOpeningAgreementDate) {
      return navigate(locations.docs(), {
        state: { step: 1 },
      });
    }

    return navigate(locations.dashboard());
  };

  const showWalletHasNewAmountNotification = () => {
    setShowUserWalletHasNewAmountNotification(true);
    setTimeout(() => {
      setShowUserWalletHasNewAmountNotification(false);
    }, 5000);
  };

  const renderCustomNotificationButton = (onDeposit: () => void) => (
    <Box sx={{ mt: '-2px' }}>
      <MButton
        variant="contained"
        onClick={onDeposit}
        id="DepositButton"
        typographyProps={{ sx: { padding: '8px 24px', fontWeight: 600, fontSize: '16px' } }}
        buttonProps={{
          sx: {
            ml: 'auto',
            m: { xs: 'auto', md: 'unset !important' },
            borderRadius: '8px',
            order: { xs: 1, md: 2 },
            p: 0,
            backgroundColor: COLORS.SECONDARY_GREEN,
            backgroundImage: 'none',
            width: { xs: 'auto', md: 'auto' },
            '&:hover': {
              backgroundColor: COLORS.SECONDARY_GREEN,
              boxShadow: 'none',
            },
          },
        }}
      >
        {t('dashboard.startInvest')}
      </MButton>
    </Box>
  );

  const renderNotification = () => (
    <>
      {isNotCompleted && (
        <Notification
          showSlider
          fullWidth={false}
          percent={completePercent}
          onClick={onClickCompleteProfile}
          buttonText={t('dashboard.completeProfile')}
          text={
            <Typography variant="bodyMedium" sx={{ fontWeight: 600, color: COLORS.MAIN_DARK, whiteSpace: 'pre-line' }}>
              {t('dashboard.completeProfileNote')}
            </Typography>
          }
        />
      )}
      {profileInReview && (
        <Notification
          onClick={contactUs}
          fullWidth={false}
          buttonText={t('dashboard.contactUs')}
          status={NotificationType.INFO}
          text={
            <>
              <Typography
                variant="bodyMedium"
                sx={{ fontWeight: 600, color: COLORS.MAIN_DARK, whiteSpace: 'pre-line' }}
              >
                {t('dashboard.reviewProfileNote')}
              </Typography>
              <br />
              <Typography variant="bodyMedium" sx={{ color: COLORS.MAIN_DARK, whiteSpace: 'pre-line' }}>
                {`${t('dashboard.reviewProfileNote2')}. ${t('dashboard.reviewProfileNote3')}.`}
              </Typography>
            </>
          }
        />
      )}
      {profileInactive && (
        <Notification
          onClick={contactUs}
          fullWidth={false}
          buttonText={t('dashboard.contactUs')}
          text={
            <>
              <Typography
                variant="bodyMedium"
                sx={{ fontWeight: 600, color: COLORS.MAIN_DARK, whiteSpace: 'pre-line' }}
              >
                {t('dashboard.inactiveProfileNote')}
              </Typography>
              <br />
              <Typography variant="bodyMedium" sx={{ color: COLORS.MAIN_DARK, whiteSpace: 'pre-line' }}>
                {t('dashboard.inactiveProfileNote2')}
              </Typography>
            </>
          }
        />
      )}
      {isJustActivated && (!subscribedProducts || !subscribedProducts?.items.length) && (
        <Notification
          status={NotificationType.SUCCESS_WITH_BORDER}
          ctaButton={
            <Deposit
              showWalletHasNewAmountNotification={showWalletHasNewAmountNotification}
              renderCustomButton={renderCustomNotificationButton}
              {...depositConfig}
              page="dashboard"
            />
          }
          text={
            <>
              <Typography
                variant="bodyMedium"
                sx={{
                  fontSize: { xs: '12px', md: '18px' },
                  fontWeight: 600,
                  color: COLORS.MAIN_DARK,
                  whiteSpace: 'pre-line',
                }}
              >
                {t('dashboard.accountApprovedTitle')}
              </Typography>
              {/* <br />
              <Typography variant="bodyMedium" sx={{ fontSize: 14, color: COLORS.MAIN_DARK, whiteSpace: 'pre-line' }}>
                {t('dashboard.accountApprovedSubtitle')}
              </Typography> */}
            </>
          }
        />
      )}
    </>
  );

  const handleChangefund = (p: any) => {
    // if (product?.isCustom) {
    //   const item = savedRecommendedPortfolio ? JSON.parse(savedRecommendedPortfolio) : '';
    //   const initialIndexFunds = p?.indexFunds ? p.indexFunds : recommendedPortfolio.associatedIndexFunds;
    //   const updatedIndexFunds = initialIndexFunds?.map((fund: any) => {
    //     const associatedIndexFund = item?.associatedIndexFunds?.find((aif: any) => aif?.id === fund?.id);
    //     if (associatedIndexFund) {
    //       return {
    //         ...fund,
    //         weight: associatedIndexFund.weight,
    //       };
    //     }
    //     return fund;
    //   });
    //   setCurrentSelectedProduct({
    //     ...p,
    //     associatedIndexFunds: updatedIndexFunds,
    //   });
    // } else {
    //   setCurrentSelectedProduct(p);
    // }

    setCurrentSelectedProduct(p);

    dispatch(setProductToInvest({ backendProductId: p.backendProductId, toScreen: 'dashboard' }));
  };

  const handleAddProduct = async () => {
    await dispatch(deleteUserPreference(UserPreferenceTypeEnum.PRODUCT_TYPE));
    dispatch(cleanUserPreferences());
    navigate(locations.products(), {
      state: { type: 'list' },
    });
  };

  return (
    <Box
      sx={{ padding: { xs: '32px 24px', md: '32px 32px 0 32px' } }}
      maxWidth="100%"
      className={isMobile ? 'page-beta-dashboard-mobile' : ''}
    >
      <Grid container display="flex" flexDirection="column">
        <Grid item container display="flex" flexDirection="column">
          <Typography
            variant="h6"
            id="welcomeHeader"
            sx={{
              textTransform: 'capitalize',
              fontWeight: 400,
              fontSize: { xs: '16px', md: '20px' },
              lineHeight: '28px',
              color: COLORS.X_DARK_BLUE,
            }}
          >
            {t('dashboard.welcome')}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              fontWeight: 600,
              fontSize: { xs: '24px', md: '36px' },
              lineHeight: { xs: 'normal', md: '44px' },
              color: COLORS.MAIN_DARK,
            }}
          >
            {(isEnglish
              ? `${data?.firstNameEn} ${data?.familyNameEn}`
              : `${data?.firstNameAr} ${data?.familyNameAr}`) || user?.fullName}
          </Typography>
          <Typography
            variant="bodySmall"
            sx={{
              fontWeight: 400,
              fontSize: { xs: '14px', md: '14px' },
              lineHeight: '24px',
              color: COLORS.LIGHT_GRAY,
              margin: '5px 0 18px 0',
            }}
          >
            {t('dashboard.lastUpdated')}:&nbsp;{lastUpdated}
          </Typography>
        </Grid>
        {isNotification && renderNotification()}
        {subscribedProducts?.items && <Widgets subscribedProducts={subscribedProducts?.items} />}
        {isReadyToStartInvesting ? (
          <Notification
            status={NotificationType.SUCCESS}
            padding={{ xs: '12px 0', md: '32px 0' }}
            text={
              <Typography variant="bodyMedium" sx={{ color: COLORS.MAIN_DARK, whiteSpace: 'pre-line' }}>
                {t('dashboard.youHaveInYourWallet', {
                  amount: round2Format(Number(bankItem?.userBankDetails.amount) || 0, i18n.language),
                })}
              </Typography>
            }
          />
        ) : (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="nowrap"
            sx={{
              pt: '43px',
              width: '100%',
            }}
          />
        )}

        <Fade in={isProductAlreadySubscribed} unmountOnExit>
          <div>
            <Notification
              status={NotificationType.ERROR}
              padding={{ xs: '0 0 24px', md: '0 0 24px' }}
              text={
                <Typography variant="bodyMedium" sx={{ color: COLORS.MAIN_DARK, whiteSpace: 'pre-line' }}>
                  {tApiError('ALREADY_SUBSCRIBED_TO_PORTFOLIO')}
                </Typography>
              }
            />
          </div>
        </Fade>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 400,
              fontSize: { xs: '20px', md: '20px' },
              lineHeight: '44px',
              color: COLORS.BLACK,
            }}
          >
            {t('dashboard.newDashboard.myProducts')}
          </Typography>
          <MButton
            variant="outlined"
            onClick={handleAddProduct}
            typographyProps={{
              sx: {
                padding: '8px 24px',
                borderRadius: '80px',
                border: `1px solid ${COLORS.X_DARK_BLUE}`,
                color: COLORS.X_DARK_BLUE,
                fontSize: 16,
                fontWeight: 600,
              },
            }}
            buttonProps={{
              sx: {
                // p: 0,
                backgroundColor: COLORS.WHITE,
                backgroundImage: 'none',
                display: { xs: 'none', md: 'block' },
                height: '40px',
              },
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            {t('dashboard.newDashboard.addAnotherProducts')}
          </MButton>
        </Box>
        <Grid
          container
          columnSpacing={4}
          rowSpacing={4}
          sx={{ mt: { xs: 4, md: 0 }, ml: { xs: 0, md: -4 }, width: { xs: '100%', md: 'unset' } }}
        >
          {render ? (
            products?.map((p: any) => (
              <Grid
                item
                key={p.id || p.portfolioId}
                xs={12}
                md={6}
                sx={{ p: { xs: '0 0 16px 0 !important', md: '16px 0 0 16px !important' }, justifyContent: 'stretch' }}
              >
                <ProductCard
                  product={p}
                  onClickFund={() => {
                    handleChangefund(p);
                    if (p.isSubscribed) setOpenAddMoreFundDialog(true);
                    else setOpenSubscribeDialog(true);
                  }}
                  onDelete={() => onDeleteProduct(p.portfolioId || p.id)}
                  products={product}
                  firstSubscribedProductId={firstSubscribedProductId?.id?.toString()}
                />
              </Grid>
            ))
          ) : (
            <Loader />
          )}
        </Grid>
        {isMobile && (
          <Box component="div" className="add-additional-products-container">
            <MButton
              className="btn-add-additional-products"
              variant="contained"
              onClick={handleAddProduct}
              typographyProps={{
                sx: {
                  fontSize: 16,
                  fontWeight: 600,
                  textAlign: 'center',
                },
              }}
              buttonProps={{
                size: 'small',
              }}
            >
              <AddIcon sx={{ mr: 1 }} />
              {t('dashboard.newDashboard.addAnotherProducts')}
            </MButton>
          </Box>
        )}
      </Grid>
      <Box sx={{ mt: { xs: '32px', md: '64px' } }}>
        <Typography component="h1" variant="h5" sx={{ fontSize: 20, fontWeight: 400, mb: 4 }}>
          {t('tutorials.title')}
        </Typography>
        <TutorialView beta />
      </Box>
      <SubscribePortfolioDialog
        open={openSubscribeDialog}
        closeDialog={closeDialog}
        onClose={onCloseSubscribeDialog}
        onSuccessButtonClick={() => onSuccessButtonClick(currentSelectedProduct?.id)}
        minAmount={currentSelectedProduct?.productType?.minimumPortfolioInvestment}
        currentSelectedProduct={currentSelectedProduct}
      />
      <AddMoreFundDialog
        open={openAddMoreFundDialog}
        onSuccessButtonClick={onSuccessButtonClick}
        showSuccessfulScreen={showSuccessfulScreen}
        closeDialog={closeDialog}
        subscribedPortfolio={currentSelectedProduct}
        minAmount={currentSelectedProduct?.productType?.minimumAddFundInvestment}
      />

      <SuccessAndFailurePaymentModals />
      <CongratulationModal
        open={openCongratulationModal}
        handleClose={() => {
          setOpenCongratulationModal(false);
          if (data?.userId) LocalStorage.setCongratulationModalAppearBefore(data?.userId.toString());
        }}
        beneficiaryName={bankItem?.user?.fullName}
        accountNumber={bankItem?.userBankDetails?.accountNumber || ''}
        ibanNumber={bankItem?.iban || ''}
        depositConfig={depositConfig}
      />
    </Box>
  );
};

export default dashboardView;
