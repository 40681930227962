import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment-hijri';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import COLORS from '../../../constants/colors';
import { defineNafazUserType } from '../../../helpers/nafaz';
import { useFindProfileDataQuery } from '../../../redux/features/auth/auth.apiSlice';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { ReduxState, useTypedDispatch } from '../../../redux/store';
import { findUserSignedAgreement } from '../../../store_deprecated/actions/userAgreementActions';
import { findProfileData } from '../../../store_deprecated/actions/authActions';
import { agreementSignedSelector } from '../../../store_deprecated/selectors/agreementSelectors';
import { UserAgreementName } from '../../../store_deprecated/types/userAgreementTypes';
import Content from './Content';
import CustomerInfo from './CustomerInfo';
import './account-opening-agreement-view.css';

const AccountOpeningAgreementView: React.FC = () => {
  const { t, i18n } = useTranslation('account-opening-agreement', {
    useSuspense: true,
  });
  const dispatch = useTypedDispatch();
  const { user } = useSelector(authSelector);
  const [values, setValues] = useState({});
  const isEnglish = i18n.language === 'en';

  const { data } = useFindProfileDataQuery();

  const signAccountOpeningAgreementDate = useSelector((state: ReduxState) =>
    agreementSignedSelector(state, UserAgreementName.ACCOUNT_OPENING_AGREEMENT),
  );

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await Promise.all([
          dispatch(findProfileData()),
          dispatch(findUserSignedAgreement(UserAgreementName.ACCOUNT_OPENING_AGREEMENT)),
        ]);
      } catch (e) {
        console.log('Some error');
      }
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const signAgreementDate = moment(signAccountOpeningAgreementDate || Date.now());
    const signAgreementDay = signAgreementDate.locale(i18n.language).format('dddd');
    const signAgreementDateFormat = signAgreementDate.locale(i18n.language).format('DD/MM/YYYY');
    const signAgreementDateHijriFormat = signAgreementDate.locale(i18n.language).format('iDD/iMM/iYYYY');

    setValues({
      customerName: isEnglish ? data?.fullNameEn : data?.fullNameAr,
      customerNumber: user?.id,
      customerEmail: data?.email,
      customersInvestmentAccountNumber: user?.id,
      agreementNo: `${user?.id}`.padStart(10, '0'),
      firstName: isEnglish ? data?.firstNameEn : data?.firstNameAr,
      fatherName: isEnglish ? data?.fatherNameEn : data?.fatherNameAr,
      grandFatherName: isEnglish ? data?.grandFatherNameEn : data?.grandFatherNameAr,
      surname: isEnglish ? data?.familyNameEn : data?.familyNameAr,
      nationality: isEnglish ? data?.nationalityEn : data?.nationalityAr,
      sex: data?.gender?.name,
      occupation: data?.companyName,
      dateOfBirth: moment(data?.birthDate)
        .locale(i18n.language)
        .format('DD-MMM-YYYY'),
      typeOfIdCard: defineNafazUserType(data?.nationalId || ''),
      idCardNo: data?.nationalId,
      dateOfIssue: moment(data?.idIssueDate)
        .locale(i18n.language)
        .format('DD-MMM-YYYY'),
      expiryDate: moment(data?.idExpiryDate)
        .locale(i18n.language)
        .format('DD-MMM-YYYY'),
      emailAddress: data?.email,
      mobileNumber: data?.phone,
      address: data?.address || {},
      signAgreementDay,
      signAgreementDate: signAgreementDateFormat,
      signAgreementDateHijri: signAgreementDateHijriFormat,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, user, isEnglish]);

  return (
    <Container component="main" maxWidth="xl" sx={{ height: { xs: '65vh', md: '100vh' } }}>
      <Box style={{ position: 'absolute', right: 0, top: '40%' }}>
        <img src="/images/logo-right-cut.svg" alt="madkhol-frame" />
      </Box>
      <Grid
        container
        display="flex"
        sx={{
          height: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '5px !important',
            backgroundColor: 'transparent !important',
            borderRadius: '4px !important',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#111827 !important',
            borderRadius: '4px !important',
          },
          textAlign: 'left',
          pt: { xs: '24px', md: 32 },
          pb: { xs: '24px', md: 32 },
          pr: { xs: '24px', md: 34 },
          pl: { xs: '24px', md: 43 },
        }}
        className="account-opening-agreement-view"
      >
        <Grid item width="100%">
          <Typography
            textAlign="center"
            variant={isEnglish ? 'h1' : 'cairoHeader2'}
            color={COLORS.MAIN_DARK}
            sx={{ display: 'block', mb: 6 }}
          >
            {t('title')}
          </Typography>
        </Grid>
        <Grid item width="100%">
          <CustomerInfo data={values} />
        </Grid>
        <Grid item width="100%">
          <Content data={values} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccountOpeningAgreementView;
