import { Logout } from './authTypes';

export const GET_TRANSACTIONS_HISTORY_REQUEST = 'GET_TRANSACTIONS_HISTORY_REQUEST';
export const GET_TRANSACTIONS_HISTORY_SUCCESS = 'GET_TRANSACTIONS_HISTORY_SUCCESS';
export const GET_TRANSACTIONS_HISTORY_FAIL = 'GET_TRANSACTIONS_HISTORY_FAIL';
export const CLEAR_TRANSACTION = 'CLEAR_TRANSACTION';

export enum TransactionActivityEnum {
  CASH_RECEIVED = 'Cash Received',
  SUBSCRIPTION_TO_PORTFOLIO = 'Subscription to a Portfolio',
  RETURNED_AMOUNT = 'Returned Amount',
  CASH_TRANSFERRED = 'Cash Transferred',
}

export type TransactionsHistoryDataItemActivity = {
  id: number;
  activity: TransactionActivityEnum;
  descriptionEn: string;
  descriptionAr: string;
};

export type TransactionsHistoryDataItem = {
  id: number;
  amount: number;
  activity: TransactionsHistoryDataItemActivity;
  details: { indexFundId: number };
  createdAt: Date;
};

export type TransactionsHistoryData = {
  items: TransactionsHistoryDataItem[];
  totalItems: number;
};

export type TransactionsHistory = {
  data: TransactionsHistoryData;
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};

export type FindTransactionsHistoryPayload = PaginatedRequest;

export interface RequestTransactionsHistory {
  type: typeof GET_TRANSACTIONS_HISTORY_REQUEST;
}

export interface RequestTransactionsHistorySuccess {
  type: typeof GET_TRANSACTIONS_HISTORY_SUCCESS;
  payload: { result: TransactionsHistoryData; isLoadMore: boolean };
}

export interface RequestGetTransactionsHistoryFail {
  type: typeof GET_TRANSACTIONS_HISTORY_FAIL;
  payload: string;
}

export interface ClearTransactions {
  type: typeof CLEAR_TRANSACTION;
}

export type TransactionAction =
  | RequestTransactionsHistory
  | RequestTransactionsHistorySuccess
  | RequestGetTransactionsHistoryFail
  | Logout
  | ClearTransactions;

export type TransactionState = Readonly<{
  transactionsHistory: TransactionsHistory;
}>;
