import React from 'react';

import './RiskLevel.scss';
import Typography from '@mui/material/Typography';
import { Rating } from '@mui/material';
import { getRiskLevel } from '../../../../utils/riskLevel';

interface RiskLevelFilledProps {
  level: number;
}

const RiskLevelEmpty = () => <div className="risk-level-empty" />;
const RiskLevelFilled: React.FC<RiskLevelFilledProps> = ({ level }) => (
  <div
    className={`risk-level-filled  ${level === 3 && 'risk-level-filled-moderate'} ${
      level > 3 && 'risk-level-filled-high'
    }`}
  />
);

interface RiskLevelProps {
  maxScore: number;
  onlyRating?: boolean;
}

const RiskLevel: React.FC<RiskLevelProps> = ({ maxScore, onlyRating = false }) => {
  const level = getRiskLevel(maxScore);

  return (
    <div className="risk-level">
      {!onlyRating && <Typography className="title">Risk profile</Typography>}
      <Rating value={level} readOnly emptyIcon={<RiskLevelEmpty />} icon={<RiskLevelFilled level={level} />} />
    </div>
  );
};

export default RiskLevel;
