import React, { useEffect, useImperativeHandle, useState, useRef, useCallback } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import debounce from 'lodash.debounce';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { Alert, Snackbar } from '@mui/material';
import COLORS from '../constants/colors';
import * as LocalStorage from '../helpers/LocalStorage';
import { authSelector } from '../redux/features/auth/auth.selectors';
import { locations } from '../routes/locations';
import {
  // ReduxState,
  useTypedDispatch,
} from '../redux/store';
import { findUserSignedAgreement } from '../store_deprecated/actions/userAgreementActions';
import {
  agreementSelector,
  // agreementSignedSelector,
} from '../store_deprecated/selectors/agreementSelectors';
import { UserAgreementName } from '../store_deprecated/types/userAgreementTypes';
import { IndexFund } from '../store_deprecated/types/indexFundTypes';
import {
  PortfolioAssociatedIndexFund,
  RecommendedPortfolio,
  SubscribedPortfolio,
  SaveUserPrefrancePortfolio,
} from '../store_deprecated/types/portfolioTypes';
import { getSaveChartColor } from '../utils/charts';
import { formatNumber, round4 } from '../utils/number';
import ChangeAllocationAgreementDialog from './ChangeAllocationAgreementDialog';
import { BorderLinearProgress, StyledTableCell } from './CustomTable';
import { saveUserPreference } from '../redux/modules/userPreference/userPreference.actions';
import { UserPreferenceTypeEnum } from '../types/userPreference';

type RowDataItem = {
  indexFund: IndexFund;
  id: number;
  allocation: number;
  range: { value: number; color: string };
};

function createData(
  indexFund: IndexFund,
  id: number,
  allocation: number,
  range: { value: number; color: string },
): RowDataItem {
  return { indexFund, id, allocation, range };
}

interface TargetAllocationTableProps {
  allowEdit?: boolean;
  portfolio?: SubscribedPortfolio | RecommendedPortfolio;
  onChangeWeights?: (items: { id: number; weight: number }[]) => void;
  cashAllocationPercentage: number;
  savedRecommendedPortfolioData?: SaveUserPrefrancePortfolio;
  customs?: boolean;
}

export interface TargetAllocationTableRef {
  resetWeights: () => void;
  getTotalAllocations: () => number;
}

const ALLOCATION_CHANGE_STEP = 1;

const PortfolioTargetAllocationTable = React.forwardRef<TargetAllocationTableRef, TargetAllocationTableProps>(
  (props, ref) => {
    const {
      allowEdit = false,
      portfolio,
      onChangeWeights,
      cashAllocationPercentage,
      savedRecommendedPortfolioData,
      customs,
    } = props;
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useTypedDispatch();
    const { user } = useSelector(authSelector);
    const [rows, setRows] = useState<RowDataItem[]>([]);
    const [isNormalized, setIsNormalized] = useState(false);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const [currentType, setCurrentType] = useState<'increment' | 'decrement' | null>(null);
    const [openAllocationAgreementDialog, setOpenAllocationAgreementDialog] = useState(false);
    const [successWeightSnackbar, setSuccessWeightSnackbar] = useState(false);
    // const signAllocationAgreementDate = useSelector((state: ReduxState) =>
    //   agreementSignedSelector(
    //     state,
    //     UserAgreementName.CHANGE_ALLOCATION_AGREEMENT
    //   )
    // );

    const { isLoading } = useSelector(agreementSelector);
    const isEnglish = i18n.language === 'en';

    //* *********** Touch Logic ******************** */
    const _intervalRef = useRef<NodeJS.Timer | null>(null);
    const isSwiping = useRef(false);

    const swipeHandlers = useSwipeable({
      onSwipeStart: () => {
        isSwiping.current = true;
      },
      onSwiped: () => {
        isSwiping.current = false;
      },
      delta: 10, // min distance(px) before a swipe starts. *See Notes*
      preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
      trackTouch: true, // track touch input
      trackMouse: false, // track mouse input
      rotationAngle: 0, // set a rotation angle
      swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
      touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
    });

    const calculateNewRows = (
      selectedPortfolio: SubscribedPortfolio | RecommendedPortfolio,
      currentRows: RowDataItem[] = [],
      weights: Pick<PortfolioAssociatedIndexFund, 'id' | 'weight'>[] = [],
    ) => {
      const categories = selectedPortfolio?.associatedIndexFunds?.reduce(
        (res, item) => {
          const { asset } = item.indexFund;

          if (!asset) {
            return res;
          }

          if (res[asset.id]) {
            res[asset.id].weight = round4(res[asset.id].weight + +item.weight);
          } else {
            res[asset.id] = {
              name: isEnglish ? asset.nameEn : asset.nameAr,
              weight: round4(+item.weight),
            };
          }

          return res;
        },
        {} as Record<number, { name: string; weight: number }>,
      );

      return (
        selectedPortfolio?.associatedIndexFunds?.map((item) => {
          // @ts-ignore
          const items = Object.values(categories);
          items.sort((a: any, b: any) => b.weight - a.weight);
          items.push({ name: 'cash', weight: 0 });
          // @ts-ignore
          const targetIndex = items.findIndex(
            (cat) => cat.name === item.indexFund.asset[isEnglish ? 'nameEn' : 'nameAr'],
          );

          const savedWeight = weights.find((w) => w?.id === item.id)?.weight;
          const currentRow = currentRows.find((row) => row.id === item.id);

          return createData(item.indexFund, item.id, currentRow?.allocation ?? savedWeight ?? item.weight, {
            value: currentRow?.allocation ?? savedWeight ?? item.weight,
            color: getSaveChartColor(
              // @ts-ignore
              items.length,
              targetIndex,
            ),
          });
        }) || []
      );
    };

    useEffect(() => {
      const fetchInitialData = async () => {
        try {
          await Promise.all([dispatch(findUserSignedAgreement(UserAgreementName.CHANGE_ALLOCATION_AGREEMENT))]);
        } catch (e) {
          console.log('Some error');
        }
      };

      if (allowEdit) {
        fetchInitialData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowEdit]);

    useEffect(() => {
      if (portfolio && user?.id) {
        const newRows = calculateNewRows(portfolio, [], savedRecommendedPortfolioData?.associatedIndexFunds || []);

        setRows(newRows);

        setIsNormalized(!newRows.find((item) => item.allocation % 1 !== 0));

        if (onChangeWeights) {
          onChangeWeights(newRows.map((item) => ({ id: item.id, weight: item.allocation })));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolio, isEnglish]);

    const normalizeAllocations = (rowDataItems: RowDataItem[]) =>
      rowDataItems.map((item) => {
        item.allocation = Math.round(item.allocation);
        item.range.value = Math.round(item.allocation);

        return item;
      });

    const handleClickWithSwipecheck = useCallback(
      debounce((newRows, sum, portfolioId, custom) => {
        if (sum === 100) {
          const updateWeights = newRows.map((item: any) => ({
            id: item.id,
            weight: item.allocation,
          }));

          dispatch(
            saveUserPreference({
              type: UserPreferenceTypeEnum.RECOMMENDED_PORTFOLIO,
              value: JSON.stringify({
                custom,
                portfolioId,
                associatedIndexFunds: updateWeights,
              }),
            }),
          );
          setSuccessWeightSnackbar(true);
        }
      }, 2000),
      [],
    );

    const onChange = (index: number, type: 'increment' | 'decrement') => {
      // if (!signAllocationAgreementDate) {
      //   return setOpenAllocationAgreementDialog(true);
      // }

      const prevSum = rows.reduce((res, item) => res + item.allocation, cashAllocationPercentage);

      if (type === 'increment' && prevSum > 99) return;

      let newRows = [...rows];

      if (!isNormalized) {
        newRows = normalizeAllocations(newRows);
      }

      const dx = type === 'increment' ? 1 : -1;
      const step = dx * ALLOCATION_CHANGE_STEP;

      if (newRows[index].allocation + step < 0) {
        return;
      }

      newRows[index].allocation += step;
      newRows[index].range.value += step;

      const sum = newRows.reduce((res, item) => res + item.allocation, cashAllocationPercentage);
      if (sum > 100) return;

      setRows(newRows);
      setIsNormalized(true);

      if (portfolio?.id && user?.id) {
        LocalStorage.setRecommendedPortfolioAllocations(
          user.id,
          portfolio.id,
          newRows.map((item) => ({
            id: item.id,
            weight: item.allocation,
          })),
        );
        handleClickWithSwipecheck(newRows, sum, portfolio.id, customs);
      }

      if (onChangeWeights) {
        onChangeWeights(newRows.map((item) => ({ id: item.id, weight: item.allocation })));
      }
    };

    const getTotalAllocations = () =>
      round4(rows.reduce((res, item) => res + item.allocation, cashAllocationPercentage));

    const onReset = () => {
      if (portfolio) {
        const newRows = calculateNewRows(portfolio, []);
        setRows(newRows);
        setIsNormalized(false);

        const updateWeights = newRows.map((item: any) => ({
          id: item.id,
          weight: item.allocation,
        }));

        if (onChangeWeights) {
          onChangeWeights(newRows.map((item) => ({ id: item.id, weight: item.allocation })));
        }

        if (user?.id) {
          LocalStorage.removeRecommendedPortfolioAllocations(user.id, portfolio.id);
        }
        dispatch(
          saveUserPreference({
            type: UserPreferenceTypeEnum.RECOMMENDED_PORTFOLIO,
            value: JSON.stringify({
              portfolioId: portfolio?.id,
              associatedIndexFunds: updateWeights,
            }),
          }),
        );
        setSuccessWeightSnackbar(true);
      }
    };

    useImperativeHandle(ref, () => ({
      resetWeights: () => onReset(),
      getTotalAllocations: () => getTotalAllocations(),
    }));

    const goToFundDetails = (id: number) => {
      navigate(locations.fundDetails(id), {
        state: { from: pathname },
      });
    };

    const closeAllocationAgreementDialog = () => {
      setOpenAllocationAgreementDialog(false);
    };

    const [longPress, setLongPress] = useState(false);
    useEffect(() => {
      if (longPress) {
        _intervalRef.current = setInterval(() => {
          if (currentIndex !== null && currentType !== null) onChange(currentIndex, currentType);
        }, 500);
      } else {
        // @ts-ignore
        clearInterval(_intervalRef.current);
      }

      return () => {
        // @ts-ignore
        clearInterval(_intervalRef.current);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [longPress]);
    //* ****************************************** */

    const handleClickWithSwipe = (index: number, type: 'increment' | 'decrement') => {
      setTimeout(() => {
        if (isSwiping.current) return;
        onChange(index, type);
      }, 300);
    };

    const handleLongPressWithSwipe = (isLongPress: boolean) => {
      setTimeout(() => {
        if (isSwiping.current) return;
        setLongPress(isLongPress);
      }, 300);
    };

    return (
      <Box {...swipeHandlers}>
        <TableContainer component={Paper} elevation={1} sx={{ minWidth: { xs: 'unset', md: 650 } }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{
                    minWidth: { xs: '200px', md: 'auto' },
                    width: { xs: 'max-content', md: 'auto' },
                  }}
                >
                  {t('fundStrategy')}
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    minWidth: { xs: allowEdit ? '170px' : '100px', md: 'auto' },
                  }}
                >
                  {t('allocation')} (
                  <span
                    style={{
                      color: getTotalAllocations() < 100 ? COLORS.RED : 'unset',
                    }}
                  >
                    {getTotalAllocations()}%
                  </span>
                  )
                </StyledTableCell>
                <StyledTableCell sx={{ minWidth: { xs: '170px', md: 'auto' } }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow key={`portfolio-allocation-table-asset-${row.id}-${row.indexFund.asset.id}`}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{
                      color: COLORS.PRIMARY_BLUE,
                      fontWeight: 600,
                      fontSize: { xs: '12px', md: '14px' },
                      lineHeight: '20px',
                      width: { xs: '35%', md: '25%' },
                    }}
                  >
                    <Link underline="none" onClick={() => goToFundDetails(row.indexFund.id)} sx={{ cursor: 'pointer' }}>
                      {isEnglish ? row.indexFund.nameEn : row.indexFund.nameAr}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      color: COLORS.MAIN_DARK,
                      fontWeight: 500,
                      fontSize: { xs: '12px', md: '14px' },
                      lineHeight: '20px',
                      width: { xs: '40%', md: '25%' },
                    }}
                  >
                    <IconButton
                      size="small"
                      sx={{
                        width: '16px',
                        height: '16px',
                        display: allowEdit && !isLoading ? 'inline-flex' : 'none',
                      }}
                      onClick={() => handleClickWithSwipe(rowIndex, 'decrement')}
                      onPointerDown={() => handleLongPressWithSwipe(true)}
                      onPointerUp={() => handleLongPressWithSwipe(false)}
                      onPointerEnter={() => {
                        setCurrentIndex(rowIndex);
                        setCurrentType('decrement');
                      }}
                      onPointerLeave={() => {
                        setCurrentIndex(null);
                        setCurrentType(null);
                      }}
                    >
                      <RemoveCircleOutlineOutlinedIcon sx={{ color: COLORS.SECONDARY_GREEN }} />
                    </IconButton>
                    <Box
                      component="span"
                      sx={{
                        margin: { xs: '0 6px', md: '0 13px' },
                        width: { xs: '50%', md: '20%' },
                        display: 'inline-block',
                        textAlign: 'center',
                      }}
                    >
                      {formatNumber(row.allocation, i18n.language, 2)}%
                    </Box>
                    <IconButton
                      sx={{
                        width: '16px',
                        height: '16px',
                        display: allowEdit && !isLoading ? 'inline-flex' : 'none',
                      }}
                      onClick={() => handleClickWithSwipe(rowIndex, 'increment')}
                      onPointerDown={() => handleLongPressWithSwipe(true)}
                      onPointerUp={() => handleLongPressWithSwipe(false)}
                      onPointerEnter={() => {
                        setCurrentIndex(rowIndex);
                        setCurrentType('increment');
                      }}
                      onPointerLeave={() => {
                        setCurrentIndex(null);
                        setCurrentType(null);
                      }}
                    >
                      <AddCircleOutlineOutlinedIcon sx={{ color: COLORS.SECONDARY_GREEN }} />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      color: COLORS.MAIN_DARK,
                      fontWeight: 500,
                      fontSize: { xs: '12px', md: '14px' },
                      padding: { xs: '4px !important', md: '8px' },
                      lineHeight: '20px',
                    }}
                  >
                    <BorderLinearProgress
                      variant="determinate"
                      value={row.range.value}
                      sx={{
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: row.range.color,
                        },
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid
          item
          container
          flexDirection="column"
          sx={{
            pt: { xs: '32px !important', md: '64px !important' },
            width: '100%',
          }}
        >
          <Typography
            variant="bodyLarge"
            sx={{
              color: COLORS.DARK_GREY,
              fontWeight: 400,
              fontSize: { xs: '14px', md: '16px' },
              lineHeight: '28px',
            }}
          >
            {t('cashAllocationIs', {
              val: formatNumber(cashAllocationPercentage, i18n.language, 2),
            })}
          </Typography>
          <Fade in={getTotalAllocations() !== 100}>
            <Typography
              variant="bodyLarge"
              sx={{
                color: COLORS.RED,
                fontWeight: 400,
                fontSize: { xs: '14px', md: '16px' },
                lineHeight: '28px',
              }}
            >
              {t('weightAllocationValidationMessage')}
            </Typography>
          </Fade>
        </Grid>

        <ChangeAllocationAgreementDialog
          open={openAllocationAgreementDialog}
          onClose={closeAllocationAgreementDialog}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={successWeightSnackbar}
          autoHideDuration={2000}
          sx={{
            maxWidth: 'unset',
            minWidth: 'unset',
            width: 'fit-content',
            m: 'auto',
            '& .MuiPaper-root': {
              maxWidth: 'unset',
              minWidth: 'unset',
              width: 'fit-content',
              m: 'auto',
            },
          }}
          onClose={() => setSuccessWeightSnackbar(false)}
        >
          <Alert
            severity="success"
            variant="outlined"
            sx={{ bgcolor: COLORS.PRIMARY_LIGHT_GREEN, width: '100%', position: 'relative', zIndex: '9999' }}
            onClose={() => setSuccessWeightSnackbar(false)}
          >
            {t('weightChangeSuccessFullMessage')}
          </Alert>
        </Snackbar>
      </Box>
    );
  },
);

export default PortfolioTargetAllocationTable;
