import * as yup from 'yup';
import { nafazDataFormFields } from '../fields/nafazData';

export const nafazDataFormSchema = yup.object({
  [nafazDataFormFields.maritalStatus]: yup.string().trim().required('missingField'),
  [nafazDataFormFields.educationLevel]: yup.string().trim().required('missingField'),
  [nafazDataFormFields.companyName]: yup.string().trim().required('missingField'),
  [nafazDataFormFields.employerAddress]: yup.string().trim().required('missingField'),
  [nafazDataFormFields.fiveYearsFinancialSector]: yup.boolean().required('missingField'),
  [nafazDataFormFields.fiveYearsFinancialSectorDescription]: yup
    .string()
    .trim()
    .when(nafazDataFormFields.fiveYearsFinancialSector, {
      is: true,
      then: (schema) => schema.required('missingField'),
    }),
  [nafazDataFormFields.boardDirectorsMember]: yup.boolean().required('missingField'),
  [nafazDataFormFields.boardDirectorsMemberDescription]: yup
    .string()
    .trim()
    .when(nafazDataFormFields.boardDirectorsMember, {
      is: true,
      then: (schema) => schema.required('missingField'),
    }),
  [nafazDataFormFields.individualWithHighProfilePoliticalRole]: yup.boolean().required('missingField'),
  [nafazDataFormFields.individualWithHighProfilePoliticalRoleDescription]: yup
    .string()
    .trim()
    .when(nafazDataFormFields.individualWithHighProfilePoliticalRole, {
      is: true,
      then: (schema) => schema.required('missingField'),
    }),
  [nafazDataFormFields.notBeneficialOwner]: yup.boolean().required('missingField'),
  [nafazDataFormFields.notBeneficialOwnerDescription]: yup
    .string()
    .trim()
    .when(nafazDataFormFields.notBeneficialOwner, {
      is: true,
      then: (schema) => schema.required('missingField'),
    }),
});
