import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as sentryBrowser from '@sentry/browser';
import jwtDecode from 'jwt-decode';
import { analytics } from '../../../helpers/analytics';
import * as LocalStorage from '../../../helpers/LocalStorage';
import { LoginResponse } from '../../../types/auth';
import { User } from '../../../types/user';
import { cleanAuth } from './auth.actions';
import { AuthState } from './auth.types';

const initialState: AuthState = {
  user: null,
  accessToken: '',
  isLoggedIn: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<LoginResponse>) => {
      const { accessToken } = action.payload;

      if (accessToken) {
        LocalStorage.setAccessToken(accessToken);

        state.isLoggedIn = null;
        state.accessToken = accessToken;

        state.user = jwtDecode<User>(accessToken);
      }
    },
    setUserProfile: (state, action: PayloadAction<User>) => {
      sentryBrowser.setUser({
        id: `${action.payload.id}`,
        username: action.payload.fullName,
        email: action.payload.email,
      });
      analytics.alias(action.payload.id);
      analytics.identify(`${action.payload.id}`, {
        email: action.payload.email,
        phone: action.payload.mobile,
        name: action.payload.fullName,
      });

      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logOut: (state) => {
      state.user = null;
      state.isLoggedIn = false;
      state.accessToken = '';

      LocalStorage.removeAccessToken();
      analytics.reset();
      sentryBrowser.configureScope((scope) => scope.setUser({}));
    },
    // Define the resetAuth action
    resetAuth: (state) => {
      Object.assign(state, initialState);

      LocalStorage.removeAccessToken();
      analytics.reset();
      sentryBrowser.configureScope((scope) => scope.setUser({}));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cleanAuth.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setCredentials, logOut, setUserProfile, resetAuth } = authSlice.actions;

export const authReducer = authSlice.reducer;
