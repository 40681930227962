import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import RepeatIcon from '@mui/icons-material/Repeat';
import COLORS from '../../../constants/colors';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { useGetAppConfigQuery } from '../../../redux/features/config/config.apiSlice';
import { useTypedDispatch } from '../../../redux/store';
import { DocumentTypes } from '../../../store_deprecated/types/docsTypes';
import { getDocsDataSelector } from '../../../store_deprecated/selectors/docsSelectors';
import { fetchUploadedDocumentURL } from '../../../store_deprecated/actions/docsActions';
import { locations } from '../../../routes/locations';
import { UserStatus } from '../../../constants/user';
import { ConfigKey } from '../../../types/config';

const AttachmentsSection = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(authSelector);
  const docsDataState = useSelector(getDocsDataSelector);
  const { data: appConfig } = useGetAppConfigQuery(ConfigKey.UPLOAD_FLOW_ENABLED);
  const uploadFlowEnabled = JSON.parse(appConfig?.value || 'true');
  const [fileLoading, setFileLoading] = useState<Record<string, boolean>>({});
  const isUserActive = user?.status?.name === UserStatus.ACTIVE;

  const selfieDoc = docsDataState?.data?.find((a) => a.documentType.type === DocumentTypes.SELFIE);
  const idDoc = docsDataState?.data?.find((a) => a.documentType.type === DocumentTypes.VERIFICATION_DOCUMENT);

  const openFile = async (file: string) => {
    setFileLoading((prev) => ({ ...prev, [file]: true }));
    try {
      const url: string = await dispatch(fetchUploadedDocumentURL(file));

      if (url) {
        window.open(url, '_blank');
      }
    } catch (e: any) {
      console.error('Open File error', e);
    }
    setFileLoading((prev) => ({ ...prev, [file]: false }));
  };

  const onReplace = () => navigate(locations.docs());

  return (
    <>
      <Grid item sx={{ mt: '48px' }}>
        <Typography
          variant="h5"
          sx={{
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '24px',
            lineHeight: '32px',
            color: COLORS.MAIN_DARK,
          }}
        >
          {t('userProfile.attachments')}
        </Typography>
      </Grid>

      <Grid
        item
        display={uploadFlowEnabled ? 'flex' : 'none'}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: '32px', p: '24px', borderRadius: '12px' }}
        component={Paper}
        elevation={0}
      >
        <Typography variant="bodyMediumB" sx={{ color: COLORS.MAIN_DARK }}>
          {t('userProfile.nationalId')}
        </Typography>
        <Grid display="flex" flexDirection="row" flexWrap="nowrap">
          {Boolean(idDoc) && (
            <Button
              variant="text"
              disabled={fileLoading[idDoc!.documentLink]}
              onClick={() => openFile(idDoc!.documentLink)}
              id="ViewNationalIdButton"
              startIcon={<VisibilityOutlinedIcon />}
              sx={{ textTransform: 'capitalize', color: COLORS.PRIMARY_BLUE }}
            >
              <Typography
                variant="bodySmall"
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '24px',
                }}
              >
                {t('view')}
              </Typography>
            </Button>
          )}

          {!isUserActive && (
            <Button
              variant="text"
              onClick={onReplace}
              id="ReplaceNationalIdButton"
              startIcon={<RepeatIcon />}
              sx={{
                ml: '8px',
                textTransform: 'capitalize',
                color: COLORS.LIGHT_GRAY,
              }}
            >
              <Typography
                variant="bodySmall"
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '24px',
                }}
              >
                {t('replace')}
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        display={uploadFlowEnabled ? 'flex' : 'none'}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: '32px', p: '24px', borderRadius: '12px' }}
        component={Paper}
        elevation={0}
      >
        <Typography variant="bodyMediumB" sx={{ color: COLORS.MAIN_DARK }}>
          {t('userProfile.selfiePhoto')}
        </Typography>
        <Grid display="flex" flexDirection="row" flexWrap="nowrap">
          {Boolean(selfieDoc) && (
            <Button
              variant="text"
              disabled={fileLoading[selfieDoc!.documentLink]}
              onClick={() => openFile(selfieDoc!.documentLink)}
              id="ViewSelfiePhotoButton"
              startIcon={<VisibilityOutlinedIcon />}
              sx={{ textTransform: 'capitalize', color: COLORS.PRIMARY_BLUE }}
            >
              <Typography
                variant="bodySmall"
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '24px',
                }}
              >
                {t('view')}
              </Typography>
            </Button>
          )}

          {!isUserActive && (
            <Button
              variant="text"
              onClick={onReplace}
              id="ReplaceSelfiePhotoButton"
              startIcon={<RepeatIcon />}
              sx={{
                ml: '8px',
                textTransform: 'capitalize',
                color: COLORS.LIGHT_GRAY,
              }}
            >
              <Typography
                variant="bodySmall"
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '24px',
                }}
              >
                {t('replace')}
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: '32px', p: '24px', borderRadius: '12px' }}
        component={Paper}
        elevation={0}
      >
        <Typography variant="bodyMediumB" sx={{ color: COLORS.MAIN_DARK }}>
          {t('userProfile.accountOpeningAgreement')}
        </Typography>
        <Grid display="flex" flexDirection="row" flexWrap="nowrap">
          <Button
            variant="text"
            onClick={() => navigate(locations.accountOpeningAgreement())}
            id="ViewAccountOpeningAgreementButton"
            startIcon={<VisibilityOutlinedIcon />}
            sx={{ textTransform: 'capitalize', color: COLORS.PRIMARY_BLUE }}
          >
            <Typography
              variant="bodySmall"
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
              }}
            >
              {t('view')}
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: '32px', p: '24px', borderRadius: '12px' }}
        component={Paper}
        elevation={0}
      >
        <Typography variant="bodyMediumB" sx={{ color: COLORS.MAIN_DARK }}>
          {t('userProfile.dataPrivacy')}
        </Typography>
        <Grid display="flex" flexDirection="row" flexWrap="nowrap">
          <Button
            variant="text"
            href="https://madkhol.com/data-privacy"
            id="ViewDataPrivacyButton"
            startIcon={<VisibilityOutlinedIcon />}
            sx={{ textTransform: 'capitalize', color: COLORS.PRIMARY_BLUE }}
          >
            <Typography
              variant="bodySmall"
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
              }}
            >
              {t('view')}
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: '32px', p: '24px', borderRadius: '12px' }}
        component={Paper}
        elevation={0}
      >
        <Typography variant="bodyMediumB" sx={{ color: COLORS.MAIN_DARK }}>
          {t('userProfile.termsAndConditions')}
        </Typography>
        <Grid display="flex" flexDirection="row" flexWrap="nowrap">
          <Button
            variant="text"
            onClick={() => navigate(locations.termsAndConditions())}
            id="ViewTermsAndConditionsButton"
            startIcon={<VisibilityOutlinedIcon />}
            sx={{ textTransform: 'capitalize', color: COLORS.PRIMARY_BLUE }}
          >
            <Typography
              variant="bodySmall"
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
              }}
            >
              {t('view')}
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: '32px', p: '24px', borderRadius: '12px' }}
        component={Paper}
        elevation={0}
      >
        <Typography variant="bodyMediumB" sx={{ color: COLORS.MAIN_DARK }}>
          {t('userProfile.shariahCompliantCertification')}
        </Typography>
        <Grid display="flex" flexDirection="row" flexWrap="nowrap">
          <Button
            variant="text"
            href="/docs/MFTC-2411-01-01-12-22 Platform Documentation New.pdf"
            target="_blank"
            id="ViewShariahCompliantCertificationButton"
            startIcon={<VisibilityOutlinedIcon />}
            sx={{ textTransform: 'capitalize', color: COLORS.PRIMARY_BLUE }}
          >
            <Typography
              variant="bodySmall"
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
              }}
            >
              {t('view')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AttachmentsSection;
