import './DocButton.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ProductFile } from '../../../../types/product';
import { getUploadedDocumentURL } from '../../../../helpers/api_deprecated';
import { singleDownload } from '../../../../helpers/request';

interface DocButtonProps {
  document: ProductFile;
  className?: string;
}

const DocButton: React.FC<DocButtonProps> = ({ document, className = '' }) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  const onClick = async (e: any) => {
    e.preventDefault();

    const response = await getUploadedDocumentURL({
      access: 'private',
      fileId: document.documentLink,
    });
    if (response?.url) {
      await singleDownload(response.url, {
        rename: () => document.documentLink,
      });
    }
  };

  return (
    <div className={`doc-btn ${className}`} onClick={onClick}>
      <img
        className={isEnglish ? 'img img-en' : 'img img-ar'}
        width={22}
        height={22}
        src="/images/icons/pdf.svg"
        alt="madkhol-portfolio"
      />
      <Typography className={isEnglish ? 'text text-en' : 'text text-ar'}>
        {t(document?.type ? `products.${document?.type}` : 'products.document')}
      </Typography>
    </div>
  );
};

export default DocButton;
