import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import COLORS from '../../../constants/colors';
import ChangePasswordDialog from '../../../shared/ChangePasswordDialog';
import ChangePasswordSuccessfulScreen from './ChangePasswordSuccessfulScreen';

const ChangePasswordSection = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openSuccessfulDialog, setOpenSuccessfulDialog] = useState(false);

  const openSuccessfulScreenDialog = () => {
    setOpenSuccessfulDialog(true);
  };

  const closeSuccessfulScreenDialog = () => {
    setOpenSuccessfulDialog(false);
  };

  const onClosePasswordDialog = (isSuccess = false) => {
    setOpen(false);

    if (isSuccess) {
      openSuccessfulScreenDialog();
    }
  };

  return (
    <Grid
      item
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ mt: '32px', p: '24px', borderRadius: '12px' }}
      component={Paper}
      elevation={0}
    >
      <ChangePasswordDialog open={open} onClose={onClosePasswordDialog} />
      <ChangePasswordSuccessfulScreen
        openSuccessfulDialog={openSuccessfulDialog}
        onSuccessButtonClick={closeSuccessfulScreenDialog}
      />

      <Typography variant="bodyMediumB" sx={{ color: COLORS.MAIN_DARK }}>
        {t('userProfile.changePassword')}
      </Typography>
      <Button
        variant="text"
        onClick={() => setOpen(true)}
        id="ChangePasswordButton"
        sx={{
          textTransform: 'capitalize',
          textDecoration: 'underline',
          color: COLORS.PRIMARY_BLUE,
        }}
      >
        <Typography
          variant="bodySmall"
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
          }}
        >
          {t('change')}
        </Typography>
      </Button>
    </Grid>
  );
};

export default ChangePasswordSection;
