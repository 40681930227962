import React from 'react';

import './GicsWeight.scss';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import MadkholSlider from '../Slider/Slider';
import { formatNumber } from '../../../../utils/number';

interface GicsWeightProps {
  name: string;
  weight: number;
  color?: string;
  showThumb?: boolean;
  max?: number;
}

const GicsWeight: React.FC<GicsWeightProps> = ({ max = 100, color, showThumb, weight, name }) => {
  const { i18n } = useTranslation();

  return (
    <div className="gics-weight">
      <div className="position">
        <Typography sx={{ cursor: 'pointer' }} fontWeight="bold">
          {name}
        </Typography>
        <Typography fontWeight="bold">{formatNumber(Number(weight) || 0, i18n.language, 0)}%</Typography>
      </div>
      <MadkholSlider value={weight} max={max} disabled color={color} showThumb={showThumb} />
    </div>
  );
};

export default GicsWeight;
