import { captureException } from '@sentry/react';
import axios, { Axios, AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';
import { CONTACT_US } from '../constants/APIs';
import { getAccessToken, removeAccessToken, removeLoggedIn } from './LocalStorage';

const controller = new AbortController();

export class ApiService {
  private readonly instance: Axios;

  constructor(baseURL = process.env.REACT_APP_BASE_API_URL) {
    this.instance = axios.create({
      headers: { 'Content-Type': 'application/json' },
      responseType: 'json',
      baseURL: baseURL || process.env.REACT_APP_BASE_API_URL,
    });
    this.instance.defaults.headers.common.accept = 'application/json'; // for all requests
    this.instance.defaults.headers.common['Content-Type'] = 'application/json'; // for all requests

    this.instance.interceptors.response.use(
      (res) => res.data,
      (error) => {
        if (error.response?.status === 401) {
          const { location } = window;
          const { origin, pathname } = location;

          if (pathname !== '/login') sessionStorage.setItem('lastPage', pathname);

          controller.abort();
          removeAccessToken();
          removeLoggedIn();

          location.replace(`${origin}/login`);
        }
        return Promise.reject(error);
      },
    );

    this.instance.interceptors.request.use(
      (req) => {
        const accessToken = getAccessToken();

        if (accessToken) {
          const payload = jwtDecode<object & { exp: number }>(accessToken);
          const isExpired = Date.now() >= payload.exp * 1000;
          if (!isExpired) {
            req.headers.setAuthorization(`Bearer ${accessToken}`);
          } else if (req.url !== CONTACT_US) {
            window.postMessage('NotAuthorized');
          }
        } else if (req.url !== CONTACT_US) {
          window.postMessage('NotAuthorized');
        }

        req.headers.set('x-anonymous-id', localStorage.getItem('ajs_anonymous_id') || '');

        return req;
      },
      (error: unknown) => {
        console.error('[ApiService API request error]', error);
        return Promise.reject(error);
      },
    );
  }

  handleError = (error: any) => {
    const clientErrors = /4[0-9][0-9]/g;
    const serverErrors = /5[0-9][0-9]/g;

    if (error.response) {
      if (error?.response?.status?.toString().match(clientErrors)?.length) {
        // captureException(error, { extra: { errorResponse: error?.response?.data } });

        throw new Error(error?.response?.data?.message || 'Something went wrong, Please try again!');
      } else if (error?.response?.status?.toString().match(serverErrors)?.length) {
        captureException(error, { extra: { errorResponse: error?.response?.data } });

        throw new Error(error?.response?.data?.message || 'Something went wrong in server side');
      }
    } else if (error.request) {
      throw new Error('No response received');
    }

    throw new Error(error?.message || 'Something went wrong in server side');
  };

  get = <R = any>(url: string, config?: AxiosRequestConfig) =>
    this.instance.get<any, R>(url, { ...config, signal: controller.signal });

  post = <R = any, B = any>(url: string, body: B, config?: AxiosRequestConfig): Promise<R> =>
    this.instance.post<any, R, B>(url, body, config);

  put = <R = any, B = any>(url: string, body: B): Promise<R> => this.instance.put<any, R, B>(url, body);

  remove = <R = any, B = any>(url: string, config?: AxiosRequestConfig): Promise<R> =>
    this.instance.delete<any, R, B>(url, config);
}
