import { apiSlice } from '../../../api/common/apiSlice';
import { ApiTagType } from '../../../constants/apiTagType';
import { toQueryParams } from '../../../utils/request';
import { FetchIndexFundsResponse } from '../../../types/indexFunds';

export const indexFundsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchIndexFunds: builder.query<FetchIndexFundsResponse, PaginatedRequest>({
      query: (data) => ({
        url: `/app/index-fund?${toQueryParams(data)}`,
        method: 'GET',
      }),
      providesTags: (res) => {
        if (res) {
          return [
            ...(res.items || []).map(({ id }) => ({ type: ApiTagType.IndexFund as const, id })),
            { type: ApiTagType.IndexFund, id: 'LIST' },
          ];
        }
        return [{ type: ApiTagType.IndexFund, id: 'LIST' }];
      },
    }),
  }),
});

export const { useFetchIndexFundsQuery } = indexFundsApiSlice;
