import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';
import COLORS from '../constants/colors';
import { useTypedDispatch } from '../redux/store';
import { signUserAgreement } from '../store_deprecated/actions/userAgreementActions';
import { agreementSelector } from '../store_deprecated/selectors/agreementSelectors';
import { UserAgreementName } from '../store_deprecated/types/userAgreementTypes';
import MButton from './MButton';

interface ChangeAllocationAgreementDialogProps {
  open: boolean;
  onClose: (isAgree?: boolean) => void;
  postSign?: () => void;
  confirmProduct?: () => void;
}

export default function ChangeAllocationAgreementDialog(props: ChangeAllocationAgreementDialogProps) {
  const { open, onClose, postSign, confirmProduct } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useTypedDispatch();
  const [agree, setAgree] = useState(false);
  const isEnglish = i18n.language === 'en';

  const { isLoading, error, isErrorExist } = useSelector(agreementSelector);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => () => setAgree(false), [open]);

  const handleClose = async (isAgree: boolean) => {
    if (isLoading) {
      return;
    }

    if (isAgree) {
      try {
        await dispatch(signUserAgreement(UserAgreementName.CHANGE_ALLOCATION_AGREEMENT));
        if (confirmProduct) confirmProduct();
        if (postSign) postSign();
      } catch (e) {
        console.error(e);
      }
    }

    return onClose(isAgree);
  };

  const renderContent = () => (
    <Grid
      container
      flexDirection="column"
      justifyContent="center"
      alignItems={isEnglish ? 'center' : 'flex-start'}
      rowSpacing="24px"
    >
      <Grid item xs={12} sm container>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 500,
                fontSize: { xs: '22px', md: '35px' },
                lineHeight: { xs: '32px', md: '48px' },
                color: COLORS.MAIN_DARK,
              }}
            >
              {t('changeAllocationAgreementDialog.title')}
            </Typography>
            <Typography variant="body1" color={COLORS.LIGHT_GRAY}>
              {t('changeAllocationAgreementDialog.affectedDate')}:&nbsp;
              {moment().locale(i18n.language).format('DD/MM/YYYY')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton onClick={() => handleClose(false)}>
            <CloseIcon sx={{ fontSize: '25px', color: COLORS.MAIN_DARK }} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item textAlign="left">
        <Typography variant="bodySmall" color={COLORS.BLACK}>
          {t('changeAllocationAgreementDialog.description')}
        </Typography>
      </Grid>
      <Grid item component="ul" paddingLeft="20px" paddingTop="15px">
        <li>
          <Typography variant="bodySmall" sx={{ color: COLORS.BLACK }}>
            {t('changeAllocationAgreementDialog.point1')}
          </Typography>
        </li>
        <li>
          <Typography variant="bodySmall" sx={{ color: COLORS.BLACK }}>
            {t('changeAllocationAgreementDialog.point2')}
          </Typography>
        </li>
        <li>
          <Typography variant="bodySmall" sx={{ color: COLORS.BLACK }}>
            {t('changeAllocationAgreementDialog.point3')}
          </Typography>
        </li>
        <li>
          <Typography variant="bodySmall" sx={{ color: COLORS.BLACK }}>
            {t('changeAllocationAgreementDialog.point4')}
          </Typography>
        </li>
      </Grid>
      <Grid item container xs={12}>
        <Grid item sm={12} md={8}>
          <FormControlLabel
            name="signAgreement"
            control={
              <Checkbox
                checked={agree}
                onChange={(event, checked) => setAgree(checked)}
                name="signAgreement"
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 'clamp(1rem,1vw + 1rem,1.5rem)',
                  },
                  color: COLORS.SECONDARY_GREEN,
                }}
                checkedIcon={<CheckBoxOutlinedIcon sx={{ color: COLORS.SECONDARY_GREEN }} />}
              />
            }
            label={
              <Typography
                variant="h6"
                sx={{
                  color: agree ? COLORS.SECONDARY_GREEN : COLORS.MAIN_DARK,
                }}
                textAlign="left"
              >
                {t('changeAllocationAgreementDialog.signAgreement')}
              </Typography>
            }
            sx={{
              width: '100%',
              border: `1px solid ${COLORS.XX_LIGHT_GREEN}`,
              borderRadius: 24,
              margin: { xs: '30px 0', md: '30px 0' },
              marginLeft: 0,
              marginRight: 0,
              padding: { xs: '12px 12px', md: '12px 12px' },
              gap: '10px',
              alignItems: {
                xs: 'flex-start',
                md: 'center',
              },
            }}
          />
        </Grid>
      </Grid>
      {(isLoading || isErrorExist) && (
        <Grid item container justifyContent="flex-start" component="div" display="flex">
          {isLoading && <CircularProgress />}
          {isErrorExist && (
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '28px',
                color: COLORS.RED,
              }}
            >
              {error}
            </Typography>
          )}
        </Grid>
      )}
      <Grid
        item
        container
        gap="32px"
        direction="row"
        sx={{
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Grid item>
          <MButton
            id="SignChangeAllocationAgreementButton"
            variant="contained"
            onClick={() => handleClose(true)}
            disabled={!agree || isLoading}
            buttonProps={{
              sx: {
                backgroundColor: COLORS.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS.X_DARK_BLUE,
                },
                width: {
                  xs: '100%',
                },
              },
            }}
          >
            {t('sign')}
          </MButton>
        </Grid>
        <Grid item>
          <MButton
            variant="outlined"
            onClick={() => handleClose(false)}
            disabled={isLoading}
            buttonProps={{
              sx: {
                border: 'solid 1px #989DA1',
                color: '#4C5155',
                fontSize: '18px',
                width: {
                  xs: '100%',
                },
              },
            }}
          >
            <Typography
              sx={{
                color: '#4C5155',
                fontSize: '18px',
              }}
            >
              {t('cancel')}
            </Typography>
          </MButton>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Dialog open={open} onClose={() => handleClose(false)} maxWidth="lg" disableScrollLock fullScreen={fullScreen}>
      <Box
        sx={{
          padding: '32px 24px',
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            width: '5px !important',
            backgroundColor: 'transparent !important',
            borderRadius: '4px !important',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#111827 !important',
            borderRadius: '4px !important',
          },
        }}
      >
        {renderContent()}
      </Box>
    </Dialog>
  );
}
