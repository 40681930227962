/* eslint-disable import/no-unresolved */
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import StockCard from './StockCard';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './widgets.styles.css';

/* eslint-enable import/no-unresolved */

const widgets = [
  {
    title: 'US Stocks',
    leftAttrTitle: '30 day SEC',
    leftAttrValue: '1.25 %',
    rightAttrTitle: 'ROI',
    rightAttrValue: '+1500 $',
  },
  {
    title: 'Developed Markets Stocks',
    leftAttrTitle: '30 day SEC',
    leftAttrValue: '1.25 %',
    rightAttrTitle: 'ROI',
    rightAttrValue: '+1500 $',
  },
  {
    title: 'US Real Estate',
    leftAttrTitle: '30 day SEC',
    leftAttrValue: '1.25 %',
    rightAttrTitle: 'ROI',
    rightAttrValue: '+1500 $',
  },
  {
    title: 'US Stocks',
    leftAttrTitle: '30 day SEC',
    leftAttrValue: '1.25 %',
    rightAttrTitle: 'ROI',
    rightAttrValue: '+1500 $',
  },
  {
    title: 'Developed Markets Stocks',
    leftAttrTitle: '30 day SEC',
    leftAttrValue: '1.25 %',
    rightAttrTitle: 'ROI',
    rightAttrValue: '+1500 $',
  },
  {
    title: 'US Real Estate',
    leftAttrTitle: '30 day SEC',
    leftAttrValue: '1.25 %',
    rightAttrTitle: 'ROI',
    rightAttrValue: '+1500 $',
  },
];

const PortfolioDetailsWidgets: React.FC = () => {
  const lessXs = useMediaQuery('(max-width:450px)');
  const sm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const md = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const lg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));

  let slidesPerView = lessXs ? 1 : 1.3;

  if (sm) {
    slidesPerView = 1.5;
  }

  if (md) {
    slidesPerView = 2.3;
  }

  if (lg) {
    slidesPerView = 2.7;
  }

  return (
    <Swiper
      spaceBetween={26}
      slidesPerView={slidesPerView}
      pagination={{
        enabled: true,
        type: 'bullets',
        clickable: true,
        progressbarOpposite: true,
      }}
      modules={[Pagination]}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      style={{ padding: '1px', height: '100%' }}
    >
      {widgets.map((item, index) => (
        <SwiperSlide key={`${item.title}-${index}`}>
          <StockCard data={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default PortfolioDetailsWidgets;
