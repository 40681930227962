import * as yup from 'yup';
import PasswordValidationStatus from '../../constants/PasswordValidationStatus';
import { changePasswordFormFields } from '../fields/formFields';

export const changePasswordFormSchema = yup.object({
  [changePasswordFormFields.oldPassword]: yup.string().trim().required('missingField'),
  [changePasswordFormFields.newPassword]: yup.string().required('missingField').trim().strongPassword({
    minLengthMessage: PasswordValidationStatus.SHORT_PASSWORD,
    maxLengthMessage: 'maxLengthPasswordError',
    lowerCaseMessage: PasswordValidationStatus.COMBINATION_SEQUENCE_OR_CONSECUTIVE_PATTERN,
    consecutiveMessage: PasswordValidationStatus.COMBINATION_SEQUENCE_OR_CONSECUTIVE_PATTERN,
    sequentialMessage: PasswordValidationStatus.COMBINATION_SEQUENCE_OR_CONSECUTIVE_PATTERN,
    upperCaseMessage: PasswordValidationStatus.AT_LEAST_UPPERCASE_CHAR,
    digitMessage: PasswordValidationStatus.COMBINATION_SEQUENCE_OR_CONSECUTIVE_PATTERN,
    specialMessage: PasswordValidationStatus.COMBINATION_SEQUENCE_OR_CONSECUTIVE_PATTERN,
  }),
  [changePasswordFormFields.confirmNewPassword]: yup
    .string()
    .oneOf([yup.ref(changePasswordFormFields.newPassword), undefined], 'passwordsMustMatch')
    .required('passwordsMustMatch'),
});
