export const toQueryParams = (data: { [prop: string]: any }): string => {
  const values: Record<string, string> = {};

  for (const dataKey in data) {
    if (data[dataKey] !== undefined && data[dataKey] !== null) {
      values[dataKey] = String(data[dataKey]);
    }
  }

  return new URLSearchParams(values).toString();
};

// eslint-disable-next-line no-promise-executor-return
export const delay = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds));

const download = async (url: string, name: string) => {
  // Chrome requires the timeout
  await delay(100);

  if (navigator.userAgent.indexOf('Chrome') !== -1 || navigator.userAgent.indexOf('Firefox') !== -1) {
    const a = document.createElement('a');

    a.download = name;
    a.href = url;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    a.style.display = 'none';

    document.body.append(a);

    a.click();
    a.remove();
  } else {
    // Safari & Opera iOS
    window.open(url, '_blank');
  }
};

export async function multiDownload(
  urls: string[],
  { rename }: { rename?: (_: { url: string; index: number; urls: string[] }) => string } = {},
) {
  if (!urls) {
    throw new Error('`urls` required');
  }

  for (let index = 0; index < urls.length; index++) {
    const url = urls[index];
    const name = typeof rename === 'function' ? rename({ url, index, urls }) : '';

    await delay(index * 1000);
    await download(url, name);
  }
}

export async function singleDownload(url: string, { rename }: { rename?: (_: { url: string }) => string } = {}) {
  if (!url) {
    throw new Error('`url` required');
  }

  const name = typeof rename === 'function' ? rename({ url }) : '';

  await download(url, name);
}
