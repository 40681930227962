import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import COLORS from '../constants/colors';

export const MTextField = React.forwardRef((props: TextFieldProps, ref: React.ForwardedRef<HTMLInputElement>) => {
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  return (
    <TextField
      fullWidth
      required
      margin="none"
      variant="outlined"
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px',
          '& fieldset': { borderColor: COLORS.XLIGHT_GRAY },
        },
        '& .MuiFormHelperText-root': {
          fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
        },
      }}
      {...props}
      InputProps={{
        ...props.InputProps,
        style: {
          fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
          fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
        },
      }}
    />
  );
});
