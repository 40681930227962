import React, { useEffect, useState } from 'react';

import './ProductCard.scss';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import ProductWeight from '../ProductWeight/ProductWeight';
import ProductInfo from '../ProductInfo/ProductInfo';
import DocButton from '../DocButton/DocButton';
import { Product, ProductFile } from '../../../../types/product';
import { productService } from '../../../../api';
import { FetchProductItemsResponse, NewProduct } from '../../../../store_deprecated/types/portfolioTypes';
import { sliderColors } from '../Slider/Slider';
// import ProductFooter from '../ProductFooter/ProductFooter';
import { formatNumber } from '../../../../utils/number';
import GicsWeight from '../GicsWeight/GicsWeight';
import { useGetAppConfigQuery } from '../../../../redux/features/config/config.apiSlice';
import { ConfigKey } from '../../../../types/config';
import ProductIcons from '../../../../constants/ProductIcons';
import COLORS from '../../../../constants/colors';
import ProductCardLoading from './ProductCardLoading';
import MButton from '../../../../shared/MButton';

interface ProductCardProps {
  product: Product;
  onClick: (p: Product, portfolio: NewProduct) => void;
  selected: boolean;
  isList?: boolean;
  isLoading?: boolean;
  onSelectAndInvest: (p: Product, portfolio: NewProduct) => void;
  showInvestButton?: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  onClick,
  selected,
  isList,
  isLoading,
  onSelectAndInvest,
  showInvestButton,
}) => {
  const { id, nameEn, nameAr, descriptionEn, descriptionAr, minimumPortfolioInvestment, minimumAddFundInvestment } =
    product;
  const { t, i18n } = useTranslation();
  const { data: appConfigCacheAllocation } = useGetAppConfigQuery(ConfigKey.CACHE_ALLOCATION_PERCENTAGE);
  const isEnglish = i18n.language === 'en';
  const [portfolio, setPortfolio] = useState<NewProduct>();
  const [hasAssociatedIndexFunds, setHasAssociatedIndexFunds] = useState<boolean | undefined>(true);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [collapsed, setCollapsed] = useState(isMobile);
  const [productFiles, setProductFiles] = useState<ProductFile[]>([]);

  useEffect(() => {
    // TODO: We showing only first one
    productService.fetchProductPortfolio(id).then(({ data }: { data: FetchProductItemsResponse }) => {
      setPortfolio(data?.items[0] || {});
      const hasIndexFunds = !!data?.items[0] || false;
      setHasAssociatedIndexFunds(hasIndexFunds);
    });
  }, []);

  // Watch for product.files and transform them
  useEffect(() => {
    if (product?.files?.length === 0) return;
    const files = product?.files.map((file) => ({
      id: file.id,
      documentLink: file.documentLink,
      type: file.type || (file.fileType?.name as string),
    }));
    setProductFiles(files);
  }, [product.files]);

  const handleCollapse = () => setCollapsed((prev) => !prev);

  const renderIndexFunds = () => (
    <div className={isList ? 'weights__list' : 'weights'}>
      {!portfolio ? (
        <CircularProgress />
      ) : (
        portfolio.associatedIndexFunds?.map((fund, idx) => (
          <div key={fund.id} className={`${isList && 'product-card__in-weights'}`}>
            <ProductWeight fund={fund} color={sliderColors[idx % sliderColors.length]} />
            <ProductInfo
              strategy={fund.indexFund.strategy}
              manager={isEnglish ? fund.indexFund.manager.nameEn : fund.indexFund.manager.nameAr}
              expReturn={fund.indexFund.expectedReturn}
            />
          </div>
        ))
      )}
    </div>
  );

  const renderGics = () => {
    if (!portfolio) {
      return <CircularProgress />;
    }

    const weightByGics = portfolio.associatedIndexFunds.reduce(
      (res, aif) => {
        for (const g of aif.indexFund.gics) {
          const name = isEnglish ? g.gics.nameEn : g.gics.nameAr;
          res[name] = (res[name] || 0) + (g.weight * aif.weight) / 100;
        }

        return res;
      },
      {} as Record<string, number>,
    );

    return (
      <div className={isList ? 'weights__list' : 'weights'}>
        {Object.keys(weightByGics).map((key, idx) => (
          <div key={key} className={isList ? 'gics-weight__list' : 'gics-weight'}>
            <GicsWeight name={key} color={sliderColors[idx % sliderColors.length]} weight={weightByGics[key]} />
          </div>
        ))}
      </div>
    );
  };

  const selectAndInvest = () => {
    onSelectAndInvest(product, portfolio as NewProduct);
  };

  if (isLoading) return <ProductCardLoading />;
  if (!hasAssociatedIndexFunds) return;

  return (
    <div
      className={`product-card ${isList && 'product-card__in-list'} ${selected && 'product-card-selected'}`}
      onClick={() => onClick(product, portfolio as NewProduct)}
    >
      <div className="header">
        <div className={isList ? 'header-in-list' : ''}>
          <div className={isList ? 'header-img-text__list' : 'header-img-text'}>
            {isList && (
              <img
                className={isEnglish ? 'invest-icon invest-icon__en' : 'invest-icon invest-icon__ar'}
                src={`/images/icons/${ProductIcons[nameEn.toLowerCase()] || 'control.svg'}`}
                alt=""
              />
            )}
            <div className={isList ? 'title-in-list__text' : ''}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: 20,
                  fontWeight: 700,
                  color: COLORS.PRIMARY_BLUE,
                }}
              >
                {isEnglish ? nameEn : nameAr}
              </Typography>
              <Typography className="description">{isEnglish ? descriptionEn : descriptionAr}</Typography>
            </div>
          </div>
          {isList && (
            <div
              className={
                !selected
                  ? 'doc-buttons__no-margin title-in-list__buttons title-in-list__no-padding'
                  : 'doc-buttons title-in-list__buttons'
              }
            >
              {productFiles.map((file) => (
                <DocButton key={file.id} document={file} className={`fact-doc ${isList ? 'fact-doc__list' : ''}`} />
              ))}
            </div>
          )}
          {isList && (
            <div
              className={
                isList
                  ? `survey-block title-in-list__hint ${isEnglish ? '' : 'title-in-list__hint__ar'} ${
                      selected ? 'title-in-list__hint__selected' : ''
                    } ${selected ? '' : 'title-in-list__hint__hide'}`
                  : ''
              }
            >
              {selected && (
                <>
                  <img
                    className={isEnglish ? 'img-en' : 'img-ar'}
                    src="/images/icons/shield.svg"
                    alt="madkhol-shield"
                  />
                  <Typography>{t('products.completeSurvey')}</Typography>
                </>
              )}
            </div>
          )}
          {isList && (
            <div
              className={
                isList
                  ? `fee title-in-list__fee title-in-list__fee__desktop ${
                      isEnglish ? '' : 'title-in-list__fee__desktop__ar'
                    }`
                  : ''
              }
            >
              <Typography sx={{ fontSize: isEnglish ? 20 : 18 }}>{t('products.fee')}</Typography>
              <Typography fontWeight="bold" sx={{ fontSize: isEnglish ? 20 : 18 }}>
                {formatNumber(Number(product.fee) || 0, i18n.language, 0)}%
              </Typography>
            </div>
          )}
        </div>
        <div className="toggleIcon">
          {collapsed ? <ExpandMoreIcon onClick={handleCollapse} /> : <ExpandLessIcon onClick={handleCollapse} />}
        </div>
      </div>
      {!isList && (
        <div className="constraints">
          <div className="constraint">
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              {t('products.minInvestment')}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
              }}
              fontWeight="bold"
            >
              {`${formatNumber(Number(minimumPortfolioInvestment) || 0, i18n.language, 0)} ${t('currency.SAR')}`}
            </Typography>
          </div>
          <div className="constraint">
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              {t('products.additions')}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 600,
              }}
              fontWeight="bold"
            >
              {`${formatNumber(Number(minimumAddFundInvestment) || 0, i18n.language, 0)} ${t('currency.SAR')}`}
            </Typography>
          </div>
        </div>
      )}

      {!isList && productFiles.length > 0 && (
        <>
          <div className={isList ? 'separator__list' : 'separator'} />
          <div className={`doc-buttons ${collapsed && !selected ? 'doc-buttons__no-margin' : ''}`}>
            {productFiles.map((file) => (
              <DocButton key={file.id} document={file} className="fact-doc" />
            ))}
          </div>
        </>
      )}
      {selected && !isList && (
        <div className={`survey-block ${collapsed ? 'survey-block__less-margin' : ''}`}>
          <img className={isEnglish ? 'img-en' : 'img-ar'} src="/images/icons/shield.svg" alt="madkhol-shield" />
          <Typography>{t('products.completeSurvey')}</Typography>
        </div>
      )}
      <div
        className={
          !isList ? `product-weight-list ${isEnglish ? 'product-weight-list__en' : 'product-weight-list__ar'}` : ''
        }
      >
        {!collapsed && (portfolio?.displayByGics ? renderGics() : renderIndexFunds())}
      </div>
      <div className={isList ? 'separator__list separator__custom-margin' : 'separator separator__custom-margin'} />
      {!isList && (
        <div className="fee">
          <Typography sx={{ fontSize: 20 }}>{t('products.fee')}</Typography>
          <Typography sx={{ fontSize: 20, fontWeight: 700 }}>
            {formatNumber(Number(product.fee) || 0, i18n.language, 0)}%
          </Typography>
        </div>
      )}
      {isList && (
        <div className={isList ? 'fee title-in-list__fee title-in-list__fee__mobile' : ''}>
          <Typography sx={{ fontSize: 18 }}>{t('products.fee')}</Typography>
          <Typography fontWeight="bold" sx={{ fontSize: 18 }}>
            {formatNumber(Number(product.fee) || 0, i18n.language, 0)}%
          </Typography>
        </div>
      )}
      {/* <ProductFooter minInvestment={minimumPortfolioInvestment} additions={minimumAddFundInvestment} /> */}
      {isMobile && selected && showInvestButton && (
        <MButton
          variant="contained"
          onClick={selectAndInvest}
          typographyProps={{ sx: { padding: '12px 32px', fontWeight: 700 } }}
          buttonProps={{
            sx: {
              order: { xs: 1, md: 2 },
              p: 0,
              backgroundColor: COLORS.X_DARK_BLUE,
              backgroundImage: 'none',
              width: { xs: '100%', md: 'auto' },
              marginTop: '40px',
            },
          }}
        >
          {t('products.surveyBtn')}
        </MButton>
      )}
    </div>
  );
};

export default ProductCard;
