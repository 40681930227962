import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { getFundChartData, getIndexFundData, getUserIndexFundsData } from '../../helpers/api_deprecated';
import {
  CLEAR_INDEX_FUND,
  ClearIndexFund,
  FundChartDataItem,
  GET_FUND_CHART_DATA_FAIL,
  GET_FUND_CHART_DATA_REQUEST,
  GET_FUND_CHART_DATA_SUCCESS,
  GET_INDEX_FUND_FAIL,
  GET_INDEX_FUND_REQUEST,
  GET_INDEX_FUND_SUCCESS,
  GET_USER_INDEX_FUND_FAIL,
  GET_USER_INDEX_FUND_REQUEST,
  GET_USER_INDEX_FUND_SUCCESS,
  IndexFund,
  RequestGetFundChartData,
  RequestGetFundChartDataFail,
  RequestGetFundChartDataSuccess,
  RequestGetIndexFund,
  RequestGetIndexFundFail,
  RequestGetIndexFundSuccess,
  RequestGetUserIndexFunds,
  RequestGetUserIndexFundsFail,
  RequestGetUserIndexFundsSuccess,
  UserIndexFund,
} from '../types/indexFundTypes';

export const requestGetIndexFund = (): RequestGetIndexFund => ({
  type: GET_INDEX_FUND_REQUEST,
});

export const requestGetIndexFundSuccess = (result: IndexFund): RequestGetIndexFundSuccess => ({
  type: GET_INDEX_FUND_SUCCESS,
  payload: result,
});

export const requestGetIndexFundFail = (payload: string): RequestGetIndexFundFail => ({
  type: GET_INDEX_FUND_FAIL,
  payload,
});

export const requestGetUserIndexFunds = (): RequestGetUserIndexFunds => ({
  type: GET_USER_INDEX_FUND_REQUEST,
});

export const requestGetUserIndexFundsSuccess = (result: UserIndexFund[]): RequestGetUserIndexFundsSuccess => ({
  type: GET_USER_INDEX_FUND_SUCCESS,
  payload: result,
});

export const requestGetUserIndexFundsFail = (payload: string): RequestGetUserIndexFundsFail => ({
  type: GET_USER_INDEX_FUND_FAIL,
  payload,
});

export const requestGetFundChartData = (): RequestGetFundChartData => ({
  type: GET_FUND_CHART_DATA_REQUEST,
});

export const requestGetFundChartDataSuccess = (result: FundChartDataItem[]): RequestGetFundChartDataSuccess => ({
  type: GET_FUND_CHART_DATA_SUCCESS,
  payload: result,
});

export const requestGetFundChartDataFail = (payload: string): RequestGetFundChartDataFail => ({
  type: GET_FUND_CHART_DATA_FAIL,
  payload,
});

export const clearIndexFund = (): ClearIndexFund => ({
  type: CLEAR_INDEX_FUND,
});

export const findIndexFundData =
  (id: number): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestGetIndexFund());
    try {
      const response: any = await getIndexFundData(id);

      dispatch(requestGetIndexFundSuccess(response.data));

      return response.data;
    } catch (error: any) {
      dispatch(requestGetIndexFundFail(error.message));
    }
  };

export const findUserIndexFunds = (): ThunkAction<any, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(requestGetUserIndexFunds());
  try {
    const response: any = await getUserIndexFundsData();

    dispatch(requestGetUserIndexFundsSuccess(response.data));

    return response.data;
  } catch (error: any) {
    dispatch(requestGetUserIndexFundsFail(error.message));
  }
};

export const findFundChartData =
  (id: number): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestGetFundChartData());
    try {
      const response: any = await getFundChartData(id);

      dispatch(requestGetFundChartDataSuccess(response.data));

      return response.data;
    } catch (error: any) {
      dispatch(requestGetFundChartDataFail(error.message));
    }
  };
