import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import { useFormik } from 'formik';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import COLORS from '../../../constants/colors';
import { useFindProfileDataQuery, useUpdateProfileDataMutation } from '../../../redux/features/auth/auth.apiSlice';
import SelectInput from '../../../shared/SelectInput';
import { MARITAL_STATUS } from '../../../constants/nafaz/marital-status';
import { EDUCATION_LEVEL } from '../../../constants/nafaz/education-level';
import { Kyc, ProfileDataInfo } from '../../../store_deprecated/types/authTypes';
import { nafazDataFormFields } from '../../../forms/fields/nafazData';
import { nafazDataFormSchema } from '../../../forms/validationSchema/nafazData';
import MButton from '../../../shared/MButton';
import { locations } from '../../../routes/locations';
import {
  getNafazAdditionalData,
  setNafazAdditionalData,
  clearNafazAdditionalData,
} from '../../../helpers/LocalStorage';

const additionalQuestions = [
  {
    labelEn: 'I worked in the financial sector for five years or with a US-registered broker-dealer (FINRA).',
    labelAr:
      'سبق لي العمل في القطاع المالي خلال السنوات الخمس السابقة أو كوسيط مضارب في شركة مسجلة في السوق الأمريكي (FINRA)؟',
    field: nafazDataFormFields.fiveYearsFinancialSector,
    fieldDescription: nafazDataFormFields.fiveYearsFinancialSectorDescription,
  },
  {
    labelEn:
      'I am a board of directors member or have a relationship with a board of directors member, an audit committee member, or a senior executive in a listed company.',
    labelAr: 'أنا عضو أو ذو علاقة بعضو مجلس إدارة أو لجنة مراجعة أو أحد كبار التنفيذيين في شركة مدرجة؟',
    field: nafazDataFormFields.boardDirectorsMember,
    fieldDescription: nafazDataFormFields.boardDirectorsMemberDescription,
  },
  {
    labelEn:
      'I am an individual with a high-profile political role, have a relationship with a high-profile political position, or someone entrusted with a prominent public function locally or internationally.',
    labelAr:
      'أنا عضو أو ذو علاقة بعضو مكلّف بمهمات عليا في المملكة أو في دولة أجنبية أو مناصب إدارة عليا أو وظيفة في إحدى المنظمات الدولية؟',
    field: nafazDataFormFields.individualWithHighProfilePoliticalRole,
    fieldDescription: nafazDataFormFields.individualWithHighProfilePoliticalRoleDescription,
  },
  {
    labelEn: 'I am not the beneficial owner of the account or business relationship.',
    labelAr: 'أنا لست المستفيد الحقيقي من الحساب؟',
    field: nafazDataFormFields.notBeneficialOwner,
    fieldDescription: nafazDataFormFields.notBeneficialOwnerDescription,
  },
];

interface NafazAdditionalDataFormProps {
  profileData: ProfileDataInfo;
}

interface CustomizedState {
  from?: string;
}

const NafazAdditionalDataForm: React.FC<NafazAdditionalDataFormProps> = (props) => {
  const { profileData } = props;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = (location.state as CustomizedState) || {};
  const isEnglish = i18n.language === 'en';

  const [updateProfileData, { isLoading: isUpdateLoading }] = useUpdateProfileDataMutation();
  const { isLoading } = useFindProfileDataQuery();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const listOfMaritalStatus = MARITAL_STATUS[i18n.language];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const educationLevels = EDUCATION_LEVEL[i18n.language];

  const loading = isLoading || isUpdateLoading;

  const { handleChange, handleSubmit, values, isValid, setFieldValue, touched, errors, getFieldProps } = useFormik({
    validateOnMount: true,
    initialValues: getNafazAdditionalData(profileData.id) || {
      [nafazDataFormFields.maritalStatus]: '',
      [nafazDataFormFields.educationLevel]: '',
      [nafazDataFormFields.companyName]: '',
      [nafazDataFormFields.employerAddress]: '',
      [nafazDataFormFields.fiveYearsFinancialSector]: false,
      [nafazDataFormFields.fiveYearsFinancialSectorDescription]: '',
      [nafazDataFormFields.boardDirectorsMember]: false,
      [nafazDataFormFields.boardDirectorsMemberDescription]: '',
      [nafazDataFormFields.individualWithHighProfilePoliticalRole]: false,
      [nafazDataFormFields.individualWithHighProfilePoliticalRoleDescription]: '',
      [nafazDataFormFields.notBeneficialOwner]: false,
      [nafazDataFormFields.notBeneficialOwnerDescription]: '',
    },
    validationSchema: nafazDataFormSchema,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      await updateProfileData({
        ...formValues,
        confirmDate: new Date(),
      }).unwrap();
      clearNafazAdditionalData(profileData.id);
      navigate(state?.from || locations.survey());
    },
  });

  useEffect(() => {
    setNafazAdditionalData(profileData.id, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    for (const field of Object.keys(nafazDataFormFields)) {
      if (!values[field] && profileData?.[field as keyof Kyc]) {
        setFieldValue(field, profileData[field as keyof Kyc]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  const onCompleteProfileLater = () => {
    navigate(locations.dashboard());
  };

  return (
    <Grid component="form">
      <Grid
        item
        component={Paper}
        elevation={0}
        display="flex"
        flexDirection="column"
        sx={{
          borderRadius: '12px',
          paddingX: { xs: 6, md: 12 },
          paddingY: { xs: 6, md: 8 },
          marginTop: 8,
          width: '1030px',
          '@media (max-width: 1050px)': {
            width: '100%',
          },
        }}
      >
        <Grid
          container
          sx={{
            maxWidth: '340px',
            '@media (max-width: 1050px)': {
              maxWidth: '100%',
            },
          }}
        >
          <Grid item xs={12}>
            <Typography component="h5" variant="h5" sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}>
              {t('nafazData.additionalInfo.title')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 8 }}>
            <SelectInput
              id={nafazDataFormFields.maritalStatus}
              name={nafazDataFormFields.maritalStatus}
              label="nafazData.additionalInfo.maritalStatus"
              selectPlaceholder="nafazData.additionalInfo.select"
              error={touched.maritalStatus && !!errors.maritalStatus}
              helperText={touched.maritalStatus && t(errors.maritalStatus as string)}
              value={values[nafazDataFormFields.maritalStatus]}
              values={listOfMaritalStatus}
              handleChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 8 }}>
            <SelectInput
              id={nafazDataFormFields.educationLevel}
              name={nafazDataFormFields.educationLevel}
              label="nafazData.additionalInfo.educationLevel"
              selectPlaceholder="nafazData.additionalInfo.select"
              error={touched.educationLevel && !!errors.educationLevel}
              helperText={touched.educationLevel && t(errors.educationLevel as string)}
              value={values[nafazDataFormFields.educationLevel]}
              values={educationLevels}
              handleChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12} marginTop={8} marginBottom={3}>
            <Typography
              variant="bodyLargeM"
              color={COLORS.MAIN_DARK}
              textAlign="justify"
              paragraph
              sx={{
                marginBottom: 1,
                fontSize: { xs: '0.875rem', md: '1.125rem' },
              }}
            >
              {t('nafazData.additionalInfo.occupation')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <Box marginBottom={8}>
                <Typography
                  variant={isEnglish ? 'bodyMedium' : 'cairoM'}
                  color={COLORS.MAIN_DARK}
                  textAlign="justify"
                  paragraph
                  sx={{
                    marginBottom: 1,
                    fontSize: { xs: '0.875rem', md: '1rem' },
                  }}
                >
                  {t('nafazData.additionalInfo.companyName')}
                </Typography>
                <TextField
                  id="CompanyName"
                  variant="outlined"
                  fullWidth
                  error={touched.companyName && !!errors.companyName}
                  helperText={touched.companyName && t(errors.companyName as string)}
                  {...getFieldProps(nafazDataFormFields.companyName)}
                  value={values[nafazDataFormFields.companyName]}
                  required
                  sx={{
                    borderRadius: '8px',
                    '& .MuiFormHelperText-root': {
                      fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                    },
                    '& .MuiFormLabel-root.Mui-error': {
                      backgroundColor: 'transparent !important',
                    },
                    '& .MuiInput-underline.Mui-error:after': {
                      borderBottomColor: 'transparent !important',
                    },
                    '& .MuiFormHelperText-root.Mui-error': {
                      backgroundColor: 'transparent !important',
                    },
                  }}
                  InputProps={{
                    required: true,
                    style: {
                      fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                      backgroundColor: 'white',
                    },
                  }}
                  placeholder={t('nafazData.additionalInfo.companyNamePlaceholder')}
                  type="text"
                  onChange={handleChange}
                />
              </Box>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Box marginBottom={8}>
                <Typography
                  variant={isEnglish ? 'bodyMedium' : 'cairoM'}
                  color={COLORS.MAIN_DARK}
                  textAlign="justify"
                  paragraph
                  sx={{
                    marginBottom: 1,
                    fontSize: { xs: '0.875rem', md: '1rem' },
                  }}
                >
                  {t('nafazData.additionalInfo.employerAddress')}
                </Typography>
                <TextField
                  id="EmployerAddress"
                  variant="outlined"
                  fullWidth
                  error={touched.employerAddress && !!errors.employerAddress}
                  helperText={touched.employerAddress && t(errors.employerAddress as string)}
                  {...getFieldProps(nafazDataFormFields.employerAddress)}
                  value={values[nafazDataFormFields.employerAddress]}
                  required
                  sx={{
                    borderRadius: '8px',
                    '& .MuiFormHelperText-root': {
                      fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                    },
                    '& .MuiFormLabel-root.Mui-error': {
                      backgroundColor: 'transparent !important',
                    },
                    '& .MuiInput-underline.Mui-error:after': {
                      borderBottomColor: 'transparent !important',
                    },
                    '& .MuiFormHelperText-root.Mui-error': {
                      backgroundColor: 'transparent !important',
                    },
                  }}
                  InputProps={{
                    required: true,
                    style: {
                      fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                      backgroundColor: 'white',
                    },
                  }}
                  placeholder={t('nafazData.additionalInfo.employerAddressPlaceholder')}
                  type="text"
                  onChange={handleChange}
                />
              </Box>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sx={{ mb: 8 }}>
            <Typography component="h5" variant="h5" sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}>
              {t('nafazData.additionalInfo.allAppliesTitle')}
            </Typography>
          </Grid>
          {additionalQuestions.map((question) => (
            <React.Fragment key={`${question.field}-${i18n.language}`}>
              <Grid item xs={12}>
                <FormControlLabel
                  {...getFieldProps(question.field)}
                  control={
                    <Checkbox
                      checked={Boolean(values[question.field])}
                      onChange={(event, checked) => setFieldValue(question.field, checked)}
                      name={question.field}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: 'clamp(1rem,1vw + 1rem,1.5rem)',
                        },
                        color: COLORS.SECONDARY_GREEN,
                      }}
                      checkedIcon={<CheckBoxOutlinedIcon sx={{ color: COLORS.SECONDARY_GREEN }} />}
                    />
                  }
                  label={
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: { xs: '0.875rem', md: '1.125rem' },
                        color: values[question.field] ? COLORS.SECONDARY_GREEN : COLORS.MAIN_DARK,
                      }}
                      textAlign="left"
                    >
                      {isEnglish ? question.labelEn : question.labelAr}
                    </Typography>
                  }
                  sx={{
                    width: '100%',
                    border: `1px solid ${COLORS.XX_LIGHT_GREEN}`,
                    borderRadius: 1,
                    marginBottom: { xs: 6, md: 3 },
                    marginLeft: 0,
                    marginRight: 0,
                    padding: '4px 3px',
                  }}
                />
              </Grid>
              <Collapse in={Boolean(values[question.field])} sx={{ width: '100%' }}>
                <FormControl fullWidth sx={{ mb: 4 }} {...getFieldProps(question.fieldDescription)}>
                  <Typography
                    variant={isEnglish ? 'bodyMedium' : 'cairoM'}
                    color={COLORS.MAIN_DARK}
                    textAlign="justify"
                    paragraph
                    sx={{ marginBottom: 1 }}
                  >
                    {t('nafazData.additionalInfo.pleaseSpecify')}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    error={touched[question.fieldDescription] && !!errors[question.fieldDescription]}
                    helperText={touched[question.fieldDescription] && t(errors[question.fieldDescription] as string)}
                    {...getFieldProps(question.fieldDescription)}
                    value={values[question.fieldDescription]}
                    sx={{
                      borderRadius: '8px',
                      '& .MuiFormHelperText-root': {
                        fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                      },
                      '& .MuiFormLabel-root.Mui-error': {
                        backgroundColor: 'transparent !important',
                      },
                      '& .MuiInput-underline.Mui-error:after': {
                        borderBottomColor: 'transparent !important',
                      },
                      '& .MuiFormHelperText-root.Mui-error': {
                        backgroundColor: 'transparent !important',
                      },
                    }}
                    InputProps={{
                      style: {
                        fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                        backgroundColor: 'white',
                      },
                    }}
                    placeholder={t('nafazData.additionalInfo.placeholder')}
                    type="text"
                    onChange={handleChange}
                  />
                </FormControl>
              </Collapse>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>

      <Grid item sx={{ mt: 8, width: '100%' }}>
        <MButton
          id="NafazDataConfirm"
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          disabled={loading || !isValid}
          buttonProps={{
            sx: {
              height: { xs: '50px', md: 60 },
              backgroundColor: COLORS?.X_DARK_BLUE,
            },
          }}
        >
          {t('confirm')}
        </MButton>
      </Grid>

      {/* <Grid item sx={{ mt: 4, width: '100%' }}>
        <MButton
          id="NafazDataCompleteProfileLater"
          variant="contained"
          fullWidth
          onClick={onCompleteProfileLater}
          disabled={loading}
          buttonProps={{
            sx: {
              height: { xs: '50px', md: 60 },
              backgroundColor: 'transparent',
              color: COLORS?.X_DARK_BLUE,
              '&:hover': {
                backgroundColor: '#fff !important',
                boxShadow: 'none',
              },
            },
          }}
        >
          {t('nafazData.completeAccountLater')}
        </MButton>
      </Grid> */}
    </Grid>
  );
};

export default NafazAdditionalDataForm;
