export const hasConsecutiveCharacters = (s: string[]) => {
  if (s.length < 3) {
    return false;
  }

  let consCount = 1;

  for (let i = 1; i < s.length; i++) {
    if (s[i].charCodeAt(0) - s[i - 1].charCodeAt(0) === 1) {
      consCount += 1;
    } else {
      consCount = 1;
    }

    if (consCount >= 3) {
      return true;
    }
  }

  return false;
};

export const isTextValid = (value: string) => {
  const textRegEx = /[a-zA-Z\u0621-\u064A]/;
  return textRegEx.test(value);
};

export const isEmailValid = (value: string) => {
  const emailRegEx = /^([a-zA-Z0-9])(([a-zA-Z0-9])*([._+-])*([a-zA-Z0-9]))*@(([a-zA-Z0-9-])+(\.))+([a-zA-Z]{2,4})+$/;
  return emailRegEx.test(value);
};

const getValidation = (isArabic: boolean, v: string) => {
  const numberValue = Number(v).toLocaleString(isArabic ? 'ar-SA' : 'en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  let valid = true;

  if (Number.isNaN(numberValue) || Number.isNaN(v) || numberValue === 'NaN' || `${v}`.endsWith('.') || !`${v}`) {
    valid = false;
  }

  return { numberValue, valid };
};

export const formatValue = (isArabic: boolean, v: string) => {
  const { numberValue, valid } = getValidation(isArabic, v);

  if (!valid) {
    return v;
  }

  return numberValue;
};
