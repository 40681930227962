import { LOGOUT } from '../types/authTypes';
import {
  CLEAR_TRANSACTION,
  GET_TRANSACTIONS_HISTORY_FAIL,
  GET_TRANSACTIONS_HISTORY_REQUEST,
  GET_TRANSACTIONS_HISTORY_SUCCESS,
  TransactionAction,
  TransactionState,
} from '../types/transactionTypes';

const INITIAL_STATE: TransactionState = {
  transactionsHistory: {
    data: {
      items: [],
      totalItems: 0,
    },
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
};

/**
 * TODO: We must migrate to `@reduxjs/toolkit`!
 * @deprecated
 */
const transactionReducer = (
  state: TransactionState = INITIAL_STATE,
  action: TransactionAction = { type: LOGOUT },
): TransactionState => {
  switch (action.type) {
    case GET_TRANSACTIONS_HISTORY_REQUEST:
      return {
        ...state,
        transactionsHistory: {
          ...state.transactionsHistory,
          error: '',
          isLoading: true,
        },
      };

    case GET_TRANSACTIONS_HISTORY_SUCCESS:
      return {
        ...state,
        transactionsHistory: {
          ...state.transactionsHistory,
          error: '',
          data: action.payload.isLoadMore
            ? {
                items: [...state.transactionsHistory.data.items, ...action.payload.result.items],
                totalItems: action.payload.result.totalItems,
              }
            : action.payload.result,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case GET_TRANSACTIONS_HISTORY_FAIL:
      return {
        ...state,
        transactionsHistory: {
          ...state.transactionsHistory,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case CLEAR_TRANSACTION:
      return { ...INITIAL_STATE };

    case LOGOUT:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default transactionReducer;
