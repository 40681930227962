import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import * as LocalStorage from '../helpers/LocalStorage';
import { madkhoulBankInfoSelector } from '../store_deprecated/selectors/walletSelectors';
import COLORS from '../constants/colors';
import { formatNumber, round2Format } from '../utils/number';
import FundAmountField from '../components/User/Education/FundAmountField';
import { formatValue } from '../helpers/string';
import MButton from './MButton';
import { getSubscribeToPortfolioSelector } from '../store_deprecated/selectors/portfolioSelectors';
import { ReduxState, useTypedDispatch } from '../redux/store';
import { resetSubscribedToPortfolio, subscribedToPortfolio } from '../store_deprecated/actions/portfolioActions';
import { authSelector } from '../redux/features/auth/auth.selectors';
import SelectInput from './SelectInput';
import { setProductToInvest } from '../redux/features/products/selectedCardToInvest';

interface AddInvestFromWalletProps {
  closeDialog?: () => void;
  minAmount?: number;
  handleAmountField: (event: any) => void;
  PaymentMethodChild: JSX.Element;
  amount: string;
  currentSelectedProduct: any;
  setShowSuccessfulScreen: React.Dispatch<React.SetStateAction<boolean>>;
  shouldShowProductList?: boolean;
  page?: string;
}

export default function AddInvestFromWallet(props: AddInvestFromWalletProps) {
  const {
    closeDialog = () => {},
    minAmount = 1000,
    handleAmountField,
    PaymentMethodChild,
    amount,
    currentSelectedProduct,
    setShowSuccessfulScreen,
    shouldShowProductList,
    page,
  } = props;
  const { t, i18n } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');
  const isFundAmountError = Boolean(amount) && +amount < minAmount;
  const isArabic = i18n.language === 'ar';

  const bankItem = useSelector(madkhoulBankInfoSelector);

  const dispatch = useTypedDispatch();

  const { user } = useSelector(authSelector);

  const { error, isLoading } = useSelector(getSubscribeToPortfolioSelector);

  const onConfirm = async (fundAmount?: number) => {
    if (fundAmount) {
      const result = (await dispatch(
        subscribedToPortfolio({
          associatedIndexFunds: currentSelectedProduct?.associatedIndexFunds,
          portfolioId: currentSelectedProduct?.id,
          fundAmount,
        }),
      )) as any;

      if (result) {
        setShowSuccessfulScreen(true);
        LocalStorage.clearPreselectedProduct(user!.id);
      }
    } else {
      closeDialog();
      dispatch(resetSubscribedToPortfolio());
    }
  };

  const products: any = useSelector((storeData: ReduxState) => storeData.products.products);
  const { backendProductId } = useSelector((state: ReduxState) => state.selectedCardToInvest);
  const handleChange = (param: any) => {
    const product = products.find((p: any) => p.backendProductId === param.target.value);

    dispatch(
      setProductToInvest({
        backendProductId: param.target.value,
        toScreen: page,
        isSubscribed: product?.isSubscribed,
        product,
      }),
    );
  };

  const renderSubscribePortfolioDialog = () => (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" rowSpacing="48px">
      <Grid item textAlign="right" alignSelf="end">
        <IconButton onClick={() => closeDialog()}>
          <CloseIcon sx={{ fontSize: '12px', color: COLORS.MAIN_DARK }} />
        </IconButton>
      </Grid>
      <Grid
        item
        sx={{
          paddingTop: { xs: '24px !important', md: '48px !important' },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: 500,
            fontSize: { xs: '24px', md: '40px' },
            lineHeight: { xs: '32px', md: '48px' },
            color: COLORS.MAIN_DARK,
          }}
        >
          {t('invest.subscribePortfolioDialog.title')}
        </Typography>
      </Grid>
      <Grid item container flexDirection="row" justifyContent="space-around" alignItems="center">
        <Typography
          variant="bodyMedium"
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {t('invest.subscribePortfolioDialog.currentFund')}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '28px',
            color: COLORS.PRIMARY_BLUE,
          }}
        >
          {t('invest.subscribePortfolioDialog.nPerMonth', {
            val: round2Format(Number(bankItem?.userBankDetails?.amount) || 0, i18n.language),
          })}
        </Typography>
      </Grid>
      {shouldShowProductList && (
        <Grid item container xs={12} sx={{ marginTop: 8 }}>
          <SelectInput
            id="productList"
            name="products"
            label="depositDialog.productListTitle"
            selectPlaceholder="depositDialog.select"
            values={products?.map((product: any) => ({
              label: isArabic ? product?.productType?.nameAr : product?.productType?.nameEn,
              value: product?.backendProductId,
            }))}
            handleChange={handleChange}
            required
            value={backendProductId}
          />
        </Grid>
      )}
      <Grid item sx={{ width: '100%' }}>
        <FundAmountField
          isArabic={isArabic}
          value={formatValue(isArabic, (amount || '').toString()).replace(/٬/g, ',')}
          handleChange={handleAmountField}
        />
        <Typography
          variant="bodySmall"
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {t('invest.subscribePortfolioDialog.note', { val: formatNumber(minAmount, i18n.language) })}
        </Typography>
      </Grid>
      {PaymentMethodChild}
      {(isLoading || !!error || isFundAmountError) && (
        <Grid item>
          {isLoading && <CircularProgress />}
          {(!!error || isFundAmountError) && (
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '28px',
                color: COLORS.RED,
              }}
            >
              {tApiError(error) ||
                tApiError('FUND_AMOUNT_LESS_THAN_MINIMUM_SUBSCRIPTION_AMOUNT', {
                  val: formatNumber(minAmount, i18n.language, 2),
                })}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item container rowSpacing="24px">
        <Grid item width="100%">
          <MButton
            id="ConfirmInvestButton"
            variant="contained"
            onClick={() => onConfirm(+amount.replace(/\D/g, ''))}
            disabled={!amount || isFundAmountError || isLoading}
            fullWidth
            buttonProps={{
              sx: {
                backgroundColor: COLORS.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS.X_DARK_BLUE,
                },
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
                padding: { xs: '16px', md: '16px 48px' },
              },
            }}
          >
            {t('invest.subscribePortfolioDialog.confirmButtonTitle')}
          </MButton>
        </Grid>
        <Grid item width="100%">
          <MButton
            variant="outlined"
            onClick={() => closeDialog()}
            disabled={isLoading}
            fullWidth
            buttonProps={{
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
              },
            }}
          >
            {t('cancel')}
          </MButton>
        </Grid>
      </Grid>
    </Grid>
  );

  return renderSubscribePortfolioDialog();
}
