import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MButton from '../../../shared/MButton';
import { useTypedDispatch } from '../../../redux/store';
import RequestInvestSuccessfulScreen from '../Invest/RequestInvestSuccessfulScreen';
import {
  cancelSubscription,
  clearPortfolio,
  findSubscriptionsHistory,
} from '../../../store_deprecated/actions/portfolioActions';
import {
  getCancelSubscriptionSelector,
  getSubscriptionsHistorySelector,
} from '../../../store_deprecated/selectors/portfolioSelectors';
import COLORS from '../../../constants/colors';
import {
  SubscriptionsHistoryDataItem,
  SubscriptionStatus,
  SubscriptionType,
} from '../../../store_deprecated/types/portfolioTypes';
import Loader from '../../../shared/Loader';
import { formatNumber } from '../../../utils/number';

const mapStatusToInfo = {
  [SubscriptionStatus.APPROVED]: {
    color: COLORS.X_DARK_BLUE,
  },
  [SubscriptionStatus.APPROVED_BY_CAST]: {
    color: COLORS.X_DARK_BLUE,
  },
  [SubscriptionStatus.CREATED]: {
    color: COLORS.X_DARK_BLUE,
  },
  [SubscriptionStatus.PARTIAL_APPROVED]: {
    color: COLORS.X_DARK_BLUE,
  },
  [SubscriptionStatus.PARTIAL_APPROVED_BY_CAST]: {
    color: COLORS.X_DARK_BLUE,
  },
  [SubscriptionStatus.PENDING]: {
    color: COLORS.X_DARK_BLUE,
  },
  [SubscriptionStatus.IN_PROGRESS]: {
    color: COLORS.X_DARK_BLUE,
  },
  [SubscriptionStatus.WAITING_CAST_APPROVAL]: {
    color: COLORS.X_DARK_BLUE,
  },
  [SubscriptionStatus.REJECTED]: {
    color: COLORS.X_DARK_BLUE,
  },
  [SubscriptionStatus.CANCELED]: {
    color: COLORS.X_DARK_BLUE,
  },
  [SubscriptionStatus.FAILED]: {
    color: COLORS.X_DARK_BLUE,
  },
};

type ACTION_INFO = {
  [props: string]: {
    text: string;
    handler?: (event: React.MouseEvent<HTMLButtonElement>, item: SubscriptionsHistoryDataItem) => void;
  };
};

const OrdersView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useTypedDispatch();
  const matchMobileView = useMediaQuery('(max-width:600px)');
  const isEnglish = i18n.language === 'en';

  const [openSuccessfulDialog, setOpenSuccessfulDialog] = React.useState(false);

  const subscriptionsHistory = useSelector(getSubscriptionsHistorySelector);
  const { isLoading: isRedemptionLoading } = useSelector(getCancelSubscriptionSelector);
  const isLoading = subscriptionsHistory.isLoading || isRedemptionLoading;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchSubscriptionsHistory = useCallback(
    debounce((limit = 10, offset = 0) => {
      dispatch(findSubscriptionsHistory({ limit, offset }));
    }, 300),
    [],
  );

  const closeDialog = () => {
    setOpenSuccessfulDialog(false);
  };

  const onSuccessButtonClick = () => {
    closeDialog();
  };

  const loadMore = () => {
    searchSubscriptionsHistory(10, subscriptionsHistory.data.items.length);
  };

  const cancelRedemptionHandler = async (
    event: React.MouseEvent<HTMLButtonElement>,
    item: SubscriptionsHistoryDataItem,
  ) => {
    try {
      await dispatch(cancelSubscription(item.id));

      setOpenSuccessfulDialog(true);
      searchSubscriptionsHistory();
    } catch (e: any) {
      console.error('cancelRedemption error', e.message);
    }
  };

  const cancelSubscriptionHandler = async (
    event: React.MouseEvent<HTMLButtonElement>,
    item: SubscriptionsHistoryDataItem,
  ) => {
    try {
      await dispatch(cancelSubscription(item.id));

      searchSubscriptionsHistory();
    } catch (e: any) {
      console.error('cancelSubscription error', e.message);
    }
  };

  const rejectionReasonHandler = (event: React.MouseEvent<HTMLButtonElement>, item: SubscriptionsHistoryDataItem) => {
    alert(item?.changes?.statusReason);
  };

  const showHandler = (item: SubscriptionsHistoryDataItem) =>
    (item.type?.name === SubscriptionType.SUBSCRIPTION &&
      [SubscriptionStatus.PENDING, SubscriptionStatus.REJECTED].includes(item.status?.name)) ||
    (item.type?.name === SubscriptionType.REDEMPTION &&
      [SubscriptionStatus.PENDING, SubscriptionStatus.REJECTED].includes(item.status?.name));

  const mapActionInfo = useRef<ACTION_INFO>({
    // REDEMPTION
    [`${SubscriptionType.REDEMPTION}_${SubscriptionStatus.APPROVED}`]: {
      text: 'cancelRedemption',
      handler: cancelRedemptionHandler,
    },
    [`${SubscriptionType.REDEMPTION}_${SubscriptionStatus.APPROVED_BY_CAST}`]: {
      text: 'cancelRedemption',
      handler: cancelRedemptionHandler,
    },
    [`${SubscriptionType.REDEMPTION}_${SubscriptionStatus.CREATED}`]: {
      text: 'cancelRedemption',
      handler: cancelRedemptionHandler,
    },
    [`${SubscriptionType.REDEMPTION}_${SubscriptionStatus.PARTIAL_APPROVED}`]: {
      text: 'cancelRedemption',
      handler: cancelRedemptionHandler,
    },
    [`${SubscriptionType.REDEMPTION}_${SubscriptionStatus.PARTIAL_APPROVED_BY_CAST}`]: {
      text: 'cancelRedemption',
      handler: cancelRedemptionHandler,
    },
    [`${SubscriptionType.REDEMPTION}_${SubscriptionStatus.PENDING}`]: {
      text: 'cancelRedemption',
      handler: cancelRedemptionHandler,
    },
    [`${SubscriptionType.REDEMPTION}_${SubscriptionStatus.WAITING_CAST_APPROVAL}`]: {
      text: 'cancelRedemption',
      handler: cancelRedemptionHandler,
    },
    [`${SubscriptionType.REDEMPTION}_${SubscriptionStatus.REJECTED}`]: {
      text: 'rejectionReason',
      handler: rejectionReasonHandler,
    },
    [`${SubscriptionType.REDEMPTION}_${SubscriptionStatus.CANCELED}`]: {
      text: '',
    },
    [`${SubscriptionType.REDEMPTION}_${SubscriptionStatus.FAILED}`]: {
      text: 'rejectionReason',
      handler: rejectionReasonHandler,
    },
    // SUBSCRIPTION
    [`${SubscriptionType.SUBSCRIPTION}_${SubscriptionStatus.APPROVED}`]: {
      text: 'cancelSubscription',
      handler: cancelSubscriptionHandler,
    },
    [`${SubscriptionType.SUBSCRIPTION}_${SubscriptionStatus.APPROVED_BY_CAST}`]: {
      text: 'cancelSubscription',
      handler: cancelSubscriptionHandler,
    },
    [`${SubscriptionType.SUBSCRIPTION}_${SubscriptionStatus.CREATED}`]: {
      text: 'cancelSubscription',
      handler: cancelSubscriptionHandler,
    },
    [`${SubscriptionType.SUBSCRIPTION}_${SubscriptionStatus.PARTIAL_APPROVED}`]: {
      text: 'cancelSubscription',
      handler: cancelSubscriptionHandler,
    },
    [`${SubscriptionType.SUBSCRIPTION}_${SubscriptionStatus.PARTIAL_APPROVED_BY_CAST}`]: {
      text: 'cancelSubscription',
      handler: cancelSubscriptionHandler,
    },
    [`${SubscriptionType.SUBSCRIPTION}_${SubscriptionStatus.PENDING}`]: {
      text: 'cancelSubscription',
      handler: cancelSubscriptionHandler,
    },
    [`${SubscriptionType.SUBSCRIPTION}_${SubscriptionStatus.WAITING_CAST_APPROVAL}`]: {
      text: 'cancelSubscription',
      handler: cancelSubscriptionHandler,
    },
    [`${SubscriptionType.SUBSCRIPTION}_${SubscriptionStatus.REJECTED}`]: {
      text: 'rejectionReason',
      handler: rejectionReasonHandler,
    },
    [`${SubscriptionType.SUBSCRIPTION}_${SubscriptionStatus.CANCELED}`]: {
      text: '',
    },
    [`${SubscriptionType.SUBSCRIPTION}_${SubscriptionStatus.FAILED}`]: {
      text: 'rejectionReason',
      handler: rejectionReasonHandler,
    },
    // UPGRADE
    [`${SubscriptionType.UPGRADE}_${SubscriptionStatus.REJECTED}`]: {
      text: 'rejectionReason',
      handler: rejectionReasonHandler,
    },
    [`${SubscriptionType.UPGRADE}_${SubscriptionStatus.FAILED}`]: {
      text: 'rejectionReason',
      handler: rejectionReasonHandler,
    },
  });

  useEffect(() => {
    searchSubscriptionsHistory();

    return () => {
      dispatch(clearPortfolio());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderActionLink = (item: SubscriptionsHistoryDataItem) => {
    const { text, handler } = mapActionInfo.current[`${item.type.name}_${item.status.name}`] || {};

    if (!text || !handler || !showHandler(item) || !item?.changes?.statusReason) {
      return null;
    }

    return (
      <Button variant="text" disabled={isLoading} onClick={(e) => handler(e, item)}>
        <Typography
          variant="bodySmall"
          sx={{
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
            color: COLORS.PRIMARY_BLUE,
          }}
        >
          {t(`orders.action.${text}`)}
        </Typography>
      </Button>
    );
  };

  const getApprovedRedemptionTranslationMessage = (amount: number, actualAmount: number) =>
    t('orders.redemptionAmount', {
      amount: formatNumber(actualAmount, i18n.language, 2),
    });
  // const diff = amount - actualAmount;

  // if (!diff) {
  //   return t('orders.redemptionAmount', {
  //     amount: formatNumber(amount, i18n.language, 2),
  //   });
  // }

  // return t(diff > 0 ? 'orders.redemptionAmountLess' : 'orders.redemptionAmountMore', {
  //   amount: formatNumber(amount, i18n.language, 2),
  //   diff: formatNumber(Math.abs(diff), i18n.language, 2),
  // });

  const getApprovedSubscriptionTranslationMessage = (amount: number, actualAmount: number) =>
    // Commented by Saad's requested for not confusing user(maybe should be uncommented later)
    // const diff = amount - actualAmount;

    // if (!diff) {
    t('orders.subscriptionAmount', {
      amount: formatNumber(actualAmount, i18n.language, 2),
    });
  // }
  //
  // return t("orders.subscriptionAmountWithDifference", {
  //   amount: formatNumber(amount, i18n.language, 2),
  //   diff: formatNumber(Math.abs(diff), i18n.language, 2),
  // });

  const renderHistoryItem = (item: SubscriptionsHistoryDataItem) => {
    const statusInfo = mapStatusToInfo[item.status.name] || {};
    const actualAmount = item.userPortfolio.actualAmount + item.userPortfolio.cacheAllocationValue;
    const isApproved = [SubscriptionStatus.APPROVED, SubscriptionStatus.PARTIAL_APPROVED].includes(item.status.name);
    const isRedemption = item.type.name === SubscriptionType.REDEMPTION;
    // eslint-disable-next-line no-nested-ternary
    const amount = isRedemption
      ? item.changes?.redeemAll
        ? t('orders.redeemAll')
        : formatNumber(item.changes?.amount, i18n.language, 2)
      : formatNumber(Number(item.userPortfolio.fundAmount) || 0, i18n.language, 2);
    const approvedMessage = isRedemption
      ? getApprovedRedemptionTranslationMessage(item.userPortfolio.fundAmount, actualAmount)
      : getApprovedSubscriptionTranslationMessage(item.userPortfolio.fundAmount, actualAmount);

    const message = isApproved
      ? approvedMessage
      : t(isRedemption ? 'orders.redemptionAmount' : 'orders.subscriptionAmount', { amount });

    const product = isEnglish
      ? item.userPortfolio.subscribedPortfolio?.productNameEn
      : item.userPortfolio.subscribedPortfolio?.productNameAr;

    return (
      <Grid item xs={12} key={item.id}>
        <Grid
          container
          rowSpacing="8px"
          flexDirection="column"
          component={Paper}
          elevation={0}
          sx={{ p: '24px', borderRadius: '12px' }}
        >
          <Grid item display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                fontSize: { xs: '16px', md: '20px' },
                lineHeight: '24px',
                color: COLORS.DARK_GREY,
              }}
            >
              {t(`orders.type.${item.type.name}`, { portfolio: product })}
            </Typography>
            <Box
              sx={{
                padding: { xs: '12px', md: '12px 28px' },
                background: statusInfo.color,
                borderRadius: '4px',
                minWidth: { xs: 'fit-content', md: 'unset' },
                ml: 1,
                textAlign: 'center',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontSize: '11px',
                  lineHeight: '22px',
                  color: COLORS.WHITE,
                }}
              >
                {t(`orders.status.${item.status.name}`)}
              </Typography>
            </Box>
          </Grid>
          <Grid item display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography
              variant="h4"
              sx={{
                fontWeight: 500,
                fontSize: { xs: '20px', md: '28px' },
                lineHeight: { xs: '24px', md: '36px' },
                color: COLORS.MAIN_DARK,
              }}
            >
              {message.replace(/٬/g, ',')}
            </Typography>
            <Typography
              variant="bodySmall"
              sx={{
                fontWeight: 600,
                fontSize: { xs: '12px', md: '14px' },
                lineHeight: '20px',
                color: COLORS.LIGHT_GRAY,
              }}
            >
              {moment(item.createdAt).locale(i18n.language).format('MMM D YYYY')}
            </Typography>
          </Grid>
          <Grid item display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography
              variant="bodySmall"
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
                color: COLORS.LIGHT_GRAY,
              }}
            >
              {`${t(`orders.${item?.userPortfolio?.subscribedPortfolio?.id ? 'portfolio' : 'goal'}`)}:`}
              <span>&nbsp;</span>
              <Typography
                variant="bodySmall"
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: COLORS.DARK_GREY,
                }}
              >
                {isEnglish
                  ? item?.userPortfolio?.subscribedPortfolio?.nameEn
                  : item?.userPortfolio?.subscribedPortfolio?.nameAr}
              </Typography>
            </Typography>
            {/* {!matchMobileView && renderActionLink(item)} */}
          </Grid>
          {/* {matchMobileView && (
            <Grid item display="flex" flexDirection="row" justifyContent="center" alignItems="center">
              {renderActionLink(item)}
            </Grid>
          )} */}
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        padding: { xs: '56px 24px', md: '32px 32px 0 32px' },
        height: 'calc(100vh - 275px)',
        mt: { xs: '-56px', md: 'auto' },
      }}
    >
      <Grid container flexDirection="column" justifyContent="space-between">
        <Grid item>
          <Typography component="h1" variant="h5" sx={{ fontWeight: 500, fontSize: '24px', lineHeight: '32px' }}>
            {t('orders.title')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          rowSpacing="18px"
          justifyContent="space-between"
          sx={{
            p: '3px',
            mt: '32px',
            height: '100%',
            maxHeight: 'calc(100vh - 370px)',
            '@media screen and (orientation: landscape)': {
              minHeight: {
                xs: '40vh !important',
                sm: '40vh !important',
              },
            },
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '5px !important',
              backgroundColor: 'transparent !important',
              borderRadius: '4px !important',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#111827 !important',
              borderRadius: '4px !important',
            },
          }}
        >
          {isLoading && <Loader />}
          {subscriptionsHistory.data.items.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', color: COLORS.LIGHT_GRAY }}
            >
              <Typography variant={isEnglish ? 'smallDisplayH' : 'cairoHeader1B'} gutterBottom>
                {t('noItems')}
              </Typography>
            </Box>
          )}
          {subscriptionsHistory.data.items.map(renderHistoryItem)}
          {subscriptionsHistory.data.items.length < subscriptionsHistory.data.totalItems && (
            <Grid item width="100%" display="flex" justifyContent="center">
              <MButton
                variant="contained"
                onClick={loadMore}
                disabled={subscriptionsHistory.isLoading}
                buttonProps={{
                  sx: {
                    padding: '8px 24px',
                    borderRadius: '8px',
                    boxShadow: 'none',
                  },
                }}
                typographyProps={{
                  variant: !isEnglish ? 'cairoBodyB' : 'bodySmallM',
                }}
                id="SearchButton"
              >
                {t('orders.loadMore')}
              </MButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Dialog open={openSuccessfulDialog} onClose={closeDialog} disableScrollLock>
        <Box sx={{ padding: '32px 24px' }}>
          <RequestInvestSuccessfulScreen onSuccessButtonClick={onSuccessButtonClick} />
        </Box>
      </Dialog>
    </Box>
  );
};

export default OrdersView;
