import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Grid, Radio, RadioGroup, FormControlLabel, FormControl, Button } from '@mui/material';
import COLORS from '../constants/colors';
import { madkhoulBankInfoSelector } from '../store_deprecated/selectors/walletSelectors';
import MButton from './MButton';
import FundAmountField from '../components/User/Education/FundAmountField';
import { ar2En, round2Format } from '../utils/number';
import { formatValue } from '../helpers/string';
import useHyperPay from '../helpers/hooks/useHyperPay';
import PaymentHyperPayProcessingScreen from './PaymentHyperPayProcessingScreen';
import PaymentSuccessfulScreen from './PaymentSuccessfulScreen';
import PaymentFailureScreen from './PaymentFailureScreen';
import SelectInput from './SelectInput';
import useGetUserProducts from '../helpers/hooks/useGetUserProducts';
import { ReduxState, useTypedDispatch } from '../redux/store';
import { setProductToInvest } from '../redux/features/products/selectedCardToInvest';
import { fetchAnbMasterardConfigAction } from '../store_deprecated/actions/walletActions';

interface PayWithMadaOrVisaOrMasterCardProps {
  closeDialog: (success?: boolean) => void;
  minAmount?: number;
  PaymentMethodChild: React.ReactNode;
  shouldShowProductList?: boolean;
  providerName: 'hyperPayMada' | 'hyperPayVisaOrMaster';
  addCardProviderName: 'addCardHyperPayVisaOrMaster' | 'addCardHyperPayMada';
  amount: string;
  handleAmountField: (event: any) => void;
  page?: string;
}

export default function PayWithMadaOrVisaOrMasterCard(props: PayWithMadaOrVisaOrMasterCardProps) {
  const {
    closeDialog = () => {},
    minAmount = 1,
    PaymentMethodChild,
    providerName,
    addCardProviderName,
    shouldShowProductList,
    amount,
    handleAmountField,
    page,
  } = props;
  const { t, i18n } = useTranslation();

  const isAmountError = Boolean(amount) && +amount < minAmount;
  const isArabic = i18n.language === 'ar';
  const [depositConfig, setDepositConfig] = useState<any | null>(null);

  const dispatch = useTypedDispatch();

  const bankItem = useSelector(madkhoulBankInfoSelector);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const anbMastercardConfig = await dispatch(fetchAnbMasterardConfigAction());

        if (anbMastercardConfig?.url && anbMastercardConfig?.version) {
          setDepositConfig({
            anbMastercardCheckoutBaseUrl: anbMastercardConfig.url,
            anbMastercardCheckoutVersion: anbMastercardConfig.version,
          });
        }
      } catch (e) {
        console.log('Some error');
      }
    };

    fetchInitialData();
  }, []);

  const {
    hyperPayCheckoutId,
    isLoading,
    onCloseDepositDialog,
    onSuccessButtonClick,
    showFailureScreen,
    showHyperPayProcessingScreen,
    showSuccessfulScreen,
  } = useHyperPay({
    ...depositConfig,
    anbMastercardCheckoutCancelCallBack: 'anbMastercardCheckoutCancelCallback',
    page: 'dashboard',
  });

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    closeDialog();
  };

  const products: any = useSelector((storeData: ReduxState) => storeData.products.products);

  const { backendProductId } = useSelector((storeData: ReduxState) => storeData.selectedCardToInvest);

  const handleChange = (param: any) => {
    dispatch(setProductToInvest({ backendProductId: param.target.value, toScreen: page }));
  };

  const [selectedOption, setSelectedOption] = useState('oneTime');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleButtonClick = () => {
    if (selectedOption === 'oneTime') {
      onCloseDepositDialog(providerName, parseInt(amount, 10));
    } else {
      onCloseDepositDialog(addCardProviderName, parseInt(amount, 10));
    }
  };

  const renderContent = () => (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" rowSpacing="48px" padding="0px ">
      <Grid item textAlign="right" alignSelf="end">
        <IconButton onClick={() => closeDialog()}>
          <CloseIcon sx={{ fontSize: '12px', color: COLORS.MAIN_DARK }} />
        </IconButton>
      </Grid>
      <Grid item sx={{ paddingTop: { xs: '24px !important', md: '0px !important' } }}>
        <Typography
          variant="h1"
          sx={{
            fontWeight: 500,
            fontSize: { xs: '24px', md: '44px' },
            lineHeight: { xs: '32px', md: '48px' },
            color: COLORS.MAIN_DARK,
          }}
        >
          {t('depositDialog.title')}
        </Typography>
      </Grid>

      {shouldShowProductList && (
        <Grid item container xs={12} sx={{ marginTop: 8 }}>
          <SelectInput
            id="productList"
            name="products"
            label="depositDialog.productListTitle"
            selectPlaceholder="depositDialog.select"
            values={products?.map((product: any) => ({
              label: isArabic ? product?.productType?.nameAr : product?.productType?.nameEn,
              value: product?.backendProductId,
            }))}
            handleChange={handleChange}
            required
            value={backendProductId}
          />
        </Grid>
      )}
      <Grid item sx={{ width: '100%' }}>
        <FundAmountField
          isArabic={isArabic}
          value={formatValue(isArabic, amount.toString()).replace(/٬/g, ',')}
          label="depositDialog.foundAmountFieldLabel"
          placeholder="depositDialog.foundAmountFieldPlaceholder"
          handleChange={handleAmountField}
        />
        <Typography
          variant="bodySmall"
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {t('depositDialog.note', { val: '1,000' })}
        </Typography>
      </Grid>

      {PaymentMethodChild}
      <Grid container rowSpacing="24px" marginTop={0}>
        <Grid item width="100%">
          <Typography
            variant={isArabic ? 'cairoM' : 'bodyMedium'}
            color={COLORS.MAIN_DARK}
            textAlign="justify"
            paragraph
            sx={{ marginBottom: 1 }}
          >
            {t('depositDialog.selectAction')}
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup value={selectedOption} onChange={handleRadioChange}>
              <FormControlLabel value="oneTime" control={<Radio />} label={t('depositDialog.oneTimePayment')} />
              <FormControlLabel value="addCard" control={<Radio />} label={t('depositDialog.addCard')} />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item width="100%">
          <MButton
            variant="contained"
            onClick={handleButtonClick}
            id="PaymentButton"
            disabled={
              !amount ||
              isAmountError ||
              isLoading ||
              (shouldShowProductList && products.length === 0 && backendProductId === undefined)
            }
          >
            {t('confirm')}
          </MButton>
        </Grid>
        <Grid item width="100%">
          <MButton variant="outlined" onClick={handleClose} disabled={isLoading} fullWidth>
            {t('cancel')}
          </MButton>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      {!showHyperPayProcessingScreen && !showSuccessfulScreen && !showFailureScreen && renderContent()}
      {showHyperPayProcessingScreen && (
        <PaymentHyperPayProcessingScreen
          checkoutId={hyperPayCheckoutId}
          isRecurring={false}
          onCloseRecurringSettings={onSuccessButtonClick}
        />
      )}
      {showSuccessfulScreen && <PaymentSuccessfulScreen onSuccessButtonClick={onSuccessButtonClick} />}
      {showFailureScreen && <PaymentFailureScreen onSuccessButtonClick={onSuccessButtonClick} />}
    </>
  );
}
