// Survey
export const GET_CLIENT_CONFIG = 'app/client/user-survey-config';
export const START_SURVEY = 'app/survey/start-new-survey';
export const GET_QUESTION = 'app/survey/survey-question';
export const ANSWER_QUESTION = 'app/survey/answer-survey-question';
export const GET_ANSWERED_QUESTION = 'app/survey/survey-question-answer';
export const FINISH_SURVEY = 'app/survey/calculate';
export const GET_LAST_SURVEY_RESULT = 'app/survey/last-result';

// Portfolio
export const GET_RECOMMENDED_PORTFOLIO = 'app/portfolio/recommended';
export const GET_RECOMMENDED_PORTFOLIO_BY_PRODUCT = 'app/portfolio/recommended/product-type';
export const GET_SUBSCRIBED_PORTFOLIO = 'app/portfolio/subscribed';
export const DO_SUBSCRIBE_TO_PORTFOLIO = 'app/portfolio/subscribe';
export const DO_ADD_MORE_FUND_TO_PORTFOLIO = (id: number) => `app/portfolio/subscribed/${id}/add-more-fund`;
export const GET_SUBSCRIPTIONS_HISTORY = 'app/portfolio/subscriptions/history';
export const REDEEM_PORTFOLIO = 'app/portfolio/subscribed';
export const CANCEL_SUBSCRIPTION = 'app/portfolio/subscriptions';
export const GET_PORTFOLIO_LATEST_STATISTICS = 'app/portfolio/statistics/latest';
export const GET_PORTFOLIO_STATISTICS = 'app/portfolio/statistics';
export const GET_PORTFOLIO = 'app/portfolio';

// Index Fund
export const GET_INDEX_FUND_DATA = 'app/index-fund';
export const GET_FUND_CHART_DATA = 'app/index-fund';
export const GET_USER_INDEX_FUNDS_DATA = 'app/user/index-funds';

// Transactions
export const GET_TRANSACTIONS_HISTORY = 'app/transactions/user-transactions';

// Bank
export const GET_BANK_INFO = 'app/user-bank';
export const SAVE_BANK_INFO = 'app/user-bank';
export const DELETE_BANK_INFO = 'app/user-bank';
export const GET_EDIT_BANK_INFO = 'app/user-bank';
export const GET_BANKS = 'app/user-bank/banks-autocomplete';
export const SET_PRIMARY_BANK_ACCOUNT = 'app/user-bank/set-primary-account';

// Contact Us
export const CONTACT_US = 'anonymous/contact-us';

// FAQs
export const GET_FAQS = 'app/faqs';

// Profile
export const GET_PROFILE_DATA = 'app/user/profile';
export const PUT_PROFILE_DATA = 'app/user/profile';

// Iam
export const GET_IAM_AUTHORIZATION = 'iam/auth/authorize';
export const GET_RAND_AUTH = 'iam/auth/login-request';
export const GET_NAFAZ_STATUS = 'iam/auth/status';

// Docs
export const GET_DOCS_TYPE = 'app/attachments/document-types';
export const UPLOAD_DOC = 'media/upload';
export const GET_DOC_URL = 'media/upload';
export const SAVE_ATTACHED_DOCS = 'app/attachments';
export const GET_ATTACHED_DOCS = 'app/attachments';

// USER
export const GET_USER_SIGNED_AGREEMENT = 'app/user-sign-agreement';
export const SIGN_USER_AGREEMENT = 'app/user-sign-agreement';
export const DO_WITHDRAW = 'app/user/withdraw';
export const ANB_MASTERCARD_CONFIG = 'app/anb-mastercard/config';
export const CREATE_ANB_MASTERCARD_SESSION = 'app/anb-mastercard/session';
export const CREATE_APPLE_PAY_PAYMENT = 'app/anb-mastercard/order';
export const CREATE_APPLE_PAY_SESSION = 'app/apple/session';
export const CREATE_HYPER_PAY_CHECKOUT = 'app/hyper-pay/checkout';
export const QUERY_HYPER_PAY_PAYMENT_STATUS = 'app/hyper-pay/payment/status';
export const QUERY_HYPER_PAY_REGISTRATION_STATUS = 'app/hyper-pay/registration/status';
export const SAVE_SUBSCRIPTION = 'app/subscriptions';
export const GET_SUBSCRIPTIONS = 'app/subscriptions';
export const SET_PRIMARY_SUBSCRIPTION_CARD = 'app/subscriptions/set-primary-card';
export const DELETE_SUBSCRIPTION = 'app/subscriptions';
export const GET_CREATED_SUBSCRIPTION = 'app/subscriptions/created-subscription';
export const GET_NET_WITHDRAW = 'app/user/withdraw/net';
export const GET_STATEMENT_DELTA = 'app/user/payment/delta';
export const GET_MINIMUM_ADD_FUND = (id: number) => `app/portfolio/subscribed/${id}/minimum-add-fund`;
// Alpaca
export const GET_ALPACA_ACTIVITIES = 'app/user/alpaca/activities';
export const GET_ALPACA_DIVIDENDS = 'app/user/alpaca/dividends';
