import * as yup from 'yup';
import PasswordValidationStatus from '../../constants/PasswordValidationStatus';
import {
  addBankAccountFormFields,
  redemptionFormFields,
  resetPasswordEmailFormFields,
  resetPasswordMobileFormFields,
  resetPasswordNewFormFields,
} from '../fields/formFields';
import { emailSchema } from './common/emailSchema';
import './common/extends';

export * from './profileData';
export * from './changePassword';
export * from './nafazData';

export const addBankAccountFormSchema = yup.object({
  [addBankAccountFormFields.bankType]: yup.number().required('bankTypeIsRequired'),
  [addBankAccountFormFields.bankName]: yup.string().trim().required('bankNameIsRequired'),
  [addBankAccountFormFields.fullName]: yup.string().trim().required('fullNameIsRequired'),
  [addBankAccountFormFields.ibanNumber]: yup
    .string()
    .trim()
    .iban('invalidIbanFormat')
    .uppercase()
    .strict()
    .required('bankAccountIbanNumberIsRequired'),
  [addBankAccountFormFields.accountNumber]: yup.string().trim().required('bankAccountNumberIsRequired'),
  [addBankAccountFormFields.swiftCode]: yup
    .string()
    .trim()
    .matches(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/, 'invalidSwiftFormat')
    .required('swiftCodeIsRequired'),
});

export const dynamicValidationValues = {
  redemptionMaxAmount: Infinity,
};

export const getRedemptionFormSchema = (minimumRedemption: number) =>
  yup.object({
    [redemptionFormFields.amount]: yup.number().when([redemptionFormFields.redeemAll], {
      is: false,
      then: (schema) =>
        schema
          .positive('redemption.amountPositive')
          .min(minimumRedemption, 'redemption.minimumAmount')
          .max(dynamicValidationValues.redemptionMaxAmount, 'redemption.maximumAmount')
          .required('redemption.amountIsRequired'),
    }),
    [redemptionFormFields.reason]: yup.string().trim().required('redemption.reasonIsRequired'),
    [redemptionFormFields.redeemAll]: yup.boolean().required(),
  });

export const resetPasswordMobileFormSchema = yup.object({
  [resetPasswordMobileFormFields.mobile]: yup.string().trim().required('missingField').phone('invalidFormat'),
});

export const resetPasswordEmailFormSchema = yup.object({
  [resetPasswordEmailFormFields.email]: emailSchema,
});

export const resetPasswordNewFormSchema = yup.object({
  [resetPasswordNewFormFields.newPassword]: yup.string().required('missingField').trim().strongPassword({
    minLengthMessage: PasswordValidationStatus.SHORT_PASSWORD,
    maxLengthMessage: 'maxLengthPasswordError',
    lowerCaseMessage: PasswordValidationStatus.COMBINATION_SEQUENCE_OR_CONSECUTIVE_PATTERN,
    consecutiveMessage: PasswordValidationStatus.COMBINATION_SEQUENCE_OR_CONSECUTIVE_PATTERN,
    sequentialMessage: PasswordValidationStatus.COMBINATION_SEQUENCE_OR_CONSECUTIVE_PATTERN,
    upperCaseMessage: PasswordValidationStatus.AT_LEAST_UPPERCASE_CHAR,
    digitMessage: PasswordValidationStatus.COMBINATION_SEQUENCE_OR_CONSECUTIVE_PATTERN,
    specialMessage: PasswordValidationStatus.COMBINATION_SEQUENCE_OR_CONSECUTIVE_PATTERN,
  }),
  [resetPasswordNewFormFields.confirmNewPassword]: yup
    .string()
    .oneOf([yup.ref(resetPasswordNewFormFields.newPassword), undefined], 'passwordsMustMatch')
    .required('passwordsMustMatch'),
});
