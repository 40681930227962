import Snackbar from '@mui/material/Snackbar';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as semver from 'semver';
import packageJson from '../../../../package.json';
import COLORS from '../../../constants/colors';
import { useGetAppConfigQuery } from '../../../redux/features/config/config.apiSlice';
import * as serviceWorkerRegistration from '../../../serviceWorkerRegistration';
import MButton from '../../../shared/MButton';
import { ConfigKey } from '../../../types/config';

export const ServiceWorkerWrapper: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [showReload, setShowReload] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

  const { data: appConfig } = useGetAppConfigQuery(ConfigKey.WEB_APP_VERSION);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const onSWSuccess = (registration: ServiceWorkerRegistration) => {
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    const checkRemoteVersion = async () => {
      const serverVersion = appConfig?.value || '0.0.0';

      const localVersion = packageJson.version;

      if (semver.gt(serverVersion, localVersion)) {
        setShowReload(true);
        console.info('[ServiceWorkerWrapper serverVersion new]', { serverVersion, localVersion });
      }

      localStorage.setItem('serverVersion', serverVersion);
      localStorage.setItem('localVersion', localVersion);
    };

    checkRemoteVersion();

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    // serviceWorkerRegistration.register();

    if (JSON.parse(process.env.REACT_APP_REGISTER_SW || 'false') === true) {
      serviceWorkerRegistration.register({ onUpdate: onSWUpdate, onSuccess: onSWSuccess });
    } else {
      serviceWorkerRegistration.unregister();
    }
  }, [appConfig]);

  // effect added from router location to check for a new service worker on every
  // page transition (change of route).
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        setWaitingWorker(registration.waiting);
        registration?.update();
      });
    }
  }, [location]);

  const reloadPage = () => {
    setShowReload(false);

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        if (registration.waiting) {
          // send the skip message to kick off the service worker install.
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          // add an listener to reload page when the new service worker is ready.
          registration.waiting.addEventListener('statechange', (event: Event) => {
            const { state = '' } = (event.target as unknown as { state: string }) || {};
            if (state === 'activated') {
              window.location.reload();
            }
          });
        }
      });
    }
  };

  return (
    <Snackbar
      open={showReload}
      message={t('newVersion')}
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{
        '& .MuiSnackbarContent-root': {
          background: COLORS.LINEAR_GRADIENT,
        },
      }}
      action={
        <MButton
          id="ReloadNewVersion"
          variant="text"
          onClick={reloadPage}
          buttonProps={{
            sx: {
              padding: 0,
              size: 'small',
              color: 'inherit',
              textTransform: 'none',
              margin: '0!important',
            },
          }}
        >
          {t('reload')}
        </MButton>
      }
    />
  );
};
