export const BRANDS: Record<string, { image: string; url: string }> = {
  'exxon mobil corp': {
    image: 'exxonmobillogocolor2x.png',
    url: 'https://corporate.exxonmobil.com/',
  },
  'johnson & johnson': {
    image: 'johnson-johnson.png',
    url: 'https://www.jnj.com/',
  },
  'chevron corp': {
    image: 'chevron.png',
    url: 'https://www.chevron.com/',
  },
  'procter & gamble': {
    image: 'P&G_logo.png',
    url: 'https://pg.com/',
  },
  'pfizer inc': {
    image: 'pfizer.png',
    url: 'https://www.pfizer.com/',
  },
  'merck & co inc': {
    image: 'merck.svg',
    url: 'https://www.merck.com/',
  },
  'shell plc': {
    image: 'shell.svg',
    url: 'https://www.shell.com/',
  },
  'danaher corp': {
    image: 'danaher.png',
    url: 'https://www.danaher.com/',
  },
  'cisco systems inc': {
    image: 'cisco-logo-1.png',
    url: 'https://www.cisco.com/',
  },
  'abbott laboratories': {
    image: 'Abbott_Laboratories_logo.svg',
    url: 'https://www.abbott.com/',
  },
  'novartis ag': {
    image: 'novartis.svg',
    url: 'https://www.novartis.com/',
  },
  'linde plc': {
    image: 'Linde_plc_logo.png',
    url: 'https://www.linde.com/',
  },
  conocophillips: {
    image: 'conocophillipslogobig.png',
    url: 'https://www.conocophillips.com/',
  },
  'adobe inc': {
    image: 'Adobe_Systems_Logo_002.svg',
    url: 'https://www.adobe.com/',
  },
  'salesforce inc': {
    image: 'salesforce.svg',
    url: 'https://www.salesforce.com/',
  },
  'bhp group ltd': {
    image: 'bhp-orange.png',
    url: 'https://www.bhp.com/',
  },
  totalenergies: {
    image: 'totalenergies.png',
    url: 'https://totalenergies.com/',
  },
  'intel corporation corp': {
    image: 'Intel_1968_logo.png',
    url: 'https://www.intel.com/',
  },
  'advanced micro devices inc': {
    image: 'amd.png',
    url: 'https://www.amd.com',
  },
  sap: {
    image: 'sap.svg',
    url: 'https://www.sap.com/',
  },
  'bp plc': {
    image: 'bp-logo.svg',
    url: 'https://www.bp.com/',
  },
  'medtronic plc': {
    image: 'mdt_logo_white.png',
    url: 'https://europe.medtronic.com/',
  },
  'intuit inc': {
    image: 'intuit.png',
    url: 'https://www.intuit.com/',
  },
  'prologis reit inc': {
    image: 'PROLOGIS.png',
    url: 'https://www.prologis-reit.co.jp',
  },
  'samsung electronics': {
    image: 'samsung.png',
    url: 'https://www.samsung.com/',
  },
  'reliance industries ltd': {
    image: 'RIL_Logo.png',
    url: 'https://www.ril.com/',
  },
  'vale sa': {
    image: 'Vale-logo.svg',
    url: 'https://www.vale.com/',
  },
  'sk hynix inc': {
    image: 'skhynix.png',
    url: 'https://www.skhynix.com/',
  },
  'samsung sdi ltd': {
    image: 'SAMSUNG SDI LTD.png',
    url: 'https://www.samsungsdi.com',
  },
  'lg chem ltd': {
    image: 'lg chem.png',
    url: 'https://www.lgchem.com',
  },
  'hindustan unilever ltd': {
    image: 'HINDUSTAN UNILEVER LTD.svg',
    url: 'https://www.hul.co.in/',
  },
  'emirates telecom': {
    image: 'etisalat-logo.svg',
    url: 'https://www.etisalat.ae/',
  },
  'petroleo brasileiro pref sa': {
    image: 'PETROLEO BRASILEIRO PREF SA.svg',
    url: 'https://petrobras.com.br',
  },
  'kuwait finance house': {
    image: 'KUWAIT FINANCE HOUSE.png',
    url: 'https://www.kfh.com/',
  },
  posco: {
    image: 'Posco.png',
    url: 'https://www.posco.com/',
  },
  'asian paints ltd': {
    image: 'asian-paint-logo.png',
    url: 'https://www.asianpaints.com/',
  },
  'maruti suzuki india ltd': {
    image: 'maruti-logo.png',
    url: 'https://www.marutisuzuki.com/',
  },
  'china resources land ltd': {
    image: 'CHINA RESOURCES LAND LTD.png',
    url: 'https://en.crland.com.hk/',
  },
  'kia corporation corp': {
    image: 'kia.png',
    url: 'https://www.kia.com',
  },
  'china mengniu dairy ltd': {
    image: 'china Mengniu.png',
    url: 'http://www.mengniu.com.cn/',
  },
  'adani total gas ltd': {
    image: 'adani_gas.svg',
    url: 'https://www.adanigas.com/',
  },
  'telekomunikasi indonesia': {
    image: 'TELEKOMUNIKASI INDONESIA.svg',
    url: 'https://www.telkom.co.id/sites',
  },
  'grupo mexico b': {
    image: 'GRUPO MEXICO B.svg',
    url: 'https://www.gmexico.com/',
  },
  'sun pharmaceutical industries ltd': {
    image: 'sun.png',
    url: 'https://sunpharma.com/',
  },
  'samsung biologics ltd': {
    image: 'Samsung_Biologics_Logo.svg.png',
    url: 'https://samsungbiologics.com/',
  },
  'formosa plastics corp': {
    image: 'FORMOSA PLASTICS CORP.svg',
    url: 'https://www.fpcusa.com/',
  },
  'china petroleum and chemical corp': {
    image: 'CHINA PETROLEUM AND CHEMICAL CORP.png',
    url: 'http://www.sinopec.com/listco/en/',
  },
  'qatar islamic bank': {
    image: 'Qatar_Islamic_Bank_logo.svg.png',
    url: 'https://www.qib.com.qa/en/',
  },
  'china steel corp': {
    image: 'China_Steel_Logo.png',
    url: 'http://www.csc.com.tw/indexe.html',
  },
  'nan ya plastics corp': {
    image: 'NAN YA PLASTICS CORP.png',
    url: 'http://www.npc.com.tw/j2npc/enus/',
  },
  'enn energy holdings ltd': {
    image: 'ENN ENERGY HOLDINGS LTD.png',
    url: 'http://ir.ennenergy.com/en/global/home.php',
  },
  'hyundai mobis ltd': {
    image: 'Hyundai_Mobis_logo.svg.png',
    url: 'http://www.mobis.co.kr/',
  },
  'china overseas land investment ltd': {
    image: 'China_Overseas_Land_and_Investment_Limited.png',
    url: 'http://www.coli.com.hk/',
  },
  'gold fields ltd': {
    image: 'gold-fields-logo.svg',
    url: 'https://www.goldfields.com/',
  },
  'impala platinum ltd': {
    image: 'Impala_Platinum_logo.svg.png',
    url: 'http://www.implats.co.za/',
  },
  'china shenhua energy ltd h': {
    image: 'CHINA SHENHUA ENERGY LTD H.png',
    url: 'http://www.csec.com/',
  },
  'tata steel ltd': {
    image: 'Tata_Steel_Logo.svg',
    url: 'https://www.tatasteel.com/',
  },
  petrochina: {
    image: 'petrochina.png',
    url: 'http://www.petrochina.com.cn/',
  },
  'zto express': {
    image: 'zto.png',
    url: 'https://zto.investorroom.com/',
  },
  'bangkok dusit medical services non': {
    image: 'bdms-logo1.png',
    url: 'https://www.bdms.co.th/',
  },
  'centrais eletr bras-eletrobras': {
    image: 'Eletrobras.png',
    url: 'http://eletrobras.com/en/',
  },
  'ultratech cement ltd': {
    image: 'ULTRATECH CEMENT LTD.png',
    url: 'http://www.ultratechcement.com/',
  },
  'apple inc': {
    image: 'Apple_logo_black-svg.png',
    url: 'https://www.apple.com/',
  },
  'microsoft corp': {
    image: 'microsoft.png',
    url: 'https://www.microsoft.com/',
  },
  'tesla inc': {
    image: 'Tesla_Motors.svg.png',
    url: 'https://www.tesla.com/',
  },
  'alphabet inc class a': {
    image: 'Alphabet Inc Class A.png',
    url: 'https://abc.xyz/',
  },
  'nvidia corp': {
    image: 'nvidia.png',
    url: 'https://www.nvidia.com/',
  },
  'meta platforms inc class a': {
    image: 'meta.png',
    url: 'https://www.facebook.com/',
  },
  'the home depot inc': {
    image: 'home depot.png',
    url: 'http://www.homedepot.com/',
  },
  'eli lilly and co': {
    image: 'Eli_Lilly_and_Company.png',
    url: 'https://www.lilly.com/Pfizer',
  },
  'pepsico inc': {
    image: 'PepsiCo_logo.svg',
    url: 'https://www.pepsico.com/',
  },
  'coca-cola co': {
    image: 'The_Coca-Cola_Company_(2020).svg.png',
    url: 'https://coca-colacompany.com/',
  },
  'broadcom inc': {
    image: 'Broadcom_Ltd_Logo.svg.png',
    url: 'http://broadcom.com/',
  },
  'thermo fisher scientific inc': {
    image: 'Thermo_Fisher_Scientific_logo.svg',
    url: 'http://www.thermofisher.com/',
  },
  'texas instruments inc': {
    image: 'TexasInstruments-Logo.svg',
    url: 'http://ti.com/',
  },
  'al rajhi banking & investment corp.': {
    image: 'alrajhibank.png',
    url: 'https://www.alrajhibank.com.sa',
  },
  'saudi basic industries corp': {
    image: 'SABIC_logo.svg',
    url: 'https://www.sabic.com/',
  },
  'saudi arabian oil company (saudi aramco)': {
    image: 'Saudi_Aramco_logo.svg',
    url: 'https://www.aramco.com/',
  },
  'saudi telecom': {
    image: 'STC-01.png',
    url: 'http://www.stc.com.sa/',
  },
  'saudi arabian mining company': {
    image: 'Saudi Arabian Mining Company.png',
    url: 'http://www.maaden.com.sa/en',
  },
  'alinma bank': {
    image: 'Alinma_logo.png',
    url: 'http://www.alinma.com/',
  },
  'saudi arabian fertilizer company': {
    image: 'Safco_logo.svg.png',
    url: 'https://www.safco.com.sa/',
  },
  'bank al bilad': {
    image: 'Bank_albilad.png',
    url: 'https://www.bankalbilad.com/',
  },
  'sahara international petrochemical co.': {
    image: 'sahara.png',
    url: 'http://www.sipchem.com/',
  },
  'saudi electricity': {
    image: 'Saudi_Electricity_Company_logo.png',
    url: 'http://www.se.com.sa/',
  },
  almarai: {
    image: 'Almarai_Corporate_Logo.svg.png',
    url: 'http://www.almarai.com/',
  },
  'acwa power co': {
    image: 'ACWA_Power_logo.png',
    url: 'http://acwapower.com/',
  },
  'yanbu national petrochemicals': {
    image: 'Yansab_Logo.png',
    url: 'http://www.yansab.com.sa/',
  },
  'bupa arabia': {
    image: 'Bupa_Arabia_logo.jpg',
    url: 'http://www.bupa.com.sa/',
  },
  'al mouwasat medical services': {
    image: 'mouwasat.png',
    url: 'http://www.mouwasat.com/',
  },
  'bank aljazira': {
    image: 'Aljazira_Bank_Logo.svg',
    url: 'https://www.baj.com.sa/',
  },
  'jarir marketing': {
    image: 'Jarir_Bookstore_logo.svg.png',
    url: 'https://www.jarir.com/',
  },
  'saudi kayan petrochemical': {
    image: 'saudikayan.png',
    url: 'http://www.saudikayan.com/',
  },
  'saudi industrial investment group': {
    image: 'SAUDI INDUSTRIAL INVESTMENT GROUP.png',
    url: 'http://www.siig.com.sa/',
  },
  'dar al arkan real estate development': {
    image: 'DAAR-Living.svg',
    url: 'https://www.daralarkan.com/',
  },
  'savola group': {
    image: 'Savola_Logo.png',
    url: 'https://www.savola.com',
  },
  'saudi research and media group': {
    image: 'Saudi_Research_and_Media_Group_New_Logo.png',
    url: 'https://www.srmg.com/en',
  },
  'advanced petrochemical': {
    image: 'advancedpetrochem.png',
    url: 'http://advancedpetrochem.com/',
  },
  'national industrialization': {
    image: 'NATIONAL INDUSTRIALIZATION.png',
    url: 'https://epca.eu/company/tasnee',
  },
  'emaar the economic city': {
    image: 'kaec.png',
    url: 'http://www.kaec.net/',
  },
  equinix: {
    image: 'Equinix_logo.png',
    url: 'http://equinix.com/',
  },
  'crown castle': {
    image: 'Crown_Castle_Logo.png',
    url: 'http://crowncastle.com/',
  },
  prologis: {
    image: 'Prologis_Logo.png',
    url: 'https://www.prologis.com/',
  },
  'public storage': {
    image: 'Public_Storage_Logo.png',
    url: 'http://publicstorage.com/',
  },
  'avalonbay communities': {
    image: 'Avalon-Bay-logo.jpg',
    url: 'https://www.avaloncommunities.com/',
  },
  'equity residential': {
    image: 'Equity_Residential_logo.png',
    url: 'http://equityapartments.com/',
  },
  'mid-america apartment communities': {
    image: 'MAA_logo-color.png',
    url: 'https://www.maac.com/',
  },
  'weyerhaeuser company': {
    image: 'Weyerhaeuser.png',
    url: 'https://www.weyerhaeuser.com/',
  },
  'essex property trust': {
    image: 'Essex Logo.png',
    url: 'https://www.essexapartmenthomes.com/',
  },
  'camden property trust': {
    image: 'Camden-property-trust-logo.png',
    url: 'http://camdenliving.com/',
  },
  'equity lifestyle properties': {
    image: 'els.png',
    url: 'https://www.equitylifestyleproperties.com/',
  },
  'segro plc': {
    image: 'SEGRO.png',
    url: 'https://www.segro.com/',
  },
  'rexford industrial realty': {
    image: 'Rexford_Industrial_Realty_Logo.png',
    url: 'https://www.rexfordindustrial.com/',
  },
  'eastgroup properties': {
    image: 'eastgroup.png',
    url: 'https://www.eastgroup.net/',
  },
  'first industrial realty trust': {
    image: 'first-industrial.png',
    url: 'https://www.firstindustrial.com/',
  },
  dgcx: {
    image: 'dgcx-logo.png',
    url: 'https://www.dgcx.ae/',
  },
  'albilad capital': {
    image: 'albilad-capital.png',
    url: 'https://www.albilad-capital.com/pages/en/fund/BasketInformation.aspx',
  },
  jadwa: {
    image: 'jadwa.png',
    url: 'https://jadwa.com/en/real-estate',
  },
  'jadwa global sukuk': {
    image: 'jadwa.png',
    url: 'https://jadwa.com/en/fixed-income#two',
  },
  'tadawul reit list': {
    image: 'tadawul.png',
    url: 'https://www.saudiexchange.sa/wps/portal/saudiexchange/ourmarkets/funds-market-watch/reits?locale=en',
  },
  'albilad capital reits': {
    image: 'albilad-capital-reit.png',
    url: 'https://www.albilad-capital.com/pages/en/fund/albiladfund%20of%20fundreit.aspx',
  },
  'snb capital reits': {
    image: 'snb.svg',
    url: 'https://www.alahlicapital.com/Pages/MutualFundDetails.aspx?MFId=47',
  },
  gld: {
    image: 'gld.svg',
    url: 'https://www.ssga.com/us/en/intermediary/etfs/funds/spdr-gold-shares-gld',
  },
};
