import { MenuItemClick, MenuItemLink, MenuItemSimple } from './types';

function isLinkMenuItem(menuItem: MenuItemClick | MenuItemLink): menuItem is MenuItemLink {
  return (menuItem as MenuItemLink).path !== undefined;
}

export function isSelectedItem(menuItem: MenuItemSimple, pathname: string): boolean {
  if (isLinkMenuItem(menuItem)) {
    if (menuItem.pathRegexp) {
      return menuItem.pathRegexp.test(pathname);
    }
    return pathname === menuItem.path;
  }

  return false;
}
