export enum profileDataFormFields {
  fullName = 'fullName',
  phone = 'phone',
  phoneOtp = 'phoneOtp',
  email = 'email',
  emailOtp = 'emailOtp',
  // address = "address",
  birthDate = 'birthDate',
  employerAddress = 'employerAddress',
  companyName = 'companyName',
  jobTitle = 'jobTitle',
  nationalId = 'nationalId',
}
