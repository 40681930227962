import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import COLORS from '../../../constants/colors';

type StockData = {
  title: string;
  leftAttrTitle: string;
  leftAttrValue: string;
  rightAttrTitle: string;
  rightAttrValue: string;
};

interface StockCardProps {
  data: StockData;
}

const StockCard = ({ data }: StockCardProps) => (
  <Paper
    sx={{ padding: '24px', mb: { xs: '12px', md: 'unset' }, minWidth: '285px' }}
    component={Grid}
    flexDirection="column"
    container
  >
    <Grid item sx={{ pb: '12px' }}>
      <Typography variant="bodyLargeM" sx={{ color: COLORS.MAIN_DARK }}>
        {data.title}
      </Typography>
    </Grid>
    <Grid item container flexDirection="row" justifyContent="space-between" sx={{ pb: '4px' }}>
      <Grid item xs={8} textAlign="left">
        <Typography
          variant="body2"
          sx={{
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '20px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {data.leftAttrTitle}
        </Typography>
      </Grid>
      <Grid item xs={4} textAlign="left">
        <Typography
          variant="body2"
          sx={{
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '20px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {data.rightAttrTitle}
        </Typography>
      </Grid>
    </Grid>
    <Grid item container flexDirection="row" justifyContent="space-between">
      <Grid item xs={8} textAlign="left">
        <Typography variant="bodyMediumB" sx={{ color: COLORS.MAIN_DARK }}>
          {data.leftAttrValue}
        </Typography>
      </Grid>
      <Grid item xs={4} textAlign="left">
        <Typography variant="bodyMediumB" sx={{ color: COLORS.SECONDARY_GREEN }}>
          {data.rightAttrValue}
        </Typography>
      </Grid>
    </Grid>
  </Paper>
);

export default StockCard;
