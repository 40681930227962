import React, { useEffect } from 'react';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import COLORS from '../constants/colors';
import { useLogoutMutation } from '../redux/features/auth/auth.apiSlice';
import { selectIsLoggedIn } from '../redux/features/auth/auth.selectors';
import { logOut } from '../redux/features/auth/auth.slice';

const LogoutIdleTimer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [remaining, setRemaining] = React.useState(0);
  const timeout = 1000 * 60 * 10;
  const promptBeforeIdle = 1000 * 60;
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [logout] = useLogoutMutation();

  const onPrompt = () => {
    if (isLoggedIn) {
      return setRemaining(promptBeforeIdle);
    }

    setOpen(true);
  };

  const onIdle = () => {
    setOpen(false);
    setRemaining(0);
    logout({});
    dispatch(logOut());
  };

  const onActive = (event?: Event, idleTimer?: IIdleTimer) => {
    if (idleTimer?.isPrompted()) {
      setOpen(false);
      setRemaining(0);
    }
  };

  const { activate, isPrompted, getRemainingTime } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt,
    onIdle,
    onActive,
    startOnMount: true,
    crossTab: true,
  });

  const handleClose = () => {
    setOpen(false);
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableScrollLock
    >
      <Box sx={{ padding: '32px 24px' }}>
        <DialogTitle
          id="alert-dialog-title"
          variant="h1"
          gutterBottom
          sx={{
            fontSize: 'clamp(2rem,3vw + 1rem,2.75rem)',
            fontWeight: 500,
            lineHeight: '48px',
          }}
        >
          {t('logoutIdleTimer.title')}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', alignItems: 'center' }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: COLORS.DARK_GREY,
              fontWeight: 500,
              fontSize: 'clamp(1rem,6vw + 1rem,1.125rem)',
              lineHeight: '28px',
            }}
          >
            {t('logoutIdleTimer.descriptionBeforeSeconds')}&nbsp;
            <span>{remaining}</span>
            &nbsp;{t('logoutIdleTimer.descriptionAfterSeconds')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onIdle}>
            <Typography
              sx={{
                color: COLORS.RED,
                fontWeight: 400,
                fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
                lineHeight: '24px',
                textTransform: 'capitalize',
              }}
              variant="body1"
            >
              {t('logoutIdleTimer.logoutButton')}
            </Typography>
          </Button>
          <Button onClick={handleClose}>
            <Typography
              sx={{
                color: COLORS.PRIMARY_BLUE,
                fontWeight: 400,
                fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
                lineHeight: '24px',
                textTransform: 'capitalize',
              }}
              variant="body1"
            >
              {t('logoutIdleTimer.stayButton')}
            </Typography>
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default LogoutIdleTimer;
