import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import COLORS from '../../../constants/colors';
import MButton from '../../../shared/MButton';

interface RequestInvestSuccessfulScreenProps {
  onSuccessButtonClick: () => void;
}

const RequestInvestSuccessfulScreen = ({ onSuccessButtonClick }: RequestInvestSuccessfulScreenProps) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  return (
    <>
      <Box
        sx={{
          padding: { xs: '24px', md: '48px' },
          maxWidth: { xs: '100%', md: '650px' },
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <img src="/images/icons/mail-sent.svg" alt="mail sent" />
        <Typography
          variant={isEnglish ? 'h6' : 'cairoButtonR'}
          color={COLORS.SECONDARY_GREEN}
          paragraph
          textAlign="center"
          sx={{ marginTop: 8, marginBottom: 8 }}
        >
          {t('invest.successfulRequest.title')}
        </Typography>
        <Typography
          variant={isEnglish ? 'h6' : 'cairoButtonR'}
          color={COLORS.DARK_GREY}
          paragraph
          textAlign="center"
          sx={{ marginBottom: 3 }}
        >
          {t('invest.successfulRequest.body')}
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          maxWidth: { xs: '100%', md: '650px' },
          margin: 'auto',
          mx: { xs: '24px', md: 'auto' },
        }}
      >
        <MButton
          id="RequestInvestSuccessDoneButton"
          variant="text"
          onClick={onSuccessButtonClick}
          fullWidth
          buttonProps={{
            sx: {
              height: { xs: '50px', md: 60 },
            },
          }}
        >
          {t('done')}
        </MButton>
      </Box>
    </>
  );
};

export default RequestInvestSuccessfulScreen;
