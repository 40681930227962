import { Logout } from './authTypes';

export const GET_FAQS_REQUEST = 'GET_FAQS_REQUEST';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_FAQS_FAIL = 'GET_FAQS_FAIL';
export const CLEAR_FAQ = 'CLEAR_FAQ';

export type FaqItem = {
  id: number;
  title: string;
  description: string;
};

export type Faq = {
  items: FaqItem[];
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};
export interface RequestGetFaqs {
  type: typeof GET_FAQS_REQUEST;
}

export interface RequestGetFaqsSuccess {
  type: typeof GET_FAQS_SUCCESS;
  payload: FaqItem[];
}

export interface RequestGetFaqsFail {
  type: typeof GET_FAQS_FAIL;
}

export interface ClearFaq {
  type: typeof CLEAR_FAQ;
}

export type FaqAction = RequestGetFaqs | RequestGetFaqsSuccess | RequestGetFaqsFail | Logout | ClearFaq;

export type FaqState = Readonly<{
  faq: Faq;
}>;
