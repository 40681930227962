import { ChartDataset, Chart as ChartJS } from 'chart.js';

export const gaugeArrow = {
  id: 'gaugeArrow',
  afterDraw(chart: ChartJS) {
    const datasets = chart.config.data.datasets as (ChartDataset<'doughnut', number[]> & { needleValue: number })[];
    const { needleValue } = datasets[0];
    const dataTotal: number = datasets[0].data.reduce((a, b) => a + b, 0);

    const { ctx } = chart;
    const cw = chart.canvas.offsetWidth;
    const ch = chart.canvas.offsetHeight;
    const cx = cw / 2;
    const cy = ch - ch / 4;
    // const cy = ch - (ch / 3.3);

    const bigCircleRadius = ch * 0.05;
    const smallCircleRadius = bigCircleRadius / 1.5;
    const thirdCircleRadius = smallCircleRadius / 1.5;
    // const circleCenterY = (ch - (ch / 4)) - bigCircleRadius;
    const circleCenterY = ch - ch / 4;
    const circleCenterX = cx;
    const arrowWidth = bigCircleRadius / 2;
    // const arrowLength = ch / 2.5 - bigCircleRadius;
    const arrowLength = ch / 2.5;

    const angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;

    ctx.translate(cx, cy);
    ctx.rotate(angle);
    ctx.beginPath();
    ctx.moveTo(0, -arrowWidth);
    ctx.lineTo(arrowLength, 0);
    ctx.lineTo(0, arrowWidth);
    ctx.fillStyle = '#7EA356';
    ctx.fill();
    ctx.rotate(-angle);
    ctx.translate(-cx, -cy);

    ctx.beginPath();
    ctx.arc(circleCenterX, circleCenterY, bigCircleRadius, 0, Math.PI * 2);
    ctx.fill();

    ctx.fillStyle = '#ffffff';

    ctx.beginPath();
    ctx.arc(circleCenterX, circleCenterY, smallCircleRadius, 0, Math.PI * 2);
    ctx.fill();

    ctx.fillStyle = '#7EA356';

    ctx.beginPath();
    ctx.arc(circleCenterX, circleCenterY, thirdCircleRadius, 0, Math.PI * 2);
    ctx.fill();

    ctx.save();
  },
};
