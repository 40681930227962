import React from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Header from '../shared/Header/Header';
import Footer from '../shared/Footer';
import packageJson from '../../package.json';
import COLORS from '../constants/colors';
import { Autologin } from '../components/User/Autologin';

export default function HomePageLayout() {
  return (
    <Autologin>
      <Box sx={{ height: '100%' }}>
        <Box>
          <Header />
        </Box>
        <Box
          sx={{
            padding: { xs: '0px', md: '24px 0px 24px 24px' },
            backgroundColor: '#F7F9FA',
            height: '100%',
          }}
        >
          <Box
            sx={{
              backgroundImage: 'url("/images/nafaz-background-image.svg")',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right top 10px',
              minHeight: 'calc(100vh - 260px)',
            }}
            style={{
              backgroundSize: '190px',
            }}
          >
            <Outlet />
          </Box>
        </Box>
        <Box>
          <Footer backgroundColor={COLORS.WHITE} />
        </Box>
        <Box display="none" className="app-version">
          <Typography variant="body2">Version: {packageJson.version}</Typography>
        </Box>
      </Box>
    </Autologin>
  );
}
