import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import COLORS from '../../../constants/colors';

interface FullNameFieldProps {
  isArabic: boolean;
  fullName: string;
  fullNameInvalidMessage: string;
  handleChangeFullName: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleFullNameValidation: () => void;
}

const FullNameField: React.FC<FullNameFieldProps> = ({
  isArabic,
  fullName,
  fullNameInvalidMessage,
  handleChangeFullName,
  handleFullNameValidation,
}) => {
  const { t } = useTranslation('auth', { useSuspense: true });
  return (
    <>
      <Typography
        variant={isArabic ? 'cairoM' : 'bodyMedium'}
        color={COLORS.MAIN_DARK}
        textAlign="justify"
        paragraph
        sx={{ marginBottom: 1 }}
      >
        {t('signUpFullNameLabel')}
      </Typography>
      <TextField
        id="fullName"
        variant="outlined"
        value={fullName}
        fullWidth
        type="text"
        autoComplete="name"
        autoFocus
        placeholder={t('signUpFullNamePlaceholder')}
        onChange={handleChangeFullName}
        onBlur={handleFullNameValidation}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& fieldset': { borderColor: COLORS.XLIGHT_GRAY },
          },
          '& .MuiFormHelperText-root': {
            fontFamily: isArabic ? 'Noto Kufi Arabic' : 'Montserrat',
          },
        }}
        InputProps={{
          style: {
            fontFamily: isArabic ? 'Noto Kufi Arabic' : 'Montserrat',
            fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
          },
        }}
        error={fullNameInvalidMessage !== ''}
        helperText={t(fullNameInvalidMessage)}
      />
    </>
  );
};

export default FullNameField;
