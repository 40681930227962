import { LOGOUT } from '../types/authTypes';
import {
  DocsAction,
  DocsState,
  GET_DOC_TYPES_FAIL,
  GET_DOC_TYPES_REQUEST,
  GET_DOC_TYPES_SUCCESS,
  GET_DOCS_DATA_FAIL,
  GET_DOCS_DATA_REQUEST,
  GET_DOCS_DATA_SUCCESS,
  SEND_DOCS_DATA_FAIL,
  SEND_DOCS_DATA_REQUEST,
  SEND_DOCS_DATA_SUCCESS,
  UPLOAD_NATIONAL_ID_FAIL,
  UPLOAD_NATIONAL_ID_REQUEST,
  UPLOAD_NATIONAL_ID_SUCCESS,
  UPLOAD_SELFIE_PICTURE_FAIL,
  UPLOAD_SELFIE_PICTURE_REQUEST,
  UPLOAD_SELFIE_PICTURE_SUCCESS,
} from '../types/docsTypes';

const INITIAL_STATE: DocsState = {
  docTypes: {
    types: [],
    isLoading: false,
    isErrorExist: false,
  },
  nationalId: {
    isLoading: false,
    isErrorExist: false,
  },
  selfiePicture: {
    isLoading: false,
    isErrorExist: false,
  },
  sendDocsData: {
    isLoading: false,
    isErrorExist: false,
  },
  docsData: {
    data: [],
    isLoading: false,
    isErrorExist: false,
    isDataLoaded: false,
  },
};

/**
 * TODO: We must migrate to `@reduxjs/toolkit`!
 * @deprecated
 */
const docsReducer = (state: DocsState = INITIAL_STATE, action: DocsAction = { type: LOGOUT }): DocsState => {
  switch (action.type) {
    case UPLOAD_NATIONAL_ID_REQUEST:
      return {
        ...state,
        nationalId: {
          ...state.nationalId,
          isLoading: true,
        },
      };

    case UPLOAD_NATIONAL_ID_SUCCESS:
      return {
        ...state,
        nationalId: {
          ...state.nationalId,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case UPLOAD_NATIONAL_ID_FAIL:
      return {
        ...state,
        nationalId: {
          ...state.nationalId,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case UPLOAD_SELFIE_PICTURE_REQUEST:
      return {
        ...state,
        selfiePicture: {
          ...state.selfiePicture,
          isLoading: true,
        },
      };

    case UPLOAD_SELFIE_PICTURE_SUCCESS:
      return {
        ...state,
        selfiePicture: {
          ...state.selfiePicture,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case UPLOAD_SELFIE_PICTURE_FAIL:
      return {
        ...state,
        selfiePicture: {
          ...state.selfiePicture,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case GET_DOC_TYPES_REQUEST:
      return {
        ...state,
        docTypes: {
          ...state.docTypes,
          isLoading: true,
        },
      };

    case GET_DOC_TYPES_SUCCESS:
      return {
        ...state,
        docTypes: {
          ...state.docTypes,
          types: action.payload,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case GET_DOC_TYPES_FAIL:
      return {
        ...state,
        docTypes: {
          ...state.docTypes,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case SEND_DOCS_DATA_REQUEST:
      return {
        ...state,
        sendDocsData: {
          ...state.sendDocsData,
          isLoading: true,
        },
      };

    case SEND_DOCS_DATA_SUCCESS:
      return {
        ...state,
        sendDocsData: {
          ...state.docTypes,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case SEND_DOCS_DATA_FAIL:
      return {
        ...state,
        sendDocsData: {
          ...state.sendDocsData,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case GET_DOCS_DATA_REQUEST:
      return {
        ...state,
        docsData: {
          ...state.docsData,
          isLoading: true,
        },
      };

    case GET_DOCS_DATA_SUCCESS:
      return {
        ...state,
        docsData: {
          ...state.docsData,
          data: action.payload,
          isLoading: false,
          isErrorExist: false,
          isDataLoaded: true,
        },
      };

    case GET_DOCS_DATA_FAIL:
      return {
        ...state,
        docsData: {
          ...state.docsData,
          isLoading: false,
          isErrorExist: true,
        },
      };

    default:
      return state;
  }
};

export default docsReducer;
