import { LOGOUT } from '../types/authTypes';
import {
  CLEAR_USER_AGREEMENT,
  GET_USER_SIGNED_AGREEMENT_FAIL,
  GET_USER_SIGNED_AGREEMENT_REQUEST,
  GET_USER_SIGNED_AGREEMENT_SUCCESS,
  SIGN_USER_AGREEMENT_FAIL,
  SIGN_USER_AGREEMENT_REQUEST,
  SIGN_USER_AGREEMENT_SUCCESS,
  UserAgreementAction,
  UserAgreementState,
} from '../types/userAgreementTypes';

const INITIAL_STATE: UserAgreementState = {
  agreement: {
    data: {},
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
};

/**
 * TODO: We must migrate to `@reduxjs/toolkit`!
 * @deprecated
 */
const userAgreementReducer = (
  state: UserAgreementState = INITIAL_STATE,
  action: UserAgreementAction = { type: LOGOUT },
): UserAgreementState => {
  switch (action.type) {
    case GET_USER_SIGNED_AGREEMENT_REQUEST:
      return {
        ...state,
        agreement: {
          ...state.agreement,
          error: '',
          isLoading: true,
        },
      };

    case GET_USER_SIGNED_AGREEMENT_SUCCESS: {
      const newData = { ...state.agreement.data };

      if (action.payload) {
        newData[action.payload.agreement.name] = action.payload.createdAt;
      }

      return {
        ...state,
        agreement: {
          ...state.agreement,
          error: '',
          data: newData,
          isLoading: false,
          isErrorExist: false,
        },
      };
    }

    case GET_USER_SIGNED_AGREEMENT_FAIL:
      return {
        ...state,
        agreement: {
          ...state.agreement,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case SIGN_USER_AGREEMENT_REQUEST:
      return {
        ...state,
        agreement: {
          ...state.agreement,
          error: '',
          isLoading: true,
        },
      };

    case SIGN_USER_AGREEMENT_SUCCESS:
      return {
        ...state,
        agreement: {
          ...state.agreement,
          error: '',
          data: {
            ...state.agreement.data,
            [action.payload]: new Date(),
          },
          isLoading: false,
          isErrorExist: false,
        },
      };

    case SIGN_USER_AGREEMENT_FAIL:
      return {
        ...state,
        agreement: {
          ...state.agreement,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case CLEAR_USER_AGREEMENT:
      return { ...INITIAL_STATE };

    case LOGOUT:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default userAgreementReducer;
