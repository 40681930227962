import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress } from '@mui/material';
import CenterContent from '../../../shared/CenterContent';

import './CustomProductView.scss';
import FundSelector from './FundSelector/FundSelector';
import COLORS from '../../../constants/colors';
import { useFetchIndexFundsQuery } from '../../../redux/features/indexFunds/indexFunds.apiSlice';
import MButton from '../../../shared/MButton';
import { IndexFund } from '../../../store_deprecated/types/indexFundTypes';
import { locations } from '../../../routes/locations';
import { UserPreferenceTypeEnum } from '../../../types/userPreference';
import { saveUserPreference } from '../../../redux/modules/userPreference/userPreference.actions';
import { useTypedDispatch } from '../../../redux/store';
import { useFetchProductsQuery } from '../../../redux/features/products/products.apiSlice';
import { Product } from '../../../types/product';
import * as LocalStorage from '../../../helpers/LocalStorage';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { AssociatedIndexFund } from '../../../store_deprecated/types/portfolioTypes';
import {
  useFindProfileDataQuery,
  useLazyGetIamAuthorizationUrlQuery,
} from '../../../redux/features/auth/auth.apiSlice';
import { fetchLastSurveyResult } from '../../../store_deprecated/actions/surveyActions';
import { getSurveyResult } from '../../../store_deprecated/selectors/surveySelectors';

const CustomProductView: React.FC = () => {
  const { user } = useSelector(authSelector);
  const dispatch = useTypedDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { state: routerState } = useLocation();
  const { data: kyc } = useFindProfileDataQuery();
  const listType = routerState?.type;
  const returnTo = routerState?.returnTo;
  const [selectedFunds, setSelectedFunds] = useState<IndexFund[]>([]);
  const surveyResult = useSelector(getSurveyResult);
  const [getIamAuthorizationUrl] = useLazyGetIamAuthorizationUrlQuery();

  const { data: indexFunds } = useFetchIndexFundsQuery({
    limit: 100,
    filter: JSON.stringify({
      items: [
        {
          columnField: 'customDisplay',
          operatorValue: 'is',
          value: true,
        },
      ],
    }),
  });

  const { data: products } = useFetchProductsQuery({});
  const customProduct = products?.items?.filter((product: Product) => product.isCustom)[0];

  const onSelectedFundsChange = (funds: IndexFund[]) => {
    setSelectedFunds(funds);

    const product = LocalStorage.getPreselectedProduct(user!.id);

    LocalStorage.setPreselectedProduct(user!.id, {
      ...product,
      indexFunds: funds.map((f) => ({
        id: f.id,
        indexFund: f,
        indexFundID: f.id,
        weight: 0,
      })),
      initial: false,
    });
  };

  useEffect(() => {
    dispatch(fetchLastSurveyResult());
  }, []);

  useEffect(() => {
    if (!user || !indexFunds?.items) {
      return;
    }

    const preselectedProduct = LocalStorage.getPreselectedProduct(user.id);

    if (!preselectedProduct) {
      return;
    }

    const availableSelectedIndexFunds: IndexFund[] = [];
    const availableSelectedAssociatedIndexFunds: AssociatedIndexFund[] = [];

    for (const aif of preselectedProduct.indexFunds) {
      const indexFund = indexFunds?.items.find((item) => item?.id === aif?.indexFund?.id);

      if (indexFund) {
        availableSelectedIndexFunds.push(indexFund);
        availableSelectedAssociatedIndexFunds.push(aif);
      }
    }

    setSelectedFunds(availableSelectedIndexFunds);
    LocalStorage.setPreselectedProduct(user.id, {
      ...preselectedProduct,
      indexFunds: availableSelectedAssociatedIndexFunds,
      initial: false,
    });
  }, [user, indexFunds]);

  const onSurveyClick = async () => {
    await dispatch(
      saveUserPreference({
        type: UserPreferenceTypeEnum.PRODUCT_TYPE,
        value: JSON.stringify({
          ...customProduct,
          indexFunds: selectedFunds.map((indexFund) => ({ id: indexFund.id, indexFund, weight: 0 })),
        }),
      }),
    );

    if (!kyc?.id) {
      const { url } = await getIamAuthorizationUrl('en' || i18n.language).unwrap();

      if (url) {
        window.open(url, '_self');
        return;
      }

      return alert("Nafaz is disabled! Please update the KYC data in admin and change the user's status");
    }

    if (kyc?.id && !kyc?.confirmDate) {
      return navigate(locations.nafazData());
    }

    if (kyc?.id && kyc?.confirmDate && !surveyResult?.category?.id) {
      return navigate(locations.survey());
    }

    return navigate(`${locations.riskSurvey()}?page=recommendedPortfolios`, {
      state: {
        from: locations.customProduct(),
        type: listType,
      },
    });
  };

  const onBackClick = () => {
    if (returnTo) navigate(returnTo);
    else if (listType) navigate(locations.dashboard());
    else {
      navigate(locations.products(), {
        state: { type: listType },
      });
    }
  };

  return (
    <CenterContent>
      {indexFunds ? (
        <div className="custom-product-view">
          <img src="/images/icons/portfolio.svg" alt="madkhol-portfolio" className="portfolio-icon" />
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: 24, md: 40 },
                fontWeight: { xs: 600, md: 500 },
                lineHeight: 'normal',
                mb: { xs: '8px', md: 'unset' },
              }}
            >
              {t(listType ? 'products.existCustomBuildTitle' : 'products.customBuildTitle')}
            </Typography>
            <Typography color={COLORS.LIGHT_GRAY} sx={{ fontSize: { xs: 11, md: 18 }, lineHeight: 'normal' }}>
              {t(listType ? 'products.existCustomBuildDescription' : 'products.customBuildDescription')}
            </Typography>
          </Box>
          <Typography color={COLORS.BLACK} sx={{ fontSize: { xs: 14, md: 18 }, my: { xs: '24px', md: 'unset' } }}>
            {t('products.customBuildList')}
          </Typography>

          <FundSelector
            indexFunds={indexFunds?.items || []}
            selectedFunds={selectedFunds}
            onChange={onSelectedFundsChange}
          />
          <Box
            sx={{
              display: { xs: 'grid', md: 'flex' },
              gridGap: { xs: '16px', md: '32px' },
              width: { xs: '100%', md: 'auto' },
            }}
          >
            <MButton
              variant="outlined"
              onClick={onBackClick}
              typographyProps={{
                sx: {
                  padding: '16px 32px',
                  background: COLORS.WHITE,
                  border: `1px solid ${COLORS.X_DARK_BLUE}`,
                  color: COLORS.X_DARK_BLUE,
                  fontSize: 14,
                  fontWeight: 400,
                },
              }}
              buttonProps={{
                sx: {
                  height: '42px',
                  order: { xs: 3, md: listType === 'details' ? 1 : 0 },
                  width: { xs: '100%', md: 'auto' },
                  '&:before': {
                    background: COLORS.TRANSPARENT,
                  },
                },
              }}
            >
              {t('back')}
            </MButton>
            <MButton
              variant="contained"
              disabled={!selectedFunds.length}
              onClick={onSurveyClick}
              typographyProps={{ fontSize: 14, sx: { padding: '12px 32px', fontWeight: 700 } }}
              buttonProps={{
                sx: {
                  order: { xs: 1, md: 2 },
                  p: 0,
                  height: '42px',
                  backgroundColor: COLORS.X_DARK_BLUE,
                  backgroundImage: 'none',
                  width: { xs: '100%', md: '210px' },
                },
              }}
            >
              {t('products.surveyBtn')}
            </MButton>
          </Box>
        </div>
      ) : (
        <CircularProgress />
      )}
    </CenterContent>
  );
};

export default CustomProductView;
