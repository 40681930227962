import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import COLORS from '../../../constants/colors';
import AccountOpeningAgreementData from '../../../assets/data/account-opening-agreement.json';

interface ContentProps {
  data: IAnyObject;
}

const Content: React.FC<ContentProps> = ({ data }) => {
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  const replaceAll = (str: string, mapObj: IAnyObject) => {
    const re = new RegExp(
      Object.keys(mapObj)
        // eslint-disable-next-line no-useless-escape
        .map((key) => `\{${key}\}`)
        .join('|'),
      'gi',
    );

    return str.replace(re, (matched) => mapObj[matched.substring(1, matched.length - 1)] || matched);
  };

  const renderItem = (clause: IAnyObject, description: IAnyObject | string, index: number | string) => {
    if (typeof description === 'string') {
      return (
        <Typography
          key={`${clause.id}-description-${index}`}
          variant={isEnglish ? 'bodyMedium' : 'cairoR16'}
          color={COLORS.DARK_GREY}
          sx={{ display: 'block', mb: 4, whiteSpace: 'pre-wrap', ml: 4 }}
          dangerouslySetInnerHTML={{ __html: replaceAll(description, data) }}
        />
      );
    }
    if ('sign' in description) {
      return (
        <Box textAlign="end" key={`${clause.id}-description-${index}`}>
          <Typography
            component="span"
            variant={isEnglish ? 'bodyMedium' : 'cairoR16'}
            color={COLORS.DARK_GREY}
            dangerouslySetInnerHTML={{
              __html: replaceAll(description.sign, data),
            }}
          />
        </Box>
      );
    }
    if ('title' in description) {
      return (
        <React.Fragment key={`${clause.id}-description-${index}`}>
          <Typography
            variant={isEnglish ? 'bodyMedium' : 'cairoR16'}
            color={COLORS.DARK_GREY}
            sx={{
              display: 'block',
              whiteSpace: 'pre-wrap',
              ml: clause?.style?.descriptionTitleMarginLeft || {
                xs: 3,
                md: 12,
              },
              mb: 4,
            }}
            dangerouslySetInnerHTML={{
              __html: replaceAll(description.title, data),
            }}
          />
          {description.desc?.map((desc: IAnyObject | string, descIndex: number) => {
            if (typeof desc === 'string') {
              return (
                <Typography
                  key={`${clause.id}-description-${index}-desc-${descIndex}`}
                  variant={isEnglish ? 'bodyMedium' : 'cairoR16'}
                  color={COLORS.DARK_GREY}
                  sx={{
                    display: 'block',
                    mb: 3,
                    whiteSpace: 'pre-wrap',
                    ml: {
                      xs: 3,
                      md: 12,
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: replaceAll(desc, data) }}
                />
              );
            }
            return (
              <React.Fragment key={`${clause.id}-description-${index}-desc-${descIndex}-subtitle`}>
                <Typography
                  variant={isEnglish ? 'bodyMedium' : 'cairoR16'}
                  color={COLORS.DARK_GREY}
                  sx={{
                    display: 'block',
                    mb: 3,
                    whiteSpace: 'pre-wrap',
                    ml: {
                      xs: 3,
                      md: 12,
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: replaceAll(desc.subtitle, data),
                  }}
                />
                {desc.text?.map((text: string, textIndex: number) => (
                  <Typography
                    key={`${clause.id}-description-${index}-desc-${descIndex}-text-${textIndex}`}
                    variant={isEnglish ? 'bodyMedium' : 'cairoR16'}
                    color={COLORS.DARK_GREY}
                    sx={{
                      display: 'block',
                      mb: 4,
                      whiteSpace: 'pre-wrap',
                      ml: {
                        xs: 4,
                        md: 20,
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: replaceAll(text, data),
                    }}
                  />
                ))}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      );
    }
  };

  return (
    <Box>
      {AccountOpeningAgreementData[i18n.language as 'en' | 'ar'].map((clause: IAnyObject) => (
        <Box key={clause.id}>
          <Typography
            variant="h3"
            color={COLORS.MAIN_DARK}
            sx={{ mb: 5, mt: 6 }}
            textAlign={clause?.style?.titleAlign || 'left'}
            dangerouslySetInnerHTML={{ __html: clause.title }}
          />
          {clause.description?.map((description: IAnyObject | string, index: number) =>
            renderItem(clause, description, index),
          )}
        </Box>
      ))}
    </Box>
  );
};

export default Content;
