export enum ActivityType {
  FILL = 'FILL', // Order Fills (Partial/Full)
  ACATC = 'ACATC', // ACATS IN/OUT (Cash)
  ACATS = 'ACATS', // ACATS IN/OUT (Securities)
  CIL = 'CIL', // Cash in Lieu of Stock
  CSD = 'CSD', // Cash Disbursement (+)
  CSW = 'CSW', // Cash Withdrawable
  DIV = 'DIV', // Dividend
  DIVCGL = 'DIVCGL', // Dividend (Capital Gain Long Term)
  DIVCGS = 'DIVCGS', // Dividend (Capital Gain Short Term)
  DIVNRA = 'DIVNRA', // Dividend Adjusted (NRA Withheld)
  DIVROC = 'DIVROC', // Dividend Return of Capital
  DIVTXEX = 'DIVTXEX', // Dividend (Tax Exempt)
  FEE = 'FEE', // REG and TAF Fees
  INT = 'INT', // Interest (Credit/Margin)
  JNLC = 'JNLC', // Journal Entry (Cash)
  JNLS = 'JNLS', // Journal Entry (Stock)
  MA = 'MA', // Merger/Acquisition
  PTC = 'PTC', // Pass Thru Change
  REORG = 'REORG', // Reorg CA
  SPIN = 'SPIN', // Stock Spinoff
  SPLIT = 'SPLIT', // Stock Split
}

export enum ActivityCategory {
  trade_activity = 'trade_activity', // Trade activities
  non_trade_activity = 'non_trade_activity', // Non trade activities
}

export enum OrderSide {
  BUY = 'buy',
  SELL = 'sell',
}

export enum OrderType {
  MARKET = 'market',
  LIMIT = 'limit',
  STOP = 'stop',
  STOP_LIMIT = 'stop_limit',
  TRAILING_STOP = 'trailing_stop',
}

export enum OrderStatus {
  NEW = 'new',
  ACCEPTED = 'accepted',
  HELD = 'held',
  PARTIALLY_FILLED = 'partially_filled',
  FILLED = 'filled',
  DONE_FOR_TODAY = 'done_for_day',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  REPLACED = 'replaced',
  PENDING_CANCEL = 'pending_cancel',
  PENDING_REPLACE = 'pending_replace',
}
