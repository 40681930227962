import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import RequestInvestSuccessfulScreen from './RequestInvestSuccessfulScreen';
import { ar2En } from '../../../utils/number';
import { useTypedDispatch } from '../../../redux/store';
import PayWithMadaOrVisaOrMasterCard from '../../../shared/PayWithMadaOrVisaOrMasterCard';
import PaymentMethods, { PaymentMethodsType } from '../../../shared/PaymentMethods';
import PayWithApple from '../../../shared/PayWithApple';
import AddInvestFromWallet from '../../../shared/AddInvestFromWallet';

interface SubscribePortfolioDialogProps {
  open: boolean;
  isLoading?: boolean;
  error?: string;
  closeDialog?: () => void;
  onSuccessButtonClick?: () => void;
  onClose: (amount?: number) => void;
  minAmount?: number;
  currentSelectedProduct?: any;
}

export default function SubscribePortfolioDialog(props: SubscribePortfolioDialogProps) {
  const {
    open,
    isLoading,
    error,
    onClose,
    closeDialog = () => {},
    onSuccessButtonClick = () => {},
    minAmount = 1000,
    currentSelectedProduct,
  } = props;
  const dispatch = useTypedDispatch();
  const [amount, setAmount] = useState('');

  const [PaymentMethod, setPaymentMethod] = useState<PaymentMethodsType>('WALLET');

  const [showSuccessfulScreen, setShowSuccessfulScreen] = useState(false);

  useEffect(() => {
    setPaymentMethod('WALLET');
    setAmount('');
  }, [open]);

  const handleClose = (selectedFundAmount?: number) => {
    if (isLoading) {
      return;
    }
    setAmount('');
    return !showSuccessfulScreen ? onClose(selectedFundAmount) : closeDialog();
  };
  const parseValue = (v: string | number) =>
    // eslint-disable-next-line no-useless-escape
    `${v}`
      .replace(/([\.\-\,])(?=.*\1)/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/[^.\-\d]/g, '');

  const handleOnPaymentMethodClick = (paymentName: PaymentMethodsType) => {
    setPaymentMethod(paymentName);
  };

  const handleAmountField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const initialValue = event.target.value;

    const newValue: string = parseValue(ar2En(initialValue));
    const newValueNumber = +newValue;

    if (Number.isNaN(newValueNumber) || newValue.endsWith('.') || !newValue) {
      event.target.value = newValue;

      setAmount(event.target.value);

      return;
    }

    event.target.value = `${newValueNumber}`;

    setAmount(event.target.value);
    sessionStorage.setItem('lastPaymentAmount', `${newValueNumber}`);
  };

  const _onSuccessButtonClick = () => {
    setShowSuccessfulScreen(false);
    onSuccessButtonClick?.();
  };

  const PaymentMethodChild = <PaymentMethods selectedId={PaymentMethod} handleClick={handleOnPaymentMethodClick} />;

  const renderContent = () => {
    if (PaymentMethod === 'WALLET') {
      return (
        <AddInvestFromWallet
          PaymentMethodChild={PaymentMethodChild}
          handleAmountField={handleAmountField}
          closeDialog={closeDialog}
          minAmount={minAmount}
          amount={amount}
          currentSelectedProduct={currentSelectedProduct}
          setShowSuccessfulScreen={setShowSuccessfulScreen}
        />
      );
    }
    if (PaymentMethod === 'MASTER') {
      return (
        <PayWithMadaOrVisaOrMasterCard
          closeDialog={closeDialog}
          PaymentMethodChild={PaymentMethodChild}
          providerName="hyperPayVisaOrMaster"
          addCardProviderName="addCardHyperPayVisaOrMaster"
          amount={amount}
          handleAmountField={handleAmountField}
        />
      );
    }
    if (PaymentMethod === 'VISA') {
      return (
        <PayWithMadaOrVisaOrMasterCard
          closeDialog={closeDialog}
          PaymentMethodChild={PaymentMethodChild}
          providerName="hyperPayVisaOrMaster"
          addCardProviderName="addCardHyperPayVisaOrMaster"
          amount={amount}
          handleAmountField={handleAmountField}
        />
      );
    }
    if (PaymentMethod === 'MADA') {
      return (
        <PayWithMadaOrVisaOrMasterCard
          providerName="hyperPayMada"
          closeDialog={closeDialog}
          PaymentMethodChild={PaymentMethodChild}
          addCardProviderName="addCardHyperPayMada"
          amount={amount}
          handleAmountField={handleAmountField}
        />
      );
    }
    if (PaymentMethod === 'APPLE') {
      return (
        <PayWithApple
          PaymentMethodChild={PaymentMethodChild}
          closeDialog={closeDialog}
          amount={amount}
          handleAmountField={handleAmountField}
        />
      );
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <Box sx={{ padding: '32px 24px !important' }}>
        {!showSuccessfulScreen ? (
          renderContent()
        ) : (
          <RequestInvestSuccessfulScreen onSuccessButtonClick={_onSuccessButtonClick} />
        )}
      </Box>
    </Dialog>
  );
}
