import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import COLORS from '../../../constants/colors';
import { madkhoulBankInfoSelector } from '../../../store_deprecated/selectors/walletSelectors';

const IbanNumber: React.FC = () => {
  const { t } = useTranslation();

  const bankItem = useSelector(madkhoulBankInfoSelector);

  return (
    <Grid
      item
      container
      flexDirection="row"
      justifyContent="space-between"
      component={Paper}
      elevation={0}
      sx={{
        p: '24px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '12px',
      }}
    >
      <Grid item container flexDirection="column" md={12} lg={10}>
        <Typography
          sx={{
            color: COLORS.MAIN_DARK,
            fontWeight: 500,
            fontSize: 'clamp(1rem,1vw + 1rem,1.25rem)',
            lineHeight: { md: '28px' },
            marginBottom: '12px',
          }}
          variant="h6"
        >
          {t('yourMadkholIbanNumber')}
        </Typography>
        <Typography
          variant="bodySmall"
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            marginBottom: '12px',
            color: COLORS.LIGHT_GRAY,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {t('arabNationalBank')}
          <Box
            sx={{
              display: { xs: 'inline-block', lg: 'none' },
              ml: { xs: 'auto', lg: '5px' },
            }}
          >
            <img src="/images/anb-logo.png" alt="" style={{ width: '60px' }} />
          </Box>
        </Typography>
        <Typography
          sx={{
            color: COLORS.MAIN_DARK,
            fontWeight: 400,
            fontSize: 'clamp(1rem,6vw + 1rem,1.125rem)',
            lineHeight: '28px',
          }}
          variant="bodyLarge"
        >
          {bankItem?.iban || '-'}
        </Typography>
      </Grid>
      <Grid
        item
        display="flex"
        justifyContent="center"
        alignItems="center"
        md={12}
        lg={2}
        sx={{ display: { xs: 'none', lg: 'flex' } }}
      >
        <img src="/images/anb-logo.png" alt="" style={{ width: '80px' }} />
      </Grid>
    </Grid>
  );
};

export default IbanNumber;
