import React from 'react';
import OldDashboardView from '../../components/User/Dashboard/DashboardView';
import DashboardView from '../../components/User/Dashboard/BetaDashboardView';

interface DashboardProps {
  beta?: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ beta }) => (beta ? <DashboardView /> : <OldDashboardView />);

export default Dashboard;
