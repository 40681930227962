import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { isSelectedItem } from './helpers';
import { CustomListItemButton, Drawer as CustomDrawer, DrawerHeader } from './SidebarElements';
import { MenuItems, MenuItemSimple } from './types';
import packageJson from '../../../../package.json';
import { locations } from '../../../routes/locations';
import COLORS from '../../../constants/colors';

interface ISidebarProps {
  open: boolean;
  handleDrawerClose: () => void;
  menuItems: MenuItems;
}

function Sidebar({ open, handleDrawerClose, menuItems }: ISidebarProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const matchMdAndAboveView = useMediaQuery('(min-width:900px)');
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const onClick = (menuItem: MenuItemSimple) => {
    // @ts-ignore
    if (menuItem?.path?.includes('about')) {
      window.open('https://madkhol.com/who-we-are', '_blank');
    } else {
      if ('path' in menuItem) {
        navigate(menuItem.path);
        if (!matchMdAndAboveView) handleDrawerClose();
        return;
      }
      if (!matchMdAndAboveView) handleDrawerClose();
      menuItem.onClick();
    }
  };

  const renderSimpleMenuItem = (menuItem: MenuItemSimple, index: number) => {
    const isSelected = isSelectedItem(menuItem, pathname);
    return (
      <CustomListItemButton
        selected={isSelected}
        onClick={() => onClick(menuItem)}
        key={`menu-item-${index}`}
        sx={{
          height: '40px',
          justifyContent: open ? 'initial' : 'center',
          borderRadius: '4px',
          mb: '18px',
        }}
      >
        <Tooltip title={open ? '' : t(menuItem.title)} placement="top">
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? '14px' : 'auto',
              width: '20px',
              height: '20px',
              justifyContent: 'center',
              color: isSelected ? COLORS.WHITE : COLORS.PRIMARY_BLUE,
            }}
          >
            <menuItem.IconComponent />
          </ListItemIcon>
        </Tooltip>
        {open && (
          <ListItemText
            primary={t(menuItem.title)}
            primaryTypographyProps={{
              variant: 'bodySmall',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
            }}
          />
        )}
      </CustomListItemButton>
    );
  };

  const renderContent = () => (
    <>
      <Grid item>
        <DrawerHeader sx={{ height: '145px!important' }}>
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
            <Grid
              container
              justifyContent={open ? 'flex-start' : 'center'}
              alignItems="center"
              wrap="nowrap"
              sx={{ px: open ? '32px' : 0 }}
            >
              <img
                style={{
                  transition: 'width .3s',
                  // eslint-disable-next-line no-nested-ternary
                  width: open ? (isArabic ? '130px' : '155px') : '55px',
                  objectFit: 'cover',
                  height: '32px',
                  objectPosition: isArabic ? '100% 0' : '0 0',
                  cursor: 'pointer',
                }}
                onClick={() => navigate(locations.dashboard())}
                src={isArabic ? '/images/logo-with-text-ar.png' : '/images/logo-with-text-en.png'}
                alt="madkhol logo"
              />
            </Grid>
          </Grid>
        </DrawerHeader>
      </Grid>
      <Box sx={{ position: 'absolute', left: 0, bottom: '10%' }}>
        <img
          style={{ width: '100%' }}
          src={isArabic ? '/images/logo-right-cut.svg' : '/images/logo-left-cut.svg'}
          alt=""
        />
      </Box>
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexWrap="nowrap"
        sx={{ px: '32px', height: '100%' }}
      >
        {menuItems.map((items, listIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item container key={`main-menu-item-${listIndex}`}>
            <List component="div" disablePadding sx={{ width: '100%' }}>
              {items.map(renderSimpleMenuItem)}
            </List>
          </Grid>
        ))}
      </Grid>
      <Box display="none" alignItems="center" justifyContent="center" marginTop="auto">
        <Typography variant="body2">
          {open ? 'Version:' : ''} {packageJson.version}
        </Typography>
      </Box>
    </>
  );

  if (matchMdAndAboveView) {
    return (
      <CustomDrawer variant="permanent" open={open} elevation={0}>
        {renderContent()}
      </CustomDrawer>
    );
  }

  return (
    <Drawer open={open} anchor="left" onClose={handleDrawerClose}>
      {renderContent()}
    </Drawer>
  );
}

export default Sidebar;
