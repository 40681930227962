import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { TextFieldProps } from '@mui/material/TextField';
import PhoneInput, { Props } from 'react-phone-number-input';
import COLORS from '../../constants/colors';
import { CustomTextField } from '../CustomTextField';
import 'react-phone-number-input/style.css';
import './mobile-field.css';

export const MobileField = React.forwardRef(
  (props: Props<TextFieldProps>, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { t, i18n } = useTranslation('auth', { useSuspense: true });
    const isEnglish = i18n.language === 'en';

    const onValueChange = (value?: string): void => {
      if (props.onChange) {
        props.onChange({
          target: {
            name: props.name,
            value,
          },
        } as any);
      }
    };

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '62px',
            height: '53px',
            border: '1px solid #D1D5DB',
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mr: '4px',
            mb: 'auto',
            p: 4,
          }}
        >
          <img src="/images/auth/saudi-arabia.svg" alt="Saudi Arabia Flag" />
        </Box>
        <PhoneInput
          value={props.value as any}
          onChange={(value) => onValueChange(value)}
          onBlur={props.onBlur}
          disabled={props.disabled}
          international
          withCountryCallingCode
          countryCallingCodeEditable={false}
          country="SA"
          id="phone"
          name="phone"
          defaultCountry="SA"
          countries={['SA']}
          // eslint-disable-next-line react/no-unstable-nested-components
          inputComponent={CustomTextField}
          style={{ width: '100%' }}
          numberInputProps={{
            id: props.id,
            variant: 'outlined',
            fullWidth: true,
            placeholder: t('signUpFormMobilePlaceholder'),
            autoComplete: 'tel',
            sx: {
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                '& fieldset': {
                  borderColor: COLORS.XLIGHT_GRAY,
                },
              },
              '& .MuiFormHelperText-root': {
                fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
              },
            },
            InputProps: {
              style: {
                fontFamily: isEnglish ? 'Montserrat' : 'Noto Kufi Arabic',
                fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
              },
            },
            error: props.error,
            helperText: props.helperText,
            ...props,
          }}
        />
      </Box>
    );
  },
);
