import { createSelector } from 'reselect';
import { ReduxState } from '../../redux/store';
import { Agreement, UserAgreementName } from '../types/userAgreementTypes';

export const agreementSelector = ({ agreementState }: ReduxState) => agreementState.agreement;
export const agreementSignedSelector = createSelector(
  agreementSelector,
  (state: ReduxState, agreementName: UserAgreementName) => agreementName,
  (agreement: Agreement, agreementName: UserAgreementName) => agreement.data[agreementName],
);
