import { useLayoutEffect, useState } from 'react';
import { getMinimumAddFund } from '../api_deprecated';

function useGetMinimumAddFundInvestment(backendProductId: string) {
  const [minimumAddFundInvestment, setMinimumAddFundInvestment] = useState(0);

  const getMinimumAddFundInvestment = async () => {
    try {
      const { data } = await getMinimumAddFund(parseInt(backendProductId, 10));

      setMinimumAddFundInvestment(data?.minimumAddFund);
    } catch (error) {
      setMinimumAddFundInvestment(0);
    }
  };

  useLayoutEffect(() => {
    if (backendProductId !== '-1') {
      getMinimumAddFundInvestment();
    }
  }, [backendProductId]);

  return { minimumAddFundInvestment };
}

export default useGetMinimumAddFundInvestment;
