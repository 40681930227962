import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { doSignUserAgreement, getUserSignedAgreement } from '../../helpers/api_deprecated';
import {
  CLEAR_USER_AGREEMENT,
  ClearUserAgreement,
  GET_USER_SIGNED_AGREEMENT_FAIL,
  GET_USER_SIGNED_AGREEMENT_REQUEST,
  GET_USER_SIGNED_AGREEMENT_SUCCESS,
  RequestGetUserSignedAgreement,
  RequestGetUserSignedAgreementFail,
  RequestGetUserSignedAgreementSuccess,
  RequestSignUserAgreement,
  RequestSignUserAgreementFail,
  RequestSignUserAgreementSuccess,
  SIGN_USER_AGREEMENT_FAIL,
  SIGN_USER_AGREEMENT_REQUEST,
  SIGN_USER_AGREEMENT_SUCCESS,
  UserAgreementName,
  UserSignAgreement,
} from '../types/userAgreementTypes';

export const requestGetUserSignedAgreement = (): RequestGetUserSignedAgreement => ({
  type: GET_USER_SIGNED_AGREEMENT_REQUEST,
});

export const requestGetUserSignedAgreementSuccess = (
  payload: UserSignAgreement,
): RequestGetUserSignedAgreementSuccess => ({
  type: GET_USER_SIGNED_AGREEMENT_SUCCESS,
  payload,
});

export const requestGetUserSignedAgreementFail = (payload: string): RequestGetUserSignedAgreementFail => ({
  type: GET_USER_SIGNED_AGREEMENT_FAIL,
  payload,
});

export const requestSignUserAgreement = (): RequestSignUserAgreement => ({
  type: SIGN_USER_AGREEMENT_REQUEST,
});

export const requestSignUserAgreementSuccess = (payload: UserAgreementName): RequestSignUserAgreementSuccess => ({
  type: SIGN_USER_AGREEMENT_SUCCESS,
  payload,
});

export const requestSignUserAgreementFail = (payload: string): RequestSignUserAgreementFail => ({
  type: SIGN_USER_AGREEMENT_FAIL,
  payload,
});

export const clearAgreement = (): ClearUserAgreement => ({
  type: CLEAR_USER_AGREEMENT,
});

export const findUserSignedAgreement =
  (payload: UserAgreementName): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestGetUserSignedAgreement());

    try {
      const response: any = await getUserSignedAgreement(payload);

      dispatch(requestGetUserSignedAgreementSuccess(response.data));

      return response.data;
    } catch (error: any) {
      dispatch(requestGetUserSignedAgreementFail(error.message));
    }
  };

export const signUserAgreement =
  (payload: UserAgreementName): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestSignUserAgreement());

    try {
      const response: any = await doSignUserAgreement(payload);

      dispatch(requestSignUserAgreementSuccess(payload));

      return response.data;
    } catch (error: any) {
      dispatch(requestSignUserAgreementFail(error.message));
    }
  };
