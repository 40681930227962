import {
  FetchUserPreferenceResponse,
  SaveUserPreferenceRequest,
  SaveUserPreferenceResponse,
} from '../types/userPreference';
import { ApiService } from './common/ApiService';

class UserPreferenceService extends ApiService {
  pathname = 'app/user-preference';

  fetchUserPreferenceByType = async (type: string) =>
    this.get<FetchUserPreferenceResponse>(`${this.pathname}/type/${type}`);

  saveUserPreference = async (data: SaveUserPreferenceRequest) =>
    this.post<SaveUserPreferenceResponse, SaveUserPreferenceRequest>(`${this.pathname}`, data);

  deleteUserPreference = async (type: string) => this.delete<any>(`${this.pathname}/type/${type}`);

  fetchRecommendedPortfolios = async () => this.get(`${this.pathname}/recommended/portfolio`);

  postRecommendedPortfolios = async (data: any) => this.post(`${this.pathname}/recommended/portfolio`, data);

  deleteRecommendedPortfolio = async (id: number) => this.delete(`${this.pathname}/recommended/portfolio/${id}`);
}

export const userPreferenceService = new UserPreferenceService();
