import { Selector } from 'react-redux';
import { GlobalState } from '../types';
import { DocsDataState, DocType } from '../types/docsTypes';

export const getDocTypesSelector: Selector<GlobalState, number, string> = (state, docType) => {
  const { types } = state.docsState.docTypes;
  const foundDoc = types.filter((doc: DocType) => doc.type === docType)[0];
  return foundDoc?.id;
};

export const getDocsDataSelector: Selector<GlobalState, DocsDataState> = (state) => state.docsState.docsData;

export const getNationalIdFailureSelector: Selector<GlobalState, boolean> = (state) =>
  state.docsState.nationalId.isErrorExist;

export const getSelfieFailureSelector: Selector<GlobalState, boolean> = (state) =>
  state.docsState.selfiePicture.isErrorExist;

export const getSendDocsDataFailureSelector: Selector<GlobalState, boolean> = (state) =>
  state.docsState.sendDocsData.isErrorExist;
