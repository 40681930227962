import React from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import COLORS from '../constants/colors';

interface SocialMediaShareDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function SocialMediaShareDialog({ open, onClose }: SocialMediaShareDialogProps) {
  const { t } = useTranslation();
  const hashtags: string[] = ['مدخول', 'الاستثمار‬⁩', 'الادخار', 'استثمر', 'fintech', 'مال'];

  const handleClose = () => {
    onClose();
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={handleClose}>
      <Box sx={{ padding: '24px' }}>
        <Grid container flexDirection="column" justifyContent="center" alignItems="center">
          <Grid item textAlign="right" alignSelf="end">
            <IconButton onClick={() => handleClose()}>
              <CloseIcon sx={{ fontSize: '12px', color: COLORS.MAIN_DARK }} />
            </IconButton>
          </Grid>
          <Grid
            item
            sx={{
              paddingBottom: '24px',
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontWeight: 500,
                fontSize: { xs: '24px', md: '32px' },
                color: COLORS.MAIN_DARK,
              }}
            >
              {t('invite.shareOn')}
            </Typography>
          </Grid>
          <Grid
            item
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            height="38.75px"
            sx={{ paddingBottom: '24px' }}
          >
            <Box sx={{ margin: '0 10px' }}>
              <TwitterShareButton url="https://twitter.com/MadkholCapital" hashtags={hashtags}>
                <TwitterIcon size={40} round />
              </TwitterShareButton>
            </Box>
            <Box sx={{ margin: '0 10px' }}>
              <LinkedinShareButton url="https://www.linkedin.com/company/madkhol">
                <LinkedinIcon size={40} round />
              </LinkedinShareButton>
            </Box>
            <Box sx={{ margin: '0 10px' }}>
              <WhatsappShareButton title="Madkhol -" url="https://app.madkhol.com">
                <WhatsappIcon size={40} round />
              </WhatsappShareButton>
            </Box>
            <Box sx={{ margin: '-6px 10px 0' }}>
              <IconButton
                sx={{
                  backgroundColor: COLORS.X_LIGHT_GRAY,
                  width: '38.75px',
                  height: '38.75px',
                  '&:hover': {
                    backgroundColor: COLORS.X_LIGHT_GRAY,
                  },
                }}
                onClick={() => {
                  navigator.clipboard.writeText('https://app.madkhol.com');
                }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
