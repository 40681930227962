import React, { useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { gaugeArrow } from '../helpers/charts';

interface GaugeChartProps {
  needleValue: number;
  reverse?: boolean;
}

const DS1BackgroundColor = ['#42A8EA', '#3392CF', '#2482BE', '#003E78', '#002C55'];
const DS2BackgroundColor = ['#1D70DC', '#1652AF', '#113E93', '#011635', '#01111f'];

const GaugeChart = ({ needleValue, reverse }: GaugeChartProps) => {
  const options: ChartOptions<'doughnut'> = {
    circumference: 180,
    rotation: -90,
    responsive: true,
    cutout: '70%',
    layout: {
      padding: 0,
    },
    animation: {
      animateRotate: false,
      animateScale: true,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };
  const scale = [10, 20, 20, 20, 30];
  const [data, setData] = React.useState({
    datasets: [
      {
        data: scale,
        backgroundColor: DS1BackgroundColor,
        display: true,
        needleValue,
        borderColor: 'transparent',
      },
      {
        data: scale,
        weight: 0.3,
        backgroundColor: DS2BackgroundColor,
        display: true,
        borderColor: 'transparent',
      },
    ],
  });

  useEffect(() => {
    const newData = { ...data, datasets: [...data.datasets] };

    newData.datasets[0].needleValue = needleValue;

    setData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needleValue]);

  useEffect(() => {
    const newData = { ...data, datasets: [...data.datasets] };

    newData.datasets[0].needleValue = reverse ? 100 - needleValue : needleValue;
    newData.datasets[0].backgroundColor = reverse ? [...DS1BackgroundColor].reverse() : DS1BackgroundColor;
    newData.datasets[1].backgroundColor = reverse ? [...DS2BackgroundColor].reverse() : DS2BackgroundColor;

    const newScaledData = !reverse ? [...scale].reverse() : scale;
    newData.datasets[0].data = newScaledData;
    newData.datasets[1].data = newScaledData;

    setData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reverse]);

  return <Doughnut id="gauge" data={data} options={options} plugins={[gaugeArrow]} />;
};

export default GaugeChart;
