import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ButtonGroup, Button } from '@mui/material';
import COLORS from '../../../constants/colors';
import { getPortfolioStatisticsSelector } from '../../../store_deprecated/selectors/portfolioSelectors';
import LineChart from './LineChart';
import { formatNumber } from '../../../utils/number';
import {
  isWithinWeek,
  isWithinMonth,
  isWithinSemiYear,
  isWithinYear,
  firstDayOfMonth,
  lastDayOfMonth,
} from '../../../utils/dates';
import './past-performance-tab.styles.css';
import {
  PortfolioAllStatisticsItemData,
  PortfolioStatisticsData,
  RecommendedPortfolio,
  SubscribedPortfolio,
} from '../../../store_deprecated/types/portfolioTypes';
import Widgets from './WidgetsV2';
import PortfolioPositionsTable from './PortfolioPositionsTableV2';
import { UserIndexFund } from '../../../store_deprecated/types/indexFundTypes';

const firstDayMobileDateFormat = '1/M/yy';
const firstDayWebDateFormat = 'M/1/yyyy';
const lastDayMobileDateFormat = 'dd/M/yy';
const lastDayWebDateFormat = 'M/dd/yyyy';
const matchMobileView = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

interface PastPerformanceProps {
  portfolio?: SubscribedPortfolio | RecommendedPortfolio;
  latestStatistics?: PortfolioStatisticsData | null;
  userIndexFunds?: UserIndexFund[];
}

const CompoundingReturnTab: React.FC<PastPerformanceProps> = ({
  portfolio,
  latestStatistics = null,
  userIndexFunds = [],
}) => {
  const { t, i18n } = useTranslation();

  const { all: portfolioStatistics } = useSelector(getPortfolioStatisticsSelector);
  const all = [...portfolioStatistics].reverse();

  const [currentFilter, setCurrentFilter] = useState('monthly');
  const [data, setData] = useState<PortfolioAllStatisticsItemData[]>([]);

  const listOfFilters = ['weekly', 'monthly', 'semiYearly', 'yearly'];

  const listOfFilterUtils = {
    weekly: (date: string) => isWithinWeek(date),
    monthly: (date: string) => isWithinMonth(date),
    semiYearly: (date: string) => isWithinSemiYear(date),
    yearly: (date: string) => isWithinYear(date),
  };

  const setFilter = (filter: string) => {
    setCurrentFilter(filter);
    // @ts-ignore
    const isWithinRange = listOfFilterUtils[filter];
    let localPoints: number[] = [];
    const filteredData = all.filter((record, index) => {
      const inRange = isWithinRange(record.date);
      if (filter === 'semiYearly' || filter === 'yearly') {
        const firstDateOfMonth = firstDayOfMonth(
          new Date(record.date),
          matchMobileView ? firstDayMobileDateFormat : firstDayWebDateFormat,
        );

        const lastDateOfMonth = lastDayOfMonth(
          new Date(record.date),
          matchMobileView ? lastDayMobileDateFormat : lastDayWebDateFormat,
        );

        if (
          inRange &&
          (record.date === firstDateOfMonth || record.date === lastDateOfMonth) &&
          !localPoints.includes(index)
        ) {
          localPoints = [...localPoints, index];

          return true;
        }
        return false;
      }

      return inRange;
    });

    setData(filteredData);
  };

  useEffect(() => {
    setFilter(currentFilter);
  }, []);

  const quickFilterButtons = listOfFilters.map((filter) => (
    <Button
      key={filter}
      variant={currentFilter === filter ? 'contained' : 'outlined'}
      sx={{
        backgroundImage: currentFilter === filter ? COLORS.LINEAR_GRADIENT : COLORS.WHITE,
        textTransform: 'none',
        boxShadow: 'none',
        '&.MuiButtonBase-root:hover': {
          boxShadow: 'none',
        },
      }}
      onClick={() => setFilter(filter)}
    >
      {t(`graphQuickFilters.${filter}`)}
    </Button>
  ));

  return (
    <Grid
      container
      component="div"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      rowSpacing="64px"
    >
      <Grid item width="100%">
        <Widgets beta />
      </Grid>
      <Grid item sx={{ width: '100%', textAlign: 'center' }}>
        <ButtonGroup size="small" sx={{ mx: 'auto', my: 4 }}>
          {quickFilterButtons}
        </ButtonGroup>
        <Grid item sx={{ my: '12px', textAlign: 'left' }} className="chart-hint">
          <Typography
            variant="bodyLarge"
            sx={{
              color: COLORS.DARK_GREY,
              fontWeight: 400,
              fontSize: { xs: '14px', md: '16px' },
              lineHeight: '28px',
            }}
          >
            {t('fundDetails.chartYAxisHint')}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            width: '100%',
            overflowY: 'scroll',
            height: { xs: '370px', md: '100%' },
          }}
        >
          <Box
            sx={{
              width: { xs: '200%', md: '100%' },
              height: '100%',
              pb: 4,
            }}
          >
            <LineChart statistics={data.length ? data : all} />
          </Box>
        </Grid>
        <Grid item sx={{ mt: 4, textAlign: 'center' }}>
          <Typography
            variant="bodyLarge"
            sx={{
              color: COLORS.DARK_GREY,
              fontWeight: 400,
              fontSize: { xs: '14px', md: '16px' },
              lineHeight: '28px',
            }}
          >
            {t('fundDetails.chartCaption', {
              val: formatNumber(all[all.length - 1]?.totalReturn, i18n.language, 2),
            })}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          width: '100%',
          overflowX: 'auto',
          p: '2px',
          pt: { xs: '0px !important', md: '64px !important' },
        }}
      >
        <PortfolioPositionsTable
          portfolio={portfolio}
          latestStatistics={latestStatistics}
          userIndexFunds={userIndexFunds}
          beta
        />
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <Typography
          variant="bodyLarge"
          sx={{
            color: COLORS.DARK_GREY,
            fontWeight: 400,
            fontSize: { xs: '12px', md: '16px' },
            lineHeight: '28px',
          }}
        >
          {t('portfolioPositions.hint')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CompoundingReturnTab;
