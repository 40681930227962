import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as API from '../../helpers/api_deprecated';
import { UploadDocumentPayload } from '../types/authTypes';
import {
  AttachedDoc,
  Attachment,
  DocType,
  FetchUploadedDocumentURLPayload,
  GET_DOC_TYPES_FAIL,
  GET_DOC_TYPES_REQUEST,
  GET_DOC_TYPES_SUCCESS,
  GET_DOCS_DATA_FAIL,
  GET_DOCS_DATA_REQUEST,
  GET_DOCS_DATA_SUCCESS,
  GetDocsData,
  GetDocsDataFail,
  GetDocsDataSuccess,
  GetDocTypes,
  GetDocTypesFail,
  GetDocTypesSuccess,
  RequestUploadNationalId,
  RequestUploadNationalIdFail,
  RequestUploadNationalIdSuccess,
  RequestUploadSelfiePicture,
  RequestUploadSelfiePictureFail,
  RequestUploadSelfiePictureSuccess,
  SEND_DOCS_DATA_FAIL,
  SEND_DOCS_DATA_REQUEST,
  SEND_DOCS_DATA_SUCCESS,
  SendDocsData,
  SendDocsDataFail,
  SendDocsDataSuccess,
  UPLOAD_NATIONAL_ID_FAIL,
  UPLOAD_NATIONAL_ID_REQUEST,
  UPLOAD_NATIONAL_ID_SUCCESS,
  UPLOAD_SELFIE_PICTURE_FAIL,
  UPLOAD_SELFIE_PICTURE_REQUEST,
  UPLOAD_SELFIE_PICTURE_SUCCESS,
} from '../types/docsTypes';

export const requestUploadNationalId = (): RequestUploadNationalId => ({
  type: UPLOAD_NATIONAL_ID_REQUEST,
});

export const requestUploadNationalIdSuccess = (): RequestUploadNationalIdSuccess => ({
  type: UPLOAD_NATIONAL_ID_SUCCESS,
});

export const requestUploadNationalIdFail = (): RequestUploadNationalIdFail => ({
  type: UPLOAD_NATIONAL_ID_FAIL,
});

export const requestUploadSelfiePicture = (): RequestUploadSelfiePicture => ({
  type: UPLOAD_SELFIE_PICTURE_REQUEST,
});

export const requestUploadSelfiePictureSuccess = (): RequestUploadSelfiePictureSuccess => ({
  type: UPLOAD_SELFIE_PICTURE_SUCCESS,
});

export const requestUploadSelfiePictureFail = (): RequestUploadSelfiePictureFail => ({
  type: UPLOAD_SELFIE_PICTURE_FAIL,
});

export const sendDocsData = (): SendDocsData => ({
  type: SEND_DOCS_DATA_REQUEST,
});

export const sendDocsDataSuccess = (): SendDocsDataSuccess => ({
  type: SEND_DOCS_DATA_SUCCESS,
});

export const sendDocsDataFail = (): SendDocsDataFail => ({
  type: SEND_DOCS_DATA_FAIL,
});

export const getDocsData = (): GetDocsData => ({
  type: GET_DOCS_DATA_REQUEST,
});

export const getDocsDataSuccess = (payload: Attachment[]): GetDocsDataSuccess => ({
  type: GET_DOCS_DATA_SUCCESS,
  payload,
});

export const getDocsDataFail = (): GetDocsDataFail => ({
  type: GET_DOCS_DATA_FAIL,
});

export const getDocTypes = (): GetDocTypes => ({
  type: GET_DOC_TYPES_REQUEST,
});

export const getDocTypesSuccess = (payload: DocType[]): GetDocTypesSuccess => ({
  type: GET_DOC_TYPES_SUCCESS,
  payload,
});

export const getDocTypesFail = (): GetDocTypesFail => ({
  type: GET_DOC_TYPES_FAIL,
});

export const fetchDocumentsType = (): ThunkAction<any, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(getDocTypes());
  try {
    const response: any = await API.getDocumentsType();

    dispatch(getDocTypesSuccess(response));
  } catch (error: any) {
    dispatch(getDocTypesFail());
  }
};

export const uploadNationalId =
  (payload: UploadDocumentPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestUploadNationalId());
    try {
      const response: any = await API.uploadDocument(payload);

      dispatch(requestUploadNationalIdSuccess());

      return response.filename;
    } catch (error: any) {
      dispatch(requestUploadNationalIdFail());
    }
  };

export const fetchUploadedDocumentURL =
  (payload: FetchUploadedDocumentURLPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async () => {
    try {
      const response: any = await API.getUploadedDocumentURL(payload);

      return response.url;
    } catch (error: any) {
      // some error
    }
  };

export const uploadSelfiePicture =
  (payload: UploadDocumentPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestUploadSelfiePicture());
    try {
      const response: any = await API.uploadDocument(payload);

      dispatch(requestUploadSelfiePictureSuccess());

      return response.filename;
    } catch (error: any) {
      dispatch(requestUploadSelfiePictureFail());
    }
  };

export const sendAttachedDocuments =
  (payload: AttachedDoc[]): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(sendDocsData());

    try {
      await API.sendAttachedDoc(payload);

      dispatch(sendDocsDataSuccess());
    } catch (error: any) {
      dispatch(sendDocsDataFail());
    }
  };

export const fetchAttachedDocuments = (): ThunkAction<any, any, unknown, AnyAction> => async (dispatch) => {
  dispatch(getDocsData());

  try {
    const result = await API.getAttachedDocs();

    dispatch(getDocsDataSuccess(result));
  } catch (error: any) {
    dispatch(getDocsDataFail());
  }
};
