import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import FAQS from '../../constants/faqs';
// import { getFaqs } from "../../helpers/APIs";
import {
  CLEAR_FAQ,
  ClearFaq,
  FaqItem,
  GET_FAQS_FAIL,
  GET_FAQS_REQUEST,
  GET_FAQS_SUCCESS,
  RequestGetFaqs,
  RequestGetFaqsFail,
  RequestGetFaqsSuccess,
} from '../types/faqTypes';

export const requestGetFaqs = (): RequestGetFaqs => ({
  type: GET_FAQS_REQUEST,
});

export const requestGetFaqsSuccess = (result: FaqItem[]): RequestGetFaqsSuccess => ({
  type: GET_FAQS_SUCCESS,
  payload: result,
});

export const requestGetFaqsFail = (): RequestGetFaqsFail => ({
  type: GET_FAQS_FAIL,
});

export const clearFaq = (): ClearFaq => ({
  type: CLEAR_FAQ,
});

export const findFaqs =
  (lang: string): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestGetFaqs());
    try {
      // TODO: Use when BE is ready
      // const response: any = await getFaqs();

      const response = {
        data: FAQS[lang],
      };

      dispatch(requestGetFaqsSuccess(response.data));

      return response.data;
    } catch (error: any) {
      dispatch(requestGetFaqsFail());
    }
  };
