import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import { alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { CustomChip } from '../../components/User/CustomChip';
import { useSendEmailVerificationMutation, useVerifyEmailOTPMutation } from '../../redux/features/auth/auth.apiSlice';
import { locations } from '../../routes/locations';
import MButton from '../../shared/MButton';
import COLORS from '../../constants/colors';
import CenterContent from '../../shared/CenterContent';

export const EmailVerification = () => {
  const { t } = useTranslation();
  const { t: tApiError, i18n } = useTranslation('apiError');
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const isEnglish = i18n.language === 'en';
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResent, setIsResent] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const email = search.get('email');
  const code = search.get('code');

  const [sendEmailVerification] = useSendEmailVerificationMutation();
  const [verifyEmailOTP] = useVerifyEmailOTPMutation();

  const onContinue = async () => {
    if (isVerified) {
      return navigate(locations.home());
    }

    setIsLoading(true);
    try {
      await sendEmailVerification({ email: email! }).unwrap();

      setIsResent(true);
    } catch (error: any) {
      if (i18n.exists(`apiError:${error?.data?.data}`)) {
        setErrorMessage(`apiError:${error?.data?.data}`);
      } else if (i18n.exists(`apiError:${error?.data?.message}`)) {
        setErrorMessage(`apiError:${error?.data?.message}`);
      } else {
        setErrorMessage(error?.data?.message);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const verifyEmail = async () => {
      if (email && code) {
        setIsLoading(true);
        try {
          await verifyEmailOTP({ email, otpCode: code }).unwrap();

          setIsVerified(true);
        } catch (error: any) {
          if (i18n.exists(`apiError:${error?.data?.data}`)) {
            setErrorMessage(`apiError:${error?.data?.data}`);
          } else if (i18n.exists(`apiError:${error?.data?.message}`)) {
            setErrorMessage(`apiError:${error?.data?.message}`);
          } else {
            setErrorMessage(error?.data?.message);
          }
          if (error?.data?.data === 'EMAIL_IS_ALREADY_VERIFIED') {
            setIsVerified(true);
          }
        }
        setIsLoading(false);
      }
    };

    verifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, code]);

  const renderContent = () => {
    if (isLoading) {
      return <CircularProgress />;
    }

    if (isResent) {
      return t('emailResent');
    }

    if (isVerified) {
      return t('emailVerified');
    }
  };

  return (
    <CenterContent>
      <Grid container height="100%" alignItems="center" justifyContent="center">
        <Grid
          item
          container
          component={Paper}
          elevation={0}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          rowSpacing="16px"
          maxWidth="sm"
          padding="0px 24px"
          borderRadius={3}
        >
          <Grid
            item
            sx={{
              padding: { xs: '24px', md: '48px' },
              maxWidth: { xs: '100%', md: '650px' },
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <img src={`/images/${isVerified ? 'mail-sent' : 'mail-not-sent'}.svg`} alt="status icon" />
            <Typography
              variant={isEnglish ? 'h6' : 'cairoButtonR'}
              color={COLORS.SECONDARY_GREEN}
              paragraph
              textAlign="center"
              sx={{ marginTop: 8, marginBottom: 8 }}
            >
              {renderContent()}
            </Typography>
            {errorMessage && (
              <CustomChip
                icon={
                  <CancelIcon
                    sx={{
                      width: '14px',
                      height: '14px',
                      color: `${COLORS.RED}!important`,
                    }}
                  />
                }
                label={tApiError(errorMessage)}
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  borderRadius: '6px',
                  width: '100%',
                  justifyContent: 'start',
                  padding: '24px 12px',
                  backgroundColor: alpha(COLORS.RED, 0.12),
                  whiteSpace: 'pre-wrap',
                }}
              />
            )}
          </Grid>
          <Grid
            item
            sx={{
              maxWidth: { xs: '100%', md: '650px' },
              margin: 'auto',
              mx: { xs: '24px', md: 'auto' },
            }}
          >
            <MButton
              variant="text"
              fullWidth
              disabled={!email}
              buttonProps={{
                sx: {
                  height: { xs: '50px', md: 60 },
                },
              }}
              onClick={onContinue}
            >
              {t(isVerified ? 'continue' : 'resend')}
            </MButton>
          </Grid>
        </Grid>
      </Grid>
    </CenterContent>
  );
};
