import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import COLORS from '../../constants/colors';
import TermsData from '../../assets/data/terms-and-conditions.json';

export const TermsAndConditions = () => {
  const { t, i18n } = useTranslation('terms-and-conditions', {
    useSuspense: true,
  });

  const isEnglish = i18n.language === 'en';

  return (
    <Container component="main" maxWidth="xl" sx={{ height: { xs: '65vh', md: '100vh' } }}>
      <Box style={{ position: 'absolute', right: 0, top: '40%' }}>
        <img src="/images/logo-right-cut.svg" alt="madkhol-frame" />
      </Box>
      <Box
        sx={{
          height: '100%',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '5px !important',
            backgroundColor: 'transparent !important',
            borderRadius: '4px !important',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#111827 !important',
            borderRadius: '4px !important',
          },
          textAlign: 'left',
          py: { xs: '24px', md: 32 },
          pr: { xs: '24px', md: 34 },
          pl: { xs: '24px', md: 43 },
        }}
        className="hide-scrollbar"
      >
        <Typography
          variant={isEnglish ? 'h1' : 'cairoHeader2'}
          color={COLORS.MAIN_DARK}
          sx={{ display: 'block', mb: 6 }}
        >
          {t('termsTitle')}
        </Typography>
        <Typography variant={isEnglish ? 'bodyMedium' : 'cairoR16'} color={COLORS.DARK_GREY}>
          {t('description1')}
        </Typography>
        <Typography
          variant={isEnglish ? 'bodyMedium' : 'cairoR16'}
          color={COLORS.DARK_GREY}
          sx={{ display: 'block', mb: 4, mt: 4 }}
        >
          {t('description2')}
        </Typography>
        {TermsData[i18n.language as 'en' | 'ar'].map((clause: IAnyObject, index) => (
          <Box key={clause.id}>
            <Typography variant="h3" color={COLORS.MAIN_DARK} sx={{ mb: 5, mt: 6 }}>
              {clause.id}. {clause.title}
            </Typography>
            {clause.description.map((description: IAnyObject | string, dIndex: number) => {
              if (index + 1 === 1 && typeof description === 'object') {
                // for terms points
                return (
                  <Box sx={{ mb: 3, ml: 4 }} key={`${clause.id}-${dIndex}`}>
                    <Typography
                      component="span"
                      variant={isEnglish ? 'bodyMedium' : 'cairoR16'}
                      color={COLORS.DARK_GREY}
                      sx={{
                        whiteSpace: 'pre-wrap',
                        fontWeight: 'bold',
                        mr: 2,
                      }}
                    >
                      {description.term}
                    </Typography>
                    <Typography
                      component="span"
                      variant={isEnglish ? 'bodyMedium' : 'cairoR16'}
                      color={COLORS.DARK_GREY}
                    >
                      {description.definition}
                    </Typography>
                  </Box>
                );
              }
              if (index + 1 === 30 && typeof description === 'object') {
                // for the conflict of interest policy
                return (
                  <React.Fragment key={`${clause.id}-${dIndex}`}>
                    <Typography variant="h4" color={COLORS.DARK_GREY} sx={{ ml: 4, mb: 4 }}>
                      {description.title}
                    </Typography>
                    {description.desc.map((desc: IAnyObject, ddIndex: number) => (
                      <React.Fragment key={`${clause.id}-${dIndex}-${ddIndex}`}>
                        <Typography
                          variant={isEnglish ? 'bodyMedium' : 'cairoR16'}
                          color={COLORS.DARK_GREY}
                          sx={{
                            display: 'block',
                            mb: 3,
                            whiteSpace: 'pre-wrap',
                            ml: 12,
                          }}
                        >
                          {desc.subtitle}
                        </Typography>
                        {desc.text.map((text: string, ddtIndex: number) => (
                          <Typography
                            key={`${clause.id}-${dIndex}-${ddIndex}-${ddtIndex}`}
                            variant={isEnglish ? 'bodyMedium' : 'cairoR16'}
                            color={COLORS.DARK_GREY}
                            sx={{
                              display: 'block',
                              mb: 4,
                              whiteSpace: 'pre-wrap',
                              ml: 16,
                            }}
                          >
                            {text}
                          </Typography>
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                );
              }
              return (
                <Typography
                  key={`${clause.id}-${dIndex}`}
                  variant={isEnglish ? 'bodyMedium' : 'cairoR16'}
                  color={COLORS.DARK_GREY}
                  sx={{
                    display: 'block',
                    mb: 4,
                    whiteSpace: 'pre-wrap',
                    ml: 4,
                  }}
                >
                  {description as string}
                </Typography>
              );
            })}
          </Box>
        ))}
      </Box>
    </Container>
  );
};
