import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import COLORS from '../../../constants/colors';
import { useTypedDispatch } from '../../../redux/store';
import { signUserAgreement } from '../../../store_deprecated/actions/userAgreementActions';
import { agreementSelector } from '../../../store_deprecated/selectors/agreementSelectors';
import { UserAgreementName } from '../../../store_deprecated/types/userAgreementTypes';
import MButton from '../../../shared/MButton';

interface ChangeAllocationAgreementDialogProps {
  open: boolean;
  onClose: (isAgree?: boolean) => void;
  postSign?: () => void;
}

export default function ChangeAllocationAgreementDialog(props: ChangeAllocationAgreementDialogProps) {
  const { open, onClose, postSign } = props;
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const [agree, setAgree] = useState(false);

  const { isLoading, error, isErrorExist } = useSelector(agreementSelector);

  useEffect(() => () => setAgree(false), [open]);

  const handleClose = async (isAgree: boolean) => {
    if (isLoading) {
      return;
    }

    if (isAgree) {
      try {
        await dispatch(signUserAgreement(UserAgreementName.CHANGE_ALLOCATION_AGREEMENT));
        if (postSign) postSign();
      } catch (e) {
        console.error(e);
      }
    }

    return onClose(isAgree);
  };

  const renderContent = () => (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" rowSpacing="48px">
      <Grid item textAlign="right" alignSelf="end">
        <IconButton onClick={() => handleClose(false)}>
          <CloseIcon sx={{ fontSize: '12px', color: COLORS.MAIN_DARK }} />
        </IconButton>
      </Grid>
      <Grid
        item
        textAlign="center"
        sx={{
          paddingTop: { xs: '24px !important', md: '24px !important' },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: 500,
            fontSize: { xs: '22px', md: '35px' },
            lineHeight: { xs: '32px', md: '48px' },
            color: COLORS.MAIN_DARK,
          }}
        >
          {t('changeAllocationAgreementDialog.title')}
        </Typography>
      </Grid>
      <Grid
        item
        textAlign="center"
        sx={{
          paddingTop: { xs: '24px !important', md: '48px !important' },
        }}
      >
        <Typography
          variant="bodyLarge"
          sx={{
            fontWeight: 400,
            fontSize: { xs: '14px', md: '16px' },
            lineHeight: { xs: '20px', md: '28px' },
            color: COLORS.DARK_GREY,
          }}
        >
          {t('changeAllocationAgreementDialog.description')}
        </Typography>
      </Grid>
      <Grid item component="ul">
        <li>
          <Typography variant="bodySmall" sx={{ color: COLORS.DARK_GREY }}>
            {t('changeAllocationAgreementDialog.point1')}
          </Typography>
        </li>
        <li>
          <Typography variant="bodySmall" sx={{ color: COLORS.DARK_GREY }}>
            {t('changeAllocationAgreementDialog.point2')}
          </Typography>
        </li>
        <li>
          <Typography variant="bodySmall" sx={{ color: COLORS.DARK_GREY }}>
            {t('changeAllocationAgreementDialog.point3')}
          </Typography>
        </li>
        <li>
          <Typography variant="bodySmall" sx={{ color: COLORS.DARK_GREY }}>
            {t('changeAllocationAgreementDialog.point4')}
          </Typography>
        </li>
      </Grid>
      <Grid item>
        <FormControlLabel
          name="signAgreement"
          control={
            <Checkbox
              checked={agree}
              onChange={(event, checked) => setAgree(checked)}
              name="signAgreement"
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 'clamp(1rem,1vw + 1rem,1.5rem)',
                },
                color: COLORS.SECONDARY_GREEN,
              }}
              checkedIcon={<CheckBoxOutlinedIcon sx={{ color: COLORS.SECONDARY_GREEN }} />}
            />
          }
          label={
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: '0.75rem', md: '1.125rem' },
                lineHeight: { xs: '20px', md: '28px' },
                color: agree ? COLORS.SECONDARY_GREEN : COLORS.MAIN_DARK,
              }}
              textAlign="left"
            >
              {t('changeAllocationAgreementDialog.signAgreement')}
            </Typography>
          }
          sx={{
            width: '100%',
            border: `1px solid ${COLORS.XX_LIGHT_GREEN}`,
            borderRadius: 1,
            marginBottom: { xs: 6, md: 3 },
            marginLeft: 0,
            marginRight: 0,
            padding: { xs: '4px 5px 3px 3px', md: '4px 3px' },
          }}
        />
      </Grid>
      {(isLoading || isErrorExist) && (
        <Grid item>
          {isLoading && <CircularProgress />}
          {isErrorExist && (
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '28px',
                color: COLORS.RED,
              }}
            >
              {error}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item container rowSpacing="24px">
        <Grid item width="100%">
          <MButton
            id="SignChangeAllocationAgreementButton"
            variant="contained"
            onClick={() => handleClose(true)}
            disabled={!agree || isLoading}
            fullWidth
          >
            {t('sign')}
          </MButton>
        </Grid>
        <Grid item width="100%">
          <MButton variant="outlined" onClick={() => handleClose(false)} disabled={isLoading} fullWidth>
            {t('cancel')}
          </MButton>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Dialog open={open} onClose={() => handleClose(false)} maxWidth="lg" disableScrollLock>
      <Box
        sx={{
          padding: '32px 24px',
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            width: '5px !important',
            backgroundColor: 'transparent !important',
            borderRadius: '4px !important',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#111827 !important',
            borderRadius: '4px !important',
          },
        }}
      >
        {renderContent()}
      </Box>
    </Dialog>
  );
}
