import { Selector } from 'react-redux';
import { GlobalState } from '../types';
import {
  AnbMastercardConfigInfo,
  Bank,
  BankInfo,
  BankItem,
  BankType,
  DepositInfo,
  StatementInfo,
  SubscriptionItem,
  WithdrawInfo,
} from '../types/walletTypes';

// Wallet Slice Selectors

export const bankInfoSelector: Selector<GlobalState, BankInfo> = (state) => state.walletState.bankInfo;

export const withdrawSelector: Selector<GlobalState, WithdrawInfo> = (state) => state.walletState.withdrawInfo;

export const depositSelector: Selector<GlobalState, DepositInfo> = (state) => state.walletState.depositInfo;

export const anbMastercardConfigSelector: Selector<GlobalState, AnbMastercardConfigInfo> = (state) =>
  state.walletState.anbMastercardConfigInfo;

export const editBankInfoSelector: Selector<GlobalState, BankItem | null> = (state) =>
  state.walletState.editBankInfo.data;

export const madkhoulBankInfoSelector: Selector<GlobalState, BankItem | undefined> = (state) => {
  const { data } = state.walletState.bankInfo;

  return data.find((item) => item.bankType.type === BankType.VIRTUAL);
};

export const otherBankInfoSelector: Selector<GlobalState, BankItem[]> = (state) => {
  const { data } = state.walletState.bankInfo;

  return data.filter((item) => item.bankType.type === BankType.OTHER);
};

export const primaryBankInfoSelector: Selector<GlobalState, BankItem | undefined> = (state) => {
  const { data } = state.walletState.bankInfo;

  return data.find((item) => item.isPrimaryAccount);
};

export const banksSelector: Selector<GlobalState, Bank[]> = (state) => state.walletState.banks.banks;

export const banksIsLoadingSelector: Selector<GlobalState, boolean> = (state) => state.walletState.banks.isLoading;

export const getBankInfoIsLoading: Selector<GlobalState, boolean> = (state) => state.walletState.bankInfo.isLoading;

export const getEditBankInfoIsLoading: Selector<GlobalState, boolean> = (state) =>
  state.walletState.editBankInfo.isLoading;

export const getBankInfoIsFailed: Selector<GlobalState, boolean> = (state) => state.walletState.bankInfo.isErrorExist;

export const subscriptionsIsLoading: Selector<GlobalState, boolean> = (state) => state.walletState.subscriptions.isLoading;

export const subscriptionsSelector: Selector<GlobalState, SubscriptionItem[]> = (state) => state.walletState.subscriptions.subscriptions;

export const getCreatedSubscriptionIsLoading: Selector<GlobalState, boolean> = (state) => state.walletState.createdSubscription.isLoading;

export const createdSubscriptionSelector: Selector<GlobalState, SubscriptionItem | null> = (state) => state.walletState.createdSubscription.subscription;

export const statementSelector: Selector<GlobalState, StatementInfo> = (state) => state.walletState.statementInfo;
