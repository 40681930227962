import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import Deposit, { DepositProps } from '../../components/User/MyWallet/Deposit';
// import ApplePay from "../../components/User/MyWallet/ApplePay";
import Widgets from '../../components/User/MyWallet/Widgets';
import IbanNumber from '../../components/User/MyWallet/IbanNumber';
import BankAccounts from '../../components/User/MyWallet/BankAccounts';
import { UserStatus } from '../../constants/user';
import { authSelector } from '../../redux/features/auth/auth.selectors';
import { useTypedDispatch } from '../../redux/store';
import {
  clearWallet,
  fetchAnbMasterardConfigAction,
  fetchBankInfo,
  fetchNetWithdraw,
  fetchStatementDelta,
} from '../../store_deprecated/actions/walletActions';
import SwiftCode from '../../components/User/MyWallet/SwiftCode';
import {
  findPortfolioLatestStatistics,
  findSubscribedPortfolio,
} from '../../store_deprecated/actions/portfolioActions';
import { findUserIndexFunds } from '../../store_deprecated/actions/indexFundActions';
import Withdraw from '../../components/User/MyWallet/Withdraw';
import BetaCards from '../../components/User/MyWallet/BetaCards';
import Notification from '../../components/User/BetaDashboard/Notification';
import NotificationType from '../../constants/NotificationType';
import COLORS from '../../constants/colors';
import { findAlpacaDividends } from '../../store_deprecated/actions/alpacaActions';
import { useFetchSubscribedProductsQuery } from '../../redux/features/products/products.apiSlice';
import SuccessAndFailurePaymentModals from '../../shared/SuccessAndFailurePaymentModals';
import { setProductToInvest } from '../../redux/features/products/selectedCardToInvest';
import useGetUserProducts from '../../helpers/hooks/useGetUserProducts';
import { setProducts } from '../../redux/features/products/getProductsSlice';
// import Cards from '../../components/User/MyWallet/Cards';

const MyWallet: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const { user } = useSelector(authSelector);
  const [depositConfig, setDepositConfig] = useState<DepositProps | null>(null);
  const { state } = useLocation();
  const { data: subscribedProducts } = useFetchSubscribedProductsQuery({});
  const isBankSave = state?.isBankSave;

  const canDeposit = user?.status?.name === UserStatus.ACTIVE;

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const results = await Promise.all([
          dispatch(fetchBankInfo()),
          dispatch(fetchNetWithdraw()),
          dispatch(fetchStatementDelta()),
          dispatch(findUserIndexFunds()),
          dispatch(findSubscribedPortfolio()),
          dispatch(findPortfolioLatestStatistics()),
          dispatch(fetchAnbMasterardConfigAction()),
          dispatch(findAlpacaDividends()),
        ]);

        const anbMastercardConfig = results?.[6];

        if (anbMastercardConfig?.url && anbMastercardConfig?.version) {
          setDepositConfig({
            anbMastercardCheckoutBaseUrl: anbMastercardConfig.url,
            anbMastercardCheckoutVersion: anbMastercardConfig.version,
          });
        }
      } catch (e) {
        console.log('Some error');
      }
    };

    fetchInitialData();

    return () => {
      dispatch(clearWallet());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const products = useGetUserProducts();

  useEffect(() => {
    if (products) {
      dispatch(setProductToInvest({ backendProductId: products?.at(0)?.backendProductId, toScreen: 'my-wallet' }));
      dispatch(setProducts(products));
    }
  }, [products?.length]);

  return (
    <Box
      sx={{
        padding: { xs: '0 24px', md: '32px' },
        mt: { xs: '0', md: 'auto' },
        minHeight: '70vh',
      }}
    >
      <Grid container flexDirection="column" justifyContent="space-between">
        <Grid item>
          <Typography component="h1" variant="h5">
            {t('myWallet')}
          </Typography>
        </Grid>
        <Grid
          item
          mt="32px"
          sx={{
            '@media (max-width:900px)': {
              width: 'fit-content',
            },
          }}
        >
          <Widgets subscribedProducts={subscribedProducts?.items} />
        </Grid>
        {isBankSave && (
          <Notification
            status={NotificationType.SUCCESS}
            padding={{ xs: '24px 0', md: '32px 0' }}
            text={
              <Typography variant="bodyMedium" sx={{ color: COLORS.MAIN_DARK, whiteSpace: 'pre-line' }}>
                {t('savedBankMsg')}
              </Typography>
            }
          />
        )}
        <Grid item container columnSpacing="24px" mt="24px">
          <Grid item xs={12} md={6} sx={{ mb: { xs: '12px', md: 'unset' } }}>
            <IbanNumber />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mb: { xs: '12px', md: 'unset' } }}>
            <SwiftCode />
          </Grid>
        </Grid>
        {canDeposit ? (
          <Grid item container columnSpacing="24px" mt="48px">
            <Grid item xs={12} sx={{ mb: { xs: '12px', md: 'unset' } }}>
              <Deposit {...depositConfig} page="my-wallet" />
            </Grid>
            <Grid item xs={12} sx={{ mb: { xs: '12px', md: 'unset' } }}>
              <Withdraw />
            </Grid>
          </Grid>
        ) : (
          <Box mt="48px">
            <Withdraw />
          </Box>
        )}
        <Box mt="22px" sx={{ width: '100%' }}>
          <BetaCards />
        </Box>
        <Grid item mt="48px">
          <BankAccounts />
        </Grid>
      </Grid>
      <SuccessAndFailurePaymentModals />
    </Box>
  );
};

export default MyWallet;
