import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useTypedDispatch } from '../../redux/store';
import { clearFaq, findFaqs } from '../../store_deprecated/actions/faqActions';
import { getFaqSelector } from '../../store_deprecated/selectors/faqSelectors';
import { formatNumber } from '../../utils/number';
import COLORS from '../../constants/colors';

const Faqs: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useTypedDispatch();
  const [expanded, setExpanded] = React.useState<Record<number, boolean>>({});

  const { items } = useSelector(getFaqSelector);

  const handleChange = (panelId: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded((prevState) => ({ ...prevState, [panelId]: isExpanded }));
  };

  useEffect(() => {
    dispatch(findFaqs(i18n.language));

    return () => {
      dispatch(clearFaq());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(findFaqs(i18n.language));

    return () => {
      dispatch(clearFaq());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <Box sx={{ padding: { xs: '0 24px', md: '32px' } }}>
      <Grid container flexDirection="column" justifyContent="space-between">
        <Grid item>
          <Typography component="h1" variant="h5">
            {t('faqs.title')}
          </Typography>
        </Grid>

        <Box mt="32px" sx={{ px: { xs: 0, md: '16%' }, width: '100%' }}>
          {items.map((i) => (
            <Accordion
              key={`faq-item-${i.id}`}
              expanded={expanded[i.id]}
              onChange={handleChange(i.id)}
              elevation={0}
              sx={{
                padding: { xs: '16px', md: '16px 24px' },
                mb: '24px !important',
                borderRadius: '8px !important',
                borderWidth: '1px !important',
                borderStyle: 'solid !important',
                borderColor: COLORS.XX_LIGHT_GREEN,
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  expanded[i.id] ? (
                    <RemoveIcon sx={{ color: COLORS.MAIN_DARK }} />
                  ) : (
                    <AddIcon sx={{ color: COLORS.MAIN_DARK }} />
                  )
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '0 0 12px !important',
                  '& .MuiAccordionSummary-content': {
                    marginBottom: '0 !important',
                  },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: { xs: '30px', md: '48px' },
                    height: { xs: '30px', md: '48px' },
                    borderRadius: '50%',
                    backgroundColor: COLORS.SECONDARY_GREEN,
                    color: COLORS.WHITE,
                  }}
                >
                  <Typography variant="bodyLarge">{formatNumber(i.id + 1, i18n.language)}</Typography>
                </Box>
                <Typography
                  variant="bodyMediumB"
                  sx={{
                    width: '85%',
                    flexShrink: 0,
                    alignSelf: 'center',
                    marginLeft: { xs: '8px', md: '16px' },
                    color: COLORS.MAIN_DARK,
                    fontSize: { xs: '0.875rem', md: '1rem' },
                  }}
                >
                  {i.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="bodyMedium"
                  sx={{
                    ml: { xs: '40px', md: '64px' },
                    color: COLORS.DARK_GREY,
                    boxDecorationBreak: 'clone',
                    maxWidth: '95%',
                    display: 'block',
                    fontSize: { xs: '0.875rem', md: '1rem' },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: i.description,
                  }}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Grid>
    </Box>
  );
};

export default Faqs;
