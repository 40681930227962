import { CaptureConsole, ContextLines, ExtraErrorData, HttpClient, ReportingObserver } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import packageJson from '../../package.json';
import { JSONParse } from './utils';

const sentryIgnoreErrors = (process.env.REACT_APP_SENTRY_IGNORE_ERRORS || '').split(',') || [];

export const initSentry = () => {
  const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

  if (SENTRY_DSN && SENTRY_DSN.length > 0) {
    Sentry.init({
      release: `madkhol-frontend@${packageJson.version}`,
      dsn: SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIRONMENT,
      attachStacktrace: true,

      integrations: [
        ...Sentry.defaultIntegrations,
        new Sentry.Replay(),
        new Sentry.BrowserTracing(),
        new CaptureConsole({ levels: ['error', 'warn'] }),
        new ContextLines(),
        new ExtraErrorData(),
        new HttpClient(),
        new ReportingObserver(),
      ],

      // This option is required for capturing headers and cookies.
      sendDefaultPii: true,

      // To set a uniform sample rate
      tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || '1.0'),
      replaysOnErrorSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || '1.0'),

      debug: JSONParse(process.env.REACT_APP_SENTRY_DEBUG || 'false') || false,

      ignoreErrors: [
        'Non-Error exception captured',
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
        'TypeError: Cancelled',
        ...sentryIgnoreErrors,
      ].filter(Boolean),

      beforeSend(event, hint) {
        if (
          hint &&
          hint.originalException &&
          hint.originalException instanceof AxiosError &&
          hint.originalException.isAxiosError
        ) {
          if (hint.originalException.response && hint.originalException.response.data) {
            const contexts = {
              ...event.contexts,
            };
            contexts.errorResponse = {
              data: hint.originalException.response.data,
              status: hint.originalException.response.status,
            };
            event.contexts = contexts;
          }
        }

        return event;
      },

      beforeSendTransaction(event) {
        return event;
      },
    });
  }
};
