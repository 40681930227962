import * as yup from 'yup';

export const emailSchema = yup
  .string()
  .trim()
  .required('missingField')
  .email('invalidFormat')
  .matches(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'invalidFormat',
  );
// .test(
//   "is-valid",
//   (message) => `${message.path} is invalid`,
//   (value) => value ? isEmailValidator(value) : new yup.ValidationError("invalidFormat")
// );

// export const madkholEmailSchema = yup
//   .string()
//   .email("Enter a valid email")
//   .emailDomain("@madkhol.com")
//   .required("Email is required")
//   .trim();

// export const madkholSecretEmailSchema = yup
//   .string()
//   .email("Username is incorrect")
//   .emailDomain("@madkhol.com", "Username is incorrect")
//   .required("Username is required")
//   .trim();
