import { isValidPhoneNumber } from 'react-phone-number-input';
import PasswordValidationStatus from '../constants/PasswordValidationStatus';

type FormData = {
  type: 'fullName' | 'mobile' | 'email' | 'password' | string;
  value: string;
};

export function hasConsecutiveCharacters(s: string[]): boolean {
  if (s.length < 3) {
    return false;
  }

  let consCount = 1;

  for (let i = 1; i < s.length; i++) {
    if (s[i].charCodeAt(0) - s[i - 1].charCodeAt(0) === 1) {
      consCount += 1;
    } else {
      consCount = 1;
    }

    if (consCount >= 3) {
      return true;
    }
  }

  return false;
}

export function validate(fn: FormData) {
  let errors: string[] = [];

  switch (fn.type) {
    case 'fullName': {
      const regex = /^[a-zA-Zء-ي ]+$/;

      return regex.test(fn.value);
    }

    case 'mobile': {
      return isValidPhoneNumber(`${fn.value}`, 'SA');
    }

    case 'email': {
      const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

      if (!regex.test(fn.value) || fn?.value?.length <= 10) {
        return false;
      }
      return true;
    }

    case 'password': {
      if ((fn.value || '').length < 8) {
        errors = [...errors, PasswordValidationStatus.SHORT_PASSWORD];
      }

      // Combination of capital & small letters, digits, and special character at least 1 character for each.
      if (
        !/^(?=.*[a-z]|[A-Z])/.test(fn.value) || // check letters
        !/^(?=.*[0-9])/.test(fn.value) || // check Digit
        !/^(?=.*[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?\/])/.test(fn.value) // check SpecialChar
      ) {
        errors = [...errors, PasswordValidationStatus.AT_LEAST_UPPERCASE_CHAR];
      }

      // 3 or more consecutive identical characters/numbers; e.g. 111, aaa, bbb, 222, etc.
      if (hasConsecutiveCharacters(fn.value.split(''))) {
        errors = [...errors, PasswordValidationStatus.COMBINATION_SEQUENCE_OR_CONSECUTIVE_PATTERN];
      }

      return errors;
    }

    default: {
      return [];
    }
  }
}
