import { LOGOUT } from '../types/authTypes';
import { CLEAR_FAQ, FaqAction, FaqState, GET_FAQS_FAIL, GET_FAQS_REQUEST, GET_FAQS_SUCCESS } from '../types/faqTypes';

const INITIAL_STATE: FaqState = {
  faq: {
    items: [],
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
};

/**
 * TODO: We must migrate to `@reduxjs/toolkit`!
 * @deprecated
 */
const faqReducer = (state: FaqState = INITIAL_STATE, action: FaqAction = { type: LOGOUT }): FaqState => {
  switch (action.type) {
    case GET_FAQS_REQUEST:
      return {
        ...state,
        faq: {
          ...state.faq,
          isLoading: true,
        },
      };

    case GET_FAQS_SUCCESS:
      return {
        ...state,
        faq: {
          ...state.faq,
          items: action.payload,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case GET_FAQS_FAIL:
      return {
        ...state,
        faq: {
          ...state.faq,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case CLEAR_FAQ:
      return { ...INITIAL_STATE };

    case LOGOUT:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default faqReducer;
