import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { getTransactionsHistory } from '../../helpers/api_deprecated';
import {
  CLEAR_TRANSACTION,
  ClearTransactions,
  FindTransactionsHistoryPayload,
  GET_TRANSACTIONS_HISTORY_FAIL,
  GET_TRANSACTIONS_HISTORY_REQUEST,
  GET_TRANSACTIONS_HISTORY_SUCCESS,
  RequestGetTransactionsHistoryFail,
  RequestTransactionsHistory,
  RequestTransactionsHistorySuccess,
  TransactionsHistoryData,
} from '../types/transactionTypes';

export const requestTransactionsHistoryPortfolio = (): RequestTransactionsHistory => ({
  type: GET_TRANSACTIONS_HISTORY_REQUEST,
});

export const requestTransactionsHistorySuccess = (payload: {
  result: TransactionsHistoryData;
  isLoadMore: boolean;
}): RequestTransactionsHistorySuccess => ({
  type: GET_TRANSACTIONS_HISTORY_SUCCESS,
  payload,
});

export const requestGetTransactionsHistoryFail = (payload: string): RequestGetTransactionsHistoryFail => ({
  type: GET_TRANSACTIONS_HISTORY_FAIL,
  payload,
});

export const clearTransactions = (): ClearTransactions => ({
  type: CLEAR_TRANSACTION,
});

export const findTransactionsHistory =
  (payload: FindTransactionsHistoryPayload): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestTransactionsHistoryPortfolio());

    const isLoadMore = !!payload.offset && payload.offset > 0;

    try {
      const response: any = await getTransactionsHistory(payload);

      dispatch(requestTransactionsHistorySuccess({ result: response.data, isLoadMore }));

      return response.data;
    } catch (error: any) {
      dispatch(requestGetTransactionsHistoryFail(error.message));
    }
  };
