import { SaveSubscriptionRequest, SaveSubscriptionResponse } from '../types/subscription';
import { ApiService } from './common/ApiService';

class SubscriptionService extends ApiService {
  pathname = 'app/subscription';

  saveSubscription = async (data: SaveSubscriptionRequest) =>
    this.post<SaveSubscriptionResponse, SaveSubscriptionRequest>(`${this.pathname}`, data);
}

export const subscriptionService = new SubscriptionService();
