import * as React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import COLORS from '../constants/colors';

interface ProgressBarProps {
  value: number;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: COLORS.SECONDARY_GREEN,
  },
}));

const ProgressBar: React.FC<ProgressBarProps> = ({ value }) => (
  <BorderLinearProgress variant="determinate" value={value} />
);

export default ProgressBar;
