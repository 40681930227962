import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import COLORS from '../../../constants/colors';
import { useTypedDispatch } from '../../../redux/store';
import { getBankInfoIsLoading, otherBankInfoSelector } from '../../../store_deprecated/selectors/walletSelectors';
import { deleteBankInfo, fetchBankInfo, setPrimaryBankAccount } from '../../../store_deprecated/actions/walletActions';
import { locations } from '../../../routes/locations';
import MButton from '../../../shared/MButton';

const BankAccounts: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const bankAccounts = useSelector(otherBankInfoSelector);
  const isLoading = useSelector(getBankInfoIsLoading);

  const addBankAccount = () => {
    navigate(locations.myWalletBankAccount(0));
  };

  const editBankAccount = (id: number) => {
    navigate(locations.myWalletBankAccount(id));
  };

  const onDeleteBankAccount = async (id: number) => {
    const result: boolean = (await dispatch(deleteBankInfo(id))) as any;

    if (result) {
      dispatch(fetchBankInfo());
    }
  };

  const onSetPrimaryBankAccount = async (id: number) => {
    const result: boolean = (await dispatch(setPrimaryBankAccount(id))) as any;

    if (result) {
      dispatch(fetchBankInfo());
    }
  };

  const renderItems = () => (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center">
      {isLoading ? (
        <CircularProgress />
      ) : (
        bankAccounts.map((item) => (
          <Grid
            key={item.id}
            item
            container
            flexDirection="row"
            flexWrap="nowrap"
            component={Paper}
            elevation={0}
            justifyContent="space-between"
            sx={{
              px: '24px',
              py: '28px',
              mb: '16px',
              borderRadius: '12px',
              '@media (max-width:600px)': {
                flexDirection: 'column',
                gap: '40px',
              },
            }}
          >
            <Grid
              item
              container
              flexDirection="row"
              flexWrap="nowrap"
              alignItems="center"
              sx={{
                '@media (max-width:600px)': {
                  justifyContent: 'space-between',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Grid item>
                  <AccountBalanceIcon
                    sx={{
                      color: COLORS.PRIMARY_BLUE,
                      marginRight: '12px',
                      width: '20px',
                      height: '20px',
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      color: COLORS.MAIN_DARK,
                      fontWeight: 400,
                      fontSize: 'clamp(1rem,6vw + 1rem,1.125rem)',
                      lineHeight: '28px',
                      marginRight: '32px',
                      '@media (max-width:400px)': {
                        fontSize: '15px',
                      },
                    }}
                    variant="body2"
                  >
                    ****&nbsp;****&nbsp;****&nbsp;{item?.iban.slice(-4)}
                  </Typography>
                </Grid>
              </Box>
              <Box>
                {item.isPrimaryAccount && (
                  <Grid item>
                    <Typography
                      sx={{
                        color: COLORS.PRIMARY_BLUE,
                        fontWeight: 600,
                        fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
                        lineHeight: '20px',
                      }}
                      variant="body1"
                    >
                      {t('primary')}
                    </Typography>
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid
              item
              container
              flexDirection="row"
              columnSpacing="8px"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                '@media (max-width:600px)': {
                  justifyContent: 'start',
                },
              }}
            >
              {!item.isPrimaryAccount && (
                <Grid item>
                  <Button
                    sx={{ color: COLORS.PRIMARY_BLUE, textTransform: 'none' }}
                    onClick={() => onSetPrimaryBankAccount(item.id)}
                  >
                    <Typography sx={{ color: COLORS.PRIMARY_BLUE }} variant="bodySmall">
                      {t('setPrimary')}
                    </Typography>
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  startIcon={<EditOutlinedIcon />}
                  sx={{ color: COLORS.PRIMARY_BLUE }}
                  onClick={() => editBankAccount(item.id)}
                >
                  <Typography
                    sx={{
                      color: COLORS.PRIMARY_BLUE,
                      fontWeight: 400,
                      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
                      lineHeight: '24px',
                      textTransform: 'capitalize',
                    }}
                    variant="body1"
                  >
                    {t('edit')}
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<DeleteForeverOutlinedIcon />}
                  sx={{ color: COLORS.RED }}
                  onClick={() => onDeleteBankAccount(item.id)}
                >
                  <Typography
                    sx={{
                      color: COLORS.RED,
                      fontWeight: 400,
                      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
                      lineHeight: '24px',
                      textTransform: 'capitalize',
                    }}
                    variant="body1"
                  >
                    {t('delete')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))
      )}
    </Grid>
  );

  return (
    <>
      <Typography
        sx={{
          color: COLORS.MAIN_DARK,
          fontWeight: 500,
          fontSize: '24px',
          lineHeight: '32px',
          marginBottom: '12px',
        }}
        variant="h6"
      >
        {t('bankAccount')}
      </Typography>
      {renderItems()}
      <Box display="flex" justifyContent="center" alignItems="center" paddingTop="24px">
        <MButton
          variant="contained"
          onClick={addBankAccount}
          id="AddBankAccountButton"
          disabled={isLoading}
          buttonProps={{
            startIcon: <AddSharpIcon />,
            sx: {
              width: { xs: '100%', md: '100%' },
              height: { xs: '50px', md: 60 },
              borderRadius: '80px',
              boxShadow: 'none',
              backgroundColor: COLORS?.X_DARK_BLUE,
              '&:hover': {
                backgroundColor: COLORS?.X_DARK_BLUE,
              },
            },
          }}
        >
          {t('addBankAccount')}
        </MButton>
      </Box>
    </>
  );
};

export default BankAccounts;
