import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTypedDispatch } from '../../redux/store';
import {
  fetchCurrentQuestion,
  answerCurrentQuestion,
  startUserSurvey,
  fetchConfig,
  fetchAnsweredQuestion,
  finishUserSurvey,
  clearSurvey,
  clearAnswers,
} from '../../store_deprecated/actions/surveyActions';
import {
  getSurvey,
  getQuestion,
  getAnswer,
  getSurveyRequestIsLoading,
  getSurveyRequestIsFailed,
  getQuestionRequestIsLoading,
  getQuestionRequestIsFailed,
  getAnswerRequestIsLoading,
  getAnswerRequestIsFailed,
  getConfig,
  finishUserSurveyRequestIsLoading,
  finishUserSurveyRequestIsFailed,
  getConfigRequestIsLoading,
} from '../../store_deprecated/selectors/surveySelectors';
import { GlobalState } from '../../store_deprecated/types';
import SurveyView from '../../components/User/Survey/SurveyView';
import {
  AnsweredQuestionPayload,
  FinishSurveyPayload,
  QuestionPayload,
  SurveyResultData,
} from '../../store_deprecated/types/surveyTypes';
import SURVEY_STATUS from '../../constants/SurveyStatus';
import QUESTION_TYPES from '../../constants/QuestionTypes';
import { locations } from '../../routes/locations';
import { getSubscribedPortfolio } from '../../helpers/api_deprecated';

const MultipleQuestion = React.lazy(() => import('../../components/User/Survey/QuestionType/Multiple'));
const SingleQuestion = React.lazy(() => import('../../components/User/Survey/QuestionType/Single'));
const NumberQuestion = React.lazy(() => import('../../components/User/Survey/QuestionType/Number'));

const Loader = React.lazy(() => import('../../shared/Loader'));
const Error = React.lazy(() => import('../../shared/Error'));

const Survey: React.FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const config = useSelector(getConfig);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [backSteps, setBackSteps] = useState(0);
  const [answer, setAnswer] = useState<string | number | number[]>('');
  const [skip, setSkip] = useState(false);
  const [isSurveyCompletedBefore, setIsSurveyCompletedBefore] = useState(false);

  const survey = useSelector(getSurvey);
  const question = useSelector((state: GlobalState) => getQuestion(state, currentStepIndex));
  const nextQuestion = useSelector((state: GlobalState) => getQuestion(state, currentStepIndex + 1));
  const existAnswer = useSelector((state: GlobalState) => getAnswer(state, question.id));
  const existNextAnswer = useSelector((state: GlobalState) => getAnswer(state, nextQuestion.id));
  const configRequestIsLoading = useSelector(getConfigRequestIsLoading);
  const surveyRequestIsLoading = useSelector(getSurveyRequestIsLoading);
  const surveyRequestIsFailed = useSelector(getSurveyRequestIsFailed);
  const questionRequestIsLoading = useSelector(getQuestionRequestIsLoading);
  const questionRequestIsFailed = useSelector(getQuestionRequestIsFailed);
  const answerRequestIsLoading = useSelector(getAnswerRequestIsLoading);
  const answerRequestIsFailed = useSelector(getAnswerRequestIsFailed);
  const finishSurveyRequestIsLoading = useSelector(finishUserSurveyRequestIsLoading);
  const finishSurveyRequestIsFailed = useSelector(finishUserSurveyRequestIsFailed);

  // const isFirstStep = currentStepIndex === 0;
  const isLastStep = () => currentStepIndex === survey.totalQuestions - 1;
  const isSurveyAnswered = () => currentStepIndex === survey.totalQuestions;

  const fetchConfiguration = async () => {
    await dispatch(fetchConfig());
  };

  const fetchSurvey = async () => {
    await dispatch(startUserSurvey());
  };

  const fetchAnswer = async (payload: AnsweredQuestionPayload) => {
    await dispatch(fetchAnsweredQuestion(payload));
  };

  const fetchQuestion = async (payload: QuestionPayload) => {
    await dispatch(fetchCurrentQuestion(payload));
  };

  const handleBack = async () => {
    if (currentStepIndex === 0) {
      navigate(locations.products());
    } else {
      setCurrentStepIndex(currentStepIndex - 1);
      setBackSteps(backSteps + 1);
      setAnswer('');
    }
  };

  const handleChooseAnotherProduct = async () => {
    navigate(locations.products(), {
      state: { type: isSurveyCompletedBefore ? 'list' : '' },
    });
  };

  const isAnswerNotChanged = () => {
    const { type } = question;

    // Return false if it is a new survey after re-take
    if (config?.currentSurvey?.surveyResultId !== existAnswer?.surveyResultId) {
      return false;
    }

    switch (type) {
      case QUESTION_TYPES.MULTIPLE:
        return (
          // @ts-ignore
          answer.length === existAnswer.answer?.length &&
          // @ts-ignore
          existAnswer.answer?.filter((ans) => answer.includes(ans)).length
        );
      case QUESTION_TYPES.SINGLE:
      case QUESTION_TYPES.NUMERIC:
        return +answer === +existAnswer.answer;
      default:
        return false;
    }
  };

  const setFlagsAfterHandleNext = () => {
    setCurrentStepIndex(currentStepIndex + 1);
    if (backSteps) {
      setBackSteps(backSteps - 1);
    }
    setSkip(false);
  };

  const handleNext = async () => {
    if (!isAnswerNotChanged()) {
      const payload = { questionId: question.id, surveyResultId: config.currentSurvey!.surveyResultId, answer };
      await dispatch(answerCurrentQuestion(payload));
    }
    setFlagsAfterHandleNext();
  };

  const handleSkip = async () => {
    // if (currentStepIndex) {
    //   setSkip(true);
    //   setFlagsAfterHandleNext();
    // } else {
    navigate(locations.dashboard());
    // }
  };

  useEffect(() => {
    const fetchData = async () => {
      const subscribedPortfolio = await getSubscribedPortfolio();

      // REASON: Can not start survey of there is a subscribed portfolio
      if (subscribedPortfolio?.data?.id) {
        return navigate(locations.dashboard());
      }

      await fetchConfiguration();
    };

    fetchData();

    return () => {
      dispatch(clearSurvey());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!config.surveyStatus) return;

    const fetchData = async () => {
      if (config.surveyStatus === SURVEY_STATUS.COMPLETED) {
        setIsSurveyCompletedBefore(true);
        dispatch(clearAnswers());
      }
      await fetchSurvey();
    };

    fetchData();

    if ([SURVEY_STATUS.NEW, SURVEY_STATUS.COMPLETED].includes(config.surveyStatus) && survey.surveyId) {
      fetchConfiguration();
    }

    if (config.surveyStatus === SURVEY_STATUS.PENDING) {
      // @ts-ignore
      const { id, nextQuestionOrder } = config.currentSurvey || {
        id: 0,
        nextQuestionOrder: 0,
      };

      setCurrentStepIndex(nextQuestionOrder || 0);

      if (!question.type && survey.surveyId) {
        const payload = {
          surveyId: id,
          surveyResultId: config.currentSurvey?.surveyResultId,
          order: nextQuestionOrder || 0,
        };
        fetchQuestion(payload);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.surveyStatus, survey.surveyId]);

  useEffect(() => {
    if (!existAnswer?.questionId && config.currentSurvey && question.id) {
      fetchAnswer({ surveyResultId: config.currentSurvey.surveyResultId, questionId: question.id });
    }
    if (question?.id && !nextQuestion?.id && !isLastStep() && survey.surveyId) {
      const payload = {
        surveyId: survey.surveyId,
        surveyResultId: config.currentSurvey?.surveyResultId,
        order: currentStepIndex + 1,
        silence: true,
      };
      fetchQuestion(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question.id]);

  useEffect(() => {
    if (!existNextAnswer?.questionId && config.currentSurvey && nextQuestion.id) {
      fetchAnswer({ surveyResultId: config.currentSurvey.surveyResultId, questionId: nextQuestion.id, silence: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextQuestion.id]);

  useEffect(() => {
    if (!answer) {
      setAnswer(existAnswer?.answer || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existAnswer.questionId]);

  useEffect(() => {
    const endSurvey = async (payload: FinishSurveyPayload) => {
      const surveyResult: SurveyResultData = (await dispatch(finishUserSurvey(payload))) as any;

      if (surveyResult.nextQuestionOrder) {
        return setCurrentStepIndex(surveyResult.nextQuestionOrder);
      }

      navigate('/risk-survey');
    };

    if (survey.surveyId && config.currentSurvey?.surveyResultId && isSurveyAnswered()) {
      endSurvey({ surveyId: survey.surveyId, surveyResultId: config.currentSurvey.surveyResultId });
    } else if (!question.type && survey.surveyId) {
      const payload = {
        surveyId: survey.surveyId,
        surveyResultId: config.currentSurvey?.surveyResultId,
        order: currentStepIndex,
      };
      fetchQuestion(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  const isValidAnswer = () => {
    if (skip && currentStepIndex) {
      return true;
    }
    const { type } = question;
    switch (type) {
      case QUESTION_TYPES.MULTIPLE:
        // @ts-ignore
        return !!answer?.length;

      case QUESTION_TYPES.SINGLE:
      case QUESTION_TYPES.NUMERIC:
        return Boolean(answer) && !Number.isNaN(+answer) && !`${answer}`.endsWith('.');

      default:
        return false;
    }
  };

  const renderQuestion = () => {
    const { type, options, answerPatternAr, answerPatternEn } = question;
    switch (type) {
      case QUESTION_TYPES.MULTIPLE:
        return (
          <MultipleQuestion
            key={`multiple-${question.id}`}
            options={options}
            setAnswer={setAnswer}
            existAnswer={existAnswer}
            currentStepIndex={currentStepIndex}
          />
        );

      case QUESTION_TYPES.SINGLE:
        return (
          <SingleQuestion
            key={`single-${question.id}`}
            options={options}
            setAnswer={setAnswer}
            existAnswer={existAnswer}
            currentStepIndex={currentStepIndex}
          />
        );

      case QUESTION_TYPES.NUMERIC:
        return (
          <NumberQuestion
            key={`number-${question.id}`}
            options={options}
            answerPatternAr={answerPatternAr}
            answerPatternEn={answerPatternEn}
            setAnswer={setAnswer}
            existAnswer={existAnswer}
            currentStepIndex={currentStepIndex}
          />
        );

      default:
        return <span />;
    }
  };

  const isLoaderRendered = useMemo(
    () =>
      configRequestIsLoading ||
      surveyRequestIsLoading ||
      questionRequestIsLoading ||
      answerRequestIsLoading ||
      finishSurveyRequestIsLoading,
    [
      configRequestIsLoading,
      surveyRequestIsLoading,
      questionRequestIsLoading,
      answerRequestIsLoading,
      finishSurveyRequestIsLoading,
    ],
  );

  if (surveyRequestIsFailed || questionRequestIsFailed || answerRequestIsFailed || finishSurveyRequestIsFailed) {
    return <Error />;
  }

  if (isLoaderRendered) {
    return <Loader />;
  }

  return (
    <SurveyView
      currentStepIndex={currentStepIndex}
      survey={survey}
      question={question}
      handleBack={handleBack}
      handleNext={handleNext}
      handleSkip={handleSkip}
      handleChooseAnotherProduct={handleChooseAnotherProduct}
      isLastStep={isLastStep}
      renderQuestion={renderQuestion}
      isValidAnswer={isValidAnswer}
      showSkip={isSurveyCompletedBefore}
    />
  );
};

export default Survey;
