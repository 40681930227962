// @ts-nocheck
import createCache from '@emotion/cache';
import { createTheme } from '@mui/material';
import React from 'react';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import COLORS from '../constants/colors';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    cairoM: React.CSSProperties;
    smallDisplayH: React.CSSProperties;
    bodyLarge: React.CSSProperties;
    bodyLargeM: React.CSSProperties;
    cairoHeader1B: React.CSSProperties;
    cairoHeader3: React.CSSProperties;
    cairoHeader3Custom: React.CSSProperties;
    cairoHeader2: React.CSSProperties;
    largeDisplayH: React.CSSProperties;
    bodySmall: React.CSSProperties;
    bodySmallB: React.CSSProperties;
    bodySmallM: React.CSSProperties;
    cairoBody: React.CSSProperties;
    cairoBodySmall: React.CSSProperties;
    cairoR: React.CSSProperties;
    h1: React.CSSProperties;
    h1B: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    h4: React.CSSProperties;
    h6: React.CSSProperties;
    h5: React.CSSProperties;
    cairoH5: React.CSSProperties;
    cairoButton: React.CSSProperties;
    cairoButtonR: React.CSSProperties;
    cairoBold: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodyMediumM: React.CSSProperties;
    bodyMediumB: React.CSSProperties;
    bodySmallAr: React.CSSProperties;
    body2: React.CSSProperties;
    cairoR16: React.CSSProperties;
    cairoH4: React.CSSProperties;
    cairoBodyB: React.CSSProperties;
    cairoBodyMB: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    cairoM?: React.CSSProperties;
    smallDisplayH?: React.CSSProperties;
    cairoHeader1B: React.CSSProperties;
    cairoHeader3: React.CSSProperties;
    cairoHeader3Custom: React.CSSProperties;
    cairoHeader2: React.CSSProperties;
    largeDisplayH: React.CSSProperties;
    bodySmall: React.CSSProperties;
    bodySmallB: React.CSSProperties;
    bodySmallM: React.CSSProperties;
    bodyLarge: React.CSSProperties;
    cairoBody: React.CSSProperties;
    cairoBodySmall: React.CSSProperties;
    cairoR: React.CSSProperties;
    h1: React.CSSProperties;
    h1B: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    h4: React.CSSProperties;
    h6: React.CSSProperties;
    h5: React.CSSProperties;
    cairoH5: React.CSSProperties;
    cairoButton: React.CSSProperties;
    cairoButtonR: React.CSSProperties;
    cairoBold: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodyMediumM: React.CSSProperties;
    bodyMediumB: React.CSSProperties;
    bodySmallAr: React.CSSProperties;
    body2: React.CSSProperties;
    cairoR16: React.CSSProperties;
    cairoH4: React.CSSProperties;
    cairoBodyB: React.CSSProperties;
    cairoBodyMB: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    cairoM: true;
    smallDisplayH: true;
    bodyLarge: true;
    bodyLargeM: true;
    bodyMedium: true;
    bodyMediumM: true;
    bodyMediumB: true;
    bodySmallB: true;
    bodySmall: true;
    bodySmallM: true;
    h1: true;
    h1B: true;
    h2: true;
    h3: true;
    h4: true;
    h6: true;
    h5: true;
    cairoH5: true;
    cairoHeader1B: true;
    cairoHeader3: true;
    cairoHeader3Custom: true;
    cairoHeader2: true;
    largeDisplayH: true;
    cairoBody: true;
    cairoBodySmall: true;
    cairoR: true;
    cairoButton: true;
    cairoButtonR: true;
    cairoBold: true;
    bodySmallAr: true;
    body2: true;
    cairoR16: true;
    cairoH4: true;
    cairoBodyB: true;
    cairoBodyMB: true;
  }
}

const palette = {
  primary: {
    main: COLORS.PRIMARY_BLUE,
  },
  secondary: {
    main: COLORS.SECONDARY_GREEN,
  },
};
const components = {
  MuiAppBar: {
    styleOverrides: {
      colorPrimary: {
        backgroundColor: '#fcfcfb',
      },
    },
  },
};
const rtlTypography = {
  typography: {
    fontFamily: '"Noto Kufi Arabic" ,sans-serif',
    bodySmallAr: {
      fontFamily: "'Noto Kufi Arabic', sans-serif",
      fontWeight: 400,
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      lineHeight: '20px',
      textTransform: 'capitalize',
    },
    largeDisplayH: {
      fontFamily: "'Noto Kufi Arabic', sans-serif",
      fontSize: 'clamp(2rem,3vw + 1rem,2.5rem)',
      fontWeight: 500,
      lineHeight: '56px',
    },
    cairoHeader1B: {
      fontFamily: "'Noto Kufi Arabic', sans-serif",
      fontSize: 'clamp(0.25rem,0.25vw + 1rem,2.75rem)',
      fontWeight: 600,
      lineHeight: '48px',
    },
    cairoHeader3: {
      fontFamily: "'Noto Kufi Arabic', sans-serif",
      fontSize: 'clamp(0.9rem,2vw + 1rem,1.875rem)',
      fontWeight: 500,
      lineHeight: '40px',
    },
    cairoHeader3Custom: {
      fontFamily: "'Noto Kufi Arabic', sans-serif",
      fontSize: 'clamp(1.5rem,2vw + 1rem,1.875rem)',
      fontWeight: 500,
      lineHeight: '40px',
    },
    cairoHeader2: {
      fontFamily: '"Noto Kufi Arabic" ,sans-serif',
      fontSize: 'clamp(1rem,2vw + 1rem,2.5rem)',
      fontWeight: 500,
      lineHeight: '48px',
    },
    cairoR: {
      fontFamily: '"Noto Kufi Arabic" ,sans-serif',
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      fontWeight: 400,
      lineHeight: '20px',
    },
    cairoBody: {
      fontFamily: '"Noto Kufi Arabic" ,sans-serif',
      fontSize: 'clamp(0.8125rem,1vw + 0.5rem,1.125rem)',
      fontWeight: 400,
      lineHeight: '28px',
    },
    cairoButton: {
      fontFamily: '"Noto Kufi Arabic" ,sans-serif',
      fontSize: 'clamp(0.875rem,1vw + 0.5rem,1.25rem)',
      fontWeight: 600,
      lineHeight: '32px',
    },
    cairoButtonR: {
      fontFamily: '"Noto Kufi Arabic" ,sans-serif',
      fontSize: 'clamp(1rem,1vw + 1rem,1.25rem)',
      fontWeight: 400,
      lineHeight: '28px',
    },
    cairoBold: {
      fontFamily: '"Noto Kufi Arabic" ,sans-serif',
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '20px',
    },
    cairoM: {
      fontFamily: '"Noto Kufi Arabic" ,sans-serif',
      fontSize: 'clamp(0.8rem,1vw + 0.5rem,1rem)',
      fontWeight: 500,
      lineHeight: '24px',
    },
    smallDisplayH: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'clamp(1.25rem,0.25vw + 1rem,2.75rem)',
      lineHeight: '48px',
    },
    bodyLarge: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 'clamp(1rem,6vw + 1rem,1.125rem)',
      lineHeight: '28px',
    },
    bodyLargeM: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'clamp(0.7rem,2vw + 0.5rem,1.125rem)',
      lineHeight: '28px',
    },
    bodyMedium: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 'clamp(0.8rem,1vw + 0.6rem,1rem)',
      lineHeight: '24px',
    },
    bodyMediumB: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '24px',
    },
    bodyMediumM: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '24px',
    },
    bodySmall: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      lineHeight: '24px',
    },
    cairoBodySmall: {
      fontFamily: 'Noto Kufi Arabic',
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      fontWeight: 600,
      lineHeight: '20px',
    },
    h1: {
      fontFamily: "'Noto Kufi Arabic', sans-serif",
      fontSize: 'clamp(2rem,3vw + 1rem,2.5rem)',
      fontWeight: 500,
      lineHeight: '48px',
    },
    h2: {
      fontFamily: "'Noto Kufi Arabic', sans-serif",
      fontSize: 'clamp(1.5rem,2vw + 1rem,2rem)',
      fontWeight: 500,
      lineHeight: '40px',
    },
    h3: {
      fontFamily: "'Noto Kufi Arabic', sans-serif",
      fontSize: 'clamp(1.25rem,3vw + 1rem,1.75rem)',
      fontWeight: 600,
      lineHeight: '36px',
    },
    h4: {
      fontFamily: "'Noto Kufi Arabic', sans-serif",
      fontSize: 'clamp(1rem,0.5vw + 1rem,1.25rem)',
      fontWeight: 600,
      lineHeight: '28px',
    },
    h6: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'clamp(1rem,0.5vw + 1rem,1.25rem)',
      lineHeight: '28px',
    },
    body2: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      lineHeight: '20px',
    },
    cairoR16: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '20px',
    },
    cairoH4: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 'clamp(0.875rem,0.5vw + 0.5rem,1.25rem)',
      lineHeight: '28px',
    },
    cairoH5: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'clamp(1rem,1vw + 0.875rem,1.5rem)',
      lineHeight: '32px',
    },
    cairoBodyB: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '20px',
    },
    cairoBodyMB: {
      fontFamily: 'Noto Kufi Arabic',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 'clamp(1rem,0.5vw + 1rem,1.25rem)',
      lineHeight: '28px',
    },
  },
};

const ltrTypography = {
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    cairoM: {
      fontFamily: '"Noto Kufi Arabic" ,sans-serif',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '24px',
    },
    smallDisplayH: {
      fontFamily: "'Montserrat', sans-serif",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'clamp(1.25rem,1.25vw + 1rem,2.75rem)',
      lineHeight: '48px',
    },
    largeDisplayH: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 'clamp(1.5rem,2.5vw + 1rem,2.5rem)',
      fontWeight: 500,
      lineHeight: '56px',
    },
    bodyLarge: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 'clamp(0.8125rem,0.2vw + 0.5rem,1.13rem)',
      fontWeight: 400,
      lineHeight: '28px',
    },
    bodyLargeM: {
      fontFamily: "'Montserrat', sans-serif",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'clamp(0.7rem,2vw + 0.5rem,1.125rem)',
      lineHeight: '28px',
    },
    bodySmall: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      fontWeight: 400,
      lineHeight: '24px',
    },
    bodySmallB: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      fontWeight: 600,
      lineHeight: '20px',
    },
    bodySmallM: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      fontWeight: 500,
      lineHeight: '20px',
    },
    h1: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 'clamp(1rem,3vw + 1rem,2.5rem)',
      fontWeight: 500,
      lineHeight: '48px',
    },
    h1B: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 'clamp(1.375rem,2vw + 1rem,2.25rem)',
      fontWeight: 600,
    },
    h2: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 'clamp(0.9rem,2vw + 1rem,2.25rem)',
      fontWeight: 500,
      lineHeight: '44px',
    },
    h3: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 'clamp(1.5rem,2vw + 1rem,2rem)',
      fontWeight: 500,
      lineHeight: '40px',
    },
    h4: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 'clamp(1.25rem,3vw + 1rem,1.75rem)',
      fontWeight: 500,
      lineHeight: '36px',
    },
    h5: {
      fontFamily: "'Montserrat', sans-serif",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'clamp(1rem,1vw + 0.875rem,1.5rem)',
      lineHeight: '32px',
    },
    h6: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 'clamp(0.875rem,0.5vw + 0.5rem,1.25rem)',
      fontWeight: 500,
      lineHeight: '28px',
    },
    bodyMedium: {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 'clamp(0.8rem,1vw + 0.6rem,1rem)',
      fontWeight: 400,
      lineHeight: '24px',
    },
    bodyMediumB: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '24px',
    },
    body2: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      lineHeight: '20px',
    },
  },
};

const ltrTheme = createTheme({
  palette,
  components,
  direction: 'ltr',
  ...ltrTypography,
  spacing: 4,
});
const rtlTheme = createTheme({
  palette,
  components,
  direction: 'rtl',
  ...rtlTypography,
  spacing: 4,
});

const cacheLtr = createCache({
  key: 'muiltr',
});

const cacheRtl = createCache({
  key: 'muirtl',
  // prefixer is the only stylis plugin by default, so when
  // overriding the plugins you need to include it explicitly
  // if you want to retain the auto-prefixing behavior.
  stylisPlugins: [prefixer, rtlPlugin],
});

export { ltrTheme, rtlTheme, cacheLtr, cacheRtl };
