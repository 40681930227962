import { Tutorial } from '../types/tutorial';

export const TUTORIALS: Record<string, Tutorial[]> = {
  en: [
    // {
    //   id: 1,
    //   title: 'Get to know the automated advisor',
    //   description:
    //     'Madkhol is a financial technology company licensed by the Capital Market Authority and compliant with the provisions of Islamic Sharia, specializing in financial planning and wealth management',
    //   url: 'https://www.youtube.com/embed/3JHiS5Inke0?controls=1&rel=0&mute=1&autoplay=1',
    //   urlToGetThumbnail: 'https://www.youtube.com/watch?v=lZxPRxUUyRU',
    //   uploadDate: '23/05/2022',
    // },
    {
      id: 2,
      title: 'Stocks',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%A7%D8%B3%D9%87%D9%85%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/1.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 3,
      title: 'Investment',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/2.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 4,
      title: 'Assets',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%A7%D8%B5%D9%88%D9%84%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/3.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 5,
      title: 'Fundamental Analysis',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%AA%D8%AD%D9%84%D9%8A%D9%84%20%D8%A7%D9%84%D8%A7%D8%B3%D8%A7%D8%B3%D9%8A%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/4.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 6,
      title: 'Technical Analysis',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%AA%D8%AD%D9%84%D9%8A%D9%84%20%D8%A7%D9%84%D9%81%D9%86%D9%8A%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/5.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 7,
      title: 'Diversification',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%AA%D9%86%D9%88%D9%8A%D8%B9%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/6.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 8,
      title: 'Bonds',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%B3%D9%86%D8%AF%D8%A7%D8%AA%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/7.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 9,
      title: 'Liquidity',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%B3%D9%8A%D9%88%D9%84%D8%A9%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/8.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 10,
      title: 'Return on Investment ROI',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%B9%D8%A7%D9%8A%D9%94%D8%AF%20%D8%B9%D9%84%D9%89%20%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/9.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 11,
      title: 'Financial Portfolio',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D9%85%D8%AD%D9%81%D8%B8%D8%A9%20%D8%A7%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1%D9%8A%D8%A9%20%D8%AC%D8%AF%D9%8A%D8%AF%20.mp4',
      urlToGetThumbnail: '/images/video-cover/10.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 12,
      title: 'Benjamin Franklin',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FQuotes%2F%D8%A8%D9%86%D8%AC%D8%A7%D9%85%D9%8A%D9%86%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/11.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 13,
      title: 'Warren Buffet',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FQuotes%2F%D9%88%D8%A7%D8%B1%D9%86%20%D8%A8%D8%A7%D9%81%D9%8A%D8%AA%20%D9%A1.mp4',
      urlToGetThumbnail: '/images/video-cover/12.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 14,
      title: 'Warren Buffet',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FQuotes%2F%D9%88%D8%A7%D8%B1%D9%86%20%D8%A8%D8%A7%D9%81%D9%8A%D8%AA%20%D9%A2.mp4',
      urlToGetThumbnail: '/images/video-cover/13.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 15,
      title: 'George Soros',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FQuotes%2F%D8%AC%D9%88%D8%B1%D8%AC%20%D8%A7%D9%84%D8%B3%D8%A7%D9%8A%D8%B2%20%D8%A7%D9%84%D8%AC%D8%AF%D9%8A%D8%AF%20.mp4',
      urlToGetThumbnail: '/images/video-cover/14.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 16,
      title: 'ANB Bank',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Bank%20instructions%2Fmadkhoul%20guides%20-%20%D8%A8%D9%86%D9%83%20%D8%A7%D9%84%D8%B9%D8%B1%D8%A8%D9%8A%20.mp4',
      urlToGetThumbnail: '/images/video-cover/15.jpg',
      uploadDate: '16/08/2024',
    },
    {
      id: 17,
      title: 'Rajhi Bank',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Bank%20instructions%2Fmadkhoul%20guide%20-%20%D8%A8%D9%86%D9%83%20%D8%A7%D9%84%D8%B1%D8%A7%D8%AC%D8%AD%D9%8A.mp4',
      urlToGetThumbnail: '/images/video-cover/16.jpg',
      uploadDate: '16/08/2024',
    },
    {
      id: 18,
      title: 'SNB Bank',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Bank%20instructions%2Fmadkhoul%20guides%202%20-%D8%A8%D9%86%D9%83%20%D8%A7%D9%84%D8%A7%D9%87%D9%84%D9%8A%20%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A%20.mp4',
      urlToGetThumbnail: '/images/video-cover/17.jpg',
      uploadDate: '16/08/2024',
    },
    {
      id: 19,
      title: 'Alinma Bank',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Bank%20instructions%2Fmadkhol%20guides-%20%D8%A8%D9%86%D9%83%20%D8%A7%D9%84%D8%A7%D9%86%D9%85%D8%A7%D8%A1%20.mp4',
      urlToGetThumbnail: '/images/video-cover/18.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 20,
      title: 'SAIB Bank',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Bank%20instructions%2Fmadkhoul%20guides%20-%20%D8%A8%D9%86%D9%83%20%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1%20%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A.mp4',
      urlToGetThumbnail: '/images/video-cover/19.jpg',
      uploadDate: '16/08/2024',
    },
  ],
  ar: [
    // {
    //   id: 1,
    //   title: 'تعرف على مستشار مدخول الآلي',
    //   description:
    //     'مدخول هي شركة تقنية مالية مرخصة من هيئة سوق المال ومتوافقة مع أحكام الشريعة الإسلامية، تتخصص في التخطيط المالي وإدارة الثروات',
    //   url: 'https://www.youtube.com/embed/lZxPRxUUyRU?controls=1&rel=0&mute=1&autoplay=1',
    //   urlToGetThumbnail: 'https://www.youtube.com/watch?v=lZxPRxUUyRU',
    //   uploadDate: '27/05/2022',
    // },
    {
      id: 2,
      title: 'الأسهم',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%A7%D8%B3%D9%87%D9%85%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/1.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 3,
      title: 'الاستثمار',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/2.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 4,
      title: 'الأصول',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%A7%D8%B5%D9%88%D9%84%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/3.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 5,
      title: 'التحليل الأساسي',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%AA%D8%AD%D9%84%D9%8A%D9%84%20%D8%A7%D9%84%D8%A7%D8%B3%D8%A7%D8%B3%D9%8A%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/4.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 6,
      title: 'التحليل الفني',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%AA%D8%AD%D9%84%D9%8A%D9%84%20%D8%A7%D9%84%D9%81%D9%86%D9%8A%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/5.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 7,
      title: 'التنويع',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%AA%D9%86%D9%88%D9%8A%D8%B9%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/6.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 8,
      title: 'السندات',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%B3%D9%86%D8%AF%D8%A7%D8%AA%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/7.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 9,
      title: 'السيولة',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%B3%D9%8A%D9%88%D9%84%D8%A9%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/8.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 10,
      title: 'العائد على الاستثمار',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D8%A7%D9%84%D8%B9%D8%A7%D9%8A%D9%94%D8%AF%20%D8%B9%D9%84%D9%89%20%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/9.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 11,
      title: 'المحفظة المالية',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FEducational%2F%D9%85%D8%AD%D9%81%D8%B8%D8%A9%20%D8%A7%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1%D9%8A%D8%A9%20%D8%AC%D8%AF%D9%8A%D8%AF%20.mp4',
      urlToGetThumbnail: '/images/video-cover/10.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 12,
      title: 'بنيامين فرانكلين',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FQuotes%2F%D8%A8%D9%86%D8%AC%D8%A7%D9%85%D9%8A%D9%86%20%D8%AC%D8%AF%D9%8A%D8%AF.mp4',
      urlToGetThumbnail: '/images/video-cover/11.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 13,
      title: 'وارن بافيت',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FQuotes%2F%D9%88%D8%A7%D8%B1%D9%86%20%D8%A8%D8%A7%D9%81%D9%8A%D8%AA%20%D9%A1.mp4',
      urlToGetThumbnail: '/images/video-cover/12.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 14,
      title: 'وارن بافيت',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FQuotes%2F%D9%88%D8%A7%D8%B1%D9%86%20%D8%A8%D8%A7%D9%81%D9%8A%D8%AA%20%D9%A2.mp4',
      urlToGetThumbnail: '/images/video-cover/13.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 15,
      title: 'جورج سوروس',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Tutorial%20page%2FQuotes%2F%D8%AC%D9%88%D8%B1%D8%AC%20%D8%A7%D9%84%D8%B3%D8%A7%D9%8A%D8%B2%20%D8%A7%D9%84%D8%AC%D8%AF%D9%8A%D8%AF%20.mp4',
      urlToGetThumbnail: '/images/video-cover/14.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 16,
      title: 'بنك العربي',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Bank%20instructions%2Fmadkhoul%20guides%20-%20%D8%A8%D9%86%D9%83%20%D8%A7%D9%84%D8%B9%D8%B1%D8%A8%D9%8A%20.mp4',
      urlToGetThumbnail: '/images/video-cover/15.jpg',
      uploadDate: '16/08/2024',
    },
    {
      id: 17,
      title: 'بنك الراجحي',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Bank%20instructions%2Fmadkhoul%20guide%20-%20%D8%A8%D9%86%D9%83%20%D8%A7%D9%84%D8%B1%D8%A7%D8%AC%D8%AD%D9%8A.mp4',
      urlToGetThumbnail: '/images/video-cover/16.jpg',
      uploadDate: '16/08/2024',
    },
    {
      id: 18,
      title: 'بنك الأهلي السعودي',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Bank%20instructions%2Fmadkhoul%20guides%202%20-%D8%A8%D9%86%D9%83%20%D8%A7%D9%84%D8%A7%D9%87%D9%84%D9%8A%20%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A%20.mp4',
      urlToGetThumbnail: '/images/video-cover/17.jpg',
      uploadDate: '16/08/2024',
    },
    {
      id: 19,
      title: 'بنك الإنماء',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Bank%20instructions%2Fmadkhol%20guides-%20%D8%A8%D9%86%D9%83%20%D8%A7%D9%84%D8%A7%D9%86%D9%85%D8%A7%D8%A1%20.mp4',
      urlToGetThumbnail: '/images/video-cover/18.png',
      uploadDate: '16/08/2024',
    },
    {
      id: 20,
      title: 'البنك السعودي للاستثمار',
      description: '',
      url: 'https://objectstorage.me-jeddah-1.oraclecloud.com/n/axvp4vawnqyw/b/public-bucket/o/Bank%20instructions%2Fmadkhoul%20guides%20-%20%D8%A8%D9%86%D9%83%20%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D8%AB%D9%85%D8%A7%D8%B1%20%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A.mp4',
      urlToGetThumbnail: '/images/video-cover/19.jpg',
      uploadDate: '16/08/2024',
    },
  ],
};
