import { captureMessage } from '@sentry/react';
import { CreateApplePaySessionPayload } from '../store_deprecated/types/walletTypes';
import { ApplePaymentType } from './enums';

export const PaymentStatus = { SUCCESS: 0, FAILURE: 1, CANCEL: 2 };

export class ApplePayJs {
  private merchantIdentifier: string;
  private merchantDisplay: string;

  constructor(config: { merchantIdentifier: string; merchantDisplay: string }) {
    this.merchantIdentifier = config.merchantIdentifier;
    this.merchantDisplay = config.merchantDisplay;

    console.log('[ApplePayJs constructor:config]', config);
  }

  canMakePayments = () => {
    // TODO: temporary disabled button
    // return false;
    try {
      console.log('[ApplePayJs canMakePayments:ApplePaySession]');
      return ApplePaySession && ApplePaySession.canMakePayments();
    } catch (err) {
      console.error('[ApplePayJs canMakePayments:ApplePaySession error]', err);
      return false;
    }
    // return new Promise((resolve, reject) => {
    //   try {
    //     console.log("[ApplePayJs canMakePayments:ApplePaySession]", ApplePaySession);

    //     const enabled = ApplePaySession && ApplePaySession.canMakePayments();

    //     resolve(enabled);
    //   } catch (err) {
    //     resolve(false);
    //   }
    // });
  };

  canMakePaymentsWithActiveCard = () => {
    try {
      console.log('[ApplePayJs canMakePaymentsWithActiveCard]');

      return ApplePaySession.canMakePaymentsWithActiveCard(this.merchantIdentifier);
    } catch (err) {
      console.error('[ApplePayJs canMakePaymentsWithActiveCard error]', err);
      return false;
    }
  };

  getPaymentRequest = (
    currencyCode: string,
    items: [],
    label: string,
    amount: number,
  ): ApplePayJS.ApplePayPaymentRequest => ({
    currencyCode,
    countryCode: 'AR',
    // requiredShippingContactFields: ["name", "email", "phone"],
    lineItems: items,
    total: {
      label,
      type: 'final',
      amount: `${amount}`,
    },
    supportedNetworks: ['mada'],
    merchantCapabilities: ['supports3DS', 'supportsDebit'],
  });

  getRecurringPaymentRequest = (
    currencyCode: string,
    items: [],
    label: string,
    amount: number,
    recurringPaymentEndDate: Date,
    recurringPaymentIntervalUnit: ApplePayJS.ApplePayRecurringPaymentDateUnit,
    recurringPaymentIntervalCount: number,
  ): ApplePayJS.ApplePayPaymentRequest => ({
    currencyCode,
    countryCode: 'AR',
    lineItems: items,
    total: {
      label,
      type: 'final',
      amount: `${amount}`,
    },
    supportedNetworks: ['mada'],
    merchantCapabilities: ['supports3DS', 'supportsDebit'],
    recurringPaymentRequest: {
      regularBilling: {
        recurringPaymentEndDate: recurringPaymentEndDate.toISOString(),
        recurringPaymentIntervalUnit,
        recurringPaymentIntervalCount,
        paymentTiming: 'recurring',
        label,
        type: 'final',
        amount: `${amount}`,
      },
      managementURL: `${window.location.protocol}//${window.location.hostname}/my-profile`,
      paymentDescription: 'Subscription',
      tokenNotificationURL: `${process.env.REACT_APP_BASE_API_URL}webhook/apple`,
    },
  });

  getOnValidateMerchant = (
    resolve: (v: unknown) => void,
    reject: (reason?: any) => void,
    session: ApplePaySession,
    createAppleSessionFn: (p: CreateApplePaySessionPayload) => any,
  ) => {
    const { merchantIdentifier } = this;
    const displayName = this.merchantDisplay;

    return async (event: ApplePayJS.ApplePayValidateMerchantEvent) => {
      console.log('[ApplePayJs session.onvalidatemerchant]', event);

      try {
        const sessionData = await createAppleSessionFn({
          validationURL: event.validationURL,
          merchantIdentifier,
          displayName,
          initiative: 'web',
          initiativeContext: window.location.hostname,
        });

        console.log('[ApplePayJs session.onvalidatemerchant apple session]', sessionData);

        captureMessage('Apple Pay onvalidatemerchant result', {
          extra: {
            sessionData,
          },
        });

        session.completeMerchantValidation(sessionData);
      } catch (err) {
        console.error('[ApplePayJs session.onvalidatemerchant error', err);
        session.abort();
      }

      // TODO:
      // Call your own server to request a new merchant session.
      // const merchantSession = await validateMerchant();
      // session.completeMerchantValidation(merchantSession);

      // performValidation(
      //   event.validationURL,
      //   this.merchantIdentifier,
      //   this.merchantDisplay,
      //   window.location.hostname,
      // )
      //   .then((response: any) => {
      //     session.completeMerchantValidation(response);
      //   })
      //   .catch((err: any) => {
      //     console.log("[ApplePayJs Validate error]", JSON.stringify(err));
      //
      //     session.abort();
      //   });
    };
  };

  getOnPaymentMethodSelected = (
    resolve: (v: unknown) => void,
    reject: (reason?: any) => void,
    session: ApplePaySession,
    amount: number,
  ) => {
    const displayName = this.merchantDisplay;
    return (event: ApplePayJS.ApplePayPaymentMethodSelectedEvent) => {
      console.log('[ApplePayJs session.onpaymentmethodselected]', event);

      // TODO:
      // Define ApplePayPaymentMethodUpdate based on the selected payment method.
      // No updates or errors are needed, pass an empty object.
      // const update = {};
      session.completePaymentMethodSelection({
        newTotal: {
          amount: amount.toString(),
          label: displayName,
        },
      });
    };
  };

  getOnShippingMethodSelected =
    (resolve: (v: unknown) => void, reject: (reason?: any) => void, session: ApplePaySession) =>
    (event: ApplePayJS.ApplePayShippingMethodSelectedEvent) => {
      console.log('[ApplePayJs session.onshippingmethodselected]', event);

      // TODO:
      // Define ApplePayShippingMethodUpdate based on the selected shipping method.
      // No updates or errors are needed, pass an empty object.
      // const update = {};
      // session.completeShippingMethodSelection(update);
    };

  getOnShippingContactSelected =
    (resolve: (v: unknown) => void, reject: (reason?: any) => void, session: ApplePaySession) =>
    (event: ApplePayJS.ApplePayShippingContactSelectedEvent) => {
      console.log('[ApplePayJs session.onshippingcontactselected]', event);

      // TODO:
      // Define ApplePayShippingContactUpdate based on the selected shipping contact.
      // const update = {};
      // session.completeShippingContactSelection(update);
    };

  getOnPaymentAuthorized =
    (
      resolve: (v: unknown) => void,
      reject: (reason?: any) => void,
      session: ApplePaySession,
      currencyCode: string,
      amount: number,
      handleApplePayPayment: (amount: number, token: any) => any,
      saveSubscription?: (token: any) => Promise<any>,
    ) =>
    async (event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => {
      try {
        console.log('[ApplePayJs session.onpaymentauthorized]', event);

        captureMessage('Apple Pay onpaymentauthorized result', {
          extra: {
            event,
            payment: event.payment,
          },
        });

        // Define ApplePayPaymentAuthorizationResult
        const paymentResponse = await handleApplePayPayment(amount, event.payment.token.paymentData);

        captureMessage('Apple Pay handleApplePayPayment result', {
          extra: {
            payment: event.payment || {},
          },
        });

        if (saveSubscription) {
          await saveSubscription(event.payment.token.paymentData);
        }

        const result = {
          status: ApplePaySession.STATUS_SUCCESS,
        };
        session.completePayment(result);

        // eslint-disable-next-line no-restricted-globals
        // resolve(paymentReponse);

        if (event?.payment?.token?.paymentData) {
          captureMessage('Apple Pay payment data result', {
            extra: {
              event,
              payment: event.payment,
              data: event?.payment?.token?.paymentData,
            },
          });

          return resolve(PaymentStatus.SUCCESS);
        }

        resolve(PaymentStatus.FAILURE);
      } catch (err) {
        console.error('ApplePayJs session.onpaymentauthorized: error]', err);

        const result = {
          status: ApplePaySession.STATUS_FAILURE,
        };
        session.completePayment(result);

        // reject(err);
        reject(PaymentStatus.FAILURE);
      }

      // amount must be provided in cents
      // performApplePayDebit(amount * 100, currencyCode, event.payment.token.paymentData)
      //   .then((response: any) => {
      //
      //     if (response.code === 100) {
      //       session.completePayment(ApplePaySession.STATUS_SUCCESS);
      //       resolve(PaymentStatus.SUCCESS);
      //     } else {
      //       console.log("[ApplePayJs Payment error in response]", JSON.stringify(response));
      //
      //       session.completePayment(ApplePaySession.STATUS_FAILURE);
      //
      //       resolve(PaymentStatus.FAILURE);
      //     }
      //   })
      //   .catch((err: any) => {
      //     console.log("[ApplePayJs Payment error]", JSON.stringify(err));
      //
      //     session.completePayment(ApplePaySession.STATUS_FAILURE);
      //
      //     reject(err);
      //   });
    };

  getOnCancel =
    (resolve: (v: unknown) => void, reject: (reason?: any) => void, session: ApplePaySession) =>
    (event: ApplePayJS.Event) => {
      console.log('[ApplePayJs session.oncancel]', event);

      resolve(PaymentStatus.CANCEL);
    };

  performApplePayPayment = (data: {
    type: ApplePaymentType;
    currencyCode: string;
    items: [];
    label: string;
    productId?: string;
    amount: number;
    recurringPaymentEndDate?: Date;
    recurringPaymentIntervalUnit?: ApplePayJS.ApplePayRecurringPaymentDateUnit;
    recurringPaymentIntervalCount?: number;
    createAppleSessionFn: (p: CreateApplePaySessionPayload) => any;
    handleApplePayPayment: (amount: number, token: any) => any;
    saveSubscription?: (token: any) => Promise<any>;
  }) => {
    if (!ApplePaySession) {
      return;
    }

    const {
      type,
      currencyCode,
      items,
      label,
      amount,
      recurringPaymentEndDate,
      recurringPaymentIntervalUnit,
      recurringPaymentIntervalCount,
      createAppleSessionFn,
      handleApplePayPayment,
      saveSubscription,
    } = data;

    return new Promise((resolve, reject) => {
      const paymentRequest =
        type === ApplePaymentType.RECURRING_PAYMENT
          ? this.getRecurringPaymentRequest(
              currencyCode,
              items,
              label,
              amount,
              recurringPaymentEndDate!,
              recurringPaymentIntervalUnit!,
              recurringPaymentIntervalCount!,
            )
          : this.getPaymentRequest(currencyCode, items, label, amount);

      const session = new ApplePaySession(+(process.env.REACT_APP_APPLE_PAY_VERSION_NUMBER || '14'), paymentRequest);

      session.onvalidatemerchant = this.getOnValidateMerchant(resolve, reject, session, createAppleSessionFn);
      session.onpaymentmethodselected = this.getOnPaymentMethodSelected(resolve, reject, session, amount);
      session.onshippingmethodselected = this.getOnShippingMethodSelected(resolve, reject, session);
      session.onshippingcontactselected = this.getOnShippingContactSelected(resolve, reject, session);
      session.onpaymentauthorized = this.getOnPaymentAuthorized(
        resolve,
        reject,
        session,
        currencyCode,
        amount,
        handleApplePayPayment,
        saveSubscription,
      );
      session.oncancel = this.getOnCancel(resolve, reject, session);

      session.begin();
    });
  };
}

export const applePayJs = new ApplePayJs({
  merchantIdentifier: process.env.REACT_APP_APPLE_PAY_MERCHANT_ID,
  merchantDisplay: process.env.REACT_APP_APPLE_PAY_MERCHANT_DISPLAY,
});
