import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import InputBase from '@mui/material/InputBase';
import { InputLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { locations } from '../../../routes/locations';
import COLORS from '../../../constants/colors';
import {
  useLazyCheckNafazAuthStatusQuery,
  useGetNafazRandomCodeMutation,
  useFindProfileDataQuery,
} from '../../../redux/features/auth/auth.apiSlice';
import { NafazAuthStatus } from '../../../types/auth';
import MButton from '../../../shared/MButton';
import { useTypedDispatch } from '../../../redux/store';
import { findProfileData } from '../../../store_deprecated/actions/authActions';
import { KycStatus } from '../../../types/kyc';
import { getProfileData } from '../../../helpers/api_deprecated';

const NafazAuthentication: React.FC = () => {
  const { t, i18n } = useTranslation('nafaz-authentication', { useSuspense: true });
  const isEnglish = i18n.language === 'en';
  const [timer, setTimer] = useState(0);
  const navigate = useNavigate();
  const [randomCode, setRandomCode] = useState(0);
  const [transId, setTransId] = useState('');
  const [nationalId, setNationalId] = useState(''); // 2009656519
  const [isValidNationalId, setIsValidNationalId] = useState(false);
  const [nationalIdError, setNationalIdError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [getNafazRandomCode] = useGetNafazRandomCodeMutation();
  const [checkNafazAuthStatus] = useLazyCheckNafazAuthStatusQuery();
  const [displayExpiredNotification, setDisplayExpiredNotification] = useState(false);
  const [displaySuccessNotification, setDisplaySuccessNotification] = useState(false);
  const [displayKycNotification, setDisplayKycNotification] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const dispatch = useTypedDispatch();
  const { data: kyc } = useFindProfileDataQuery();
  const { user } = useSelector(authSelector);
  useEffect(() => {
    if (user?.id) {
      dispatch(findProfileData());
    }
  }, [user?.id]);

  const fetchProfile = async () => {
    try {
      const { data } = await getProfileData();
      if (data) {
        navigate(locations.nafazVerification());
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (randomCode && transId) {
      interval = setInterval(() => {
        if (user?.id && !kyc?.id) {
          fetchProfile();
        } else if (interval && timer === 0) {
          clearInterval(interval);
        }
      }, 5000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [transId, randomCode, user?.id, kyc]);

  const fetchRandomCode = async () => {
    try {
      const nafazAuth = await getNafazRandomCode({ nationalId, locale: i18n.language }).unwrap();
      setRandomCode(nafazAuth.code);
      setTransId(nafazAuth.transId);
      setTimer(180);
      setShowCode(true);
    } catch (err: any) {
      console.error(err?.status);
    }
  };

  const handelVerify = () => {
    if (!nationalId) {
      setNationalIdError(true);
      setErrMsg('missingField');
      return;
    }
    if (nationalId?.length === 10) fetchRandomCode();
  };

  const formatTimer = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');
    const formattedTimer = `${paddedMinutes}:${paddedSeconds}`;
    if (seconds < 10) {
      return `${paddedMinutes}:${paddedSeconds}`;
    }
    return formattedTimer;
  };
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (randomCode && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setRandomCode(0);
      clearInterval(interval!);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [randomCode, timer]);

  const checkStatus = () => {
    const interval = setInterval(async () => {
      try {
        const { status } = await checkNafazAuthStatus(
          {
            nationalId,
            transId,
            code: randomCode,
          },
          false,
        ).unwrap();

        if (status !== NafazAuthStatus.WAITING) {
          setDisplayExpiredNotification(status === NafazAuthStatus.EXPIRED);
          setDisplaySuccessNotification(status === NafazAuthStatus.COMPLETED);
          clearInterval(interval);
        }
      } catch (err) {
        console.error(err);
      }
    }, 5000);
  };

  const onCloseNotification = () => {
    setDisplayExpiredNotification(false);
    setDisplaySuccessNotification(false);
    setDisplayKycNotification(false);
  };

  const onChangeNationalId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    const maxLength = 10;
    const isDigitsOnly = /^\d*$/.test(text);

    if (!isDigitsOnly || text.length > maxLength) {
      event.preventDefault();
      return;
    }

    setNationalId(text);
    setIsValidNationalId(text.length === maxLength);
    setNationalIdError(false);
  };

  useEffect(() => {
    if (transId && randomCode) {
      dispatch(findProfileData());
    }
  }, [transId, randomCode]);

  useEffect(() => {
    if (transId && randomCode && timer === 0 && !kyc?.id) {
      setDisplayKycNotification(true);
    }
  }, [timer]);

  useEffect(() => {
    if (kyc?.id) {
      navigate(locations.nafazVerification());
    }
  }, [kyc?.id]);

  const nationalId_ = Number(nationalId)
    .toLocaleString(i18n.language === 'ar' ? 'ar-SA' : 'en-US')
    .replace(i18n.language === 'ar' ? /٬/g : /,/g, '');

  return (
    <Box
      sx={{
        mt: { xs: '0px', md: 'auto' },
        paddingBottom: showCode ? 'auto' : '150px',
        '@media (max-width:768px)': {
          padding: '24px',
          paddingBottom: showCode ? 'auto' : '150px',
        },
      }}
    >
      {/* nafaz image */}
      <Grid container justifyContent="center" alignItems="center" flexDirection="column">
        <Box
          sx={{
            '@media (max-width:768px)': {
              display: 'none',
            },
          }}
        >
          <img src="/images/nafaz-authentication-image.svg" alt="" width={244} />
        </Box>
        <Box
          sx={{
            display: 'none',
            '@media (max-width:768px)': {
              display: 'block',
            },
          }}
        >
          <img src="/images/authentication-mobile-logo.svg" alt="" width={244} />
        </Box>
        <Grid>
          <Typography
            alignContent="center"
            sx={{
              fontWeight: 600,
              fontSize: '20px',
              marginTop: '10px',
              '@media (max-width: 768px)': {
                fontSize: '15px',
              },
            }}
          >
            {t('authenticationHeader')}
          </Typography>
        </Grid>
        <Grid>
          <Typography
            alignContent="center"
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              '@media (max-width: 768px)': {
                fontSize: '12px',
                mt: '2px',
              },
            }}
          >
            {t('authenticationDesc')}
          </Typography>
        </Grid>
      </Grid>
      {/* nafaz image */}
      {showCode ? (
        <Box
          sx={{
            mt: '32px !important',
            width: '300px',
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              backgroundColor: COLORS?.WHITE,
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '16px',
              gap: '8px',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                {t('nationalId')}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <img src="/images/profile.svg" alt="" width={24} height={24} />
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 600,
                }}
              >
                {nationalId_}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              mt: '32px',
              width: '140px',
              borderBottom: '1px solid #79B4DF',
            }}
          >
            <Box
              onClick={() =>
                window.open('https://apps.apple.com/sa/app/%D9%86%D9%81%D8%A7%D8%B0-nafath/id1598909871', '_blank')
              }
            >
              <Typography
                sx={{
                  color: COLORS?.X_DARK_BLUE,
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                {t('openNafath')}
              </Typography>
            </Box>
            <Box>
              <img src="/images/right-up-arrow.svg" alt="" width={14} height={14} />
            </Box>
          </Box>
          {randomCode > 0 && (
            <>
              <Box
                sx={{
                  mt: '32px',
                  width: '95px',
                  height: '75px',
                  backgroundColor: COLORS?.X_DARK_BLUE,
                  border: '6px solid #E0E9F0',
                  borderRadius: '48px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  sx={{
                    color: COLORS?.WHITE,
                    fontSize: '24px',
                    fontWeight: 600,
                  }}
                >
                  {Number(randomCode)
                    .toLocaleString(i18n.language === 'ar' ? 'ar-SA' : 'en-US')
                    .replace(/,/g, '')}
                </Typography>
              </Box>
              {showCode && (
                <Box
                  sx={{
                    mt: '32px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '20px',
                      color: COLORS?.MAIN_DARK,
                      fontWeight: 500,
                    }}
                  >
                    {formatTimer(timer)}
                  </Typography>
                </Box>
              )}
            </>
          )}
          <Box
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              mt: '8px',
              mb: '24px',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <img src="/images/refresh.svg" alt="" width={16} height={16} />
            </Box>
            <Box>
              <Button
                disabled={!isValidNationalId || timer > 0}
                onClick={fetchRandomCode}
                sx={{
                  color: COLORS?.X_DARK_BLUE,
                  fontSize: '16px',
                  fontWeight: 700,
                }}
              >
                {t('resendCode')}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          marginTop="20px"
          gap="24px"
          width="480px"
          margin="auto"
          sx={{
            '@media (max-width: 768px)': {
              width: '100%',
              mt: '32px',
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
            }}
          >
            <InputLabel
              shrink
              sx={{
                marginTop: { sx: 0, md: 5 },
                color: COLORS?.BLACK,
                fontSize: '14px',
              }}
            >
              {t('nationalId')}
            </InputLabel>
            <InputBase
              required
              id="nationalId"
              name="nationalId"
              type="tel"
              value={nationalId}
              placeholder={t('placeholder')}
              autoFocus
              onChange={onChangeNationalId}
              sx={{
                width: '100%',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: COLORS?.XX_LIGHT_GREEN,
                borderRadius: '8px',
                height: '100%',
                backgroundColor: COLORS?.WHITE,
                color: '#999999',
                '&:hover': {
                  borderColor: 'black',
                },
                '& input:placeholder': {
                  color: '#999999',
                },
              }}
              inputProps={{ style: { padding: 16 } }}
            />
            {nationalIdError && (
              <Typography component="h5" variant="body2" aria-live="assertive" color="error" sx={{ mb: 6 }}>
                {t(errMsg)}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <MButton
              variant="contained"
              id="EmailUsButton"
              typographyProps={{
                variant: 'bodyMediumB',
                textAlign: 'center',
                color: 'white',
              }}
              onClick={handelVerify}
              buttonProps={{
                sx: {
                  backgroundColor: COLORS?.X_DARK_BLUE,
                },
              }}
            >
              {t('verifyButton')}
            </MButton>
          </Box>
        </Box>
      )}

      <Snackbar
        sx={{
          maxWidth: 'unset',
          minWidth: 'unset',
          width: 'fit-content',
          m: 'auto',
          '& .MuiPaper-root': {
            maxWidth: 'unset',
            minWidth: 'unset',
            width: 'fit-content',
            m: 'auto',
          },
        }}
        open={displayExpiredNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={onCloseNotification}
      >
        <Alert severity="warning" variant="filled" sx={{ width: '100%' }} onClose={onCloseNotification}>
          Verification code has been expired
        </Alert>
      </Snackbar>
      <Snackbar
        sx={{
          maxWidth: 'unset',
          minWidth: 'unset',
          width: 'fit-content',
          m: 'auto',
          '& .MuiPaper-root': {
            maxWidth: 'unset',
            minWidth: 'unset',
            width: 'fit-content',
            m: 'auto',
          },
        }}
        open={displaySuccessNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={onCloseNotification}
      >
        <Alert severity="success" variant="filled" sx={{ width: '100%' }} onClose={onCloseNotification}>
          Verification code has been approved
        </Alert>
      </Snackbar>

      <Snackbar
        sx={{
          maxWidth: 'unset',
          minWidth: 'unset',
          width: 'fit-content',
          m: 'auto',
          '& .MuiPaper-root': {
            maxWidth: 'unset',
            minWidth: 'unset',
            width: 'fit-content',
            m: 'auto',
          },
        }}
        open={displayKycNotification}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={onCloseNotification}
      >
        <Alert severity="error" variant="filled" sx={{ width: '100%' }} onClose={onCloseNotification}>
          {t('userKycNotFound')}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NafazAuthentication;
