import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import COLORS from '../../../../constants/colors';
import './ProductInfo.scss';
import { formatNumber } from '../../../../utils/number';

interface ProductInfoProps {
  manager: string;
  strategy: string;
  expReturn: number;
  showBullet?: boolean;
}

const ProductInfo: React.FC<ProductInfoProps> = ({ strategy, manager, expReturn, showBullet }) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  return (
    <div className="product-info">
      <div className={isEnglish ? 'info info__en' : 'info info__ar'}>
        <Typography sx={{ fontSize: 10 }}>{t('products.strategy')}</Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 600, lineHeight: '21px' }} variant="h6">
          {t(`products.${strategy}`)}
        </Typography>
      </div>
      <div className={isEnglish ? 'info info__en' : 'info info__ar'}>
        <Typography sx={{ fontSize: 10 }}>{t('products.manager')}</Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 600, lineHeight: '21px' }} variant="h6">
          {manager}
        </Typography>
      </div>
      <div className={isEnglish ? 'info info__en' : 'info info__ar'}>
        <Typography sx={{ fontSize: 10 }}>{t('products.expReturn')}</Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '21px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
          variant="h6"
        >
          {showBullet && (
            <Box
              sx={{
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS.LIGHT_GREEN,
                mr: '6px',
              }}
            />
          )}
          {formatNumber(Number(expReturn) || 0, i18n.language, 0)}%
        </Typography>
      </div>
    </div>
  );
};

export default ProductInfo;
