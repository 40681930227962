import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ErrorIcon from '@mui/icons-material/Error';
import { PrettoSlider } from '../../../shared/CustomSlider';
import COLORS from '../../../constants/colors';
import MButton from '../../../shared/MButton';
import NotificationType from '../../../constants/NotificationType';

enum NotificationEnum {
  SUCCESS_WITH_BORDER = 'success_with_border',
}

interface NotificationProps {
  showSlider?: boolean;
  percent?: number;
  text?: JSX.Element;
  status?: NotificationType | NotificationEnum;
  onClick?: () => void;
  buttonText?: string;
  ctaButton?: JSX.Element | null;
  fullWidth?: boolean;
  padding?: object;
}

export const NotificationTheme: any = {
  [NotificationType.WARNING]: {
    background: 'transparent',
    border: `1px solid ${COLORS.ORANGE}`,
    mr: { xs: 0, md: '32px' },
    buttonColor: COLORS.ORANGE,
    borderRadius: '12px',
  },
  [NotificationType.ERROR]: {
    background: COLORS.RED,
    border: 'none',
    mr: { xs: 0, md: '14px' },
    borderRadius: '6px',
    textColor: COLORS.WHITE,
  },
  [NotificationEnum.SUCCESS_WITH_BORDER]: {
    background: 'transparent',
    border: `1px solid ${COLORS.SECONDARY_GREEN}`,
    mr: { xs: 0, md: '32px' },
    buttonColor: COLORS.SECONDARY_GREEN,
    borderRadius: '12px',
  },
  [NotificationType.INFO]: {
    background: 'transparent',
    border: `1px solid ${COLORS.PRIMARY_BLUE}`,
    mr: { xs: 0, md: '32px' },
    buttonColor: COLORS.PRIMARY_BLUE,
    borderRadius: '12px',
  },
  [NotificationType.SUCCESS]: {
    background: COLORS.SECONDARY_LIGHT_GREEN,
    border: 'none',
    mr: { xs: 0, md: '14px' },
    borderRadius: '6px',
  },
};

export const NotificationIcon: any = {
  [NotificationType.WARNING]: (
    <ErrorIcon
      sx={{
        width: '32px',
        height: '32px',
        mr: NotificationTheme[NotificationType.WARNING].mr,
        color: COLORS.ORANGE,
      }}
    />
  ),
  [NotificationType.ERROR]: (
    <CancelRoundedIcon
      sx={{
        width: '20px',
        height: '20px',
        mr: NotificationTheme[NotificationType.ERROR].mr,
        color: COLORS.WHITE,
      }}
    />
  ),
  [NotificationType.INFO]: (
    <ErrorIcon
      sx={{
        width: '32px',
        height: '32px',
        mr: NotificationTheme[NotificationType.WARNING].mr,
        color: COLORS.PRIMARY_BLUE,
      }}
    />
  ),
  [NotificationType.SUCCESS]: (
    <CheckCircleIcon
      sx={{
        width: '20px',
        height: '20px',
        mr: NotificationTheme[NotificationType.SUCCESS].mr,
        color: COLORS.SECONDARY_GREEN,
      }}
    />
  ),
  [NotificationEnum.SUCCESS_WITH_BORDER]: (
    <CheckCircleIcon
      sx={{
        width: { xs: '20px', md: '32px' },
        height: { xs: '20px', md: '32px' },
        mr: NotificationTheme[NotificationEnum.SUCCESS_WITH_BORDER].mr,
        color: COLORS.SECONDARY_GREEN,
      }}
    />
  ),
};

const Notification: React.FC<NotificationProps> = ({
  showSlider,
  percent,
  text = '',
  buttonText = '',
  padding = { xs: '0 0 24px 0', md: '32px 0 12px 0' },
  fullWidth = true,
  status = NotificationType.WARNING,
  ctaButton,
  onClick,
}) => (
  <Box sx={{ p: { ...padding }, width: '100%' }}>
    <Box
      sx={{
        display: { xs: 'block', md: 'flex' },
        textAlign: { xs: 'center', md: 'left' },
        alignItems: 'center',
        background: NotificationTheme[status].background,
        borderRadius: NotificationTheme[status].borderRadius,
        border: NotificationTheme[status].border,
        padding: { xs: '32px', md: '12px 24px' },
        width: '100%',
      }}
    >
      {NotificationIcon[status]}
      <Box
        sx={{
          width: { xs: '100%', md: fullWidth ? '100%' : '80%' },
          '& span': { color: NotificationTheme[status]?.textColor || '' },
        }}
      >
        {text}
        {showSlider && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PrettoSlider
              className="hide-thumb"
              valueLabelDisplay="off"
              aria-label="pretto slider"
              value={percent}
              min={0}
              max={100}
              // @ts-ignore
              bgColor={COLORS.ORANGE}
            />

            <Typography variant="bodyMedium" sx={{ fontWeight: 500, fontSize: '16px', color: COLORS.ORANGE, ml: 4 }}>
              {percent}%
            </Typography>
          </Box>
        )}
      </Box>
      {((onClick && buttonText) || ctaButton) && (
        <Box
          sx={{
            height: '50px',
            mt: { xs: '8px', md: '2px' },
            display: 'flex',
            justifyContent: {
              xs: 'center',
              md: 'flex-end',
            },
            alignItems: 'center',
            width: { xs: '100%' },
          }}
        >
          {ctaButton || (
            <MButton
              variant="contained"
              onClick={onClick}
              typographyProps={{ sx: { padding: '8px 24px', fontWeight: 600, fontSize: '16px' } }}
              buttonProps={{
                sx: {
                  ml: 'auto',
                  m: { xs: 'auto', md: 'unset !important' },
                  borderRadius: '8px',
                  order: { xs: 1, md: 2 },
                  p: 0,
                  backgroundColor: NotificationTheme[status]?.buttonColor,
                  backgroundImage: 'none',
                  width: { xs: 'auto', md: 'auto' },
                  '&:hover': {
                    backgroundColor: NotificationTheme[status]?.buttonColor,
                    boxShadow: 'none',
                  },
                },
              }}
            >
              {buttonText}
            </MButton>
          )}
        </Box>
      )}
    </Box>
  </Box>
);

export default Notification;
