import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchUserPreferenceResponse } from '../../../types/userPreference';
import {
  cleanUserPreferences,
  deleteUserPreference,
  fetchUserPreferenceByType,
  saveUserPreference,
  fetchRecommendedPortfolios,
  deleteRecommendedPortfolio,
  postRecommendedPortfolios,
} from './userPreference.actions';
import { UserPreferenceState } from './userPreference.types';

const initialState: UserPreferenceState = {
  userPreferences: {
    autoInvest: 'null',
  },
  recommendedPortfolios: [],
  fetchStatus: fetchStatuses.idle,
  saveStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  deletePortfolioStatus: fetchStatuses.idle,
  error: null,
};

const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserPreferenceByType.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserPreferenceByType.fulfilled,
      (state, action: PayloadAction<FetchUserPreferenceResponse>) => {
        if (action.payload?.data?.id) {
          state.userPreferences[action.payload.data.preferenceType.type] = action.payload.data.preferenceValue;
        }
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserPreferenceByType.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(saveUserPreference.pending, (state) => {
      state.error = null;
      state.saveStatus = fetchStatuses.pending;
    });
    builder.addCase(saveUserPreference.fulfilled, (state) => {
      state.saveStatus = fetchStatuses.success;
    });
    builder.addCase(saveUserPreference.rejected, (state, action) => {
      state.saveStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchRecommendedPortfolios.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchRecommendedPortfolios.fulfilled, (state, action: PayloadAction<any[]>) => {
      state.recommendedPortfolios = action.payload;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchRecommendedPortfolios.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(postRecommendedPortfolios.pending, (state) => {
      state.saveStatus = fetchStatuses.pending;
    });
    builder.addCase(postRecommendedPortfolios.fulfilled, (state, action: PayloadAction<any>) => {
      if (Array.isArray(state.recommendedPortfolios)) {
        state.recommendedPortfolios.push(action.payload);
      } else {
        state.recommendedPortfolios = [action.payload];
      }
    });
    builder.addCase(postRecommendedPortfolios.rejected, (state, action) => {
      state.saveStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteRecommendedPortfolio.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
    });
    builder.addCase(deleteRecommendedPortfolio.fulfilled, (state, action: PayloadAction<any>) => {
      state.recommendedPortfolios = action.payload;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(deleteRecommendedPortfolio.rejected, (state, action) => {
      state.error = action.error;
      state.deleteStatus = fetchStatuses.rejected;
    });

    builder.addCase(deleteUserPreference.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
    });
    builder.addCase(deleteUserPreference.fulfilled, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteUserPreference.rejected, (state, action) => {
      state.error = action.error;
      state.deleteStatus = fetchStatuses.rejected;
    });

    builder.addCase(cleanUserPreferences.type, (state) => ({
      ...initialState,
      recommendedPortfolios: state.recommendedPortfolios,
    }));
    // Object.assign(state, initialState);
  },
});

export const userPreferenceReducer = userPreferencesSlice.reducer;
