import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import COLORS from '../../../constants/colors';
import PortfolioPositionsTable from '../PortfolioDetails/PortfolioPositionsTableV2';
import {
  NewProduct,
  PortfolioStatisticsData,
  RecommendedPortfolio,
} from '../../../store_deprecated/types/portfolioTypes';
import ProductDoughnutChart from '../../../shared/ProductDoughnutChart';
import { UserIndexFund } from '../../../store_deprecated/types/indexFundTypes';
import { getSurveyResult } from '../../../store_deprecated/selectors/surveySelectors';
import { formatNumber } from '../../../utils/number';

interface PortfolioPositionsProps {
  portfolio: NewProduct;
  latestStatistics: PortfolioStatisticsData | null;
  userIndexFunds: UserIndexFund[];
  cashAllocationPercentage: number;
}

const PortfolioPositionsTab: React.FC<PortfolioPositionsProps> = (props) => {
  const { portfolio, cashAllocationPercentage, latestStatistics, userIndexFunds } = props;

  // const stocksPercentage = 90;
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  const surveyResult = useSelector(getSurveyResult);

  const category = isEnglish ? surveyResult?.category?.nameEn : surveyResult?.category?.nameAr;

  const [currentPortfolio, setCurrentPortfolio] = useState<RecommendedPortfolio>();

  useEffect(() => {
    setCurrentPortfolio({
      ...portfolio,
      associatedIndexFunds: portfolio.subscribedPortfolioAssociatedIndexFund,
    } as any);
  }, [portfolio]);

  return (
    <Grid
      container
      component="div"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      rowSpacing="64px"
    >
      <Grid
        item
        sx={{
          width: '100%',
          '@media screen and (orientation: portrait)': {
            minHeight: {
              xs: '60vh !important',
              sm: '60vh !important',
              md: '70vh !important',
              lg: '70vh !important',
            },
          },
          '@media screen and (orientation: landscape)': {
            minHeight: {
              xs: '100vh !important',
              sm: '110vh !important',
              md: '70vh !important',
              lg: '70vh !important',
            },
          },
          '@media (max-width: 900px) and (orientation: landscape)': {
            canvas: {
              m: 'unset !important',
            },
          },
          maxHeight: {
            xs: '60vh !important',
            sm: '60vh !important',
            md: '70vh !important',
            lg: '70vh !important',
          },
          maxWidth: {
            xs: '100% !important',
            sm: '100% !important',
            md: '80% !important',
            lg: '60% !important',
          },
          // marginTop: { xs: '-50px', sm: '-50px' },
          // marginBottom: { xs: '-100px', sm: '-100px' },
          marginRight: { xs: 'auto', sm: 'unset' },
        }}
        xs={12}
        md={9}
        lg={6}
        xl={6}
      >
        <ProductDoughnutChart portfolio={portfolio} cashAllocationPercentage={cashAllocationPercentage} />
      </Grid>
      <Grid
        item
        container
        width="100%"
        maxWidth={350}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          pb: '48px',
          mt: { xs: '0px', lg: '40px' },
        }}
      >
        <Grid item xs={6} textAlign="left">
          <Typography
            component="span"
            sx={{
              color: COLORS.LIGHT_GRAY,
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            {t('portfolioPositions.risk')}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography
            component="span"
            sx={{
              color: COLORS.MAIN_DARK,
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '28px',
            }}
          >
            {category}
          </Typography>
        </Grid>
        {/* <Grid item xs={6} textAlign="left"> */}
        {/*  <Typography */}
        {/*    component="span" */}
        {/*    sx={{ */}
        {/*      color: COLORS.LIGHT_GRAY, */}
        {/*      fontWeight: 400, */}
        {/*      fontSize: "16px", */}
        {/*      lineHeight: "24px", */}
        {/*    }} */}
        {/*  > */}
        {/*    {t("portfolioPositions.targetAllocation")} */}
        {/*  </Typography> */}
        {/* </Grid> */}
        {/* <Grid item xs={6} textAlign="right"> */}
        {/*  <Typography */}
        {/*    component="span" */}
        {/*    sx={{ */}
        {/*      color: COLORS.MAIN_DARK, */}
        {/*      fontWeight: 500, */}
        {/*      fontSize: "18px", */}
        {/*      lineHeight: "28px", */}
        {/*    }} */}
        {/*  > */}
        {/*    {`${formatNumber(stocksPercentage)}% ${t( */}
        {/*      "portfolioPositions.stocks" */}
        {/*    )}`} */}
        {/*  </Typography> */}
        {/* </Grid> */}
        <Grid item xs={6} textAlign="left">
          <Typography
            sx={{
              color: COLORS.LIGHT_GRAY,
              fontWeight: 400,
              fontSize: { xs: '0.875rem', md: '1rem' },
              lineHeight: '24px',
            }}
          >
            {t('expectedReturn')}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography
            sx={{
              color: COLORS.MAIN_DARK,
              fontWeight: 500,
              fontSize: 'clamp(1rem,0.5vw,1.125rem)',
              lineHeight: '28px',
            }}
          >
            {formatNumber(portfolio?.targetExpectedReturn || 0, i18n.language, 2)}%
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="left">
          <Typography
            sx={{
              color: COLORS.LIGHT_GRAY,
              fontWeight: 400,
              fontSize: { xs: '0.875rem', md: '1rem' },
              lineHeight: '24px',
            }}
          >
            {t('standardDeviation')}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography
            sx={{
              color: COLORS.MAIN_DARK,
              fontWeight: 500,
              fontSize: 'clamp(1rem,0.5vw,1.125rem)',
              lineHeight: '28px',
            }}
          >
            {formatNumber(portfolio?.targetExpectedRisk || 0, i18n.language, 2)}%
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="left">
          <Typography
            sx={{
              color: COLORS.LIGHT_GRAY,
              fontWeight: 400,
              fontSize: { xs: '0.875rem', md: '1rem' },
              lineHeight: '24px',
            }}
          >
            {t('sharpRatio')}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography
            sx={{
              color: COLORS.MAIN_DARK,
              fontWeight: 500,
              fontSize: 'clamp(1rem,0.5vw,1.125rem)',
              lineHeight: '28px',
            }}
          >
            {formatNumber(portfolio?.sharpRatio || 0, i18n.language, 2)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          width: '100%',
          overflowX: 'auto',
          p: '2px',
          pt: { xs: '0px !important', md: '64px !important' },
        }}
      >
        <PortfolioPositionsTable
          portfolio={currentPortfolio}
          latestStatistics={latestStatistics}
          userIndexFunds={userIndexFunds}
        />
      </Grid>
      <Grid
        item
        sx={{
          pt: { xs: '32px !important', md: '64px !important' },
          width: '100%',
        }}
      >
        <Typography
          variant="bodyLarge"
          sx={{
            color: COLORS.DARK_GREY,
            fontWeight: 400,
            fontSize: { xs: '12px', md: '16px' },
            lineHeight: '28px',
          }}
        >
          {t('portfolioPositions.hint')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PortfolioPositionsTab;
