export enum recurringPaymentFormFields {
  amount = 'amount',
  frequencyUnit = 'frequencyUnit',
  startDate = 'startDate',
  endDateType = 'endDateType',
  endDate = 'endDate',
  status = 'status',
  productId = 'productId',
}

export enum RecurringFrequencyUnit {
  HOURLY = 'Hourly',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
}

export const FrequencyOptions = [
  RecurringFrequencyUnit.HOURLY,
  RecurringFrequencyUnit.DAILY,
  RecurringFrequencyUnit.WEEKLY,
  RecurringFrequencyUnit.MONTHLY,
  RecurringFrequencyUnit.QUARTERLY,
];

export enum RecurringEndType {
  INFINITY = 'Infinity',
  DATE = 'Date',
}

export enum RecurringStatus {
  CREATED = 'Created', // Once it's first create by user (first step creation)
  ACTIVE = 'Active', // Once the card registered successfully (second step creation)
  EXPIRED = 'Expired',
  CANCELED = 'Canceled',
  DELETED = 'Deleted',
  REGISTERED = 'Registered',
}

export const RecurringEndTypeOptions = [RecurringEndType.INFINITY, RecurringEndType.DATE];
