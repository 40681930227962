import { Skeleton, Stack } from '@mui/material';

const ProductCardLoading = () => (
  <div className="product-card">
    <Stack spacing={1}>
      <Skeleton animation="wave" height={40} style={{ marginBottom: 6 }} />
      <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
      <Skeleton animation="wave" height={40} style={{ marginBottom: 6 }} />
      <Skeleton variant="rectangular" sx={{ height: 190 }} animation="wave" />
      <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
      <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
    </Stack>
  </div>
);

export default ProductCardLoading;
