import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import COLORS from '../../../constants/colors';
import { RecommendedPortfolio, SubscribedPortfolio } from '../../../store_deprecated/types/portfolioTypes';
import LineChart from './LineChart';

interface PastPerformanceProps {
  portfolio?: SubscribedPortfolio | RecommendedPortfolio;
}

const PastPerformanceTab: React.FC<PastPerformanceProps> = ({ portfolio }) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      component="div"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      rowSpacing="64px"
    >
      <Grid
        item
        sx={{
          width: '100%',
          height: { xs: '310px', md: '100%' },
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            width: { xs: '200%', md: '100%' },
            height: '100%',
            pb: 4,
          }}
        >
          <LineChart portfolio={portfolio} />
        </Box>
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <Typography
          variant="bodyLarge"
          sx={{
            color: COLORS.DARK_GREY,
            fontWeight: 400,
            fontSize: { xs: '12px', md: '16px' },
            lineHeight: '28px',
          }}
        >
          {t('portfolioPositions.hint')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PastPerformanceTab;
