import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import COLORS from '../../../constants/colors';
import { StyledTabs, StyledTab, TabPanel } from '../../../shared/CustomTabs';
import Breadcrumb from '../../../shared/Breadcrumb';
import { useTypedDispatch } from '../../../redux/store';
import { clearPortfolio, findSubscribedPortfolio } from '../../../store_deprecated/actions/portfolioActions';
import { getSubscribedPortfolioSelector } from '../../../store_deprecated/selectors/portfolioSelectors';
import PortfolioPositionsTab from './PortfolioPositionsTab';
import TargetAllocationTab from './TargetAllocationTab';
import PastPerformanceTab from './PastPerformanceTab';
import GoalDetailsTab from './GoalDetailsTab';
import { locations } from '../../../routes/locations';
import { fetchLastSurveyResult } from '../../../store_deprecated/actions/surveyActions';
import { DepositProps } from '../MyWallet/Deposit';
import { clearWallet, fetchAnbMasterardConfigAction } from '../../../store_deprecated/actions/walletActions';

const Loader = React.lazy(() => import('../../../shared/Loader'));

const EducationView: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [, setOpenAddMoreFundDialog] = React.useState(false);
  const [, setDepositConfig] = useState<DepositProps | null>(null);

  const subscribedPortfolio = useSelector(getSubscribedPortfolioSelector);
  const { isLoading } = subscribedPortfolio;

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      onClick={() => navigate(locations.dashboard())}
      sx={{ color: COLORS.LIGHT_GRAY, cursor: 'pointer' }}
    >
      {t('breadcrumb.dashboard')}
    </Link>,
    <Typography key="2" color="text.primary">
      {/* // @ts-ignore */}
      {t('breadcrumb.portfolioDetails')}
    </Typography>,
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await Promise.all([dispatch(fetchLastSurveyResult()), dispatch(findSubscribedPortfolio())]);
      } catch (e) {
        console.log('Some error');
      }
    };

    fetchInitialData();

    return () => {
      dispatch(clearPortfolio());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const anbMastercardConfig = await dispatch(fetchAnbMasterardConfigAction());

        if (anbMastercardConfig?.url && anbMastercardConfig?.version) {
          setDepositConfig({
            anbMastercardCheckoutBaseUrl: anbMastercardConfig.url,
            anbMastercardCheckoutVersion: anbMastercardConfig.version,
            // openCongratulationModal,
          });
        }
      } catch (e) {
        console.log('Some error');
      }
    };

    fetchInitialData();

    return () => {
      dispatch(clearWallet());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionHandler = () => {
    if (subscribedPortfolio?.id) {
      setOpenAddMoreFundDialog(true);
    }
  };

  const onCloseAddMoreFundDialog = async (fundAmount?: number) => {
    // TODO: handle when API is ready (like in StartInvestingProfile component)
    // if (fundAmount && subscribedPortfolio) {
    //   const result = await dispatch(addMoreFund({
    //     portfolioId: subscribedPortfolio.id,
    //     fundAmount,
    //   })) as any;

    // if (result) {
    //   dispatch(findSubscribedPortfolio());
    // }
    setOpenAddMoreFundDialog(false);
  };

  const closeDialog = () => {
    setOpenAddMoreFundDialog(false);
  };

  const renderActionButton = () => (
    <Button
      variant="text"
      onClick={actionHandler}
      startIcon={<AddSharpIcon />}
      id="AddMoreFundButton"
      sx={{
        background: COLORS.WHITE,
        borderRadius: '12px',
        backgroundClip: 'padding-box',
        padding: '8px 24px',
        border: 'solid 1.5px transparent',

        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
          margin: '-1.5px',
          borderRadius: 'inherit',
          backgroundImage: COLORS.LINEAR_GRADIENT,
        },

        '&.MuiButtonBase-root:hover': {
          bgcolor: COLORS.WHITE,
        },
      }}
    >
      <Typography
        variant="bodyMedium"
        sx={{
          textTransform: 'capitalize',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '24px',
        }}
      >
        {t('educationView.addMoreFund')}
      </Typography>
    </Button>
  );

  return (
    <Container component="main" sx={{ padding: '32px!important' }}>
      <Grid container columnSpacing={2} rowSpacing="34px" flexDirection="column">
        <Grid item>
          <Breadcrumb breadcrumbs={breadcrumbs} />
        </Grid>
        <Grid item container flexDirection="row" justifyContent="space-between" alignItems="center">
          <Grid item display="flex" justifyContent="center" alignItems="center">
            <img src="/images/books.svg" alt="books" width="64px" height="64px" />
            <Typography
              variant="h5"
              sx={{
                ml: '16px',
                fontWeight: 500,
                fontSize: '24px',
                lineHeight: '32px',
              }}
            >
              {t('educationView.education')}
            </Typography>
          </Grid>
          <Grid item>{renderActionButton()}</Grid>
        </Grid>
      </Grid>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ width: '100%', mt: '32px' }}>
          <Box>
            <StyledTabs value={value} onChange={handleChange}>
              <StyledTab label={t('educationView.goalDetailsTabLabel')} key={0} />
              <StyledTab label={t('educationView.portfolioPositionsTabLabel')} key={1} />
              <StyledTab label={t('educationView.targetAllocationTabLabel')} key={2} />
              <StyledTab label={t('educationView.pastPerformanceTabLabel')} key={3} />
            </StyledTabs>
          </Box>
          <TabPanel value={value} index={0}>
            <GoalDetailsTab />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PortfolioPositionsTab
              portfolio={subscribedPortfolio}
              cashAllocationPercentage={subscribedPortfolio?.userPortfolios?.[0]?.cacheAllocationPercentage}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TargetAllocationTab
              portfolio={subscribedPortfolio}
              cashAllocationPercentage={subscribedPortfolio?.userPortfolios?.[0]?.cacheAllocationPercentage}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PastPerformanceTab portfolio={subscribedPortfolio} />
          </TabPanel>
        </Box>
      )}
      {/* TODO: ref to line 123 todo from the same file */}
      {/* <AddMoreFundDialog */}
      {/*  open={openAddMoreFundDialog} */}
      {/*  onClose={onCloseAddMoreFundDialog} */}
      {/*  closeDialog={closeDialog} */}
      {/*  depositConfig={depositConfig} */}
      {/*  // error={tApiError(subscribeToPortfolio.error)} */}
      {/*  // isLoading={subscribeToPortfolio.isLoading} */}
      {/* /> */}
    </Container>
  );
};

export default EducationView;
