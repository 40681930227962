import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const About: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        padding: '32px',
        mt: { xs: '-56px', md: 'auto' },
        minHeight: '70vh',
      }}
    >
      <Grid container flexDirection="column" justifyContent="space-between">
        <Grid item>
          <Typography component="h1" variant="h5">
            {t('about.title')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
