class Locations {
  pathname = '/';

  constructor(pathname = '/') {
    this.pathname = pathname;
  }

  home = () => this.pathname;

  login = () => `${this.pathname}login`;

  resetPassword = () => `${this.pathname}reset-password`;

  signUp = () => `${this.pathname}sign-up`;

  dashboard = () => `${this.pathname}dashboard`;

  oldDashboard = () => `${this.pathname}old-dashboard`;

  productDashboard = () => `${this.pathname}product-dashboard`;

  survey = () => `${this.pathname}survey`;

  products = () => `${this.pathname}products`;

  customProduct = () => `${this.pathname}custom-product`;

  riskSurvey = () => `${this.pathname}risk-survey`;

  docs = () => `${this.pathname}docs`;

  portfolioDetails = () => `${this.pathname}portfolio-details`;

  redemption = () => `${this.pathname}redemption`;

  invest = () => `${this.pathname}invest`;

  transactionsHistory = () => `${this.pathname}transactions-history`;

  orders = () => `${this.pathname}orders`;

  myWallet = () => `${this.pathname}my-wallet`;

  myGoals = () => `${this.pathname}my-goals`;

  faqs = () => `${this.pathname}faqs`;

  askForAdvice = () => `${this.pathname}askForAdvice`;

  reports = () => `${this.pathname}reports`;

  tutorials = () => `${this.pathname}madkholAcademy`;

  askForAnAdvise = () => `${this.pathname}ask-for-advice`;

  inviteFriend = () => `${this.pathname}invite-a-friend`;

  education = () => `${this.pathname}education`;

  myProfile = () => `${this.pathname}my-profile`;

  about = () => `${this.pathname}about`;

  nafazData = () => `${this.pathname}nafaz-data`;

  fundDetails = (id?: number) => `${this.pathname}fund-details/${id !== undefined ? id : ':id'}`;

  myWalletBankAccount = (id?: number) => `${this.pathname}my-wallet/bank-account/${id !== undefined ? id : ':id'}`;

  termsAndConditions = () => `${this.pathname}terms-and-conditions`;

  accountOpeningAgreement = () => `${this.pathname}account-opening-agreement`;

  alpacaActivities = () => `${this.pathname}alpaca-activities`;

  emailVerification = () => `${this.pathname}email-verification`;

  nafazAuthentication = () => `${this.pathname}nafaz-authentication`;

  nafazVerification = () => `${this.pathname}nafaz-verification`;

  notFound = () => `${this.pathname}404`;
}

export const locations = new Locations();
