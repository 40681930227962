import { styled, CSSObject, Theme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';
import COLORS from '../../../constants/colors';

const drawerWidth = 264;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  borderWidth: 0,
  boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.05)',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

export const closedMixin = (theme: Theme): CSSObject => ({
  borderWidth: 0,
  boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.05)',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 64px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 64px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex !important',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
    minHeight: 0,
  },
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop: string) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  padding: '48px 32px',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      '&::-webkit-scrollbar': {
        width: '0px !important',
      },
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const CustomListItemButton = styled(ListItemButton)(() => ({
  padding: '10px',
  color: COLORS.MAIN_DARK,
  backgroundColor: 'transparent',
  '&.Mui-selected': {
    color: COLORS.WHITE,
    backgroundColor: COLORS.SECONDARY_GREEN,
    '&:hover': {
      backgroundColor: COLORS.SECONDARY_GREEN,
      opacity: 0.7,
    },
  },
}));
