import React, { ReactNode, useState } from 'react';
import _merge from 'lodash.merge';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import COLORS from '../constants/colors';
import { analytics } from '../helpers/analytics';

interface MButtonProps {
  id?: string;
  variant: 'text' | 'outlined' | 'contained';
  children: ReactNode | ReactNode[];
  fullWidth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  buttonProps?: ButtonProps;
  typographyProps?: TypographyProps;
  className?: string;
}

const buttonVariantToProps = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isEnglish: boolean,
): Record<string, ButtonProps> => ({
  text: {
    variant: 'text',
    sx: {
      backgroundColor: COLORS.TRANSPARENT,
      borderRadius: '80px',
      padding: '16px 48px',
    },
  },
  outlined: {
    variant: 'text',
    sx: {
      background: COLORS.WHITE,
      borderRadius: '80px',
      backgroundClip: 'padding-box',
      padding: 0,
      border: { xs: 'solid 1.5px transparent', md: 'solid 2px transparent' },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: -1,
        margin: { xs: '-1.5px', md: '-2px' },
        borderRadius: 'inherit',
        background: COLORS.X_DARK_BLUE,
      },
      '&.MuiButtonBase-root:hover': {
        bgcolor: COLORS.WHITE,
      },
    },
  },
  contained: {
    variant: 'contained',
    sx: {
      width: '100%',
      backgroundImage: COLORS.X_DARK_BLUE,
      padding: '16px 48px',
      borderRadius: '80px',
      boxShadow: 'none',
      '&.MuiButton-root.Mui-disabled': {
        backgroundImage: 'unset',
        backgroundColor: 'lightgray',
      },
    },
  },
});

const buttonVariantToTypographyProps = (isEnglish: boolean): Record<string, TypographyProps> => ({
  text: {
    variant: isEnglish ? 'h6' : 'cairoH4',
    sx: {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
    },
  },
  outlined: {
    variant: isEnglish ? 'h6' : 'cairoH4',
    sx: {
      textTransform: 'none',
      bgcolor: COLORS.WHITE,
      color: COLORS.PRIMARY_BLUE,
      borderRadius: '80px',
      padding: '16px 48px',
      margin: '0!important',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  contained: {
    variant: isEnglish ? 'h6' : 'cairoH4',
    sx: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'none',
    },
  },
});

const MButton: React.FC<MButtonProps> = (props) => {
  const {
    onClick = () => {},
    fullWidth = false,
    disabled = false,
    isLoading = false,
    buttonProps = {},
    typographyProps = {},
    className = '',
  } = props;

  const { i18n } = useTranslation('');
  const isEnglish = i18n.language === 'en';

  const [id] = useState(() => props.id || `m-button-${Math.random().toString(16).slice(2)}`);

  const allButtonProps = _merge({}, buttonVariantToProps(isEnglish)[props.variant], buttonProps);
  const allButtonTypographyProps = _merge(
    {},
    buttonVariantToTypographyProps(isEnglish)[props.variant],
    typographyProps,
  );

  const buttonClick = () => {
    analytics.track(
      'MButtonClick',
      { mButtonId: id, text: props.children?.toString() },
      { context: { locale: i18n.language } },
    );

    return onClick();
  };

  return (
    <Button
      id={id}
      variant="text"
      onClick={buttonClick}
      disabled={disabled || isLoading}
      fullWidth={fullWidth}
      className={className}
      {...allButtonProps}
    >
      {isLoading ? (
        <CircularProgress sx={{ ml: 1 }} size={24} />
      ) : (
        <Typography {...allButtonTypographyProps}>{props.children}</Typography>
      )}
    </Button>
  );
};

export default MButton;
