import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import COLORS from '../../../constants/colors';

interface PasswordFieldRolesProps {
  isArabic: boolean;
  password: string;
  passwordValidation: any;
}

const PasswordFieldRoles: React.FC<PasswordFieldRolesProps> = (props) => {
  const { isArabic, password, passwordValidation } = props;
  const { i18n } = useTranslation();

  return (
    <>
      {Object.keys(passwordValidation).map((item) => (
        <Grid
          item
          xs={12}
          textAlign={passwordValidation[item].id % 2 ? 'left' : 'right'}
          key={passwordValidation[item].id}
          display="flex"
        >
          <Typography
            variant={isArabic ? 'cairoR' : 'bodySmall'}
            sx={{
              fontSize: { xs: '12px', md: '14px' },
              // eslint-disable-next-line no-nested-ternary
              color: !password
                ? COLORS.LIGHT_GRAY
                : passwordValidation[item].isValid
                ? COLORS.SECONDARY_GREEN
                : COLORS.RED,
              width: 'calc(100% - 36px)',
              textAlign: 'left',
              position: 'relative',
              paddingLeft: '10px',
              '&:before': {
                content: '"• "',
                position: 'absolute',
                left: 0,
                top: 0,
                color: COLORS.SECONDARY_GREEN,
              },
            }}
          >
            {passwordValidation[item].text[i18n.language]}
          </Typography>
          <Box
            sx={{
              width: '36px',
              textAlign: 'right',
              visibility: password ? 'visible' : 'hidden',
            }}
          >
            {passwordValidation[item].isValid ? (
              <CheckCircleIcon color="success" sx={{ fontSize: '0.75rem' }} />
            ) : (
              <CancelIcon color="error" sx={{ fontSize: '0.75rem' }} />
            )}
          </Box>
        </Grid>
      ))}
    </>
  );
};

export default PasswordFieldRoles;
