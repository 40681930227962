import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import COLORS from '../../../constants/colors';
import { primaryBankInfoSelector } from '../../../store_deprecated/selectors/walletSelectors';

const SwiftCode: React.FC = () => {
  const { t } = useTranslation();

  const bankItem = useSelector(primaryBankInfoSelector);

  return (
    <Grid
      item
      container
      flexDirection="column"
      component={Paper}
      elevation={0}
      sx={{
        p: '24px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
        borderRadius: '12px',
        height: '100%',
      }}
    >
      <Typography
        sx={{
          color: COLORS.MAIN_DARK,
          fontWeight: 500,
          fontSize: 'clamp(1rem,1vw + 1rem,1.25rem)',
          lineHeight: '28px',
        }}
        variant="h6"
      >
        {t('swiftCode')}
      </Typography>
      <Typography
        variant="bodySmall"
        sx={{
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          marginBottom: '12px',
          color: COLORS.LIGHT_GRAY,
        }}
      >
        {t(' ')}&nbsp;
      </Typography>
      <Typography
        sx={{
          color: COLORS.MAIN_DARK,
          fontWeight: 400,
          fontSize: 'clamp(1rem,6vw + 1rem,1.125rem)',
          lineHeight: '28px',
        }}
        variant="bodyLarge"
      >
        {bankItem?.swiftCode || '-'}
      </Typography>
    </Grid>
  );
};

export default SwiftCode;
