import React from 'react';
import { styled } from '@mui/material/styles';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import COLORS from '../constants/colors';

interface StyledTabsProps extends TabsProps {
  value: number;
  scrollButtons?: boolean;
  allowScrollButtonsMobile?: boolean;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

interface StyledTabProps {
  label: string;
}

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} sx={{ width: { xs: '100%', md: 'auto' } }} />
))(({ theme }) => ({
  textTransform: 'none',
  marginRight: theme.spacing(1),
  padding: '14px 32px',
  textAlign: 'center',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: COLORS.LIGHT_GRAY,
  backgroundColor: COLORS.WHITE,
  [theme.breakpoints.down('md')]: {
    padding: '14px',
  },
  '&.Mui-selected': {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: COLORS.WHITE,
    backgroundColor: COLORS.X_DARK_BLUE,
  },
  '&.Mui-focusVisible': {
    backgroundColor: COLORS.X_DARK_BLUE,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
