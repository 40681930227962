import PasswordValidationStatus from './PasswordValidationStatus';

const PasswordValidationRules = {
  [PasswordValidationStatus.SHORT_PASSWORD]: {
    id: 0,
    text: {
      en: 'The minimum limit is 8 characters.',
      ar: 'الحد الأدنى للرمز السري هو 8 خانات.',
    },
    isValid: null,
  },
  [PasswordValidationStatus.COMBINATION_SEQUENCE_OR_CONSECUTIVE_PATTERN]: {
    id: 1,
    text: {
      en: 'Sequential or consecutive characters are not allowed. Example: abc or 123.',
      ar: 'لا يسمح بإختيار الحروف أو الأرقام المتسلسلة، على سبيل المثال  abcأو 123.',
    },
    isValid: null,
  },
  [PasswordValidationStatus.AT_LEAST_UPPERCASE_CHAR]: {
    id: 2,
    text: {
      en: 'Must have a combination of capital & small letters, digits, and at least 1 special character for each Example: @.',
      ar: 'يلزم إختيارمزيج من الأحرف الكبيرة والصغيرة والأرقام و رمز خاص على سبيل المثال، @.',
    },
    isValid: null,
  },
};

export const PasswordValidationRulesWithConfirmPassword = {
  ...PasswordValidationRules,
  [PasswordValidationStatus.PASSWORD_AND_CONFIRM_PASSWORD_DOES_MATCH]: {
    id: 3,
    text: {
      en: 'Password and confirm password matches',
      ar: 'كلمة المرور وتأكيد كلمة المرور متطابقين',
    },
    isValid: null,
  },
};

export default PasswordValidationRules;
