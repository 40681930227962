import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import { RecommendedPortfolio, SubscribedPortfolio } from '../../../store_deprecated/types/portfolioTypes';
import { getSaveChartColor } from '../../../utils/charts';

type Dataset = {
  label: string;
  data: number[];
  borderColor: string;
};

interface LineChartProps {
  portfolio?: SubscribedPortfolio | RecommendedPortfolio;
}

const labels = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct'];

function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const LineChart = ({ portfolio }: LineChartProps) => {
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  const options = {
    responsive: true,
    elements: {
      line: {
        tension: 0.4,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      interaction: {
        intersect: false,
      },
      doughnutLabelsLine: {
        display: false,
      },
    },
  };
  const [data, setData] = useState({
    labels,
    datasets: [] as Dataset[],
  });

  useEffect(() => {
    if (portfolio?.id) {
      const newData = { ...data, datasets: { ...data.datasets } };
      newData.datasets = [];

      for (let i = 0; i < portfolio.associatedIndexFunds.length; i++) {
        const asset = portfolio.associatedIndexFunds[i];
        const label = isEnglish ? asset.indexFund.asset.nameEn : asset.indexFund.asset.nameAr;

        newData.datasets.push({
          label,
          borderColor: getSaveChartColor(portfolio.associatedIndexFunds.length, i),
          data: labels.map(() => randomIntFromInterval(0, 12)),
        });
      }

      setData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio]);

  return <Line data={data} options={options} />;
};

export default LineChart;
