import { captureException } from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LogoutIcon from '@mui/icons-material/Logout';
import COLORS from '../../../constants/colors';
import { singleDownload } from '../../../helpers/request';
import MButton from '../../../shared/MButton';
import { useTypedDispatch } from '../../../redux/store';
import { fetchAttachedDocuments, fetchUploadedDocumentURL } from '../../../store_deprecated/actions/docsActions';
import { getDocsDataSelector } from '../../../store_deprecated/selectors/docsSelectors';
import { DocumentTypes, Attachment } from '../../../store_deprecated/types/docsTypes';

const OrdersView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  const dispatch = useTypedDispatch();
  const docsDataState = useSelector(getDocsDataSelector);
  const [loadingReportId, setLoadingReportId] = useState(0);
  const [reportDocs, setReportDocs] = useState<Attachment[]>([]);

  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([dispatch(fetchAttachedDocuments())]);
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredDocs = docsDataState?.data?.filter((doc) =>
      [DocumentTypes.ACCOUNT_STATEMENT, DocumentTypes.TRADE_CONFIRMATION].includes(doc.documentType.type),
    );

    setReportDocs(filteredDocs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docsDataState]);

  const openReport = async (item: Attachment) => {
    setLoadingReportId(item.id);

    try {
      const url = await dispatch(fetchUploadedDocumentURL(item.documentLink));

      await singleDownload(url, {
        rename: () => `${item.documentType.type}-${item.documentLink}`,
      });
    } catch (e) {
      captureException(e, {
        tags: {
          action: 'openReport',
          component: 'OrdersView',
          reportId: item.id,
          documentLink: item.documentLink,
        },
      });
      console.error('[openReport error]', e);
    } finally {
      setLoadingReportId(0);
    }
  };

  const renderReportItem = (item: Attachment) => {
    const activityKey = Object.keys(DocumentTypes)[Object.values(DocumentTypes).indexOf(item.documentType.type)];
    return (
      <Grid item xs={12} key={item.id}>
        <Grid
          container
          flexDirection="column"
          component={Paper}
          elevation={0}
          sx={{ p: { xs: '14px', md: '24px' }, borderRadius: '12px' }}
        >
          <Grid item display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box sx={{ display: 'block' }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: '16px', md: '20px' },
                  lineHeight: '24px',
                  color: COLORS.MAIN_DARK,
                }}
              >
                {t(`reports.${activityKey}`)}
              </Typography>
              <Typography
                variant="bodySmall"
                component="div"
                sx={{
                  // fontWeight: 600,
                  fontSize: { xs: '12px', md: '14px' },
                  lineHeight: '20px',
                  color: COLORS.LIGHT_GRAY,
                  mt: '10px',
                }}
              >
                {`${t('published')} ${moment(item.createdAt).locale(i18n.language).format('MMM D YYYY')}`}
              </Typography>
            </Box>
            <Box>
              <MButton
                variant="text"
                isLoading={loadingReportId === item.id}
                disabled={!!loadingReportId}
                buttonProps={{
                  startIcon: (
                    <LogoutIcon
                      sx={{
                        transform: 'rotate(90deg)',
                        mr: { xs: '0px', md: '12px' },
                      }}
                    />
                  ),
                  sx: {
                    textTransform: 'capitalize',
                    color: COLORS.PRIMARY_BLUE,
                    flexDirection: { xs: 'column', md: 'row' },
                    padding: '6px 8px',
                    justifyContent: 'center',
                    '& span': {
                      margin: 0,
                    },
                  },
                }}
                typographyProps={{
                  variant: 'bodySmall',
                  sx: {
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '24px',
                    textDecoration: 'none',
                  },
                }}
                onClick={() => openReport(item)}
              >
                {t('download')}
              </MButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        padding: { xs: '56px 24px', md: '32px 32px 0 32px' },
        height: 'calc(100vh - 275px)',
        mt: { xs: '-56px', md: 'auto' },
      }}
    >
      <Grid container flexDirection="column" justifyContent="space-between">
        <Grid item>
          <Typography component="h1" variant="h5" sx={{ fontWeight: 500, fontSize: '24px', lineHeight: '32px' }}>
            {t('reports.title')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          rowSpacing="18px"
          justifyContent="space-between"
          sx={{
            p: '3px',
            mt: '14px',
            height: '100%',
            maxHeight: 'calc(100vh - 370px)',
            '@media screen and (orientation: landscape)': {
              minHeight: {
                xs: '40vh !important',
                sm: '40vh !important',
              },
            },
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '5px !important',
              backgroundColor: 'transparent !important',
              borderRadius: '4px !important',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#111827 !important',
              borderRadius: '4px !important',
            },
          }}
        >
          {reportDocs.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', color: COLORS.LIGHT_GRAY }}
            >
              <Typography variant={isEnglish ? 'smallDisplayH' : 'cairoHeader1B'} gutterBottom>
                {t('noReports')}
              </Typography>
            </Box>
          )}
          {/* // @ts-ignore */}
          {reportDocs.map(renderReportItem)}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrdersView;
