import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import COLORS from '../../../constants/colors';
import CenterContent from '../../../shared/CenterContent';
import { PrettoSlider } from '../../../shared/CustomSlider';
import MButton from '../../../shared/MButton';

interface CompleteProfileProps {
  onComplete: () => void;
  percent: number;
}

const CompleteProfile: React.FC<CompleteProfileProps> = ({ onComplete, percent }) => {
  const { t } = useTranslation();

  const completeProfile = () => {
    onComplete();
  };

  return (
    <CenterContent>
      <Grid
        container
        display="flex"
        flexDirection={{ xs: 'row', md: 'column' }}
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: { xs: '24px', md: '64px' },
          background: COLORS.WHITE,
          borderRadius: { xs: '12px', md: '24px' },
        }}
        rowSpacing="24px"
      >
        <Grid
          item
          sx={{
            width: { xs: '100%', md: '300px' },
            height: { xs: 'auto', md: '300px' },
          }}
        >
          <img src="/images/complete-profile.png" alt="" style={{ width: '100%', height: '100%' }} />
        </Grid>
        <Grid item textAlign="center">
          <Typography
            variant="bodyLarge"
            sx={{
              weight: 400,
              size: '18px',
              lineHeight: '28px',
              color: COLORS.DARK_GREY,
            }}
          >
            {t('dashboard.completeProfileNote')}
          </Typography>
        </Grid>
        <Grid item display="flex" flexDirection="column" alignItems="center" sx={{ width: '360px' }}>
          <Typography
            variant="bodyMedium"
            sx={{
              weight: 500,
              size: '16px',
              lineHeight: '24px',
              color: COLORS.SECONDARY_GREEN,
            }}
          >
            {percent}%
          </Typography>
          <PrettoSlider
            className="hide-thumb"
            valueLabelDisplay="off"
            aria-label="pretto slider"
            value={percent}
            min={0}
            max={100}
          />
        </Grid>

        <Grid item sx={{ mt: '8px' }}>
          <MButton
            variant="contained"
            onClick={completeProfile}
            id="CompleteProfileButton"
            buttonProps={{
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
                backgroundColor: COLORS?.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS?.X_DARK_BLUE,
                },
              },
            }}
          >
            {t('dashboard.completeProfile')}
          </MButton>
        </Grid>
      </Grid>
    </CenterContent>
  );
};

export default CompleteProfile;
