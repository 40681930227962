import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import MButton from '../MButton';
import COLORS from '../../constants/colors';
import useWebsiteRedirect from '../../helpers/hooks/useWebsiteRedirect';

interface MobileViewProps {
  isArabic: boolean;
  isLoggedIn: boolean;
  locations: any;
  language: string;
  logoutUser: () => void;
  handleChangeLanguage: () => void;
}

const MobileView: React.FC<MobileViewProps> = ({
  isArabic,
  isLoggedIn,
  locations,
  language,
  logoutUser,
  handleChangeLanguage,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { gotToDashaboard } = useWebsiteRedirect();

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = (open: boolean) => setOpenDrawer(open);

  const changeLanguage = () => {
    handleChangeLanguage();
    toggleDrawer(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: { xs: 'flex', lg: 'none' },
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: 'flex', md: 'none' },
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        {isLoggedIn && (
          <IconButton
            size="large"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => toggleDrawer(true)}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        )}
        <img
          onClick={gotToDashaboard}
          style={{
            width: '115px',
            margin: isLoggedIn ? 'auto 0' : 'auto 0 auto 16px',
          }}
          src={i18n.language === 'ar' ? '/images/logo-with-text-ar.png' : '/images/logo-with-text-en.png'}
          alt="madkhol logo"
        />
        <Button
          onClick={changeLanguage}
          sx={{
            marginLeft: 'auto',
            paddingLeft: 0,
            paddingRight: 0,
            justifyContent: 'flex-start',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <Typography variant={isArabic ? 'bodySmall' : 'cairoM'} color="black" sx={{ textTransform: 'capitalize' }}>
            {language}
          </Typography>
        </Button>
      </Box>

      <Drawer anchor="left" open={openDrawer} onClose={() => toggleDrawer(false)}>
        <IconButton
          aria-label="close"
          onClick={() => toggleDrawer(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ width: '90vw' }} role="presentation" onKeyDown={() => toggleDrawer(false)}>
          <List
            sx={{
              width: '100%',
              height: '100vh',
              padding: '60px 25px',
            }}
            component="nav"
          >
            <ListItemButton>
              <img
                onClick={() => navigate(locations.home())}
                style={{
                  width: '115px',
                  margin: isLoggedIn ? 'auto 0 30px' : 'auto 0 auto 16px',
                }}
                src={i18n.language === 'ar' ? '/images/logo-with-text-ar.png' : '/images/logo-with-text-en.png'}
                alt="madkhol logo"
              />
            </ListItemButton>
            {/* <ListItemButton>
              <Button
                onClick={changeLanguage}
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  justifyContent: "flex-start",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Typography
                  variant={isArabic ? "bodySmall" : "cairoM"}
                  color="black"
                  sx={{ textTransform: "capitalize" }}
                >
                  {language}
                </Typography>
              </Button>
            </ListItemButton> */}
            {isLoggedIn && (
              <ListItemButton
                sx={{
                  marginTop: 'calc(75vh - 60px)',
                  width: '100%',
                  padding: 0,
                }}
              >
                <MButton
                  variant="text"
                  fullWidth
                  onClick={logoutUser}
                  id="LogoutButton"
                  buttonProps={{
                    sx: {
                      height: 36,
                      borderRadius: 2,
                      backgroundColor: COLORS.X_DARK_BLUE,
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: COLORS.X_DARK_BLUE,
                      },
                    },
                  }}
                  typographyProps={{
                    variant: isArabic ? 'cairoM' : 'bodySmallM',
                    sx: { textTransform: 'capitalize', fontWeight: 500 },
                    color: COLORS.WHITE,
                  }}
                >
                  {t('logout')}
                </MButton>
              </ListItemButton>
            )}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileView;
