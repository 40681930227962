import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 64px - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CenterContent: React.FC<{ children: React.JSX.Element[] | React.JSX.Element }> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);

export default CenterContent;
