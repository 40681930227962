import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { ReduxState, useTypedDispatch } from '../../../redux/store';
import {
  subscribedToPortfolio,
  resetSubscribedToPortfolio,
  findRecommendedPortfolioByProduct,
  clearPortfolio,
} from '../../../store_deprecated/actions/portfolioActions';
import COLORS from '../../../constants/colors';
import SubscribePortfolioDialog from '../Invest/SubscribePortfolioDialog';
import { PortfolioAssociatedIndexFund } from '../../../store_deprecated/types/portfolioTypes';
import {
  getRecommendedPortfolioSelector,
  getSubscribeToPortfolioSelector,
} from '../../../store_deprecated/selectors/portfolioSelectors';
import CenterContent from '../../../shared/CenterContent';
import { getBankInfoIsLoading, madkhoulBankInfoSelector } from '../../../store_deprecated/selectors/walletSelectors';
import MButton from '../../../shared/MButton';
import { getSurveyResult } from '../../../store_deprecated/selectors/surveySelectors';
import * as LocalStorage from '../../../helpers/LocalStorage';
import { formatNumber } from '../../../utils/number';
import { fetchUserPreferenceByType } from '../../../redux/modules/userPreference/userPreference.actions';
import { UserPreferenceTypeEnum } from '../../../types/userPreference';
import { userPreferenceByTypeSelector } from '../../../redux/modules/userPreference/userPreference.selectors';
import { fetchStatuses } from '../../../constants/fetchStatuses';

interface StartInvestingProfileProps {
  wasRejected?: boolean;
}

const StartInvestingProfile: React.FC<StartInvestingProfileProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');
  const dispatch = useTypedDispatch();
  const { user } = useSelector(authSelector);
  const surveyResult = useSelector(getSurveyResult);
  const portfolio = useSelector(getRecommendedPortfolioSelector);
  const subscribeToPortfolio = useSelector(getSubscribeToPortfolioSelector);

  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);
  const [showSuccessfulScreen, setShowSuccessfulScreen] = useState(false);

  const bankItem = useSelector(madkhoulBankInfoSelector);
  const isLoading = useSelector(getBankInfoIsLoading);

  const { userPreference: productTypePreference, fetchStatus: userPreferenceFetchStatus } = useSelector(
    (state: ReduxState) => userPreferenceByTypeSelector(state, UserPreferenceTypeEnum.PRODUCT_TYPE),
  );

  useEffect(() => {
    if (!productTypePreference && userPreferenceFetchStatus === fetchStatuses.idle) {
      dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.PRODUCT_TYPE));
    }

    if (productTypePreference && userPreferenceFetchStatus === fetchStatuses.success) {
      const preference = JSON.parse(productTypePreference);
      dispatch(
        findRecommendedPortfolioByProduct({ productTypeId: preference.id, category: surveyResult?.category.nameEn }),
      );

      return () => {
        dispatch(clearPortfolio());
      };
    }
  }, [productTypePreference, userPreferenceFetchStatus, surveyResult?.category]);

  const onCloseSubscribeDialog = async (fundAmount?: number) => {
    if (fundAmount && portfolio && user?.id) {
      const associatedIndexFunds: Pick<PortfolioAssociatedIndexFund, 'id' | 'weight'>[] =
        LocalStorage.getRecommendedPortfolioAllocations(user.id, portfolio.id) || [];

      const result = (await dispatch(
        subscribedToPortfolio({
          associatedIndexFunds,
          portfolioId: portfolio.id,
          fundAmount,
        }),
      )) as any;

      if (result) {
        setShowSuccessfulScreen(true);

        /**
         * Clear the allocation changes
         */
        LocalStorage.removeSelectedPortfolio(user.id);
        LocalStorage.removeRecommendedPortfolioAllocations(user.id, portfolio.id);
      }
    } else {
      setOpenSubscribeDialog(false);
      dispatch(resetSubscribedToPortfolio());
    }
  };

  const closeDialog = () => {
    setOpenSubscribeDialog(false);
    dispatch(resetSubscribedToPortfolio());
  };

  const onSuccessButtonClick = () => {
    closeDialog();
    window.location.reload();
  };

  const completeSubscription = () => {
    setShowSuccessfulScreen(false);
    setOpenSubscribeDialog(true);
  };

  // TODO: Hidden till implement Goals
  // const newGoal = () => {
  //   navigate(locations.invest() + "#SetGoal");
  // };

  const renderRejectedInfo = () => (
    <Grid
      item
      container
      display="flex"
      flexDirection="row"
      alignItems="center"
      flexWrap="nowrap"
      sx={{
        background: 'rgba(234, 149, 21, 0.12)',
        borderRadius: '6px',
        padding: '12px 26px',
        mt: '64px',
      }}
    >
      <ErrorIcon
        sx={{
          width: '20px',
          height: '20px',
          mr: '14px',
          color: COLORS.ORANGE,
        }}
      />
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={24}
          sx={{ bgcolor: 'rgba(126, 163, 86, 0.12)', borderRadius: '6px' }}
        />
      ) : (
        <Typography variant="bodyMedium" sx={{ color: COLORS.MAIN_DARK, whiteSpace: 'pre-line' }}>
          {t('dashboard.portfolioWasRejected')}
        </Typography>
      )}
    </Grid>
  );

  return (
    <CenterContent>
      <Grid
        container
        display="flex"
        flexDirection={{ xs: 'row', md: 'column' }}
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: { xs: '24px', md: '64px' },
          background: COLORS.WHITE,
          borderRadius: { xs: '12px', md: '24px' },
        }}
      >
        <Grid item>
          <img src="/images/new-goal.svg" alt="" style={{ width: '300px', height: '300px' }} />
        </Grid>
        {props.wasRejected && renderRejectedInfo()}
        <Grid
          item
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="nowrap"
          sx={{
            background: 'rgba(126, 163, 86, 0.12)',
            borderRadius: '6px',
            padding: '12px 26px',
            mt: '64px',
          }}
        >
          <CheckCircleIcon
            sx={{
              width: '20px',
              height: '20px',
              mr: '14px',
              color: COLORS.SECONDARY_GREEN,
            }}
          />
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={24}
              sx={{ bgcolor: 'rgba(126, 163, 86, 0.12)', borderRadius: '6px' }}
            />
          ) : (
            <Typography variant="bodyMedium" sx={{ color: COLORS.MAIN_DARK, whiteSpace: 'pre-line' }}>
              {t('dashboard.youHaveInYourWallet', {
                amount: formatNumber(Number(bankItem?.userBankDetails.amount) || 0, i18n.language, 0),
              })}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          spacing="32px"
          sx={{ mt: '32px' }}
        >
          {/* TODO: Hidden till implement Goals */}
          {/* <Grid item sx={{ width: { xs: "100%", md: "auto" } }}> */}
          {/*  <MButton */}
          {/*    variant="contained" */}
          {/*    onClick={newGoal} */}
          {/*    id="AddNewGoal" */}
          {/*    buttonProps={{ */}
          {/*      startIcon: <AddSharpIcon />, */}
          {/*      sx: { */}
          {/*        width: { xs: "100%", md: "auto" }, */}
          {/*        height: { xs: "40px", md: 60 }, */}
          {/*        borderRadius: "80px", */}
          {/*      }, */}
          {/*    }} */}
          {/*  > */}
          {/*    {t("dashboard.newGoal")} */}
          {/*  </MButton> */}
          {/* </Grid> */}
          <Grid item sx={{ width: { xs: '100%', md: 'auto' } }} display={portfolio?.id ? 'block' : 'none'}>
            <MButton
              variant="contained"
              onClick={completeSubscription}
              id="SubscribeProfileButton"
              buttonProps={{
                startIcon: <AddSharpIcon />,
                sx: {
                  width: { xs: '100%', md: 'auto' },
                  height: { xs: '50px', md: 60 },
                  borderRadius: '80px',
                  boxShadow: 'none',
                  backgroundColor: COLORS?.X_DARK_BLUE,
                },
              }}
            >
              {t('dashboard.invest')}
            </MButton>
          </Grid>
        </Grid>
      </Grid>
      <SubscribePortfolioDialog
        open={openSubscribeDialog}
        closeDialog={closeDialog}
        onSuccessButtonClick={onSuccessButtonClick}
        onClose={onCloseSubscribeDialog}
        error={tApiError(subscribeToPortfolio.error)}
        isLoading={subscribeToPortfolio.isLoading}
      />
    </CenterContent>
  );
};

export default StartInvestingProfile;
