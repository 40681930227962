import React from 'react';
import { Slider } from '@mui/material';
import styled from '@emotion/styled';
import COLORS from '../../../../constants/colors';
// import './Slider.scss';

const CustomSlider = styled(Slider)<{ showThumb: boolean; sliderColor: string; p?: string; h?: string }>(
  ({ showThumb, sliderColor, p }) => ({
    color: sliderColor,
    height: 6,
    padding: `${p} !important` || 'unset',
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      display: showThumb ? 'block' : 'none',
      height: 20,
      width: 36,
      backgroundColor: 'white',
      borderRadius: '8px',
      border: `4px solid ${sliderColor}`,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-rail': {
      height: 4,
      backgroundColor: '#E4E7EC',
    },
  }),
);

export const sliderColors = [
  COLORS.X_DARK_BLUE,
  COLORS.SECONDARY_GREEN,
  COLORS.TURQUOISE,
  COLORS.PURPLE,
  COLORS.CYAN,
  COLORS.ACCENT_CYAN,
  COLORS.DARK_GREY,
  COLORS.GREEN,
];

interface SliderProps {
  value: number;
  max?: number;
  min?: number;
  onChange?: (value: number) => void;
  color?: string;
  padding?: string;
  height?: string;
  showThumb?: boolean;
  disabled?: boolean;
}

const MadkholSlider: React.FC<SliderProps> = ({
  value,
  padding,
  height,
  onChange,
  max = 100,
  min = 0,
  disabled = false,
  showThumb = false,
  color = COLORS.X_DARK_BLUE,
}) => {
  const onValueChange = (e: any) => {
    if (disabled || !onChange) {
      return;
    }
    if (e.target.value > max) {
      onChange(max);
      return;
    }
    onChange(e.target.value);
  };

  return (
    <div style={{ height: height || 'auto' }} className={`slider ${disabled ? 'slider-disabled' : ''}`}>
      <CustomSlider
        value={value}
        onChange={onValueChange}
        max={100}
        min={min}
        sliderColor={color}
        showThumb={showThumb}
        p={padding}
      />
    </div>
  );
};

export default MadkholSlider;
