export enum ConfigKey {
  CACHE_ALLOCATION_PERCENTAGE = 'cache_allocation_percentage',
  WEB_APP_VERSION = 'web_app_version',
  UPLOAD_FLOW_ENABLED = 'upload_flow_enabled',
}

export type AppConfig = {
  key: ConfigKey;
  value: string;
};
