import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Link } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import { useFindProfileDataQuery } from '../../../redux/features/auth/auth.apiSlice';
import PaymentSuccessfulScreen from '../../../shared/PaymentSuccessfulScreen';
import COLORS from '../../../constants/colors';
import CongratulationModal from './CongratulationModal';
import { useTypedDispatch } from '../../../redux/store';
import { clearWallet, fetchAnbMasterardConfigAction } from '../../../store_deprecated/actions/walletActions';
import { madkhoulBankInfoSelector } from '../../../store_deprecated/selectors/walletSelectors';
import Deposit, { DepositProps } from '../MyWallet/Deposit';
import START_INVEST_STEPS from '../../../assets/data/start-invest-steps-page.json';
import { setCongratulationModalAppearBefore, getCongratulationModalAppearBefore } from '../../../helpers/LocalStorage';

const ActivatedProfile: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useTypedDispatch();
  const { data } = useFindProfileDataQuery();
  const [showSuccessfulToast, setShowSuccessfulToast] = useState(false);
  const showCongratulationModal = getCongratulationModalAppearBefore();
  const [openCongratulationModal, setOpenCongratulationModal] = useState<boolean>(
    /* @ts-ignore */ !showCongratulationModal || showCongratulationModal !== data?.userId,
  );
  const [depositConfig, setDepositConfig] = useState<DepositProps | null>(null);

  const bankItem = useSelector(madkhoulBankInfoSelector);
  const beneficiaryName = bankItem?.user?.fullName;
  const accountNumber = bankItem?.userBankDetails?.accountNumber || '';
  const ibanNumber = bankItem?.iban || '';

  const [successfulDialog, setOpenSuccessfulDialog] = React.useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  const closeDialog = () => {
    window.history.pushState({}, '', window.location.pathname);

    setOpenSuccessfulDialog(false);
  };

  useEffect(() => {
    if (orderId) setOpenSuccessfulDialog(true);
  }, [orderId]);

  useEffect(() => {
    window.anbMastercardCheckoutCancelCallback = () => {
      console.log('ANB Mastercard checkout process was canceled');
    };
    const fetchInitialData = async () => {
      try {
        const anbMastercardConfig = await dispatch(fetchAnbMasterardConfigAction());

        if (anbMastercardConfig?.url && anbMastercardConfig?.version) {
          setDepositConfig({
            anbMastercardCheckoutBaseUrl: anbMastercardConfig.url,
            anbMastercardCheckoutVersion: anbMastercardConfig.version,
            // openCongratulationModal,
          });
        }
      } catch (e) {
        console.log('Some error');
      }
    };

    fetchInitialData();

    return () => {
      dispatch(clearWallet());
      delete window.anbMastercardCheckoutCancelCallback;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: 'calc(100vh - 64px - 200px)',
        display: 'block',
        position: 'relative',
        padding: { xs: '18px', md: '32px' },
        background: COLORS.WHITE,
        borderRadius: { xs: '12px', md: '24px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: '20px',
        }}
      >
        <Grid
          container
          display="flex"
          flexDirection={{ xs: 'row', md: 'column' }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            sx={{
              width: { xs: '100px', md: '200px' },
              height: { xs: '100px', md: '200px' },
              mb: '32px',
            }}
          >
            <img src="/images/museum.png" alt="museum" style={{ width: '100%' }} />
          </Grid>
          <Grid
            item
            container
            sx={{
              padding: { xs: '26px', md: '12px 26px' },
              maxWidth: '300px',
              display: 'contents',
            }}
          >
            <Typography
              variant="h1"
              color={COLORS.MAIN_DARK}
              textAlign="center"
              paragraph
              sx={{
                marginBottom: 1,
                fontSize: { xs: '20px', md: '24px' },
                lineHeight: { xs: '28px', md: '32px' },
              }}
            >
              {t('dashboard.congratulation.page.hero')}
            </Typography>
            <ol>
              {START_INVEST_STEPS[i18n.language as 'en' | 'ar'].map((step: any) => (
                <li key={step.id}>
                  <Typography
                    variant="h5"
                    color={COLORS.MAIN_DARK}
                    paragraph
                    sx={{
                      marginBottom: 1,
                      width: '95%',
                      fontSize: { xs: '16px', md: '18px' },
                      lineHeight: { xs: '28px', md: '32px' },
                    }}
                  >
                    {step.text}
                  </Typography>
                </li>
              ))}
            </ol>
          </Grid>
        </Grid>
      </Box>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        sx={{
          marginBottom: '12px',
          alignItems: { xs: 'flex-start', md: 'center' },
        }}
      >
        <Grid item xs={8} textAlign="left">
          <Typography
            variant="bodySmall"
            sx={{
              color: COLORS.LIGHT_GRAY,
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            {t('bankName')}
          </Typography>
          <Typography
            sx={{
              color: COLORS.MAIN_DARK,
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
            variant="h6"
          >
            {t('arabNationalBank')}
          </Typography>
        </Grid>
        {/* <Grid item xs={4} textAlign="right" alignItems="bottom">
            <Link
              component="button"
              color="inherit"
              variant="body2"
              onClick={() => {
                navigator.clipboard.writeText("MMMM");
              }}
            >
              {t("dashboard.congratulation.dialog.copy")}
            </Link>
          </Grid> */}
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        sx={{
          marginBottom: '12px',
          alignItems: { xs: 'flex-start', md: 'center' },
        }}
      >
        <Grid item xs={8} textAlign="left">
          <Typography
            variant="bodySmall"
            sx={{
              color: COLORS.LIGHT_GRAY,
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            {t('dashboard.congratulation.dialog.beneficiaryName')}
          </Typography>
          <Typography
            sx={{
              color: COLORS.MAIN_DARK,
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
            variant="h6"
          >
            {beneficiaryName}
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign="right" alignItems="bottom">
          <Link
            component="button"
            color="inherit"
            variant="body2"
            onClick={() => {
              navigator.clipboard.writeText(beneficiaryName);
              setShowSuccessfulToast(true);
            }}
          >
            {t('dashboard.congratulation.dialog.copy')}
          </Link>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        sx={{
          marginBottom: '12px',
          alignItems: { xs: 'flex-start', md: 'center' },
        }}
      >
        <Grid item xs={8} textAlign="left">
          <Typography
            variant="bodySmall"
            sx={{
              color: COLORS.LIGHT_GRAY,
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            {t('dashboard.congratulation.dialog.accountNumber')}
          </Typography>
          <Typography
            sx={{
              color: COLORS.MAIN_DARK,
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
            variant="h6"
          >
            {accountNumber}
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign="right" alignItems="bottom">
          <Link
            component="button"
            color="inherit"
            variant="body2"
            onClick={() => {
              navigator.clipboard.writeText(accountNumber);
              setShowSuccessfulToast(true);
            }}
          >
            {t('dashboard.congratulation.dialog.copy')}
          </Link>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        sx={{
          marginBottom: '12px',
          alignItems: { xs: 'flex-start', md: 'center' },
        }}
      >
        <Grid item xs={8} textAlign="left">
          <Typography
            variant="bodySmall"
            sx={{
              color: COLORS.LIGHT_GRAY,
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            {t('dashboard.congratulation.dialog.ibanNumber')}
          </Typography>
          <Typography
            sx={{
              color: COLORS.MAIN_DARK,
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
            variant="h6"
          >
            {ibanNumber}
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign="right" alignItems="bottom">
          <Link
            component="button"
            color="inherit"
            variant="body2"
            onClick={() => {
              navigator.clipboard.writeText(ibanNumber);
              setShowSuccessfulToast(true);
            }}
          >
            {t('dashboard.congratulation.dialog.copy')}
          </Link>
        </Grid>
      </Grid>
      {depositConfig ? (
        <Grid item container columnSpacing="24px">
          <Grid item xs={12} sx={{ mb: { xs: '12px', md: 'unset' } }}>
            <Deposit
              {...depositConfig}
              anbMastercardCheckoutCancelCallBack="anbMastercardCheckoutCancelCallBack"
              page="dashboard"
            />
          </Grid>
        </Grid>
      ) : null}
      <Typography
        sx={{
          marginTop: 3,
          color: COLORS.LIGHT_GRAY,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '24px',
        }}
        variant="h6"
      >
        {t('dashboard.congratulation.dialog.note')}
      </Typography>
      <Snackbar
        sx={{
          maxWidth: 'unset',
          minWidth: 'unset',
          width: 'fit-content',
          m: 'auto',
          '& .MuiPaper-root': {
            maxWidth: 'unset',
            minWidth: 'unset',
            width: 'fit-content',
            m: 'auto',
          },
        }}
        message={t('dashboard.congratulation.dialog.copied')}
        open={showSuccessfulToast}
        onClose={() => setShowSuccessfulToast(false)}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
      <CongratulationModal
        open={openCongratulationModal}
        handleClose={() => {
          setOpenCongratulationModal(false);
          /* @ts-ignore */
          setCongratulationModalAppearBefore(data?.userId);
        }}
        beneficiaryName={bankItem?.user?.fullName}
        accountNumber={bankItem?.userBankDetails?.accountNumber || ''}
        ibanNumber={bankItem?.iban || ''}
        depositConfig={depositConfig}
      />
      <Dialog open={successfulDialog} onClose={closeDialog} disableScrollLock>
        <Box sx={{ padding: '32px 24px' }}>
          <PaymentSuccessfulScreen onSuccessButtonClick={closeDialog} orderId={orderId || ''} />
        </Box>
      </Dialog>
    </Box>
  );
};

export default ActivatedProfile;
