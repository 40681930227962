import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import COLORS from '../../../constants/colors';
import { UserStatus } from '../../../constants/user';
import {
  useFindProfileDataQuery,
  useLazyGetIamAuthorizationUrlQuery,
} from '../../../redux/features/auth/auth.apiSlice';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { useGetAppConfigQuery } from '../../../redux/features/config/config.apiSlice';
import { fetchUserPreferenceByType } from '../../../redux/modules/userPreference/userPreference.actions';
import { userPreferenceByTypeSelector } from '../../../redux/modules/userPreference/userPreference.selectors';
import { locations } from '../../../routes/locations';
import Loader from '../../../shared/Loader';
import { ReduxState, useTypedDispatch } from '../../../redux/store';
import { findProfileData } from '../../../store_deprecated/actions/authActions';
import { fetchAttachedDocuments } from '../../../store_deprecated/actions/docsActions';
import {
  findPortfolioLatestStatistics,
  findSubscribedPortfolio,
} from '../../../store_deprecated/actions/portfolioActions';
import { fetchLastSurveyResult } from '../../../store_deprecated/actions/surveyActions';
import { findUserSignedAgreement } from '../../../store_deprecated/actions/userAgreementActions';
import { fetchBankInfo } from '../../../store_deprecated/actions/walletActions';
import { agreementSignedSelector } from '../../../store_deprecated/selectors/agreementSelectors';
import { getDocsDataSelector } from '../../../store_deprecated/selectors/docsSelectors';
import { getSubscribedPortfolioSelector } from '../../../store_deprecated/selectors/portfolioSelectors';
import { getSurveyResult } from '../../../store_deprecated/selectors/surveySelectors';
import { madkhoulBankInfoSelector } from '../../../store_deprecated/selectors/walletSelectors';
import { DocumentTypes } from '../../../store_deprecated/types/docsTypes';
import { SubscribedPortfolioStatus, SubscriptionStatus } from '../../../store_deprecated/types/portfolioTypes';
import { UserAgreementName } from '../../../store_deprecated/types/userAgreementTypes';
import { ConfigKey } from '../../../types/config';
import { UserPreferenceTypeEnum } from '../../../types/userPreference';
import ActivatedProfile from './ActivatedProfile';
import CompleteProfile from './CompleteProfile';
import InactiveProfile from './InactiveProfile';
import ModeratePortfolio from './ModeratePortfolio';
import PortfolioPending from './PortfolioPending';
import ReviewProfile from './ReviewProfile';
import StartInvestingProfile from './StartInvestingProfile';

const DashboardView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(authSelector);
  const isEnglish = i18n.language === 'en';
  const [initialized, setInitialized] = useState(false);
  const [doesUserRedeemAll, setDoesUserRedeemAll] = useState(false);
  const bankItem = useSelector(madkhoulBankInfoSelector);
  const subscribedPortfolio = useSelector(getSubscribedPortfolioSelector);
  const docsDataState = useSelector(getDocsDataSelector);
  const surveyResult = useSelector(getSurveyResult);

  const { data } = useFindProfileDataQuery();
  const [getIamAuthorizationUrl] = useLazyGetIamAuthorizationUrlQuery();

  const { data: appConfigUploadFlow, isLoading: isAppConfigUploadFlowLoading } = useGetAppConfigQuery(
    ConfigKey.UPLOAD_FLOW_ENABLED,
  );
  const uploadFlowEnabled = JSON.parse(appConfigUploadFlow?.value || 'true');

  const signAccountOpeningAgreementDate = useSelector((state: ReduxState) =>
    agreementSignedSelector(state, UserAgreementName.ACCOUNT_OPENING_AGREEMENT),
  );
  const { userPreference: savedRecommendedPortfolio } = useSelector((state: ReduxState) =>
    userPreferenceByTypeSelector(state, UserPreferenceTypeEnum.RECOMMENDED_PORTFOLIO),
  );

  const selfieDoc = docsDataState?.data?.find((a) => a.documentType.type === DocumentTypes.SELFIE);
  const idDoc = docsDataState?.data?.find((a) => a.documentType.type === DocumentTypes.VERIFICATION_DOCUMENT);
  const docsUploaded = uploadFlowEnabled ? Boolean(selfieDoc && idDoc) : true;
  const surveyPassed = Boolean(surveyResult?.category?.id);

  const lastUpdated = moment(user?.updatedAt)
    .locale(i18n.language)
    .format('hh:mm A - MMM Do YYYY');
  const completePercent =
    [data?.id, docsUploaded && signAccountOpeningAgreementDate && data?.confirmDate, surveyPassed].filter(Boolean)
      .length *
      25 +
    25;
  const isNotCompleted = !docsUploaded || !signAccountOpeningAgreementDate || !data?.confirmDate || !surveyPassed;
  const isJustActivated = user?.status?.name === UserStatus.ACTIVE && !bankItem?.userBankDetails?.amount;
  const isReadyToStartInvesting =
    user?.status?.name === UserStatus.ACTIVE && bankItem && bankItem?.userBankDetails?.amount > 0;

  const onComplete = async () => {
    if (!data?.id) {
      const { url } = await getIamAuthorizationUrl('en' || i18n.language).unwrap();

      if (url) {
        window.open(url, '_self');
        return;
      }

      return alert("Nafaz is disabled! Please update the KYC data in admin and change the user's status");
    }

    if (data?.id && !data?.confirmDate) {
      return navigate(locations.nafazData());
    }

    if (!surveyPassed) {
      return navigate(locations.survey());
    }

    if (!savedRecommendedPortfolio) {
      return navigate(`${locations.riskSurvey()}?page=recommendedPortfolios`);
    }

    if (!docsUploaded || !signAccountOpeningAgreementDate) {
      return navigate(locations.docs(), {
        state: { step: docsUploaded ? 1 : 0 },
      });
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await Promise.all([
          dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.RECOMMENDED_PORTFOLIO)),
          dispatch(findProfileData()),
          dispatch(fetchBankInfo()),
          dispatch(findPortfolioLatestStatistics()),
          dispatch(findSubscribedPortfolio()),
          dispatch(fetchAttachedDocuments()),
          dispatch(fetchLastSurveyResult()),
          dispatch(findUserSignedAgreement(UserAgreementName.ACCOUNT_OPENING_AGREEMENT)),
        ]);
      } catch (e) {
        console.log('Some error');
      }
      setInitialized(true);
    };

    fetchInitialData();

    const matchMdAndAboveView = window.matchMedia('(min-width: 600px)');

    document.getElementsByTagName('main')[0].getElementsByTagName('div')[0].style.display = matchMdAndAboveView.matches
      ? 'block'
      : 'none';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isRedeemAll = !!subscribedPortfolio?.userPortfolios
      ?.map((i) => i.userPortfolioRequests)
      .flat()
      .filter((i) => i.status.name === SubscriptionStatus.IN_PROGRESS && i.changes.redeemAll).length;

    setDoesUserRedeemAll(isRedeemAll);
  }, [subscribedPortfolio]);

  const renderContent = () => {
    if (isNotCompleted) {
      return <CompleteProfile percent={completePercent} onComplete={onComplete} />;
    }

    if (user?.status?.name && [UserStatus.PENDING, UserStatus.MANUAL_CHECK_REQUIRED].includes(user.status.name)) {
      return <ReviewProfile />;
    }

    if (user?.status?.name === UserStatus.INACTIVE) {
      return <InactiveProfile />;
    }

    if (isJustActivated) {
      return <ActivatedProfile />;
    }

    if (isReadyToStartInvesting) {
      return <StartInvestingProfile />;
    }
  };

  const renderPortfolioContent = () => {
    if (subscribedPortfolio?.status?.name === SubscribedPortfolioStatus.ACCEPTED) {
      return <ModeratePortfolio />;
    }

    if (subscribedPortfolio?.status?.name === SubscribedPortfolioStatus.PENDING) {
      return <PortfolioPending />;
    }

    if (subscribedPortfolio?.status?.name === SubscribedPortfolioStatus.REJECTED) {
      return <StartInvestingProfile wasRejected />;
    }
  };

  if (!initialized || isAppConfigUploadFlowLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ padding: { xs: '0 32px', md: '32px 32px 0 32px' } }} maxWidth="100%">
      <Grid container display="flex" flexDirection="column">
        <Grid item container display="flex" flexDirection="column">
          <Typography
            variant="h6"
            id="welcomeHeader"
            sx={{
              textTransform: 'capitalize',
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '28px',
              color: COLORS.PRIMARY_BLUE,
            }}
          >
            {t('dashboard.welcome')}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              fontWeight: 600,
              fontSize: { xs: '20px', md: '36px' },
              lineHeight: '44px',
              color: COLORS.MAIN_DARK,
            }}
          >
            {(isEnglish
              ? `${data?.firstNameEn} ${data?.familyNameEn}`
              : `${data?.firstNameAr} ${data?.familyNameAr}`) || user?.fullName}
          </Typography>
          <Typography
            variant="bodySmall"
            sx={{
              fontWeight: 400,
              fontSize: { xs: '14px', md: '16px' },
              lineHeight: '24px',
              color: COLORS.LIGHT_GRAY,
            }}
          >
            {t('dashboard.lastUpdated')}:&nbsp;{lastUpdated}
          </Typography>
        </Grid>
        {Boolean(subscribedPortfolio?.id) && !doesUserRedeemAll && (
          <Grid item sx={{ marginTop: '28px' }} maxWidth="100%">
            {renderPortfolioContent()}
          </Grid>
        )}
        {!subscribedPortfolio?.id && !doesUserRedeemAll && (
          <Grid item sx={{ marginTop: '28px' }} maxWidth="100%">
            {renderContent()}
          </Grid>
        )}

        {doesUserRedeemAll && (
          <Grid item sx={{ marginTop: '28px' }} maxWidth="100%">
            <PortfolioPending />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DashboardView;
