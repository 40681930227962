import { Logout } from './authTypes';

export const GET_USER_SIGNED_AGREEMENT_REQUEST = 'GET_USER_SIGNED_AGREEMENT_REQUEST';
export const GET_USER_SIGNED_AGREEMENT_SUCCESS = 'GET_USER_SIGNED_AGREEMENT_SUCCESS';
export const GET_USER_SIGNED_AGREEMENT_FAIL = 'GET_USER_SIGNED_AGREEMENT_FAIL';

export const SIGN_USER_AGREEMENT_REQUEST = 'SIGN_USER_AGREEMENT_REQUEST';
export const SIGN_USER_AGREEMENT_SUCCESS = 'SIGN_USER_AGREEMENT_SUCCESS';
export const SIGN_USER_AGREEMENT_FAIL = 'SIGN_USER_AGREEMENT_FAIL';
export const CLEAR_USER_AGREEMENT = 'CLEAR_AGREEMENT';

export enum UserAgreementName {
  ACCOUNT_OPENING_AGREEMENT = 'account_opening_agreement',
  CHANGE_ALLOCATION_AGREEMENT = 'change_allocation_agreement',
}

export type UserAgreement = {
  id: number;
  name: UserAgreementName;
};

export type UserSignAgreement = {
  id: number;
  agreement: UserAgreement;
  createdAt: Date;
  updatedAt: Date;
};

export type AgreementData = {
  [key in UserAgreementName]?: Date;
};

export type Agreement = {
  data: AgreementData;
  error: string;
  isLoading: boolean;
  isErrorExist: boolean;
};

export interface RequestGetUserSignedAgreement {
  type: typeof GET_USER_SIGNED_AGREEMENT_REQUEST;
}

export interface RequestGetUserSignedAgreementSuccess {
  type: typeof GET_USER_SIGNED_AGREEMENT_SUCCESS;
  payload: UserSignAgreement;
}

export interface RequestGetUserSignedAgreementFail {
  type: typeof GET_USER_SIGNED_AGREEMENT_FAIL;
  payload: string;
}

export interface RequestSignUserAgreement {
  type: typeof SIGN_USER_AGREEMENT_REQUEST;
}

export interface RequestSignUserAgreementSuccess {
  type: typeof SIGN_USER_AGREEMENT_SUCCESS;
  payload: UserAgreementName;
}

export interface RequestSignUserAgreementFail {
  type: typeof SIGN_USER_AGREEMENT_FAIL;
  payload: string;
}

export interface ClearUserAgreement {
  type: typeof CLEAR_USER_AGREEMENT;
}

export type UserAgreementAction =
  | RequestGetUserSignedAgreement
  | RequestGetUserSignedAgreementSuccess
  | RequestGetUserSignedAgreementFail
  | RequestSignUserAgreement
  | RequestSignUserAgreementSuccess
  | RequestSignUserAgreementFail
  | Logout
  | ClearUserAgreement;

export type UserAgreementState = Readonly<{
  agreement: Agreement;
}>;
