import { createSelector } from '@reduxjs/toolkit';
import { ReducerState } from '../../store.types';
import { getAccessToken } from '../../../helpers/LocalStorage';

export const authSelector = ({ auth }: ReducerState) => auth;
export const authUserSelector = createSelector(authSelector, ({ user }) => user);
export const authAccessTokenSelector = createSelector(authSelector, ({ accessToken }) => accessToken);
export const selectIsLoggedIn = createSelector(
  ({ auth }: ReducerState) => auth.isLoggedIn,
  (isLoggedIn) => isLoggedIn || getAccessToken(),
);
