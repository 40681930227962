enum NAFAZ_USER_TYPE {
  NATIONAL_ID = 'nationalId',
  RESIDENT_ID = 'residentId',
  VISITOR_ID = 'visitorId',
  VISITOR_WITH_OMRAH_VISA_ID = 'visitorWithOmrahVisaId',
}

const mapIdToUserType: Record<string, NAFAZ_USER_TYPE> = {
  '^1': NAFAZ_USER_TYPE.NATIONAL_ID,
  '^2': NAFAZ_USER_TYPE.RESIDENT_ID,
  '^[3-4]': NAFAZ_USER_TYPE.VISITOR_ID,
  '^5': NAFAZ_USER_TYPE.VISITOR_WITH_OMRAH_VISA_ID,
};

export const defineNafazUserType = (id: string) => {
  const items = Object.entries(mapIdToUserType);

  for (const [reg, type] of items) {
    if (new RegExp(reg).test(id)) {
      return type;
    }
  }

  return '-';
};
