import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  BarController,
  BarElement,
} from 'chart.js';

export { doughnutLabelsLine } from './doughnutLabelsLine';
export { gaugeArrow } from './gaugeArrow';
export { goalDetails } from './goalDetails';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement,
  BarController,
  BarElement,
);
