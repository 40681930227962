export const MARITAL_STATUS: Record<string, { label: string; value: string }[]> = {
  en: [
    {
      label: 'Single',
      value: 'SINGLE',
    },
    {
      label: 'Married',
      value: 'MARRIED',
    },
    {
      label: 'Widowed',
      value: 'WIDOWED',
    },
    {
      label: 'Divorced',
      value: 'DIVORCED',
    },
  ],
  ar: [
    {
      label: 'أعزب (عزباء)',
      value: 'SINGLE',
    },
    {
      label: 'متزوج (متزوجة)',
      value: 'MARRIED',
    },
    {
      label: 'أرمل (أرملة)',
      value: 'WIDOWED',
    },
    {
      label: 'مطلق (مطلقة)',
      value: 'DIVORCED',
    },
  ],
};
