import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import _camelcase from 'lodash.camelcase';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BRANDS } from '../../../constants/brands';
import COLORS from '../../../constants/colors';
import { locations } from '../../../routes/locations';
import Breadcrumb from '../../../shared/Breadcrumb';
import Loader from '../../../shared/Loader';
import MarketPerformanceChart from '../../../shared/MarketPerformanceChart';
import MButton from '../../../shared/MButton';
import { useTypedDispatch } from '../../../redux/store';
import {
  clearIndexFund,
  findFundChartData,
  findIndexFundData,
  findUserIndexFunds,
} from '../../../store_deprecated/actions/indexFundActions';
import {
  getFundChartDataSelector,
  getIndexFundSelector,
  getUserIndexFundsIsLoadingSelector,
  getUserIndexFundsSelector,
} from '../../../store_deprecated/selectors/indexFundSelectors';
import {
  getRecommendedPortfolioSelector,
  getSubscribedPortfolioSelector,
} from '../../../store_deprecated/selectors/portfolioSelectors';
import { FundSource } from '../../../store_deprecated/types/indexFundTypes';
import { round2Format, round4Format } from '../../../utils/number';
// import MarketPerformanceBarChart from "../../../shared/MarketPerformanceBarChart";
import DisclaimerDialog from './DisclaimerDialog';
import { SubscribedPortfolio } from '../../../store_deprecated/types/portfolioTypes';
import FundPerformanceChart from './FundPerformanceChart';

interface CustomizedState {
  from?: string;
  portfolio?: SubscribedPortfolio;
}

const FundDetailsView: React.FC = () => {
  const { t, i18n } = useTranslation();
  // const matchMdAndAboveView = useMediaQuery("(min-width:600px)");
  const id = Number(useParams().id);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const state = (location.state as CustomizedState) || {};
  const isEnglish = i18n.language === 'en';
  const [openDisclaimerDialog, setOpenDisclaimerDialog] = React.useState(false);

  const fromDashboard = (state.from || '').includes('dashboard');

  const { data, error: indexFundError, isLoading: indexFundIsLoading } = useSelector(getIndexFundSelector);
  const { data: chartData, error: charDataError, isLoading: charDataIsLoading } = useSelector(getFundChartDataSelector);
  const subscribedPortfolio = useSelector(getSubscribedPortfolioSelector);
  const recommendedPortfolio = useSelector(getRecommendedPortfolioSelector);
  const userIndexFunds = useSelector(getUserIndexFundsSelector);
  const userIndexFundsIsLoading = useSelector(getUserIndexFundsIsLoadingSelector);
  const isAlpacaFund = data?.source?.name === FundSource.ALPACA_MARKET;
  const isLoading = indexFundIsLoading || charDataIsLoading || userIndexFundsIsLoading;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const error = indexFundError || charDataError;
  const objectives = ((isEnglish ? data?.objectiveEn : data?.objectiveAr) || '').split(';');
  const strategy = isEnglish ? data?.assetClass.nameEn : data?.assetClass.nameAr;
  const topHoldings = ((isEnglish ? data?.topHoldings : data?.topHoldingsAr) || '').split(';');
  // eslint-disable-next-line no-nested-ternary
  const portfolio = state.portfolio
    ? state.portfolio
    : fromDashboard
    ? subscribedPortfolio || recommendedPortfolio
    : recommendedPortfolio || subscribedPortfolio;

  const userIndexFund = userIndexFunds.find(
    // @ts-ignore
    (uif) => uif.indexFund.id === data?.id && portfolio.id === uif.subscribedPortfolio.id,
  ) || { numOfUnits: 0 };
  const associatedIndexFund = portfolio?.associatedIndexFunds.find((aif) => aif?.indexFund?.id === data?.id);

  useEffect(() => {
    dispatch(findIndexFundData(id));
    dispatch(findFundChartData(id));
    dispatch(findUserIndexFunds());

    return () => {
      dispatch(clearIndexFund());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const breadcrumbs = [
    // <Link
    //   underline="none"
    //   key="1"
    //   // onClick={() => navigate(locations.pro())}
    //   sx={{ color: COLORS.LIGHT_GRAY, cursor: "pointer" }}
    // >
    //   {t("breadcrumb.completeProfile")}
    // </Link>,
    <Link underline="none" key="1" onClick={() => navigate(-1)} sx={{ color: COLORS.LIGHT_GRAY, cursor: 'pointer' }}>
      {isEnglish ? portfolio?.nameEn : portfolio?.nameAr}
    </Link>,
    <Typography key="2" color="text.primary">
      {strategy}
    </Typography>,
  ];

  const onCloseDisclaimerDialog = async () => {
    setOpenDisclaimerDialog(false);
  };

  const onDisclaimer = () => {
    setOpenDisclaimerDialog(true);
  };

  const onOpenAccount = () => {
    navigate(-1);
  };

  // const onBack = () => {
  //   const backUrl = state?.from ? state?.from : locations.invest();

  //   navigate(backUrl);
  // };

  const goTo = (url: string) => {
    window.open(url, '_blank');
  };

  const renderTopHoldingItem = (item: string, index: number) => {
    if (!item.trim().length) {
      return null;
    }

    return (
      <Box
        key={`top-holdings-${item}-${index}`}
        sx={{
          mt: index === 0 ? 2 : 1,
          mb: { xs: 3, md: 2 },
          mx: '5px',
          minWidth: { xs: 90, md: 90 },
          maxWidth: { xs: 100, md: 150 },
          minHeight: '60px',
          maxHeight: '60px',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <img
          style={{
            width: '100%',
            height: '100%',
            cursor: 'pointer',
            objectFit: 'contain',
          }}
          src={`/images/brands/${BRANDS[item.trim().toLowerCase()]?.image}`}
          alt={item.trim()}
          onClick={() => goTo(BRANDS[item.trim().toLowerCase()]?.url)}
        />
      </Box>
    );
  };

  const renderFundDetails = () => {
    if (!data) {
      return;
    }

    return (
      <Grid key="fund-details" container display="flex" flexDirection="column">
        <Grid item sx={{ mb: 6 }}>
          <Typography component="h1" variant="smallDisplayH">
            {strategy}
          </Typography>
        </Grid>

        <Grid
          item
          container
          component={Paper}
          elevation={0}
          display="flex"
          flexDirection="column"
          sx={{
            borderRadius: '24px',
            paddingX: { xs: 6, md: 8 },
            paddingY: { xs: 6, md: 12 },
            width: '100%',
          }}
        >
          <Grid
            item
            sx={{
              mt: { xs: 4, md: 6 },
              mb: { xs: 4, md: 8 },
              color: COLORS.MAIN_DARK,
            }}
          >
            <Typography variant="h5">{t('fundDetails.investmentDetails')}</Typography>
          </Grid>

          <Grid item container display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="center"
              xs={12}
              sm={4}
              sx={{ mb: { xs: 4, md: 'unset' } }}
            >
              <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                {t('fundDetails.assetClass')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ mt: 2 }}>
                {isEnglish ? data?.asset?.nameEn : data?.asset?.nameAr}
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="center"
              xs={12}
              sm={4}
              sx={{ mb: { xs: 4, md: 'unset' } }}
            >
              <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                {t('fundDetails.shariahCompliant')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ mt: 2 }}>
                {data?.shariaaComplaint ? t('yes') : t('no')}
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="center"
              xs={12}
              sm={4}
              sx={{ mb: { xs: 4, md: 'unset' } }}
            >
              <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                {t('fundDetails.geographicalExposure')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ mt: 2 }}>
                {isEnglish ? data?.geographicalFocus?.nameEn : data?.geographicalFocus?.nameAr}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: { xs: 0, md: 8 } }}
          >
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="center"
              xs={12}
              sm={4}
              sx={{ mb: { xs: 4, md: 'unset' } }}
            >
              <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                {t('fundDetails.valuationFrequency')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ mt: 2 }}>
                {isEnglish ? data?.valuationFrequency?.nameEn : data?.valuationFrequency?.nameAr}
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="center"
              xs={12}
              sm={4}
              sx={{ mb: { xs: 4, md: 'unset' } }}
            >
              <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                {t('fundDetails.riskLevel')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ mt: 2 }}>
                {t(`riskLevel.${_camelcase(data?.riskLevel?.name)}`)}
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="center"
              xs={12}
              sm={4}
              sx={{ mb: { xs: 4, md: 'unset' } }}
            >
              <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                {t('fundDetails.benchmark')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ mt: 2 }}>
                {isEnglish ? data?.benchmark?.nameEn : data?.benchmark?.nameAr}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              mt: { xs: 0, md: 8 },
              display: fromDashboard ? 'flex' : 'none',
            }}
          >
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="center"
              xs={12}
              sm={4}
              sx={{ mb: { xs: 4, md: 'unset' } }}
            >
              <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                {t('fundDetails.units')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ mt: 2 }}>
                {round4Format(userIndexFund?.numOfUnits, i18n.language)}
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="center"
              xs={12}
              sm={4}
              sx={{ mb: { xs: 4, md: 'unset' } }}
            >
              <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                {t('fundDetails.costBase')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ mt: 2 }}>
                {round2Format(associatedIndexFund?.costBase || 0, i18n.language)}
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="center"
              xs={12}
              sm={4}
              sx={{ mb: { xs: 4, md: 'unset' } }}
            >
              <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                {t('fundDetails.todayNav')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ mt: 2 }}>
                {round2Format(data?.netAssetValuePerUnit, i18n.language)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              mt: { xs: 0, md: 8 },
              display: 'flex',
            }}
          >
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="center"
              xs={12}
              sm={4}
              sx={{ mb: { xs: 4, md: 'unset' } }}
            >
              <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                {t('fundDetails.fundCode')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ mt: 2 }}>
                {data?.code || ''}
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="center"
              xs={12}
              sm={4}
              sx={{ mb: { xs: 4, md: 'unset' } }}
            >
              <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                {t('fundDetails.manager')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ mt: 2 }}>
                {isEnglish ? data?.manager?.nameEn : data?.manager?.nameAr}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container display="flex" flexDirection="column" sx={{ mt: { xs: 0, md: 8 } }}>
            <Grid item>
              <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                {t('fundDetails.fundObjective')}
              </Typography>
            </Grid>

            <Grid item container display="flex" flexDirection="column">
              {objectives.map((item, index) => (
                <Grid
                  key={`objective-${item}-${index}`}
                  item
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  sx={{ mt: index === 0 ? 2 : 1 }}
                >
                  <Box
                    sx={{
                      display: objectives.length === 1 ? 'none' : 'unset',
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: COLORS.PRIMARY_BLUE,
                      mr: 2.5,
                    }}
                  >
                    {' '}
                  </Box>
                  <Typography variant="bodyLargeM">{item}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {isAlpacaFund && (
            <Grid item container display="flex" flexDirection="column" sx={{ mt: { xs: 0, md: 8 } }}>
              <Grid item>
                <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                  {t('fundDetails.alpacaActivities')}
                </Typography>
              </Grid>
              <Grid item sx={{ pt: '8px !important', width: '100%' }}>
                <MButton
                  variant="contained"
                  onClick={() => navigate(locations.alpacaActivities())}
                  buttonProps={{
                    sx: {
                      backgroundColor: COLORS.X_DARK_BLUE,
                      padding: '8px 24px',
                      height: { xs: '40px' },
                    },
                  }}
                  typographyProps={{
                    variant: 'bodyMediumB',
                    textAlign: 'center',
                    color: 'white',
                  }}
                >
                  {t('fundDetails.alpacaActivitiesLink')}
                </MButton>
              </Grid>
            </Grid>
          )}

          <Grid item container display="flex" flexDirection="column" sx={{ mt: { xs: 6, md: 8 } }}>
            <Grid item>
              <Typography variant="bodyMedium" sx={{ color: COLORS.LIGHT_GRAY }}>
                {t('fundDetails.topHoldings')}
              </Typography>
            </Grid>

            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                my: { xs: '10px', md: '20px' },
              }}
            >
              {topHoldings.map(renderTopHoldingItem)}
            </Box>
          </Grid>

          <Grid item sx={{ mt: { xs: 0, md: 6 }, width: '100%' }}>
            <MButton
              variant="text"
              fullWidth
              onClick={onDisclaimer}
              typographyProps={{
                sx: {
                  fontSize: { xs: '14px', md: '20px' },
                },
              }}
              buttonProps={{
                sx: {
                  backgroundColor: COLORS.X_LIGHT_CYAN,
                  height: { xs: 50, md: 60 },
                  padding: { xs: '15px', md: '16px 48px' },
                  boxShadow: 'none',
                },
              }}
            >
              {t('fundDetails.disclaimer')}
            </MButton>
          </Grid>
        </Grid>

        {/* <FundPerformanceChart chartData={chartData} /> */}
        <Grid item sx={{ mt: 6, width: '100%' }}>
          <MButton
            variant="contained"
            fullWidth
            onClick={onOpenAccount}
            buttonProps={{
              sx: {
                height: { xs: '50px', md: 60 },
                backgroundColor: COLORS.X_DARK_BLUE,
                backgroundImage: 'none',
              },
            }}
          >
            {t('back')}
          </MButton>
        </Grid>

        {/* <Grid item sx={{ mt: 4, width: "100%" }}>
          <MButton variant="outlined" fullWidth onClick={onBack}>
            {t("back")}
          </MButton>
        </Grid> */}
      </Grid>
    );
  };

  return (
    <Grid sx={{ padding: 8, maxWidth: '1200px', mx: 'auto' }} flexDirection="column" justifyContent="space-between">
      <Grid item sx={{ mb: 6 }}>
        <Breadcrumb breadcrumbs={breadcrumbs} />
      </Grid>

      {isLoading ? <Loader /> : renderFundDetails()}

      <DisclaimerDialog portfolio={portfolio} open={openDisclaimerDialog} onClose={onCloseDisclaimerDialog} />
    </Grid>
  );
};

export default FundDetailsView;
