import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectIsLoggedIn } from '../redux/features/auth/auth.selectors';
import { locations } from './locations';

type PublicRouteProps = {
  children: JSX.Element;
};

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const location = useLocation();
  const { pathname } = useLocation();
  const skipToDashboarIfPages = [locations.login(), locations.signUp(), locations.resetPassword()];
  const canAcessPublicPage = !isLoggedIn || (isLoggedIn && !skipToDashboarIfPages.includes(pathname));

  return canAcessPublicPage ? children : <Navigate to={locations.home()} state={location.state} />;
};

export default PublicRoute;
