import React, { Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import arLocale from 'date-fns/locale/ar-SA';
import enLocale from 'date-fns/locale/en-GB';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import moment from 'moment';
import 'moment/locale/ar';
import * as Sentry from '@sentry/react';
import { initSentry } from '../../helpers/sentry';
import { Error } from '../../pages/user/Error';
import { ltrTheme, rtlTheme, cacheLtr, cacheRtl } from '../../helpers/theme';
import { ScrollToTop } from '../../shared/ScrollToTop';
import { AppRoutes } from '../../routes/AppRoutes';
import LogoutIdleTimer from '../../shared/LogoutIdleTimer';
import { ServiceWorkerWrapper } from '../User/ServiceWorkerWrapper';
import '../../helpers/charts';

initSentry();

const AppView: React.FC = () => {
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery('(max-width:768px)');
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isArabic = i18n.language === 'ar';

  useLayoutEffect(() => {
    const dir = isArabic ? 'rtl' : 'ltr';
    const element = document.body;

    element.setAttribute('dir', dir);
    element.style.direction = dir;
    if (isArabic) {
      element.classList.add('arabic');
      element.classList.remove('english');
    } else {
      element.classList.add('english');
      element.classList.remove('arabic');
    }

    if (isMobile) {
      element.classList.add('mobile');
      element.classList.remove('desktop');
    }

    if (isDesktop) {
      element.classList.add('desktop');
      element.classList.remove('mobile');
    }

    moment.locale(isArabic ? 'ar' : 'en');
  }, [isArabic]);

  return (
    <CacheProvider value={isArabic ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={isArabic ? rtlTheme : ltrTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={isArabic ? arLocale : enLocale}>
          <BrowserRouter>
            <Sentry.ErrorBoundary fallback={<Error />}>
              <Suspense fallback={<div />}>
                <ServiceWorkerWrapper />
                <ScrollToTop />
                <LogoutIdleTimer />
                <AppRoutes isArabic={isArabic} />
              </Suspense>
            </Sentry.ErrorBoundary>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default AppView;
