import React from 'react';
// import { ChartData, ChartOptions } from "chart.js";
// import annotation from "chartjs-plugin-annotation";
// import { Line } from "react-chartjs-2";
// import { goalDetails } from "../../../helpers/charts";

const GoalDetailsChart = () => (
  // const options: ChartOptions<"line"> = {
  //   // responsive: true,
  //   // maintainAspectRatio: false,
  //   // layout: {
  //   //   autoPadding: true,
  //   //   padding: {
  //   //     left: 80,
  //   //     right: 80,
  //   //     top: 0,
  //   //     bottom: 0,
  //   //   },
  //   // },
  //   events: [],
  //   plugins: {
  //     title: {
  //       display: false,
  //     },
  //     legend: {
  //       display: false,
  //     },
  //     tooltip: {
  //       enabled: false,
  //     },
  //     annotation: {
  //       annotations: [{
  //         type: "box",
  //         backgroundColor: "rgba(101, 33, 171, 0.5)",
  //         borderColor: "#9CA3AF",
  //         borderWidth: 4,
  //         borderDash: [4, 18],
  //         drawTime: "afterDatasetsDraw",
  //         xMax: "80",
  //         xMin: "80",
  //         yMax: 80,
  //         yMin: 40,
  //       }, {
  //         type: "box",
  //         backgroundColor: "rgba(101, 33, 171, 0.5)",
  //         borderColor: "#EA9515",
  //         borderWidth: 4,
  //         drawTime: "afterDatasetsDraw",
  //         xMax: "80",
  //         xMin: "80",
  //         yMax: 40,
  //         yMin: 0,
  //       }],
  //     },
  //   },
  //   interaction: {
  //     intersect: false,
  //   },
  //   scales: {
  //     x: {
  //       display: false,
  //     },
  //     y: {
  //       display: false,
  //     },
  //   },
  // };
  //
  // const DATA_COUNT = 4;
  // const labels = [];
  // for (let i = 0; i < DATA_COUNT; ++i) {
  //   labels.push(i.toString());
  // }
  // const data: ChartData<"line"> = {
  //   labels: ["0", "25", "80", "200"],
  //   datasets: [
  //     {
  //       label: "Cubic interpolation",
  //       data: [20, 45, 100, 220],
  //       backgroundColor: "rgba(101, 33, 171, 0.5)",
  //       borderColor: "#9CA3AF",
  //       borderWidth: 20,
  //       fill: false,
  //       tension: 0.4,
  //       pointBorderWidth: 2,
  //       pointRadius: function (context) {
  //         const index = context.dataIndex;
  //
  //         if (index === 0) {
  //           return 19;
  //         } else if (index === 1) {
  //           return 0;
  //         } else if (index === 2) {
  //           return 35;
  //         } else {
  //           return 0;
  //         }
  //       },
  //     },
  //   ],
  // };

  // return (
  //   <Line data={data} options={options} plugins={[goalDetails, annotation]} />
  // );

  <img src="/images/goal-details.png" alt="goal-details" width="100%" />
);
export default GoalDetailsChart;
