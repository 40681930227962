import { AppAction, AppState, TOGGLE_EMAIL_US_POPUP } from '../types/appTypes';
import { LOGOUT } from '../types/authTypes';

const INITIAL_STATE: AppState = {
  isEmailUpVisible: false,
};

/**
 * TODO: We must migrate to `@reduxjs/toolkit`!
 * @deprecated
 */
const appReducer = (state: AppState = INITIAL_STATE, action: AppAction = { type: LOGOUT }): AppState => {
  switch (action.type) {
    case TOGGLE_EMAIL_US_POPUP:
      return { ...state, isEmailUpVisible: !state.isEmailUpVisible };

    case LOGOUT:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default appReducer;
