import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import MButton from '../../../shared/MButton';
import WithdrawDialog from '../../../shared/WithdrawDialog';
import { useTypedDispatch } from '../../../redux/store';
import { resetSubscribedToPortfolio } from '../../../store_deprecated/actions/portfolioActions';
import { doRequestWithdraw } from '../../../store_deprecated/actions/walletActions';
import { withdrawSelector } from '../../../store_deprecated/selectors/walletSelectors';
import COLORS from '../../../constants/colors';

const Withdraw: React.FC = () => {
  const { t } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');
  const dispatch = useTypedDispatch();
  const [showSuccessfulScreen, setShowSuccessfulScreen] = useState(false);
  const [openWithdrawDialog, setOpenWithdrawDialog] = React.useState(false);

  const withdrawData = useSelector(withdrawSelector);

  const onWithdraw = () => {
    setShowSuccessfulScreen(false);
    setOpenWithdrawDialog(true);
  };

  const closeDialog = () => {
    setOpenWithdrawDialog(false);
  };

  const onSuccessButtonClick = () => {
    closeDialog();
    window.location.reload();
  };

  const onCloseWithdrawDialog = async (withdrawAmount?: number) => {
    if (withdrawAmount) {
      const result = (await dispatch(doRequestWithdraw({ amount: withdrawAmount }))) as any;

      if (result) {
        setShowSuccessfulScreen(true);
        dispatch(resetSubscribedToPortfolio());
      }
    } else {
      setOpenWithdrawDialog(false);
      dispatch(resetSubscribedToPortfolio());
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" paddingTop="24px">
        <MButton
          variant="contained"
          onClick={onWithdraw}
          id="WithdrawButton"
          // disabled={isLoading}
          buttonProps={{
            startIcon: <CurrencyExchangeIcon />,
            sx: {
              width: { xs: '100%', md: '100%' },
              height: { xs: '50px', md: 60 },
              borderRadius: '80px',
              boxShadow: 'none',
              backgroundColor: COLORS?.X_DARK_BLUE,
              '&:hover': {
                backgroundColor: COLORS?.X_DARK_BLUE,
              },
            },
          }}
        >
          {t('withdraw')}
        </MButton>
      </Box>
      <WithdrawDialog
        open={openWithdrawDialog}
        onClose={onCloseWithdrawDialog}
        onSuccessButtonClick={onSuccessButtonClick}
        showSuccessfulScreen={showSuccessfulScreen}
        closeDialog={closeDialog}
        error={tApiError(withdrawData.error)}
        isLoading={withdrawData.isLoading}
      />
    </>
  );
};

export default Withdraw;
