import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { useTypedDispatch } from '../../../redux/store';
import {
  createHyperPayCheckoutAction,
  fetchAnbMasterardConfigAction,
  fetchSubscriptions,
} from '../../../store_deprecated/actions/walletActions';
import COLORS from '../../../constants/colors';
import NewCards from './NewCards';
import { subscriptionsIsLoading } from '../../../store_deprecated/selectors/walletSelectors';
import PaymentSuccessfulScreen from '../../../shared/PaymentSuccessfulScreen';
import PaymentFailureScreen from '../../../shared/PaymentFailureScreen';
import PaymentHyperPayProcessingScreen from '../../../shared/PaymentHyperPayProcessingScreen';
import MButton from '../../../shared/MButton';
import AddMoreFundDialog from '../../../shared/AddMoreFundDialog';
import { DepositProps } from './Deposit';

type HyperPayCheckout = {
  id: string;
  result: {
    code: string;
    description: string;
  };
  buildNumber: string;
  timestamp: string;
  ndc: string;
};

const BetaCards: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useTypedDispatch();
  const isLoadingSubscriptions = useSelector(subscriptionsIsLoading);

  const [showSuccessfulScreen, setShowSuccessfulScreen] = React.useState(false);

  const [openCardRegistraionDialog, setOpenCardRegistraionDialog] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
  const [depositConfig, setDepositConfig] = useState<DepositProps | null>(null);

  const { user } = useSelector(authSelector);

  // TODO: remove  after testing
  let displayRecurringButton = false;
  if (!isProduction || (isProduction && user?.id === 3941)) {
    displayRecurringButton = true;
  }

  const closeDialog = () => {
    setOpenCardRegistraionDialog(false);
    // TODO: make sure what we should add here to close the dialog
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const anbMastercardConfig = await dispatch(fetchAnbMasterardConfigAction());

        if (anbMastercardConfig?.url && anbMastercardConfig?.version) {
          setDepositConfig({
            anbMastercardCheckoutBaseUrl: anbMastercardConfig.url,
            anbMastercardCheckoutVersion: anbMastercardConfig.version,
            // openCongratulationModal,
          });
        }
      } catch (e) {
        console.log('Some error');
      }
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccessButtonClick = () => {
    closeDialog();
    window.location.reload();
  };

  const onDeposit = () => {
    setShowSuccessfulScreen(false);
    setOpenCardRegistraionDialog(true);
  };

  React.useEffect(() => {
    if (!openCardRegistraionDialog) {
      setOpenCardRegistraionDialog(false);
      setIsLoading(false);
      setTimeout(() => {
        // delay showSuccessfulScreen as the deposit content is shown for a short time before closing the modal
        setShowSuccessfulScreen(false);
      }, 10);
    }
  }, [openCardRegistraionDialog]);

  React.useEffect(() => {
    dispatch(fetchSubscriptions());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItems = () => (isLoading && isLoadingSubscriptions ? <CircularProgress /> : <NewCards />);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        {/* {displayRecurringButton && (
          <MButton
            variant="contained"
            onClick={onDeposit}
            typographyProps={{
              sx: {
                padding: { xs: '0px', md: '8px 24px' },
                borderRadius: '80px',
                border: 'none',
                color: COLORS.WHITE,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              },
            }}
            buttonProps={{
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
                backgroundColor: COLORS.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS.X_DARK_BLUE,
                },
              },
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            {t('dashboard.newWalletCard.addPaymentMethods')}
          </MButton>
        )} */}
        <Typography
          variant="h2"
          sx={{
            fontWeight: { xs: 600, md: 500 },
            fontSize: { xs: '20px', md: '24px' },
            lineHeight: '44px',
            color: COLORS.BLACK,
          }}
        >
          {t('dashboard.newWalletCard.myPaymentMethods')}
        </Typography>
      </Box>
      <Box sx={{ mb: '24px', width: '100%' }}>{renderItems()}</Box>

      <AddMoreFundDialog
        open={openCardRegistraionDialog}
        onSuccessButtonClick={onSuccessButtonClick}
        showSuccessfulScreen={showSuccessfulScreen}
        closeDialog={closeDialog}
        defaultSelectedMethod="MADA"
        excludedPaymentMethods={['APPLE', 'WALLET']}
        hidePayButton
        shouldShowProductList
      />
    </>
  );
};

export default BetaCards;
