import * as APIs from '../../constants/APIs';
import { AnswerQuestionPayload } from '../../store_deprecated/types/surveyTypes';
import { ApiService } from '../http';

const HTTP = new ApiService();

export async function startSurvey() {
  try {
    return await HTTP.get(APIs.START_SURVEY);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getQuestion(surveyId: number, questionOrder: number) {
  try {
    return await HTTP.get(`${APIs.GET_QUESTION}/${surveyId}`, {
      params: { questionOrder },
    });
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function answerQuestion(payload: AnswerQuestionPayload) {
  try {
    const { surveyResultId, questionId, answer } = payload;
    return await HTTP.post(`${APIs.ANSWER_QUESTION}/${surveyResultId}/${questionId}`, { answer });
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getConfig() {
  try {
    return await HTTP.get(APIs.GET_CLIENT_CONFIG);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getAnsweredQuestion(surveyResultId: number, questionId: number) {
  try {
    return await HTTP.get(`${APIs.GET_ANSWERED_QUESTION}/${surveyResultId}/${questionId}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function finishSurvey(surveyResultId: number) {
  try {
    return await HTTP.get(`${APIs.FINISH_SURVEY}/${surveyResultId}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getLastSurveyResult() {
  try {
    return await HTTP.get(`${APIs.GET_LAST_SURVEY_RESULT}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}
