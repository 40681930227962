import React from 'react';
import './FundSelector.scss';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Fund from './Fund';
import { IndexFund } from '../../../../store_deprecated/types/indexFundTypes';
import COLORS from '../../../../constants/colors';

interface FundSelectorProps {
  indexFunds: IndexFund[];
  selectedFunds: IndexFund[];
  onChange: (indexFunds: IndexFund[]) => void;
  max?: number;
}

const FundSelector: React.FC<FundSelectorProps> = ({ indexFunds, selectedFunds, onChange, max = 5 }) => {
  const { t } = useTranslation();

  const onFundClick = (indexFund: IndexFund) => {
    const idx = selectedFunds.findIndex((sf) => sf.id === indexFund.id);

    if (idx === -1 && selectedFunds.length === max) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    if (idx === -1) {
      onChange([...selectedFunds, indexFund] as IndexFund[]);
    } else {
      const { [idx]: item, ...rest } = selectedFunds;

      onChange(Object.values(rest) as IndexFund[]);
    }
  };

  return (
    <>
      {selectedFunds.length === max && <Typography color={COLORS.RED}> {t('products.exceedWarning')}</Typography>}
      <div className="fund-selector">
        {indexFunds.map((fund) => (
          <Fund
            key={fund.id}
            indexFund={fund}
            onClick={onFundClick}
            checked={!!selectedFunds.find((sf) => sf.id === fund.id)}
          />
        ))}
      </div>
    </>
  );
};

export default FundSelector;
