import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Theme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import Sidebar from '../Sidebar';
import COLORS from '../../../constants/colors';
import { locations } from '../../../routes/locations';
import { getMenuOpen, setMenuOpen } from '../../../helpers/LocalStorage';
import { MenuItems } from '../Sidebar/types';

interface IMobileNavbarProps {
  handleLogout: () => void;
  menuItems: MenuItems;
  theme: Theme;
}

function MobileNavbar({ handleLogout, menuItems, theme }: IMobileNavbarProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { user } = useSelector(authSelector);

  const [open, setOpen] = useState(getMenuOpen() || false);

  const firstName = user?.fullName.split(' ')?.[0] || '';

  const handleDrawerOpen = () => {
    setMenuOpen(true);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setMenuOpen(false);
    setOpen(false);
  };

  const languages = { ar: 'العربية', en: 'English' };
  const language = i18n.language === 'ar' ? languages.en : languages.ar;

  const [isArabic, setIsArabic] = useState<boolean>(i18n.language === 'ar');

  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-account-menu';

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = () => {
    localStorage.setItem('i18nextLng', i18n.language === 'en' ? 'ar' : 'en');
    i18n.changeLanguage(i18n.language === 'en' ? 'ar' : 'en');
    setIsArabic(i18n.language === 'ar');
  };

  const handleProfileClick = () => {
    handleMenuClose();
    navigate(locations.myProfile());
  };

  const renderMenu = (
    <Menu
      disableScrollLock
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfileClick}>{t('menu.profile')}</MenuItem>
      <MenuItem onClick={handleLogout}>{t('menu.logout')}</MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        color="transparent"
        sx={{ boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)', height: '64px' }}
      >
        <Container maxWidth={false}>
          <Toolbar disableGutters sx={{ px: { xs: 0, lg: 28 } }}>
            <Grid item display="flex" justifyContent="center" alignItems="center">
              <IconButton
                color="inherit"
                onClick={handleDrawerOpen}
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
              >
                <MenuIcon sx={{ color: COLORS.MAIN_DARK, fontSize: '24px' }} />
              </IconButton>
              <Typography variant="h6" noWrap component="div" />
            </Grid>
            <Grid item sx={{ flexGrow: 1 }} />
            <Grid container justifyContent="end" alignItems="center">
              <Grid item>
                <Button
                  onClick={handleChangeLanguage}
                  sx={{
                    '&:hover': {
                      backgroundColor: COLORS.TRANSPARENT,
                    },
                  }}
                >
                  <Typography
                    variant={isArabic ? 'body1' : 'cairoM'}
                    color="black"
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {language}
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Typography color="black" sx={{ textTransform: 'capitalize', ml: 5 }}>
                  {firstName.length < 15 ? firstName : firstName.slice(0, 15).concat('...')}
                </Typography>
              </Grid>
              {/* <Grid item> */}
              {/*  <IconButton size="small" edge="end" aria-label="settings"> */}
              {/*    <NotificationsOutlinedIcon */}
              {/*      sx={{ color: COLORS.MAIN_DARK, fontSize: "24px" }} */}
              {/*    /> */}
              {/*  </IconButton> */}
              {/* </Grid> */}
              {/* <Grid item> */}
              {/*  <IconButton size="small" edge="end" aria-label="settings"> */}
              {/*    <SettingsOutlinedIcon */}
              {/*      sx={{ color: COLORS.MAIN_DARK, fontSize: "24px" }} */}
              {/*    /> */}
              {/*  </IconButton> */}
              {/* </Grid> */}
              <Grid item>
                <IconButton
                  size="small"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <Avatar sx={{ width: 32, height: 32 }}>
                    {user?.fullName
                      .split(' ')
                      .slice(0, 2)
                      .map((i: string) => i.charAt(0))
                      .join('')}
                  </Avatar>
                </IconButton>
              </Grid>
            </Grid>
            <Sidebar menuItems={menuItems} open={open} handleDrawerClose={handleDrawerClose} />
          </Toolbar>
        </Container>
      </AppBar>
      {renderMenu}
    </>
  );
}

export default MobileNavbar;
