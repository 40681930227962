export enum KycStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum KycType {
  CITIZEN = 'Citizen',
  RESIDENT = 'Resident',
}

export enum KycGender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export interface KycTypeOption {
  id: number;
  name: KycType;
}

export interface KycStatusOption {
  id: number;
  name: KycStatus;
}

export interface KycGenderOption {
  id: number;
  name: KycGender;
}

export type UserAddress = {
  id: number;
  streetName: string;
  city: string;
  address2: string;
  address1: string;
  additionalNumber: string;
  district: string;
  unitNumber: string;
  isPrimaryAddress: boolean;
  buildingNumber: string;
  postCode: string;
  locationCoordinates: string;
  createdAt: Date;
  updatedAt: Date;
};

export type Kyc = {
  id: number;
  userId: number;
  nationalId: string;
  fullNameEn: string;
  fullNameAr: string;
  firstNameEn: string;
  firstNameAr: string;
  fatherNameAr: string;
  fatherNameEn: string;
  grandFatherNameAr: string;
  grandFatherNameEn: string;
  familyNameEn: string;
  familyNameAr: string;
  nationalityEn: string;
  nationalityAr: string;
  issueLocationAr: string;
  issueLocationEn: string;
  assuranceLevel: string;
  language: string;
  type: KycTypeOption;
  status: KycStatusOption;
  email: string;
  phone: string;
  address: UserAddress;
  birthDate: Date;
  gender: KycGenderOption;
  idIssueDate: Date;
  idExpiryDate: Date;
  iqamaExpiryDate: Date;
  maritalStatus?: string;
  educationLevel?: string;
  companyName?: string;
  jobTitle?: string;
  employerAddress?: string;
  country?: string;
  fiveYearsFinancialSector?: boolean;
  fiveYearsFinancialSectorDescription?: string;
  boardDirectorsMember?: boolean;
  boardDirectorsMemberDescription?: string;
  individualWithHighProfilePoliticalRole?: boolean;
  individualWithHighProfilePoliticalRoleDescription?: string;
  notBeneficialOwner?: boolean;
  notBeneficialOwnerDescription?: string;
  confirmDate: Date | string;
  createdAt: Date | string;
  updatedAt: Date | string;
};
