import React, { useEffect, useState } from 'react';
import { Grid, IconButton, useMediaQuery } from '@mui/material';
import { useTypedDispatch } from '../redux/store';
import { updateProductBrandToInvest } from '../redux/features/products/selectedCardToInvest';

export type PaymentMethodsType = 'MASTER' | 'MADA' | 'VISA' | 'APPLE' | 'WALLET';

const PaymentMethods = ({
  handleClick,
  selectedId,
  excludedPaymentMethods,
}: {
  handleClick?: (paymentName: PaymentMethodsType) => void;
  selectedId: PaymentMethodsType;
  excludedPaymentMethods?: PaymentMethodsType[];
}) => {
  const [_selectedId, setSelectedId] = useState<PaymentMethodsType>('WALLET');

  const breakpoint1 = useMediaQuery('(max-width:414px)');
  const breakpoint2 = useMediaQuery('(max-width:349px)');
  const breakpoint3 = useMediaQuery('(max-width:374px)');
  const breakpoint4 = useMediaQuery('(max-width:344px)');
  let dynamicWidth = '36px';

  if (breakpoint4) {
    dynamicWidth = '18px';
  } else if (breakpoint3) {
    dynamicWidth = '22px';
  } else if (breakpoint2) {
    dynamicWidth = '32px';
  } else if (breakpoint1) {
    dynamicWidth = '28px';
  }

  const dispatch = useTypedDispatch();

  const icons: {
    paymentName: PaymentMethodsType;
    src: string;
    alt: string;
  }[] = [
    { paymentName: 'WALLET', src: '/images/icons/wallet.svg', alt: 'madkhol-portfolio' },
    { paymentName: 'MASTER', src: '/images/icons/master-card.svg', alt: 'madkhol-portfolio' },
    { paymentName: 'MADA', src: '/images/icons/mada.svg', alt: 'madkhol-portfolio' },
    { paymentName: 'VISA', src: '/images/icons/visa.svg', alt: 'madkhol-portfolio' },
    { paymentName: 'APPLE', src: '/images/icons/apple-pay.svg', alt: 'madkhol-portfolio' },
  ];

  const renderIcons = icons.filter((icon) => !excludedPaymentMethods?.includes(icon.paymentName));

  useEffect(() => {
    setSelectedId(selectedId);

    dispatch(updateProductBrandToInvest(selectedId));
  }, [selectedId]);

  const onClick = (paymentName: PaymentMethodsType) => {
    setSelectedId(paymentName);
    handleClick?.(paymentName);
  };

  return (
    <Grid container spacing={2} flexDirection="row" sx={{ marginTop: '10px' }}>
      {renderIcons.map((icon) => (
        <Grid item key={icon.paymentName}>
          <IconButton
            onClick={() => onClick(icon.paymentName)}
            style={{
              borderWidth: 1,
              borderColor: _selectedId === icon.paymentName ? '#7EA256' : 'transparent',
              borderStyle: 'solid',
            }}
          >
            <img className="img img-en" width={dynamicWidth} height={36} src={icon.src} alt={icon.alt} />
          </IconButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default PaymentMethods;
