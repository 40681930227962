import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid, Typography, Box } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import SocialMediaShareDialog from '../../../shared/SocialMediaShareDialog';
import SocialMediaShareDrawer from '../../../shared/SocialMediaShareDrawer';
import MButton from '../../../shared/MButton';
// import { formatNumber } from "../../../utils/number";
import COLORS from '../../../constants/colors';

const InviteFriendView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  const matchMdAndAboveView = useMediaQuery('(min-width:600px)');
  const [openSocialMediaShareDialog, setOpenSocialMediaShareDialog] = useState(false);

  const handleOpen = () => {
    setOpenSocialMediaShareDialog(true);
  };

  const handleClose = () => {
    setOpenSocialMediaShareDialog(false);
  };

  const toggleDrawer = () => {
    setOpenSocialMediaShareDialog(false);
  };

  return (
    <Box sx={{ padding: { xs: '48px 24px', md: '32px 32px 0 32px' } }} maxWidth="100%">
      <Grid container flexDirection="column" rowSpacing="32px">
        <Grid item sx={{ paddingTop: { xs: '0 !important', md: '32px !important' } }} maxWidth="100%">
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontWeight: { xs: 600, md: 500 },
              fontSize: { xs: '14px', md: '24px' },
              lineHeight: { xs: '20px', md: '32px' },
            }}
          >
            {t('invite.title')}
          </Typography>
        </Grid>

        <Grid item maxWidth="100%">
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            sx={{
              padding: { xs: '0', md: '32px' },
            }}
            rowSpacing="24px"
          >
            <Grid
              item
              sx={{
                width: '100%',
                height: { xs: 'auto', md: '500px' },
                textAlign: 'center',
                mb: { xs: '32px', md: '90px' },
              }}
            >
              <img src="/images/invite-friend.svg" alt="" style={{ width: '100%', height: '100%' }} />
              <Box sx={{ mt: '30px' }}>
                <Typography
                  variant={isEnglish ? 'h1B' : 'cairoHeader3Custom'}
                  sx={{
                    color: COLORS.MAIN_DARK,
                    lineHeight: { xs: '28px', md: '44px' },
                  }}
                >
                  <Trans defaults={isEnglish ? 'Refer friends' : 'قم بإحالة الأصدقاء'} />
                  {/* <Trans
                    defaults={
                      isEnglish
                        ? "Refer friends and you will get <1>{{val}}$</1> each"
                        : "قم بإحالة الأصدقاء وستحصل على <1>{{val}} دولارات</1> لكل منهما"
                    } // optional defaultValue
                    values={{ val: 5 }}
                    components={{
                      1: <span style={{ color: "#7EA356" }} />,
                    }}
                  /> */}
                </Typography>
              </Box>
            </Grid>
            {/* <Grid
              item
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                mt: "32px",
                p: "24px",
                borderRadius: "12px",
                width: "100%",
              }}
              component={Paper}
              elevation={0}
            >
              <Typography
                variant="bodyMediumM"
                sx={{ color: COLORS.MAIN_DARK }}
              >
                {t("invite.invitesSent")}
              </Typography>
              <Typography
                variant="bodySmall"
                sx={{
                  fontWeight: isEnglish ? 600 : 700,
                  fontSize: "14px",
                  lineHeight: "24px",
                  textTransform: "capitalize",
                  color: COLORS.PRIMARY_BLUE,
                }}
              >
                {`10 ${t("invite.invites")}`}
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                mt: "32px",
                p: "24px",
                borderRadius: "12px",
                width: "100%",
              }}
              component={Paper}
              elevation={0}
            >
              <Typography
                variant="bodyMediumM"
                sx={{ color: COLORS.MAIN_DARK }}
              >
                {t("invite.accepted")}
              </Typography>
              <Typography
                variant="bodySmall"
                sx={{
                  fontWeight: isEnglish ? 600 : 700,
                  fontSize: "14px",
                  lineHeight: "24px",
                  textTransform: "capitalize",
                  color: COLORS.PRIMARY_BLUE,
                }}
              >
                {`1 ${t("invite.invite")}`}
              </Typography>
            </Grid>
            <Grid
              item
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                mt: "32px",
                p: "24px",
                borderRadius: "12px",
                width: "100%",
              }}
              component={Paper}
              elevation={0}
            >
              <Typography
                variant="bodyMediumM"
                sx={{ color: COLORS.MAIN_DARK }}
              >
                {t("invite.youEarned")}
              </Typography>
              <Typography
                variant="bodySmall"
                sx={{
                  fontWeight: isEnglish ? 600 : 700,
                  fontSize: "14px",
                  lineHeight: "24px",
                  textTransform: "capitalize",
                  color: COLORS.PRIMARY_BLUE,
                }}
              >
                {`${formatNumber(5)} $`}
              </Typography>
            </Grid> */}
            <Grid item sx={{ mt: '8px', width: '100%' }}>
              <MButton
                variant="contained"
                fullWidth
                onClick={handleOpen}
                buttonProps={{
                  sx: {
                    height: { xs: '50px', md: 60 },
                    borderRadius: '80px',
                    boxShadow: 'none',
                    backgroundColor: COLORS?.X_DARK_BLUE,
                    '&:hover': {
                      backgroundColor: COLORS?.X_DARK_BLUE,
                    },
                  },
                }}
              >
                <ShareIcon sx={{ mr: '8px' }} />
                {t('invite.inviteNow')}
              </MButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {matchMdAndAboveView ? (
        <SocialMediaShareDialog open={openSocialMediaShareDialog} onClose={handleClose} />
      ) : (
        <SocialMediaShareDrawer open={openSocialMediaShareDialog} onClose={toggleDrawer} />
      )}
    </Box>
  );
};

export default InviteFriendView;
