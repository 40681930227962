import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const MyGoals: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: '32px' }}>
      <Grid container flexDirection="column" justifyContent="space-between">
        <Grid item>
          <Typography component="h1" variant="h5">
            {t('myGoals.title')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyGoals;
