import { createSlice } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { SubscriptionState } from './subscription.types';
import { saveSubscription } from './subscription.actions';

const initialState: SubscriptionState = {
  saveStatus: fetchStatuses.idle,
  error: null,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveSubscription.pending, (state) => {
      state.error = null;
      state.saveStatus = fetchStatuses.pending;
    });
    builder.addCase(saveSubscription.fulfilled, (state) => {
      state.saveStatus = fetchStatuses.success;
    });
    builder.addCase(saveSubscription.rejected, (state, action) => {
      state.saveStatus = fetchStatuses.rejected;
      state.error = action.error;
    });
  },
});

export const subscriptionReducer = subscriptionSlice.reducer;
