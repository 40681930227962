import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import COLORS from '../constants/colors';
import { madkhoulBankInfoSelector } from '../store_deprecated/selectors/walletSelectors';
import MButton from './MButton';
import FundAmountField from '../components/User/Education/FundAmountField';
import RequestInvestSuccessfulScreen from '../components/User/Invest/RequestInvestSuccessfulScreen';
import { ar2En, formatNumber, round2Format } from '../utils/number';
import { formatValue } from '../helpers/string';

interface WithdrawDialogProps {
  open: boolean;
  isLoading?: boolean;
  error?: string;
  showSuccessfulScreen?: boolean;
  closeDialog?: () => void;
  onSuccessButtonClick?: () => void;
  onClose: (fundAmount?: number) => void;
}

const minWithdraw = 1;

export default function WithdrawDialog(props: WithdrawDialogProps) {
  const {
    open,
    isLoading,
    error,
    showSuccessfulScreen = false,
    closeDialog = () => false,
    onSuccessButtonClick = () => false,
    onClose,
  } = props;
  const { t, i18n } = useTranslation();
  const { t: tApiError } = useTranslation('apiError');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const isWithdrawAmountError = Boolean(withdrawAmount) && +withdrawAmount < minWithdraw;
  const isArabic = i18n.language === 'ar';

  const bankItem = useSelector(madkhoulBankInfoSelector);

  useEffect(() => () => setWithdrawAmount(''), [open]);

  const handleClose = (fundAmount?: number) => {
    if (isLoading) {
      return;
    }
    setWithdrawAmount('');
    return !showSuccessfulScreen ? onClose(fundAmount) : closeDialog();
  };

  const parseValue = (v: string | number) =>
    `${v}`
      // eslint-disable-next-line no-useless-escape
      .replace(/([\.\-\,])(?=.*\1)/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/[^.\-\d]/g, '');

  const handleFieldChange = (event: any) => {
    const initialValue = event.target.value;

    const newValue: string = parseValue(ar2En(initialValue));
    const newValueNumber = +newValue;

    // setValue(newValue);

    if (Number.isNaN(newValueNumber) || newValue.endsWith('.') || !newValue) {
      event.target.value = newValue;

      setWithdrawAmount(event.target.value);

      return;
    }

    event.target.value = `${newValueNumber}`;

    setWithdrawAmount(event.target.value);
  };

  const renderContent = () => (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" rowSpacing="48px">
      <Grid item textAlign="right" alignSelf="end">
        <IconButton onClick={closeDialog}>
          <CloseIcon sx={{ fontSize: '12px', color: COLORS.MAIN_DARK }} />
        </IconButton>
      </Grid>
      <Grid
        item
        sx={{
          paddingTop: { xs: '24px !important', md: '48px !important' },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: 500,
            fontSize: { xs: '24px', md: '44px' },
            lineHeight: { xs: '32px', md: '48px' },
            color: COLORS.MAIN_DARK,
          }}
        >
          {t('withdrawDialog.title')}
        </Typography>
      </Grid>
      <Grid item container flexDirection="row" justifyContent="space-around" alignItems="center">
        <Typography
          variant="bodyMedium"
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {t('withdrawDialog.currentFund')}
        </Typography>
        <Typography
          variant="h1"
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '28px',
            color: COLORS.PRIMARY_BLUE,
          }}
        >
          {t('withdrawDialog.nPerMonth', {
            val: round2Format(Number(bankItem?.userBankDetails?.amount) || 0, i18n.language),
          })}
        </Typography>
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <FundAmountField
          isArabic={isArabic}
          value={formatValue(isArabic, withdrawAmount.toString()).replace(/٬/g, ',')}
          label="withdrawDialog.foundAmountFieldLabel"
          placeholder="withdrawDialog.foundAmountFieldPlaceholder"
          handleChange={handleFieldChange}
        />
        <Typography
          variant="bodySmall"
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            color: COLORS.LIGHT_GRAY,
          }}
        >
          {t('withdrawDialog.note', { val: '1,000' })}
        </Typography>
      </Grid>
      {(isLoading || !!error || isWithdrawAmountError) && (
        <Grid item>
          {isLoading && <CircularProgress />}
          {(!!error || isWithdrawAmountError) && (
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '28px',
                color: COLORS.RED,
              }}
            >
              {error ||
                tApiError('AMOUNT_LESS_THAN_MINIMUM_WITHDRAW_AMOUNT', {
                  val: formatNumber(minWithdraw, i18n.language, 2),
                }) ||
                tApiError('ALREADY_HAVE_REQUEST_IN_PENDING', {
                  val: formatNumber(minWithdraw, i18n.language, 2),
                })}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item container rowSpacing="24px">
        <Grid item width="100%">
          <MButton
            id="ConfirmWithdrawButton"
            variant="contained"
            onClick={() => handleClose(+withdrawAmount)}
            disabled={!withdrawAmount || isWithdrawAmountError || isLoading}
            fullWidth
            buttonProps={{
              sx: {
                backgroundColor: COLORS?.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS?.X_DARK_BLUE,
                },
              },
            }}
          >
            {t('withdrawDialog.confirmButtonTitle')}
          </MButton>
        </Grid>
        <Grid item width="100%">
          <MButton variant="outlined" onClick={() => handleClose()} disabled={isLoading} fullWidth>
            {t('cancel')}
          </MButton>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Dialog open={open} onClose={!showSuccessfulScreen ? closeDialog : onSuccessButtonClick} disableScrollLock>
      <Box sx={{ padding: '32px 24px' }}>
        {!showSuccessfulScreen ? (
          renderContent()
        ) : (
          <RequestInvestSuccessfulScreen onSuccessButtonClick={onSuccessButtonClick} />
        )}
      </Box>
    </Dialog>
  );
}
