import { Logout } from './authTypes';

export const TOGGLE_EMAIL_US_POPUP = 'TOGGLE_EMAIL_US_POPUP';

export interface ToggleEmailUsPopup {
  type: typeof TOGGLE_EMAIL_US_POPUP;
}

export type AppAction = Logout | ToggleEmailUsPopup;

export type AppState = Readonly<{
  isEmailUpVisible: boolean;
}>;
