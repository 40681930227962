import React, { useEffect, useState } from 'react';
import i18nInitial from './helpers/i18n';
import AppView from './components/App/AppView';
import Loader from './shared/Loader';

const App: React.FC = () => {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  useEffect(() => {
    i18nInitial((v) => setTranslationsLoaded(v));
  }, []);

  return translationsLoaded ? <AppView /> : <Loader />;
};

export default App;
