import Button from '@mui/material/Button';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { OrderSide } from '../../../helpers/enums';
import { useTypedDispatch } from '../../../redux/store';
import { clearAlpaca, findAlpacaActivities } from '../../../store_deprecated/actions/alpacaActions';
import { getAlpacaActivitySelector } from '../../../store_deprecated/selectors/alpacaSelectors';
import COLORS from '../../../constants/colors';
import { AlpacaActivity, AlpacaActivityRequestParams } from '../../../store_deprecated/types/alpacaTypes';
import Loader from '../../../shared/Loader';
import { formatNumber } from '../../../utils/number';

const AlpacaActivitiesView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useTypedDispatch();
  const isEnglish = i18n.language === 'en';

  const alpacaActivities = useSelector(getAlpacaActivitySelector);
  const { isLoading } = alpacaActivities;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchAlpacaActivities = useCallback(
    debounce((params: AlpacaActivityRequestParams) => {
      dispatch(findAlpacaActivities(params));
    }, 300),
    [],
  );

  useEffect(() => {
    searchAlpacaActivities({});

    return () => {
      dispatch(clearAlpaca());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderItem = (item: AlpacaActivity) => {
    let amount = 'net_amount' in item ? +item.net_amount : +item.price * +item.qty;

    if ('side' in item) {
      amount = item.side === OrderSide.BUY ? amount : amount * -1;
    }

    return (
      <Grid item xs={12} key={item.id}>
        <Grid
          container
          rowSpacing="8px"
          flexDirection="column"
          component={Paper}
          elevation={0}
          sx={{ p: '24px', borderRadius: '12px' }}
        >
          <Grid item display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Button
              endIcon={<ContentCopyIcon fontSize="small" />}
              sx={{ textTransform: 'none', m: 0, p: 0 }}
              onClick={() => {
                navigator.clipboard.writeText(item.id);
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: '16px', md: '20px' },
                  lineHeight: '24px',
                  color: COLORS.DARK_GREY,
                }}
              >
                {t('alpacaActivities.copyActivityId')}
              </Typography>
            </Button>
          </Grid>
          <Grid item display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography
              variant="h4"
              sx={{
                fontWeight: 500,
                fontSize: { xs: '20px', md: '28px' },
                lineHeight: { xs: '24px', md: '36px' },
                color: COLORS.MAIN_DARK,
              }}
            >
              {t('alpacaActivities.amount', {
                amount: formatNumber(amount, i18n.language, 2),
              })}
            </Typography>
            <Typography
              variant="bodySmall"
              sx={{
                fontWeight: 600,
                fontSize: { xs: '12px', md: '14px' },
                lineHeight: '20px',
                color: COLORS.LIGHT_GRAY,
              }}
            >
              {moment('date' in item ? item.date : item.transaction_time)
                .locale(i18n.language)
                .format('MMM D YYYY hh:mm A')}
            </Typography>
          </Grid>
          <Grid item display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography
              variant="bodySmall"
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '24px',
                color: COLORS.LIGHT_GRAY,
              }}
            >
              {item.activity_type}
              <span>&nbsp;</span>
              <Typography
                variant="bodySmall"
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: COLORS.DARK_GREY,
                }}
              >
                {'description' in item ? item.description : item.symbol}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        padding: { xs: '56px 24px', md: '32px 32px 0 32px' },
        height: 'calc(100vh - 275px)',
        mt: { xs: '-56px', md: 'auto' },
      }}
    >
      <Grid container flexDirection="column" justifyContent="space-between">
        <Grid item>
          <Typography component="h1" variant="h5" sx={{ fontWeight: 500, fontSize: '24px', lineHeight: '32px' }}>
            {t('alpacaActivities.title')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          rowSpacing="18px"
          justifyContent="space-between"
          sx={{
            p: '3px',
            mt: '32px',
            height: '100%',
            maxHeight: 'calc(100vh - 370px)',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '5px !important',
              backgroundColor: 'transparent !important',
              borderRadius: '4px !important',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#111827 !important',
              borderRadius: '4px !important',
            },
          }}
        >
          {isLoading && <Loader />}
          {alpacaActivities.data.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', color: COLORS.LIGHT_GRAY }}
            >
              <Typography variant={isEnglish ? 'smallDisplayH' : 'cairoHeader1B'} gutterBottom>
                {t('noItems')}
              </Typography>
            </Box>
          )}
          {alpacaActivities.data.map(renderItem)}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AlpacaActivitiesView;
