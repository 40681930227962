import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Link } from '@mui/material';

import COLORS from '../constants/colors';
import MButton from './MButton';
import { locations } from '../routes/locations';

interface PaymentFailureScreenScreenProps {
  onSuccessButtonClick: () => void;
  orderId?: string;
  resourcePath?: string,
}

const PaymentFailureScreen = ({ onSuccessButtonClick, orderId, resourcePath }: PaymentFailureScreenScreenProps) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  const navigate = useNavigate();

  const isRecurring = resourcePath?.includes('payment');
  const isCardRegistration = resourcePath?.includes('registration');

  const renderFailureTitle = () => {
    if (isRecurring) {
      return t('recurringPaymentFailedScreen.title');
    }
    if (isCardRegistration) {
      return t('cardRegistrationFailedScreen.title');
    }
    return t('paymentFailedScreen.title');
  };

  return (
    <>
      <Box
        sx={{
          padding: { xs: '12px', md: '48px' },
          maxWidth: { xs: '100%', md: '650px' },
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <img src="/images/icons/failed.svg" alt="mail sent" />
        <Typography
          variant={isEnglish ? 'h6' : 'cairoButtonR'}
          color={COLORS.RED}
          paragraph
          textAlign="center"
          sx={{ marginTop: 8, marginBottom: 8 }}
        >
          {renderFailureTitle()}
          <Link onClick={() => navigate(locations.askForAnAdvise())}>{t('paymentFailedScreen.contactUs')}</Link>
          {t('paymentFailedScreen.tryAgain')}
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          maxWidth: { xs: '100%', md: '650px' },
          margin: 'auto',
          mx: { xs: '24px', md: 'auto' },
        }}
      >
        <MButton
          id="RequestInvestSuccessDoneButton"
          variant="text"
          onClick={onSuccessButtonClick}
          fullWidth
          buttonProps={{
            sx: {
              height: { xs: '50px', md: 60 },
            },
          }}
        >
          {t('paymentSuccessfulScreen.done')}
        </MButton>
      </Box>
    </>
  );
};

export default PaymentFailureScreen;
