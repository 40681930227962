import { Selector } from 'react-redux';
import { GlobalState } from '../types';
import { FundChartData, IndexFund, IndexFundSateType, UserIndexFund } from '../types/indexFundTypes';

export const getIndexFundSelector: Selector<GlobalState, IndexFundSateType> = (state) => state.indexFundState.indexFund;

export const getFundChartDataSelector: Selector<GlobalState, FundChartData> = (state) =>
  state.indexFundState.fundChartData;

export const getIndexFundDataSelector: Selector<GlobalState, IndexFund | null> = (state) =>
  state.indexFundState.indexFund.data;

export const getUserIndexFundsSelector: Selector<GlobalState, UserIndexFund[]> = (state) =>
  state.indexFundState.userIndexFunds.data;

export const getUserIndexFundsIsLoadingSelector: Selector<GlobalState, boolean> = (state) =>
  state.indexFundState.userIndexFunds.isLoading;

export const getIndexFundIsLoadingSelector: Selector<GlobalState, boolean> = (state) =>
  state.indexFundState.indexFund.isLoading;
