import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, Link, Typography } from '@mui/material';
import axios from 'axios';
import { useFindProfileDataQuery } from '../../../redux/features/auth/auth.apiSlice';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { useGetAppConfigQuery } from '../../../redux/features/config/config.apiSlice';
import { useTypedDispatch } from '../../../redux/store';
import { signUserAgreement } from '../../../store_deprecated/actions/userAgreementActions';
import {
  fetchDocumentsType,
  fetchUploadedDocumentURL,
  sendAttachedDocuments,
  uploadNationalId,
  uploadSelfiePicture,
  fetchAttachedDocuments,
} from '../../../store_deprecated/actions/docsActions';
import {
  getDocTypesSelector,
  getNationalIdFailureSelector,
  getSelfieFailureSelector,
  getSendDocsDataFailureSelector,
  getDocsDataSelector,
} from '../../../store_deprecated/selectors/docsSelectors';
import DOC_TYPES from '../../../constants/DocTypes';
import COLORS from '../../../constants/colors';
import { GlobalState } from '../../../store_deprecated/types';
import { UserAgreementName } from '../../../store_deprecated/types/userAgreementTypes';
import { UploadEntity, KycStatus } from '../../../store_deprecated/types/authTypes';
import { findProfileData } from '../../../store_deprecated/actions/authActions';
import { DocumentTypes } from '../../../store_deprecated/types/docsTypes';
import { locations } from '../../../routes/locations';
import { ConfigKey } from '../../../types/config';

const Loader = React.lazy(() => import('../../../shared/Loader'));
const UploadDocuments = React.lazy(() => import('./UploadDocuments'));
const Terms = React.lazy(() => import('./Terms'));
const UploadDocumentsSuccessfulScreen = React.lazy(() => import('./UploadDocumentsSuccessfulScreen'));
const Breadcrumb = React.lazy(() => import('../../../shared/Breadcrumb'));

interface CustomizedState {
  from?: string;
  step?: number;
}

const DocsView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [uploadFinish, setUploadFinish] = useState(false);
  const [isErrorExist, setIsErrorExist] = useState(false);
  const [docsReceived, setDocsReceived] = useState(false);
  const dispatch = useTypedDispatch();
  const { user } = useSelector(authSelector);
  const state = (location.state as CustomizedState) || {};
  const docsDataState = useSelector(getDocsDataSelector);
  const nationalDocId = useSelector((gs: GlobalState) => getDocTypesSelector(gs, DOC_TYPES.VERIFICATION_DOCUMENT));
  const selfieDocId = useSelector((gs: GlobalState) => getDocTypesSelector(gs, DOC_TYPES.SELFIE));
  const { data: appConfigUploadFlow } = useGetAppConfigQuery(ConfigKey.UPLOAD_FLOW_ENABLED);
  const uploadFlowEnabled = JSON.parse(appConfigUploadFlow?.value || 'true');

  const initialStep = state?.step || 0;

  const { data } = useFindProfileDataQuery();

  useEffect(() => {
    if (user?.id) {
      dispatch(findProfileData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  useEffect(() => {
    if (data?.id && data?.status?.name === KycStatus.ACTIVE) {
      navigate(locations.dashboard());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const nationalIdFailure = useSelector(getNationalIdFailureSelector);
  const selfieFailure = useSelector(getSelfieFailureSelector);
  const sendDocsDataFailure = useSelector(getSendDocsDataFailureSelector);

  const isRTL = i18n.dir() === 'rtl';
  const [step, setStep] = useState(initialStep);
  const [initialNationalId, setInitialNationalId] = useState<string>();
  const [initialSelfiePicture, setInitialSelfiePicture] = useState<string>();
  const [showLoader, setShowLoader] = useState(false);

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      href="/"
      variant={isRTL ? 'cairoBodySmall' : 'bodySmall'}
      sx={{ color: COLORS.LIGHT_GRAY }}
    >
      {t('breadcrumbCompleteProfile')}
    </Link>,
    <Typography key="2" color="text.primary" variant={isRTL ? 'cairoBold' : 'bodySmallB'} sx={{ fontSize: '14px' }}>
      {t('breadcrumbUploadDocs')}
    </Typography>,
  ];

  const nextStep = () => setStep(step + 1);
  const termsBack = () => {
    if (state?.from) {
      return navigate(state.from);
    }

    if (!uploadFlowEnabled) {
      return navigate(`${locations.riskSurvey()}?page=recommendedPortfolios`);
    }

    setStep(step - 1);
    setDocsReceived(false);
  };

  const uploadDocuments = async (nationalId: Blob | string, selfiePicture: Blob | string) => {
    const getBlob = async (source: Blob | string): Promise<Blob> => {
      if (typeof source !== 'string') {
        return source;
      }

      const { data: blobData } = await axios.get(source, { responseType: 'blob' });

      return blobData;
    };

    setShowLoader(true);
    setUploadFinish(false);
    setIsErrorExist(false);
    const nationalIdFormData = new FormData();
    const selfiePictureFormData = new FormData();

    const [nationalIdFile, selfieFile] = await Promise.all([getBlob(nationalId), getBlob(selfiePicture)]);

    nationalIdFormData.append('file', nationalIdFile);
    selfiePictureFormData.append('file', selfieFile);

    try {
      const uploadDocs: [string, string] = (await Promise.all([
        dispatch(
          uploadNationalId({
            formData: nationalIdFormData,
            entity: UploadEntity.nationalId,
          }),
        ),
        dispatch(
          uploadSelfiePicture({
            formData: selfiePictureFormData,
            entity: UploadEntity.selfie,
          }),
        ),
      ])) as any;

      // const getUploadDocsUrls = await Promise.all([
      //   // @ts-ignore
      //   dispatch(fetchUploadedDocumentURL(uploadDocs[0])),
      //   // @ts-ignore
      //   dispatch(fetchUploadedDocumentURL(uploadDocs[1])),
      // ]);

      const payload = [
        {
          documentTypeId: nationalDocId,
          link: uploadDocs[0],
        },
        {
          documentTypeId: selfieDocId,
          link: uploadDocs[1],
        },
      ];
      // @ts-ignore

      await dispatch(sendAttachedDocuments(payload));
    } catch {
      setIsErrorExist(false);
    } finally {
      setShowLoader(false);
      setUploadFinish(true);
    }
  };

  const signDocuments = async () => {
    try {
      setShowLoader(true);
      setIsErrorExist(false);

      await dispatch(signUserAgreement(UserAgreementName.ACCOUNT_OPENING_AGREEMENT));
      nextStep();
    } catch (err) {
      setIsErrorExist(true);
    } finally {
      setShowLoader(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <UploadDocuments
            nextStep={nextStep}
            loadedId={initialNationalId}
            loadedSelfie={initialSelfiePicture}
            uploadDocuments={uploadDocuments}
          />
        );

      case 1:
        return <Terms signDocuments={signDocuments} isErrorExist={isErrorExist} previousStep={termsBack} />;

      case 2:
        return <UploadDocumentsSuccessfulScreen />;

      default:
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchDocumentsType());

    if (!docsReceived) {
      dispatch(fetchAttachedDocuments());
    }

    const fetchUploadedDocs = async () => {
      const idDoc = docsDataState?.data?.find((a) => a.documentType.type === DocumentTypes.VERIFICATION_DOCUMENT);
      const selfieDoc = docsDataState?.data?.find((a) => a.documentType.type === DocumentTypes.SELFIE);

      const promises = [
        idDoc?.documentLink ? dispatch(fetchUploadedDocumentURL(idDoc?.documentLink)) : Promise.resolve(''),
        selfieDoc?.documentLink ? dispatch(fetchUploadedDocumentURL(selfieDoc?.documentLink)) : Promise.resolve(''),
      ];

      const [idUrl, selfieUrl] = await Promise.all(promises);
      if (idUrl) {
        setInitialNationalId(idUrl);
      }

      if (selfieUrl) {
        setInitialSelfiePicture(selfieUrl);
      }
      setDocsReceived(true);
    };

    if (docsDataState.isDataLoaded) {
      fetchUploadedDocs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docsDataState.data, docsReceived]);

  useEffect(() => {
    if (uploadFinish && !nationalIdFailure && !selfieFailure && !sendDocsDataFailure) nextStep();
    else if (nationalIdFailure || selfieFailure || sendDocsDataFailure) {
      setIsErrorExist(true);
      setUploadFinish(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFinish, nationalIdFailure, selfieFailure]);

  if (showLoader) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: 'calc(100vh - 64px - 202px)',
      }}
    >
      {step === 0 && (
        <Box
          sx={{
            width: 300,
            position: 'absolute',
            top: '45px',
            right: 0,
            textAlign: 'right',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <img src={isRTL ? '/images/logo-frame-ar.svg' : '/images/logo-frame-en.svg'} alt="madkhol logo frame" />
        </Box>
      )}
      <Box
        sx={{
          marginLeft: { sm: 8, lg: 'auto' },
          marginRight: { sm: 8, lg: 'auto' },
          marginTop: 4,
          maxWidth: '960px',
          position: 'relative',
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={{
            visibility: step === 0 ? 'visible' : 'hidden',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Grid item>
            <Breadcrumb breadcrumbs={breadcrumbs} />
          </Grid>
        </Grid>
        {renderStep()}
      </Box>
    </Box>
  );
};

export default DocsView;
