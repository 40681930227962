import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { Link as ReactLink } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import OTPInput from '../Login/OTPInput';
import CountDown from '../Login/CountDown';
import COLORS from '../../../constants/colors';
import { capitalizeFirstLetter } from '../../../utils/string';
import MButton from '../../../shared/MButton';

interface OTPViewProps {
  type?: 'email' | 'mobile';
  isArabic: boolean;
  otpSendTime: number;
  handleChangeOtp: (otp: string) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  resendOTP: () => void;
  setOtpSendTime: (v: number) => void;
  otpErrorMessage: string;
  length: number;
}

const OTPView: React.FC<OTPViewProps> = (props) => {
  const type = props.type || 'mobile';
  const { isArabic, handleChangeOtp, handleSubmit, resendOTP, setOtpSendTime, otpSendTime, otpErrorMessage, length } =
    props;
  const { t } = useTranslation('auth', { useSuspense: true });
  const errRef = useRef<any>();

  return (
    <Box sx={{ display: 'grid' }}>
      <Container component="main">
        <Box sx={{ marginTop: 10, paddingBottom: 8 }}>
          <Box sx={{ maxWidth: '600px', margin: 'auto', textAlign: 'center' }}>
            <Typography
              component="h1"
              variant={isArabic ? 'cairoHeader1B' : 'largeDisplayH'}
              gutterBottom
              id="signUpTitle"
            >
              {t('signUpTitle')}
            </Typography>
          </Box>
          <Box
            sx={{
              margin: '0 auto',
              padding: '32px 32px 0',
              maxWidth: '600px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
              borderRadius: '12px',
              backgroundColor: 'white',
            }}
            component="form"
            onSubmit={handleSubmit}
          >
            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ mt: 1, width: '100%' }}>
                    <Typography
                      variant={isArabic ? 'cairoR' : 'bodyMedium'}
                      sx={{ color: COLORS.MAIN_DARK, fontSize: '1rem' }}
                    >
                      {t(`enter${capitalizeFirstLetter(type)}Code`)}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        direction: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '56px',
                        mt: 8,
                        mb: 6,
                      }}
                    >
                      <OTPInput
                        autoFocus
                        // isNumberInput // adding isNumberInput make input field doesn't accept arabic numbers
                        length={length}
                        clearOTP={otpSendTime !== 0}
                        isArabic={isArabic}
                        className="otpContainer"
                        inputClassName="otpInput singleInput"
                        onChangeOTP={(otp) => handleChangeOtp(otp)}
                        style={{ height: '100%' }}
                        inputStyle={{
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          borderColor: COLORS.XLIGHT_GRAY,
                          borderRadius: '8px',
                          height: '60px',
                          width: '60px',
                        }}
                      />
                    </Box>
                    <Typography
                      component="h5"
                      variant="body2"
                      ref={errRef}
                      className={otpErrorMessage ? 'errmsg' : 'offscreen'}
                      aria-live="assertive"
                      color="error"
                      sx={{ mb: 4 }}
                    >
                      {t(otpErrorMessage)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <MButton
              variant="contained"
              fullWidth
              id="SignUp"
              buttonProps={{
                type: 'submit',
                sx: { boxShadow: 'none', height: { xs: '50px', md: 60 } },
              }}
              // disabled={isValidAnswer()}
            >
              {type === 'email' ? t('next') : t('signUpTitle')}
            </MButton>
            <Box sx={{ mt: 6 }}>
              <CountDown otpSendTime={otpSendTime} setOtpSendTime={setOtpSendTime} resendOTP={resendOTP} />
            </Box>
          </Box>
          <Grid container justifyContent="center" textAlign="center" sx={{ mt: 10 }}>
            <Grid item xs={12}>
              <Typography variant={isArabic ? 'cairoBold' : 'bodyMediumB'} sx={{ color: COLORS.PRIMARY_BLUE }}>
                <Link
                  component={ReactLink}
                  to="/login"
                  sx={{
                    textDecoration: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {t('loginTitle')}&nbsp;
                  {isArabic ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default OTPView;
