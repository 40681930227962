const ProductIcons: any = {
  passive: 'invest.svg',
  'madkhol passive': 'invest.svg',
  active: 'control.svg',
  'madkhol active': 'control.svg',
  'us quantitative': 'control.svg',
  'madkhol us quantitative': 'control.svg',
  balanced: 'control.svg',
  'madkhol balanced': 'control.svg',
};

export default ProductIcons;
