import { apiSlice } from '../../../api/common/apiSlice';
import { ApiTagType } from '../../../constants/apiTagType';
import { toQueryParams } from '../../../utils/request';
import { FetchProductsResponse } from '../../../types/product';

export const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchProducts: builder.query<FetchProductsResponse, PaginatedRequest>({
      query: (data) => ({
        url: `/app/product-type?${toQueryParams(data)}`,
        method: 'GET',
      }),
      providesTags: (res) => {
        if (res) {
          return [
            ...(res.items || []).map(({ id }) => ({ type: ApiTagType.Product as const, id })),
            { type: ApiTagType.Product, id: 'LIST' },
          ];
        }
        return [{ type: ApiTagType.Product, id: 'LIST' }];
      },
    }),
    fetchSubscribedProducts: builder.query<FetchProductsResponse, PaginatedRequest>({
      query: () => ({
        url: '/app/portfolio/subscribed',
        method: 'GET',
      }),
      providesTags: (res) => {
        if (res) {
          return [
            ...(res.items || []).map(({ id }) => ({ type: ApiTagType.Product as const, id })),
            { type: ApiTagType.Product, id: 'LIST' },
          ];
        }
        return [{ type: ApiTagType.Product, id: 'SUBSCRIBED LIST' }];
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useFetchProductsQuery, useFetchSubscribedProductsQuery } = productsApiSlice;
