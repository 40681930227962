import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import COLORS from '../../../constants/colors';
import CenterContent from '../../../shared/CenterContent';
import MButton from '../../../shared/MButton';
import { useTypedDispatch } from '../../../redux/store';
import { toggleEmailUsPopup } from '../../../store_deprecated/actions/appActions';
import { getEmailUpVisibilityStatus } from '../../../store_deprecated/selectors/appSelectors';

const PortfolioPending: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const isEmailUpVisible = useSelector(getEmailUpVisibilityStatus);

  const contactUs = () => {
    if (!isEmailUpVisible) dispatch(toggleEmailUsPopup());
  };

  return (
    <CenterContent>
      <Grid
        container
        display="flex"
        flexDirection={{ xs: 'row', md: 'column' }}
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: { xs: '24px', md: '64px' },
          background: COLORS.WHITE,
          borderRadius: { xs: '12px', md: '24px' },
        }}
        rowSpacing="24px"
      >
        <Grid
          item
          sx={{
            width: { xs: '100%', md: '300px' },
            height: { xs: 'auto', md: '300px' },
          }}
        >
          <img src="/images/inactive-profile.png" alt="" style={{ width: '100%', height: '100%' }} />
        </Grid>
        <Grid
          item
          container
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          rowSpacing="8px"
        >
          <Grid item textAlign="center">
            <Typography
              variant="bodyLarge"
              sx={{
                weight: 700,
                size: '18px',
                lineHeight: '28px',
                color: COLORS.ORANGE,
              }}
            >
              {t('dashboard.portfolioPendingNote')}
            </Typography>
          </Grid>
          <Grid item textAlign="center">
            <Typography
              variant="bodyLarge"
              sx={{
                weight: 400,
                size: '18px',
                lineHeight: '28px',
                color: COLORS.DARK_GREY,
              }}
            >
              {t('dashboard.portfolioPendingNote2')}
            </Typography>
          </Grid>
          <Grid item textAlign="center">
            <Typography
              variant="bodyLarge"
              sx={{
                weight: 400,
                size: '18px',
                lineHeight: '28px',
                color: COLORS.DARK_GREY,
              }}
            >
              {t('dashboard.portfolioPendingNote3')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ mt: '8px' }}>
          <MButton
            variant="contained"
            onClick={contactUs}
            id="ContactUsButton"
            buttonProps={{
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
                backgroundColor: COLORS?.X_DARK_BLUE,
              },
            }}
          >
            {t('dashboard.contactUs')}
          </MButton>
        </Grid>
      </Grid>
    </CenterContent>
  );
};

export default PortfolioPending;
