/* eslint-disable */
import { Selector } from 'react-redux';
import { GlobalState } from '../types';
import { QuestionData, AnswerData, SurveyData, ConfigData, SurveyResultData } from '../types/surveyTypes';

// Survey Slice Selectors
export const getQuestion: Selector<GlobalState, QuestionData, number> = (state, index) => {
  const { data } = state.surveyState.questions;
  const foundData = data.filter((q) => q.order === index);
  return (
    foundData[foundData.length - 1] || {
      id: 0,
      textAr: '',
      textEn: '',
      type: '',
      options: [],
    }
  );
};

export const getQuestionRequestIsLoading: Selector<GlobalState, boolean> = (state) =>
  state.surveyState.questions.isLoading;

export const getQuestionRequestIsFailed: Selector<GlobalState, boolean> = (state) =>
  state.surveyState.questions.isErrorExist;

export const getSurvey: Selector<GlobalState, SurveyData> = (state) => state.surveyState.survey.data;

export const getSurveyRequestIsLoading: Selector<GlobalState, boolean> = (state) => state.surveyState.survey.isLoading;

export const getSurveyRequestIsFailed: Selector<GlobalState, boolean> = (state) =>
  state.surveyState.survey.isErrorExist;

export const getSurveyResult: Selector<GlobalState, SurveyResultData> = (state) => state.surveyState.survey.result;

export const getAnswer: Selector<GlobalState, AnswerData, number> = (state, questionId) =>
  state.surveyState.answers.data[questionId] || { questionId: 0, surveyResultId: 0, answer: null };

export const getAnswerRequestIsLoading: Selector<GlobalState, boolean> = (state) => state.surveyState.answers.isLoading;

export const getAnswerRequestIsFailed: Selector<GlobalState, boolean> = (state) =>
  state.surveyState.answers.isErrorExist;

export const getConfig: Selector<GlobalState, ConfigData> = (state) => state.surveyState.config.data;

export const getConfigRequestIsLoading: Selector<GlobalState, boolean> = (state) => state.surveyState.config.isLoading;

export const getConfigRequestIsFailed: Selector<GlobalState, boolean> = (state) =>
  state.surveyState.config.isErrorExist;

export const finishUserSurveyRequestIsLoading: Selector<GlobalState, boolean> = (state) =>
  state.surveyState.survey.isLoading;

export const finishUserSurveyRequestIsFailed: Selector<GlobalState, boolean> = (state) =>
  state.surveyState.survey.isErrorExist;
