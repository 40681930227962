import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { resetPasswordEmailFormFields } from '../../../forms/fields/formFields';
import { resetPasswordEmailFormSchema } from '../../../forms/validationSchema/formSchema';
import COLORS from '../../../constants/colors';
import MButton from '../../../shared/MButton';

interface ResetPasswordEmailFormProps {
  errorMessage?: string;
  toggleRestoreType: () => void;
  onSubmit: (email: string) => void;
  email: string;
}

const ResetPasswordEmailForm = (props: ResetPasswordEmailFormProps) => {
  const { toggleRestoreType, onSubmit, errorMessage, email } = props;
  const { t, i18n } = useTranslation('auth', { useSuspense: true });
  const isEnglish = i18n.language === 'en';

  const { handleSubmit, values, handleChange, errors, isValid } = useFormik({
    initialValues: { [resetPasswordEmailFormFields.email]: email },
    validationSchema: resetPasswordEmailFormSchema,
    onSubmit: (formValues) => {
      onSubmit(formValues.email);
    },
  });

  return (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" sx={{ py: '9%' }}>
      <Grid item sx={{ mb: '24px' }}>
        <Typography
          component="h1"
          variant={isEnglish ? 'smallDisplayH' : 'cairoHeader1B'}
          sx={{ textTransform: 'capitalize', mb: '24px' }}
        >
          {t('restorePassword')}
        </Typography>
        {/* <Typography */}
        {/*  variant={isEnglish ? "bodyLarge" : "cairoBody"} */}
        {/*  color={COLORS.LIGHT_GRAY} */}
        {/* > */}
        {/*  Lorem ipsum dolor sit amet adipiscing elit. */}
        {/* </Typography> */}
      </Grid>
      <Grid
        item
        xs={11}
        sm={8}
        md={6}
        lg={5}
        xl={4}
        component={Paper}
        elevation={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: '48px',
          width: '100%',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05);',
          borderRadius: '12px',
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
          <Typography
            variant={!isEnglish ? 'cairoR16' : 'bodyMedium'}
            color={COLORS.MAIN_DARK}
            textAlign="justify"
            paragraph
            sx={{ marginBottom: 1 }}
          >
            {t('signUpFormEmailLabel')}
          </Typography>
          <Box
            sx={{
              mb: 7,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TextField
              id={resetPasswordEmailFormFields.email}
              name={resetPasswordEmailFormFields.email}
              variant="outlined"
              value={values[resetPasswordEmailFormFields.email]}
              fullWidth
              type="email"
              placeholder={t('signUpFormEmailPlaceholder')}
              onChange={handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '& fieldset': { borderColor: COLORS.XLIGHT_GRAY },
                },
                '& .MuiFormHelperText-root': {
                  fontFamily: !isEnglish ? 'Noto Kufi Arabic' : 'Montserrat',
                },
              }}
              InputProps={{
                style: {
                  fontFamily: !isEnglish ? 'Noto Kufi Arabic' : 'Montserrat',
                  fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
                },
              }}
              error={!!errors.email}
              helperText={t(errors.email || '')}
            />
          </Box>
          <Typography
            component="h5"
            variant="body2"
            className={errorMessage ? 'errmsg' : 'offscreen'}
            aria-live="assertive"
            color="error"
            sx={{ mb: '24px' }}
          >
            {errorMessage}
          </Typography>
          <MButton
            fullWidth
            disabled={!isValid || !values[resetPasswordEmailFormFields.email]}
            variant="contained"
            typographyProps={{ variant: isEnglish ? 'h6' : 'cairoH4' }}
            buttonProps={{
              type: 'submit',
              sx: {
                width: { xs: '100%', md: '100%' },
                height: { xs: '50px', md: 60 },
                borderRadius: '80px',
                boxShadow: 'none',
              },
            }}
          >
            {t('next')}
          </MButton>
        </Box>
        <Grid container flexDirection="column" justifyContent="center" alignItems="center" sx={{ mt: '24px' }}>
          <Grid item>
            <Typography variant="bodySmall">
              <Link onClick={toggleRestoreType} underline="none" sx={{ mx: '5px', cursor: 'pointer' }}>
                <Typography variant={isEnglish ? 'bodyMediumB' : 'cairoBold'} component="span">
                  {t('restoreWithMobile')}
                </Typography>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordEmailForm;
