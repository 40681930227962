import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { Link as ReactLink } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import FullNameField from './FullNameField';
import MobileField from './MobileField';
import EmailField from './EmailField';
import PasswordField from './PasswordField';
import PasswordFieldRoles from './PasswordFieldRoles';
import COLORS from '../../../constants/colors';
// import NonSaudiCitizensPanel from "./NonSaudiCitizensPanel";
import MButton from '../../../shared/MButton';
import { locations } from '../../../routes/locations';

interface SignupViewProps {
  isArabic: boolean;
  handleNext: (event: React.FormEvent<HTMLFormElement>) => void;
  handleMouseDownPassword: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleChangeFullName: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleFullNameValidation: () => void;
  handleChangeMobile: (mobile: string) => void;
  handleMobileValidation: () => void;
  handleChangeEmail: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleEmailValidation: () => void;
  handleChangePassword: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  isValidAnswer: () => boolean;
  fullName: string;
  fullNameInvalidMessage: string;
  mobileNumber: string;
  mobileNumberInvalidMessage: string;
  email: string;
  emailInvalidMessage: string;
  password: string;
  passwordValidation: any;
  errorMessage: string;
}

const SignupView: React.FC<SignupViewProps> = (props) => {
  const {
    isArabic,
    handleNext,
    handleMouseDownPassword,
    handleChangeFullName,
    handleFullNameValidation,
    handleChangeMobile,
    handleMobileValidation,
    handleChangeEmail,
    handleEmailValidation,
    handleChangePassword,
    isValidAnswer,
    fullName,
    fullNameInvalidMessage,
    mobileNumber,
    mobileNumberInvalidMessage,
    email,
    emailInvalidMessage,
    password,
    passwordValidation,
    errorMessage,
  } = props;
  const { t } = useTranslation('auth', { useSuspense: true });
  return (
    <Box sx={{ display: 'grid', pt: '64px', pb: '32px' }}>
      <Container
        component="main"
        sx={{
          margin: 'auto',
          width: { xs: '95%', md: '100%' },
        }}
      >
        <Box sx={{ textAlign: 'center', display: { xs: 'none', md: 'block' } }}>
          <Typography
            component="h1"
            variant={isArabic ? 'cairoHeader1B' : 'largeDisplayH'}
            gutterBottom
            id="signUpTitle"
          >
            {t('signUpTitle')}
          </Typography>
        </Box>
        {/* <Box
          sx={{
            textAlign: "center",
            display: { xs: "none", md: "block" },
            mb: 8,
          }}
        >
          <Typography
            variant={isArabic ? "cairoBody" : "bodyLarge"}
            color={COLORS.LIGHT_GRAY}
          >
            **DESCRIPTION**
          </Typography>
        </Box> */}
        <Box sx={{ mb: '12px' }}>
          {errorMessage && (
            <Chip
              icon={
                <CancelIcon
                  sx={{
                    width: '14px',
                    height: '14px',
                    color: `${COLORS.RED}!important`,
                  }}
                />
              }
              label={t(errorMessage)}
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
                borderRadius: '6px',
                width: '100%',
                justifyContent: 'start',
                padding: '24px 12px',
                backgroundColor: alpha(COLORS.RED, 0.12),
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            margin: '0 auto',
            padding: '24px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
            borderRadius: '12px',
            backgroundColor: 'white',
            maxWidth: {
              xs: '100%',
              sm: '90%',
              md: '50%',
              lg: '50%',
              xl: '60%',
            },
          }}
        >
          <Box sx={{ textAlign: 'center', display: { xs: 'block', md: 'none' } }}>
            <Typography
              component="h1"
              variant={isArabic ? 'cairoHeader1B' : 'largeDisplayH'}
              gutterBottom
              id="signUpTitle"
            >
              {t('signUpTitle')}
            </Typography>
          </Box>
          {/* <Box
            sx={{
              textAlign: "center",
              display: { xs: "block", md: "none" },
              mb: 4,
            }}
          >
            <Typography
              variant={isArabic ? "cairoBody" : "bodyLarge"}
              color={COLORS.LIGHT_GRAY}
            >
              **DESCRIPTION**
            </Typography>
          </Box> */}
          <Box component="form" onSubmit={handleNext}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FullNameField
                  isArabic={isArabic}
                  fullName={fullName}
                  fullNameInvalidMessage={fullNameInvalidMessage}
                  handleChangeFullName={handleChangeFullName}
                  handleFullNameValidation={handleFullNameValidation}
                />
              </Grid>
              <Grid item xs={12}>
                <MobileField
                  isArabic={isArabic}
                  mobileNumber={mobileNumber}
                  mobileNumberInvalidMessage={mobileNumberInvalidMessage}
                  handleChangeMobile={handleChangeMobile}
                  handleMobileValidation={handleMobileValidation}
                />
              </Grid>
              <Grid item xs={12}>
                <EmailField
                  isArabic={isArabic}
                  email={email}
                  emailInvalidMessage={emailInvalidMessage}
                  handleChangeEmail={handleChangeEmail}
                  handleEmailValidation={handleEmailValidation}
                  showLabel
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordField
                  isArabic={isArabic}
                  password={password}
                  autoComplete="new-password"
                  autoFocus={false}
                  handleChangePassword={handleChangePassword}
                  handleMouseDownPassword={handleMouseDownPassword}
                />
              </Grid>
            </Grid>

            <Grid container direction="row" sx={{ mt: 1 }}>
              <PasswordFieldRoles isArabic={isArabic} password={password} passwordValidation={passwordValidation} />
            </Grid>

            <MButton
              variant="contained"
              fullWidth
              id="SignUp"
              disabled={!isValidAnswer()}
              buttonProps={{
                type: 'submit',
                sx: {
                  backgroundColor: COLORS?.X_DARK_BLUE,
                  mt: 3,
                  width: { xs: '100%', md: '100%' },
                  height: { xs: '50px', md: 60 },
                  backgroundImage: 'none',
                  '&.MuiButton-root.Mui-disabled': {
                    backgroundColor: 'lightgray',
                  },
                },
              }}
              typographyProps={{
                variant: isArabic ? 'cairoButton' : 'h6',
                sx: {
                  textTransform: 'capitalize',
                  fontWeight: 500,
                  fontSize: 'clamp(1rem,0.5vw + 1rem,1.25rem)',
                },
              }}
            >
              {t('signUpTitle')}
            </MButton>
            <Grid container justifyContent="center" textAlign="center">
              <Grid item xs={12} sx={{ mt: 3, mb: 6 }}>
                <Typography
                  variant={isArabic ? 'cairoM' : 'bodyMedium'}
                  sx={{
                    color: COLORS.LIGHT_GRAY,
                  }}
                >
                  {t('signUpTermsFirstSection')}
                </Typography>
                <Box sx={{ height: '2px', display: { xs: 'block', md: 'none' } }}>
                  <br />
                </Box>
                <Typography
                  variant={isArabic ? 'cairoBold' : 'bodyMediumB'}
                  sx={{
                    color: COLORS.X_DARK_BLUE,
                  }}
                >
                  <Link
                    component={ReactLink}
                    to={locations.termsAndConditions()}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      textDecoration: 'none',
                    }}
                  >
                    &nbsp;{t('signUpTermsSecondSection')}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={isArabic ? 'cairoBold' : 'bodyMediumB'}
                  sx={{
                    color: COLORS.X_DARK_BLUE,
                  }}
                >
                  <Link
                    component={ReactLink}
                    to="/login"
                    sx={{
                      textDecoration: 'none',
                    }}
                  >
                    {t('signUpHaveAnAccount')}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <NonSaudiCitizensPanel isArabic={isArabic} /> */}
      </Container>
    </Box>
  );
};

export default SignupView;
