import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import COLORS from '../../../constants/colors';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    padding: '8px',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    borderLeftColor: COLORS.X_LIGHT_GRAY,
    [theme.breakpoints.up('md')]: {
      padding: '10px 8px',
    },
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: COLORS.X_LIGHT_GRAY,
    border: 'none',
    color: COLORS.MAIN_DARK,
    fontSize: 12,
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
    },
  },
}));

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'transparent',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));
