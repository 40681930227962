import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { userPreferenceByTypeSelector } from '../../redux/modules/userPreference/userPreference.selectors';
import { fetchUserPreferenceByType } from '../../redux/modules/userPreference/userPreference.actions';
import { ReduxState, useTypedDispatch } from '../../redux/store';
import { UserPreferenceTypeEnum } from '../../types/userPreference';

function useGetAutoInvestedProductId() {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.AUTO_INVEST));
  }, []);

  const { userPreference } = useSelector((state: ReduxState) =>
    userPreferenceByTypeSelector(state, UserPreferenceTypeEnum.AUTO_INVEST),
  );

  const autoInvestProductId = JSON.parse(userPreference ?? 'null');

  return {
    refetch: () => dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.AUTO_INVEST)),
    autoInvestProductId: autoInvestProductId?.productId,
  };
}

export default useGetAutoInvestedProductId;
