import React from 'react';
import { useLocation } from 'react-router-dom';
import { analytics } from '../analytics';

function usePageViews() {
  const location = useLocation();

  React.useEffect(() => {
    analytics.page(location.pathname);
  }, [location.pathname]);
}

export default usePageViews;
