import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import COLORS from '../../../constants/colors';

interface EmailFieldProps {
  isArabic: boolean;
  email: string;
  emailInvalidMessage?: string;
  handleChangeEmail: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleEmailValidation: () => void;
  showLabel?: boolean;
}

const EmailField: React.FC<EmailFieldProps> = ({
  isArabic,
  email,
  emailInvalidMessage,
  handleChangeEmail,
  handleEmailValidation,
  showLabel,
}) => {
  const { t } = useTranslation('auth', { useSuspense: true });
  return (
    <>
      {showLabel && (
        <Typography
          variant={isArabic ? 'cairoM' : 'bodyMedium'}
          color={COLORS.MAIN_DARK}
          textAlign="justify"
          fontWeight={500}
        >
          {t('signUpFormEmailLabel')}
        </Typography>
      )}
      <TextField
        id="email"
        variant="outlined"
        value={email}
        fullWidth
        type="email"
        name="email"
        placeholder={t('signUpFormEmailPlaceholder')}
        onChange={handleChangeEmail}
        onBlur={handleEmailValidation}
        sx={{
          mt: '4px',
          mb: emailInvalidMessage !== '' ? '14px' : 0,
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& fieldset': { borderColor: COLORS.XLIGHT_GRAY },
          },
          '& .MuiFormHelperText-root': {
            fontFamily: isArabic ? 'Noto Kufi Arabic' : 'Montserrat',
            position: 'absolute',
            bottom: '-23px',
          },
        }}
        InputProps={{
          style: {
            fontFamily: isArabic ? 'Noto Kufi Arabic' : 'Montserrat',
            fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
          },
        }}
        error={emailInvalidMessage !== ''}
        helperText={emailInvalidMessage ? t(emailInvalidMessage) : ''}
      />
    </>
  );
};

export default EmailField;
