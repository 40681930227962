import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useTranslation } from 'react-i18next';
import { ProductFile } from '../types/product';

interface PopupMenuProps {
  onDelete: () => void;
  showDelete: boolean;
  files: ProductFile[];
  onFileDownload: (file: ProductFile) => void;
}

const PopupMenu: React.FC<PopupMenuProps> = ({ files, onDelete, showDelete, onFileDownload }) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickDelete = () => {
    onDelete();
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box
        onClick={handleClick}
        sx={{
          display: 'flex',
          gap: '4px',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          p: 0.75,
        }}
      >
        <Box sx={{ width: 8, height: 8, backgroundColor: '#E4E7EC', borderRadius: '50%' }} />
        <Box sx={{ width: 8, height: 8, backgroundColor: '#E4E7EC', borderRadius: '50%' }} />
        <Box sx={{ width: 8, height: 8, backgroundColor: '#E4E7EC', borderRadius: '50%' }} />
      </Box>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{ zIndex: 0 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            p: '10px 20px',
            mt: 1.5,
            backgroundColor: '#EDEFF2',
            color: '#374151',
            borderRadius: '10px',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '& .MuiMenuItem-root': {
              fontSize: 14,
              fontWeight: 600,
              p: 0,
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&:focus': {
                backgroundColor: 'transparent',
              },
            },
            '& .MuiMenu-list': { p: 0, display: 'grid', gap: '8px' },
            '&:before': {
              borderRadius: '3px',
              content: '""',
              position: 'absolute',
              top: 0,
              right: 13,
              width: 10,
              height: 10,
              bgcolor: '#EDEFF2',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: isEnglish ? 'right' : 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: isEnglish ? 'right' : 'left', vertical: 'bottom' }}
      >
        {files &&
          files.length > 0 &&
          files.map((file) => (
            <MenuItem key={file.id} onClick={() => onFileDownload(file)} sx={{ color: '#374151' }}>
              <SaveAltIcon sx={{ color: '#374151', width: 16, mr: 2 }} />
              {t(file?.type ? `products.${file?.type}` : 'products.document')}
            </MenuItem>
          ))}

        {showDelete && (
          <MenuItem onClick={onClickDelete} sx={{ color: '#C3133D' }}>
            <img
              src="/images/icons/delete.svg"
              alt="trash icon"
              style={{
                marginRight: isEnglish ? '8px' : 'unset',
                marginLeft: !isEnglish ? '8px' : 'unset',
              }}
            />
            {t('delete')}
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
};

export default PopupMenu;
