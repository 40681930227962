import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import { Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
// import 'swiper/css/pagination';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import TutorialCard from './TutorialCard';
import { TUTORIALS } from '../../../constants/tutorials';

export type TutorialViewProps = {
  beta?: boolean;
};

const TutorialView: React.FC<TutorialViewProps> = ({ beta }) => {
  const { i18n } = useTranslation();

  return (
    <Swiper
      key={i18n.language}
      modules={[Pagination]}
      spaceBetween={10}
      slidesPerView={1}
      pagination={{ clickable: true }}
      dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
      breakpoints={{
        640: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        1600: { slidesPerView: 3 },
      }}
    >
      {TUTORIALS[i18n.language].map((tutorial: any) => (
        <Grid item xs={12} md={6} key={tutorial.id} sx={{ mb: { xs: '20px !important', sm: '24px !important' } }}>
          <SwiperSlide>
            <TutorialCard tutorial={tutorial} beta={beta} />
          </SwiperSlide>
        </Grid>
      ))}
    </Swiper>
  );
};

export default TutorialView;
