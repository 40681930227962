const faqs: Record<string, { id: number; title: string; description: string }[]> = {
  en: [
    {
      id: 0,
      title: 'What is the minimum investment with Madkhol?',
      description: 'The minimum amount to open an investment portfolio is 1000 Saudi riyals.',
    },
    {
      id: 1,
      title: 'How can I transfer the investment amount to my wallet?',
      description:
        'You can transfer the investment amount from your bank account to the IBAN of your wallet in the finance entry. It takes minutes for the transfer to reach your wallet. The transfer amount will appear as a balance in your wallet on your Madkhol control panel. You can also set up an automatated monthly investment transfer to.',
    },
    {
      id: 2,
      title: 'Can I withdraw my balance from the investment portfolio at any time?',
      description: 'Yes, you can. the redemption process may take up to 10 working days.',
    },
    {
      id: 3,
      title: 'Who can benefit from Madkhol services?',
      description:
        'Madkhol currently is available for Saudi citizens and residents in Saudi Arabia. Madkhol team is working hard to make individuals from other countries be able to benefit from the service. In the mean time, you can register on the subscription list until the service becomes available.',
    },
    {
      id: 4,
      title: 'How much is the management fee that Madkhol charges?',
      description: 'Management fees current is free of charge.',
    },
    {
      id: 5,
      title: 'Can I modify my risk appetite score or my portfolio weights?',
      description:
        'Yes, you can modify the portfolio to suit your desire, however, Madkhol Capital does not recommend any modifications, because the portfolio has been carefully designed to suit the investor Investors, who adjust their portfolios claim full responsibility.',
    },
    {
      id: 6,
      title: 'How much is the expected annual return on investment?',
      description:
        'It depends on the degree of risk appetite and the number of investment years committed. However, on average, the annual return ranges from 7% to 15%, subject to global market fluctuations in the overall economic conditions.',
    },
    {
      id: 7,
      title: 'What are the investment assets provided by the income service?',
      description: `Madkhol invests in diversified assets locally and globally. This includes: Saudi and international stocks, real estate, and Sukuk. We adopt a long-term passive investment strategy. The investment portfolios consist of:<br/>
      - Developed Stock Markets<br/>
      - U.S Stock Markets<br/>
      - Emerging Stock Markets<br/>
      - Domestic Stock Market<br/>
      - Real Estate<br/>
      - Commodity (Gold)<br/>
      - Diversified Fixed Income`,
    },
    {
      id: 8,
      title: 'Can I transfer money to and from an international bank account?',
      description: 'Yes, there will be additional international transfer fees.',
    },
    {
      id: 9,
      title: 'Is Madkhol Capital licensed to operate in Saudi Arabia?',
      description: `Yes, Madkhol is fully licensed by the Capital Market Authority (CMA) in Saudi Arabia.<br/>
      Please visit CMA website attached 
      <a style="word-wrap: break-word;" href="https://cma.org.sa/Market/fintech/Pages/ExpFinTechs.aspx" target="_blank">Capital Market Authority</a>`,
    },
    {
      id: 10,
      title: 'Is my Personal Data Protected?',
      description:
        'Madkhol is committed to the highest standards of data privacy and protection per the laws of the Kingdom of Saudi Arabia.',
    },
    {
      id: 11,
      title: 'How do I set a recurring standing transfer deposit to a wallet account?',
      description: `To set a recurring standing transfer deposit, apply the following process: <br/>
      1- log in to your online banking, and select Transfers.<br/>
      2- Select "recurring Transfer."<br/>
      3- Choose the type of transfer and the beneficiary (cash profit account with your IBAN). <br/>
      4- Enter the amount you wish to transfer periodically.<br/>
      5- Choose the start date (after the salary date, for example) and the end date.<br/>
      6- Choose the frequency (e.g., monthly, weekly, or quarterly)`,
    },
    {
      id: 12,
      title: "Is the fee charged to my account if I don't deposit it into the wallet?",
      description:
        'No, Madkhol charges fees for managing your investment only if an amount is invested in a portfolio.',
    },
    {
      id: 13,
      title: 'Does Madkhol investments comply with Shariah-Laws?',
      description: `Yes, all our services are Shariah-compliant and are subject to the supervision of the Capital Market Authority (CMA) of Saudi Arabia. We have appointed Shariyah Review Bureau to oversee the business model’s Sharia compliance was the natural step towards the objective.
        <a style="word-wrap: break-word;" href="https://shariyah.net/ar/media/%D8%B4%D8%B1%D9%83%D8%A9-%D9%85%D8%AF%D8%AE%D9%88%D9%84-%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A%D8%A9-%D8%AA%D8%B7%D9%84%D9%82-%D9%85%D9%86%D8%B5%D8%A9-%D8%A7%D9%84%D9%85%D8%B3%D8%AA%D8%B4/" target="_blank">Shariyah Review Bureau</a>
        `,
    },
  ],
  ar: [
    {
      id: 0,
      title: 'هل يوجد حد أدنى للاستثمار مع مدخول؟',
      description: 'الحد الأدنى لفتح محفظة استثمار مع مدخول هو 1000 ريال سعودي.',
    },
    {
      id: 1,
      title: 'كيف يمكنني تحويل مبلغ الاستثمار الى محفظتي؟',
      description:
        'يمكنك تحويل مبلغ الاستثمار من حسابك البنكي إلى رقم الآيبان الخاص بمحفظتك في مدخول  المالية. تستغرق مدة الحوالة دقائق لتصل إلى محفظتك. سيظهر مبلغ الحوالة كرصيد في محفظتك في لوحة التحكم الخاصة بك في منصة مدخول  المالية. كما يمكنك جعل العملية تلقائية بتحديد مبلغ للاستثمار ويتم تحويلة شهريًا الى حسابك وبذلك ستتم عملية الاستثمار تلقائياً.',
    },
    {
      id: 2,
      title: 'هل يمكنني سحب رصيدي من المحفظة الاستثمارية في اي وقت؟',
      description: 'نعم يمكنك، مع العلم ان عملية وصول الأموال الى حسابك البنكي قد تستغرق 10 أيام عمل.',
    },
    {
      id: 3,
      title: 'من يمكنه الاستفادة من خدمات مدخول؟',
      description:
        'خدمات مدخول متاحة حاليًا للمواطنين السعوديين والمقيمين في السعودية. كما يعمل فريق مدخول بجد لإتاحة الفرصة للأفراد في دول اخرى بالاستفادة من الخدمة. يمكن للراغبين من دول اخرى التسجيل في قائمة الاشتراك حتى تتوفر الخدمة.',
    },
    {
      id: 4,
      title: 'كم تبلغ رسوم الاشتراك لدى مدخول؟',
      description: 'الرسوم الادارية حالياً مجانية.',
    },
    {
      id: 5,
      title: 'هل يمكنني تعديل درجة المخاطرة في محفظتي  أو تعديل نسبة الأوزان في المحفظة؟',
      description:
        'نعم يمكنك التعديل المحفظة بما يتناسبك مع رغبتك ولكن لا تنصح منصة مدخول بإجراء أي تعديل. لا تتحمل أي مسؤولية على تعديل درجة المخاطر.',
    },
    {
      id: 6,
      title: 'كم يبلغ العائد السنوي المتوقع للاستثمار؟',
      description:
        'يكون ذلك بحسب درجة المخاطر وعدد سنوات الاستثمار ولكن يتراوح معدل العائد السنوي من %6 الى 10% بحسب تقلبات الاسواق العالمية والوضع الاقتصادي بشكل عام.',
    },
    {
      id: 7,
      title: 'ماهي الأصول الاستثمارية التي توفرها خدمة مدخول؟',
      description: `منصة مدخول تستثمر في أصول متنوعة محليا وعالميا مثل الأسهم السعودية والعالمية، العقار، والصكوك. تعتمد المنصة استراتيجية استثمارية طويلة المدى. تتكون المحافظ الاستثمارية من:<br/>
      - أسهم أسواق الدول المتقدمة<br/>
      - أسهم أسواق الدول النامية<br/>
      - أسهم السوق المحلي<br/>
      - الاستثمارات العقارية<br/>
      - السلع (ذهب)<br/>
      - الدخل الثابت المتنوع`,
    },
    {
      id: 8,
      title: 'هل يمكنني تحويل الأموال من والى حساب بنكي دولي؟',
      description: 'نعم ولكن برسوم التحويل الدولية الإضافية.',
    },
    {
      id: 9,
      title: 'هل خدمات مدخول مرخصة في المملكة العربية السعودية؟',
      description: `نعم منصة مدخول مصرحة من هيئة السوق المالية<br/>
      زيارة موقع هيئة السوق المالية 
      <a style="word-wrap: break-word;" href="https://cma.org.sa/Market/fintech/Pages/ExpFinTechs.aspx" target="_blank">موقع هيئة سوق المال</a>`,
    },
    {
      id: 10,
      title: 'هل بياناتي آمنة؟',
      description: 'تلتزم منصة مدخول بأعلى معايير خصوصية البيانات والمعلومات بحسب قوانين المملكة العربية السعودية',
    },
    {
      id: 11,
      title: 'كيف يمكنني تعريف أمر مستديم في حسابي البنكي؟',
      description: `يمكنك تعريف أمر مستديم لتكرار عملية الإيداع إلى محفظتك بشكل تلقائي من حسابك البنكي الشخصي  بشكل بسيط.<br/>
      1- اختر الحوالات الى بنك محلي. <br/>
      2- اختر "الأوامر المستديمة". <br/>
      3- اختر نوع التحويل والمستفيد (حساب مدخول المالية المخصص لك برقم الآيبان). <br/>
      4- أدخل المبلغ الذي ترغب بتحويله بشكل دوري.<br/>
      5- اختر تاريخ البداية (بعد تاريخ ايداع الراتب على سبيل المثال) وتاريخ النهاية.<br/>
       6- اختر فترة التكرار (شهري، أو اسبوعي، ربع سنوي).`,
    },
    {
      id: 12,
      title: 'إذا لم أودع في المحفظة، هل يتم حساب الرسوم على حسابي؟',
      description:
        'لا يتم احتساب الرسوم حتى تبدأ عملية الاستثمار؛ تستقطع المنصة رسومًا على إدارة استثمارك فقط، ولا تقوم باستقطاع أي رسوم في حال لم يكن هناك مبلغ مستثمر في المحفظة',
    },
    {
      id: 13,
      title: 'هل استثمارات مدخول متوافقة مع ضوابط الشريعة اللإسلامية؟',
      description: `نعم، جميع منتجاتنا الاستثمارية تمت مراجعتها ومتوافقة مع ضوابط الشريعة الاسلامية
      يرجى الاطلاع على الرابط المرفق. تخضع جميع منتجات مدخول الاستثمارية الى الرقابة الشرعية من قبل دار المراجعة الشرعية.
      <a style="word-wrap: break-word;" href="https://shariyah.net/ar/media/%D8%B4%D8%B1%D9%83%D8%A9-%D9%85%D8%AF%D8%AE%D9%88%D9%84-%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A%D8%A9-%D8%AA%D8%B7%D9%84%D9%82-%D9%85%D9%86%D8%B5%D8%A9-%D8%A7%D9%84%D9%85%D8%B3%D8%AA%D8%B4/" target="_blank">دار المراجعة الشرعية</a>`,
    },
  ],
};

export default faqs;
