import * as APIs from '../../constants/APIs';
import {
  CreateApplePayPaymentPayload,
  CreateApplePaySessionPayload,
  CreateMastercardSessionPayload,
  SaveBankInfoPayload,
  WithdrawPayload,
  CreateHyperPayCheckoutPayload,
  QueryHyperPayPaymentStatusPayload,
  SaveSubscriptionPayload,
  QueryHyperPayRegistrationStatusPayload,
} from '../../store_deprecated/types/walletTypes';
import { ApiService } from '../http';

const HTTP = new ApiService();

export async function getNetWithdraw() {
  try {
    return await HTTP.get(APIs.GET_NET_WITHDRAW);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function doWithdraw(payload: WithdrawPayload) {
  try {
    return await HTTP.post(APIs.DO_WITHDRAW, payload);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getAnbMasterardConfig() {
  try {
    return await HTTP.get(APIs.ANB_MASTERCARD_CONFIG);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function doCreateMastercardSession(payload: CreateMastercardSessionPayload) {
  try {
    return await HTTP.post(APIs.CREATE_ANB_MASTERCARD_SESSION, payload);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function doCreateApplePayPayment(payload: CreateApplePayPaymentPayload) {
  try {
    return await HTTP.post(APIs.CREATE_APPLE_PAY_PAYMENT, payload);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function doCreateApplePaySession(payload: CreateApplePaySessionPayload) {
  try {
    return await HTTP.post(APIs.CREATE_APPLE_PAY_SESSION, payload);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function doCreateHyperPayCheckout(payload: CreateHyperPayCheckoutPayload) {
  try {
    return await HTTP.post(APIs.CREATE_HYPER_PAY_CHECKOUT, payload);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function doQueryHyperPayPaymentStatus(payload: QueryHyperPayPaymentStatusPayload) {
  try {
    return await HTTP.post(APIs.QUERY_HYPER_PAY_PAYMENT_STATUS, payload);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function doQueryHyperPayRegistrationStatus(payload: QueryHyperPayRegistrationStatusPayload) {
  try {
    return await HTTP.post(APIs.QUERY_HYPER_PAY_REGISTRATION_STATUS, payload);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function saveUserSubscription(payload: Partial<SaveSubscriptionPayload>) {
  try {
    return await HTTP.post(APIs.SAVE_SUBSCRIPTION, payload);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getSubscriptions() {
  try {
    return await HTTP.get(APIs.GET_SUBSCRIPTIONS);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function setPrimaryUserSubscriptionCard(id: number) {
  try {
    return await HTTP.put(`${APIs.SET_PRIMARY_SUBSCRIPTION_CARD}/${id}`, {});
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function deleteUserSubscription(id: number) {
  try {
    return await HTTP.remove(`${APIs.DELETE_SUBSCRIPTION}/${id}`, {});
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getCreatedSubscription() {
  try {
    return await HTTP.get(APIs.GET_CREATED_SUBSCRIPTION);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getBankInfo() {
  try {
    return await HTTP.get(APIs.GET_BANK_INFO);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getBanks(term: string) {
  try {
    return await HTTP.get(APIs.GET_BANKS, {
      params: { term: encodeURIComponent(term) },
    });
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function saveUserBankInfo(payload: SaveBankInfoPayload) {
  try {
    return await HTTP.post(APIs.SAVE_BANK_INFO, payload);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function deleteUserBankInfo(id: number) {
  try {
    return await HTTP.remove(`${APIs.DELETE_BANK_INFO}/${id}`, {});
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function setPrimaryUserBankAccount(id: number) {
  try {
    return await HTTP.put(`${APIs.SET_PRIMARY_BANK_ACCOUNT}/${id}`, {});
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getEditBankInfo(id: number) {
  try {
    return await HTTP.get(`${APIs.GET_EDIT_BANK_INFO}/${id}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getStatementDelta() {
  try {
    return await HTTP.get(APIs.GET_STATEMENT_DELTA);
  } catch (error) {
    HTTP.handleError(error);
  }
}
