import { isWithinInterval, subMonths, subDays, subYears, format, lastDayOfMonth as lastDay } from 'date-fns';

const DATE_FORMAT = 'DD/MM/YYYY';

const isWithinWeek = (date: string) => {
  const today = new Date();
  const lastWeek = subDays(today, 7);
  return isWithinInterval(new Date(date), {
    start: lastWeek,
    end: today,
  });
};

const isWithinMonth = (date: string) => {
  const today = new Date();
  const lastMonth = subMonths(today, 1);
  return isWithinInterval(new Date(date), {
    start: lastMonth,
    end: today,
  });
};

const isWithinSemiYear = (date: string) => {
  const today = new Date();
  const lastSemiYear = subMonths(today, 6);
  return isWithinInterval(new Date(date), {
    start: lastSemiYear,
    end: today,
  });
};

const isWithinYear = (date: string) => {
  const today = new Date();
  const lastYear = subYears(today, 1);
  return isWithinInterval(new Date(date), {
    start: lastYear,
    end: today,
  });
};

const firstDayOfMonth = (date: number | Date, dateFormat: string) => format(date, dateFormat);

const lastDayOfMonth = (date: number | Date, dateFormat: string) => format(lastDay(date), dateFormat);

export { isWithinWeek, isWithinMonth, isWithinSemiYear, isWithinYear, firstDayOfMonth, lastDayOfMonth, DATE_FORMAT };
