import { createSlice } from '@reduxjs/toolkit';

export type selectedCardToInvest = {
  productId: number;
  backendProductId: string;
  name: string;
  currentBalance: string;
  toScreen: string;
  brandType: string;
  isSubscribed: boolean;
  product: any;
};

const initialState: selectedCardToInvest = {
  productId: -1,
  name: '',
  currentBalance: '',
  backendProductId: '-1',
  toScreen: 'dashboard',
  brandType: 'MADA',
  isSubscribed: false,
  product: {},
};

const selectedCardToInvest = createSlice({
  name: 'selectedCardToInvest',
  initialState,
  reducers: {
    setProductToInvest: (state, action) => {
      state.productId = action.payload?.productId;
      state.name = action.payload?.name;
      state.currentBalance =
        action.payload?.currentBalance?.trim().length === 0 ? '0.00' : action.payload?.currentBalance;
      state.backendProductId = action.payload?.backendProductId?.toString();
      state.toScreen = action.payload.toScreen;
      state.isSubscribed = action.payload.isSubscribed;
      state.product = action.payload.product;
    },
    resetProductToInvest: (state) => {
      state.currentBalance = '';
      state.productId = -1;
      state.name = '';
      state.backendProductId = '-1';
      state.toScreen = 'dashboard';
    },
    updateProductBrandToInvest: (state, action) => {
      state.brandType = action.payload;
    },
  },
});

export const { setProductToInvest, resetProductToInvest, updateProductBrandToInvest } = selectedCardToInvest.actions;

export default selectedCardToInvest.reducer;
