import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { userPreferencePortfoliosSelector } from '../../redux/modules/userPreference/userPreference.selectors';
import { useFetchSubscribedProductsQuery } from '../../redux/features/products/products.apiSlice';
import { fetchRecommendedPortfolios } from '../../redux/modules/userPreference/userPreference.actions';
import { useTypedDispatch } from '../../redux/store';
import useGetAutoInvestedProductId from './useGetAutoInvestedProductId';
import useAutoSetProductId from './useAutoSetProductId';

function useGetUserProducts() {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchRecommendedPortfolios());
  }, []);

  const { autoInvestProductId } = useGetAutoInvestedProductId();

  const recommendedPortfolios = useSelector(userPreferencePortfoliosSelector);

  const { data: subscribedProducts } = useFetchSubscribedProductsQuery({});

  const alreadySubscribedProducts =
    subscribedProducts?.items?.map((p) => ({
      ...p,
      isSubscribed: true,
      isAutoInvested: autoInvestProductId?.toString() === p.id?.toString(),
    })) ?? [];

  const allProducts = [...alreadySubscribedProducts, ...recommendedPortfolios].map((product) => {
    const backendProductId = product?.isSubscribed ? product?.id : product?.recommendedPortfolioId;

    return {
      ...product,
      backendProductId,
    };
  });

  useAutoSetProductId(alreadySubscribedProducts?.at(0)?.id?.toString() ?? '');

  return allProducts;
}

export default useGetUserProducts;
