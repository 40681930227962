import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import 'intl-pluralrules';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';

const initialize = (onLoaded: (isLoaded: boolean) => void) =>
  i18n
    // Enables the i18next backend
    .use(Backend)
    // Enable automatic language detection
    .use(LanguageDetector)
    // Enables the hook initialization module
    .use(initReactI18next)
    .init(
      {
        lng: localStorage.getItem('i18nextLng') || 'ar',
        // Standard language used
        fallbackLng: 'ar',
        debug: false,
        // Detects and caches a cookie from the language provided
        detection: {
          order: ['queryString', 'cookie'],
          caches: ['cookie'],
        },
        interpolation: {
          escapeValue: false,
          format(value: any, format?: string, lng?: string) {
            if (format === 'uppercase') {
              return value.toUpperCase();
            }
            if (value instanceof Date) {
              return moment(value)
                .locale(lng || 'ar')
                .format(format);
            }
            return value;
          },
        },
        react: {
          useSuspense: false,
        },
      },
      () => {
        onLoaded(true);
      },
    );

export default initialize;
