import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import { StyledTableCell } from '../../../shared/CustomTable';
import COLORS from '../../../constants/colors';

interface CustomerInfoProps {
  data: IAnyObject;
}

const CustomerInfo: React.FC<CustomerInfoProps> = ({ data }) => {
  const { t, i18n } = useTranslation('account-opening-agreement', {
    useSuspense: true,
  });
  const isEnglish = i18n.language === 'en';

  return (
    <Grid container flexDirection="column" width="100%" rowSpacing={6}>
      <Grid item>
        <TableContainer component={Paper} elevation={1}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">{t('customerName')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  sx={{
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                >
                  {data?.customerName}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <TableContainer component={Paper} elevation={1}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">{t('customerNumber')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  sx={{
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                >
                  {data?.customerNumber}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <TableContainer component={Paper} elevation={1}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">{t('customersInvestmentAccountNumber')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  sx={{
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                >
                  {data?.customersInvestmentAccountNumber}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item>
        <TableContainer component={Paper} elevation={1}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">{t('agreementNo')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  sx={{
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                >
                  {data?.agreementNo}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item textAlign="center">
        <Typography variant={isEnglish ? 'bodyMedium' : 'cairoR16'} color={COLORS.DARK_GREY} sx={{ fontWeight: 700 }}>
          {t('date', { val: data?.signAgreementDateHijri })}
        </Typography>
      </Grid>
      <Grid item textAlign="center">
        <Typography variant={isEnglish ? 'bodyMedium' : 'cairoR16'} color={COLORS.DARK_GREY} sx={{ fontWeight: 700 }}>
          {t('corresponding', { val: data?.signAgreementDate })}
        </Typography>
      </Grid>

      <Grid item textAlign="left">
        <Typography variant={isEnglish ? 'bodyMedium' : 'cairoR16'} color={COLORS.DARK_GREY} sx={{ fontWeight: 700 }}>
          {t('forIndividualCustomers')}
        </Typography>
      </Grid>
      <Grid container item flexDirection="column" width="100%" rowSpacing={0}>
        <Grid item width="100%">
          <TableContainer component={Paper} elevation={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">{t('firstName')}</StyledTableCell>
                  <StyledTableCell align="center">{t('fatherName')}</StyledTableCell>
                  <StyledTableCell align="center">{t('grandFatherName')}</StyledTableCell>
                  <StyledTableCell align="center">{t('familyName')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.firstName}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.fatherName}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.grandFatherName}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.surname}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item width="100%">
          <TableContainer component={Paper} elevation={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">{t('nationality')}</StyledTableCell>
                  <StyledTableCell align="center">{t('sex')}</StyledTableCell>
                  <StyledTableCell align="center">{t('occupation')}</StyledTableCell>
                  <StyledTableCell align="center">{t('dateOfBirth')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.nationality}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.sex}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.occupation}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.dateOfBirth}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item width="100%">
          <TableContainer component={Paper} elevation={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">{t('typeOfIdCard')}</StyledTableCell>
                  <StyledTableCell align="center">{t('idCardNo')}</StyledTableCell>
                  <StyledTableCell align="center">{t('dateOfIssue')}</StyledTableCell>
                  <StyledTableCell align="center">{t('expiryDate')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {t(`nafazUserType.${data?.typeOfIdCard}`)}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.idCardNo}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.dateOfIssue}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.expiryDate}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid item textAlign="left">
        <Typography variant={isEnglish ? 'bodyMedium' : 'cairoR16'} color={COLORS.DARK_GREY} sx={{ fontWeight: 700 }}>
          {t('addressContactInformationAndCorrespondence')}
        </Typography>
      </Grid>
      <Grid container item flexDirection="column" width="100%" rowSpacing={0}>
        <Grid item width="100%">
          <TableContainer component={Paper} elevation={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">{t('emailAddress')}</StyledTableCell>
                  <StyledTableCell align="center">{t('mobileNumber')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.emailAddress}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    dir="ltr"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.mobileNumber}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item width="100%">
          <TableContainer component={Paper} elevation={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">{t('buildingNumber')}</StyledTableCell>
                  <StyledTableCell align="center">{t('streetName')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.address?.buildingNumber}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.address?.streetName}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item width="100%">
          <TableContainer component={Paper} elevation={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">{t('additionalNumber')}</StyledTableCell>
                  <StyledTableCell align="center">{t('district')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.address?.additionalNumber}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.address?.district}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item width="100%">
          <TableContainer component={Paper} elevation={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">{t('postCode')}</StyledTableCell>
                  <StyledTableCell align="center">{t('unitNumber')}</StyledTableCell>
                  <StyledTableCell align="center">{t('city')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.address?.postCode}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.address?.unitNumber}
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    align="center"
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      width: '25%',
                    }}
                  >
                    {data?.address?.city}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerInfo;
