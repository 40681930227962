import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ApiResponse } from '../types/api';

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return typeof error === 'object' && error != null && 'message' in error && typeof (error as any).message === 'string';
}

export function parseApiError(error: unknown): ApiResponse {
  const apiError: ApiResponse = {
    data: {},
    message: '',
    status: 'failure',
  };

  if (isFetchBaseQueryError(error)) {
    // you can access all properties of `FetchBaseQueryError` here
    if ('error' in error) {
      apiError.message = error.error;
    } else {
      apiError.data = error.data;
    }
  } else if (isErrorWithMessage(error)) {
    // you can access a string 'message' property here
    apiError.message = error.message;
  }

  return apiError;
}
