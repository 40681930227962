import { apiSlice, authBaseUrl, iamBaseUrl } from '../../../api/common/apiSlice';
import { ApiTagType } from '../../../constants/apiTagType';
import {
  getCongratulationModalAppearBefore,
  removeCongratulationModalAppearBefore,
} from '../../../helpers/LocalStorage';
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  CheckUserAuthResponse,
  EmailVerifyOtpRequest,
  EmailVerifyOtpResponse,
  FetchProfileDataResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LoginRequest,
  LoginResponse,
  RestorePasswordRequest,
  RestorePasswordResponse,
  SendEmailOtpRequest,
  SendEmailOtpResponse,
  SendEmailVerificationRequest,
  SendEmailVerificationResponse,
  SendOtpRequest,
  SendOtpResponse,
  SignupRequest,
  SignupResponse,
  UpdateProfileDataRequest,
  UpdateProfileDataResponse,
  VerifyOtpRequest,
  VerifyOtpResponse,
  NafazRandomCodeResponse,
  NafazRandomCodeRequest,
  NafazCheckCodeStatusResponse,
  NafazCheckCodeStatusRequest,
} from '../../../types/auth';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation<SignupResponse, SignupRequest>({
      query: (data) => ({
        url: `${authBaseUrl}/auth/user-auth/signup`,
        method: 'POST',
        body: data,
      }),
    }),
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (data) => ({
        url: `${authBaseUrl}/auth/user-auth/login`,
        method: 'POST',
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${authBaseUrl}/auth/user-auth/logout`,
        method: 'POST',
        body: {},
      }),
    }),
    forgotPassword: builder.mutation<ForgotPasswordResponse, ForgotPasswordRequest>({
      query: (data) => ({
        url: `${authBaseUrl}/auth/user-auth/forget-password`,
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: builder.mutation<RestorePasswordResponse, RestorePasswordRequest>({
      query: (data) => ({
        url: `${authBaseUrl}/auth/user-auth/reset-password`,
        method: 'POST',
        body: data,
      }),
    }),
    sendOTP: builder.mutation<SendOtpResponse, SendOtpRequest>({
      query: (data) => ({
        url: `${authBaseUrl}/auth/otp/send-otp`,
        method: 'POST',
        body: data,
      }),
    }),
    sendEmailOTP: builder.mutation<SendEmailOtpResponse, SendEmailOtpRequest>({
      query: (data) => ({
        url: `${authBaseUrl}/auth/otp/send-email-otp`,
        method: 'POST',
        body: data,
      }),
    }),
    sendSignupOTP: builder.mutation<SendEmailOtpResponse, SendEmailOtpRequest>({
      query: (data) => ({
        url: `${authBaseUrl}/auth/otp/send-signup-otp`,
        method: 'POST',
        body: data,
      }),
    }),
    sendProfileUpdateOTP: builder.mutation<SendOtpResponse, SendOtpRequest>({
      query: (data) => ({
        url: `${authBaseUrl}/auth/otp/send-profile-update-otp`,
        method: 'POST',
        body: data,
      }),
    }),
    sendEmailVerification: builder.mutation<SendEmailVerificationResponse, SendEmailVerificationRequest>({
      query: (data) => ({
        url: `${authBaseUrl}/auth/user-auth/email-verification-link`,
        method: 'POST',
        body: data,
      }),
    }),
    verifyOTP: builder.mutation<VerifyOtpResponse, VerifyOtpRequest>({
      query: (data) => ({
        url: `${authBaseUrl}/auth/otp/verify-otp`,
        method: 'POST',
        body: data,
      }),
    }),
    verifyEmailOTP: builder.mutation<EmailVerifyOtpResponse, EmailVerifyOtpRequest>({
      query: (data) => ({
        url: `${authBaseUrl}/auth/otp/verify-email-otp`,
        method: 'POST',
        body: data,
      }),
    }),
    getIamAuthorizationUrl: builder.query<{ url: string }, string>({
      query: (locale) => ({
        url: `${iamBaseUrl}/iam/auth/authorize?locale=${locale}`,
        method: 'GET',
      }),
    }),
    getNafazRandomCode: builder.mutation<NafazRandomCodeResponse, NafazRandomCodeRequest>({
      query: (data) => ({
        url: `${iamBaseUrl}/iam/auth/login-request?locale=${data.locale}`,
        method: 'POST',
        body: {
          nationalId: data.nationalId,
        },
      }),
    }),
    checkNafazAuthStatus: builder.query<NafazCheckCodeStatusResponse, NafazCheckCodeStatusRequest>({
      query: (data) => ({
        url: `${iamBaseUrl}/iam/auth/status?nationalId=${data.nationalId}&transId=${data.transId}&random=${data.code}`,
        method: 'GET',
      }),
    }),
    checkUserAuth: builder.query<CheckUserAuthResponse, void>({
      query: () => ({
        url: `${authBaseUrl}/auth/user-auth/check-auth`,
        method: 'GET',
      }),
      providesTags: [ApiTagType.UserAuth],
       // Disable caching for this endpoint
      keepUnusedDataFor: 0,
    }),
    // Profile data
    findProfileData: builder.query<FetchProfileDataResponse, void>({
      query: () => ({
        url: '/app/user/profile',
        method: 'GET',
      }),
      transformResponse: (response: FetchProfileDataResponse) => {
        const userId = getCongratulationModalAppearBefore();
        if (userId !== response.userId.toString()) {
          removeCongratulationModalAppearBefore();
        }
        return response;
      },
      providesTags: [ApiTagType.UserProfile],
      // Disable caching for this endpoint
      keepUnusedDataFor: 0,
    }),
    updateProfileData: builder.mutation<UpdateProfileDataResponse, UpdateProfileDataRequest>({
      query: (data) => ({
        url: '/app/user/profile',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [ApiTagType.UserAuth, ApiTagType.UserProfile],
    }),
    changePassword: builder.mutation<ChangePasswordResponse, ChangePasswordRequest>({
      query: (data) => ({
        url: `${authBaseUrl}/auth/user-auth/change-password`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [ApiTagType.UserAuth],
    }),
  }),
});

export const {
  useSignupMutation,
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useSendOTPMutation,
  useSendSignupOTPMutation,
  useSendProfileUpdateOTPMutation,
  useSendEmailOTPMutation,
  useSendEmailVerificationMutation,
  useVerifyOTPMutation,
  useVerifyEmailOTPMutation,
  useLazyGetIamAuthorizationUrlQuery,
  useCheckUserAuthQuery,
  useFindProfileDataQuery,
  useUpdateProfileDataMutation,
  useChangePasswordMutation,
  useLazyCheckNafazAuthStatusQuery,
  useGetNafazRandomCodeMutation,
  useLazyFindProfileDataQuery,
} = authApiSlice;
