import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { captureException } from '@sentry/react';
import { Mutex } from 'async-mutex';
import { ApiTagType } from '../../constants/apiTagType';
import { httpStatusCodes } from '../../constants/httpStatusCodes';
import { logOut, setCredentials } from '../../redux/features/auth/auth.slice';
import { locations } from '../../routes/locations';
import { ApiResponse } from '../../types/api';
import { LoginResponse } from '../../types/auth';

const refreshSkipPath = [
  'auth/user-auth/login',
  'auth/user-auth/signup',
  'auth/user-auth/logout',
  'auth/user-auth/forget-password',
  'auth/user-auth/reset-password',
];

interface CustomFetchBaseQueryError {
  status: number;
  data?: {
    code: number;
    message: string;
    status: 'failure' | 'success';
    traceId?: string;
  };
}

type CustomBaseQueryFn = BaseQueryFn<
  string | FetchArgs, // Args
  unknown, // Result
  CustomFetchBaseQueryError, // Error
  { todo?: boolean }, // DefinitionExtraOptions
  FetchBaseQueryMeta // Meta
>;

// create a new mutex
const mutex = new Mutex();

const createBaseQuery = (baseUrl: string = process.env.REACT_APP_BASE_API_URL): CustomBaseQueryFn =>
  fetchBaseQuery({
    baseUrl,
    credentials: 'same-origin',
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem('accessToken');

      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }

      headers.set('x-anonymous-id', localStorage.getItem('ajs_anonymous_id') || '');

      return headers;
    },
  }) as CustomBaseQueryFn;

export const authBaseUrl = process.env.REACT_APP_AUTH_API_URI || process.env.REACT_APP_BASE_API_URL;
export const iamBaseUrl = process.env.REACT_APP_IAM_API_URI || process.env.REACT_APP_BASE_API_URL;

const appBaseQuery = createBaseQuery();
const authBaseQuery = createBaseQuery(authBaseUrl);

const baseQueryWithReAuth: CustomBaseQueryFn = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  let result = await appBaseQuery(args, api, extraOptions);

  const url = typeof args === 'string' ? args : args.url;

  if (result?.error?.status === httpStatusCodes.unauthorized && !refreshSkipPath.includes(url)) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      const { location } = window;
      const { pathname } = location;

      if (pathname !== locations.login()) {
        sessionStorage.setItem('lastPage', pathname);
      }

      try {
        // send refresh token to get new access token
        const refreshResult = await authBaseQuery(
          { url: '/auth/auth/refresh-token', method: 'POST' },
          api,
          extraOptions,
        );

        if (refreshResult?.data) {
          // store the new token
          api.dispatch(setCredentials(refreshResult.data as LoginResponse));
          // retry the original query with new access token
          result = await appBaseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logOut());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();

      result = await appBaseQuery(args, api, extraOptions);
    }
  }

  if (result?.error?.status === httpStatusCodes.forbidden) {
    console.error('[baseQueryWithReAuth error 403]', result.error);

    captureException(result.error);
  }

  if (result.data && (result?.data as ApiResponse)?.status === 'success') {
    return { ...result, data: (result.data as ApiResponse).data };
  }

  if (result?.error?.data) {
    return { ...result, data: result.error.data };
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
  tagTypes: Object.values(ApiTagType),
  // global configuration for the api
  refetchOnReconnect: true,
});
