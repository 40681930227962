import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import COLORS from '../../../constants/colors';
import PortfolioTargetAllocationTable from '../../../shared/PortfolioTargetAllocationTable';
import { RecommendedPortfolio, SubscribedPortfolio } from '../../../store_deprecated/types/portfolioTypes';
import PortfolioDoughnutChart from '../../../shared/PortfolioDoughnutChart';
import { getSurveyResult } from '../../../store_deprecated/selectors/surveySelectors';
import { formatNumber } from '../../../utils/number';

interface TargetAllocationProps {
  portfolio?: SubscribedPortfolio | RecommendedPortfolio;
  cashAllocationPercentage: number;
}

const TargetAllocationTab: React.FC<TargetAllocationProps> = (props) => {
  const { portfolio, cashAllocationPercentage } = props;
  const stocksPercentage = 90;
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  const surveyResult = useSelector(getSurveyResult);

  const category = isEnglish ? surveyResult?.category?.nameEn : surveyResult?.category?.nameAr;

  return (
    <Grid
      container
      component="div"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      rowSpacing="64px"
    >
      <Grid
        item
        sx={{
          width: '100%',
          '@media screen and (orientation: portrait)': {
            minHeight: {
              xs: '60vh !important',
              sm: '60vh !important',
              md: '70vh !important',
              lg: '70vh !important',
            },
          },
          '@media screen and (orientation: landscape)': {
            minHeight: {
              xs: '100vh !important',
              sm: '110vh !important',
              md: '70vh !important',
              lg: '70vh !important',
            },
          },
          '@media (max-width: 900px) and (orientation: landscape)': {
            canvas: {
              m: 'unset !important',
            },
          },
          maxHeight: {
            xs: '60vh !important',
            sm: '60vh !important',
            md: '70vh !important',
            lg: '70vh !important',
          },
          maxWidth: {
            xs: '100% !important',
            sm: '100% !important',
            md: '80% !important',
            lg: '60% !important',
          },
          marginTop: { xs: '-50px', sm: '-50px' },
          marginBottom: { xs: '-100px', sm: '-100px' },
        }}
        xs={12}
        md={9}
        lg={6}
        xl={6}
      >
        <PortfolioDoughnutChart portfolio={portfolio} cashAllocationPercentage={cashAllocationPercentage} />
      </Grid>
      <Grid
        item
        container
        width="100%"
        maxWidth={350}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6} textAlign="left">
          <Typography
            component="span"
            sx={{
              color: COLORS.LIGHT_GRAY,
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            {t('risk')}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography
            component="span"
            sx={{
              color: COLORS.MAIN_DARK,
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '28px',
            }}
          >
            {category}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="left">
          <Typography
            component="span"
            sx={{
              color: COLORS.LIGHT_GRAY,
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            {t('targetAllocation')}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography
            component="span"
            sx={{
              color: COLORS.MAIN_DARK,
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '28px',
            }}
          >
            {`${formatNumber(stocksPercentage, i18n.language, 2)}% ${t('stocks')}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <PortfolioTargetAllocationTable portfolio={portfolio} cashAllocationPercentage={cashAllocationPercentage} />
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <Typography
          variant="bodyLarge"
          sx={{
            color: COLORS.DARK_GREY,
            fontWeight: 400,
            fontSize: { xs: '12px', md: '16px' },
            lineHeight: '28px',
          }}
        >
          {t('portfolioPositions.hint')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TargetAllocationTab;
