import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import COLORS from '../../../constants/colors';

interface FundAmountFieldProps {
  isArabic: boolean;
  value: string;
  label?: string;
  placeholder?: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

/**
 * @constant
 * @type {number}
 *
 * 15 for a digits + 4 for commas
 */
const MAX_LENGTH = 19;

const FundAmountField: React.FC<FundAmountFieldProps> = (props) => {
  const {
    isArabic,
    value,
    handleChange,
    label = 'addMoreFundDialog.foundAmountFieldLabel',
    placeholder = 'addMoreFundDialog.foundAmountFieldPlaceholder',
  } = props;
  const { t } = useTranslation();

  return (
    <FormControl sx={{ width: '100%' }} variant="outlined">
      <Typography
        variant={isArabic ? 'cairoM' : 'bodyMedium'}
        color={COLORS.MAIN_DARK}
        textAlign="justify"
        paragraph
        sx={{ marginBottom: 1 }}
      >
        {t(label)}
      </Typography>
      <InputBase
        required
        fullWidth
        id="fundAmount"
        name="fundAmount"
        type="text"
        value={value.replace(/٬/g, ',')}
        placeholder={t(placeholder)}
        autoFocus
        onChange={handleChange}
        sx={{
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: COLORS.XLIGHT_GRAY,
          borderRadius: '8px',
          height: '100%',
          '&:hover': {
            borderColor: 'black',
          },
        }}
        inputProps={{ style: { padding: 16 }, maxLength: MAX_LENGTH }}
        endAdornment={
          <Typography
            variant={isArabic ? 'cairoM' : 'bodyMedium'}
            color={COLORS.MAIN_DARK}
            textAlign="center"
            paragraph
            sx={{ m: 0, mr: 2 }}
          >
            {t('currency.SAR')}
          </Typography>
        }
      />
    </FormControl>
  );
};

export default FundAmountField;
