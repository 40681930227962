import { Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import COLORS from '../../../constants/colors';
import { getUserIndexFundsSelector } from '../../../store_deprecated/selectors/indexFundSelectors';
import {
  getPortfolioStatisticsSelector,
  getSubscribedPortfolioSelector,
} from '../../../store_deprecated/selectors/portfolioSelectors';
import { madkhoulBankInfoSelector } from '../../../store_deprecated/selectors/walletSelectors';
import {
  SubscribedPortfolio,
  SubscribedPortfolioStatus,
  SubscriptionStatus,
} from '../../../store_deprecated/types/portfolioTypes';
import { round2Format, formatNumber } from '../../../utils/number';
import { PortfolioPositions } from '../../../utils/PortfolioPositions';

type WidgetsValues = {
  totalCostBase: string;
  totalMarketValue: string;
  totalSarReturn: string;
  totalReturn: string;
  totalAmount: string;
};

type WidgetItem = {
  title: string;
  value: string;
  tooltip: string | number;
  currency: string;
  color: string;
  valueKey: keyof WidgetsValues;
};

interface WidgetsProps {
  beta?: boolean;
}

const dashboardWidgets: WidgetItem[] = [
  {
    title: 'totalCostBaseWidgetTitle',
    value: '',
    tooltip: '',
    currency: 'SAR',
    color: COLORS.MAIN_DARK,
    valueKey: 'totalCostBase',
  },
  {
    title: 'totalMarketValueWidgetTitle',
    value: '',
    tooltip: '',
    currency: '',
    color: COLORS.SECONDARY_GREEN,
    valueKey: 'totalMarketValue',
  },
  {
    title: 'totalSarReturnWidgetTitle',
    value: '',
    tooltip: '',
    currency: 'SAR',
    color: COLORS.MAIN_DARK,
    valueKey: 'totalSarReturn',
  },
  {
    title: 'totalReturnWidgetTitle',
    value: '',
    tooltip: '',
    currency: '',
    color: COLORS.PRIMARY_BLUE,
    valueKey: 'totalReturn',
  },
  {
    title: 'totalAmountWidgetTitle',
    value: '',
    tooltip: '',
    currency: 'SAR',
    color: COLORS.ORANGE,
    valueKey: 'totalAmount',
  },
];
const compoundReturnWidgets: WidgetItem[] = [
  {
    title: 'totalReturnCompoundWidgetTitle',
    value: '',
    tooltip: '',
    currency: '',
    color: COLORS.PRIMARY_BLUE,
    valueKey: 'totalReturn',
  },
];

const WidgetsV2: React.FC<WidgetsProps> = ({ beta = false }) => {
  const [widgets, setWidgets] = useState<WidgetItem[]>(beta ? compoundReturnWidgets : dashboardWidgets);

  const { t, i18n } = useTranslation();
  const percentFormatter = Intl.NumberFormat(i18n.language === 'en' ? 'en' : 'ar-SA', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const subscribedPortfolio = useSelector(getSubscribedPortfolioSelector);
  const { latest: portfolioStatisticsData } = useSelector(getPortfolioStatisticsSelector);
  const userIndexFunds = useSelector(getUserIndexFundsSelector);
  const bankInfo = useSelector(madkhoulBankInfoSelector);

  useEffect(() => {
    let userPortfolios: SubscribedPortfolio['userPortfolios'] = [];

    if (subscribedPortfolio?.status?.name === SubscribedPortfolioStatus.ACCEPTED) {
      userPortfolios = subscribedPortfolio.userPortfolios || [];
    }

    const cashValue = userPortfolios.reduce((res, up) => {
      const uniqStatuses = [...new Set(up?.userPortfolioRequests?.map((upr) => upr.status.name))];

      if (uniqStatuses.length === 1 && uniqStatuses[0] === SubscriptionStatus.APPROVED) {
        return res + up.cacheAllocationValue;
      }

      return res;
    }, 0);

    const portfolioPositions = new PortfolioPositions(
      beta
        ? {
            realTime: false,
            portfolio: subscribedPortfolio,
            userIndexFunds,
            portfolioStatisticsData,
          }
        : {
            realTime: true,
            portfolio: subscribedPortfolio,
            userIndexFunds,
          },
    );

    const values = portfolioPositions.calculateTotalValues(portfolioPositions.calculatePositions());

    const totalMarketValue = values.totalMarketValue + cashValue;
    const netDeposit = bankInfo?.userBankDetails?.amount || 0;

    const widgetsValues = {
      totalCostBase: round2Format(values.totalCostBase + cashValue, i18n.language),
      totalMarketValue: round2Format(totalMarketValue, i18n.language),
      totalSarReturn: round2Format(values.totalSarReturn, i18n.language),
      totalReturn: percentFormatter.format((values.totalReturn || 0) / 100),
      totalAmount: round2Format(totalMarketValue + netDeposit, i18n.language),
    };

    if (userIndexFunds.length) {
      setWidgets(
        widgets.map((widget) => ({
          ...widget,
          value: widgetsValues[widget.valueKey],
          tooltip: widgetsValues[widget.valueKey],
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIndexFunds, bankInfo, portfolioStatisticsData, subscribedPortfolio, i18n.language]);

  return (
    <Grid item container columnSpacing={{ xs: 3, xl: 6 }} rowSpacing={0} justifyContent="space-between">
      {widgets.map((w) => (
        <Tooltip title={w.tooltip} followCursor key={w.title}>
          <Grid
            item
            sx={{
              mb: { xs: '12px', md: 'unset' },
              width: { xs: '100%', md: '20%' },
            }}
          >
            <Grid
              container
              flexDirection="column"
              component={Paper}
              elevation={0}
              sx={{
                p: { xs: '10px', xl: '20px' },
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
                borderRadius: '12px',
              }}
            >
              <Grid item>
                <Typography
                  sx={{
                    color: COLORS.LIGHT_GRAY,
                    fontWeight: 400,
                    fontSize: {
                      xs: '0.875rem',
                      lg: '0.7rem',
                      xl: '0.875rem',
                    },
                    lineHeight: '24px',
                  }}
                  variant="body2"
                >
                  {t(w.title)}
                </Typography>
              </Grid>
              <Grid item flexDirection="row" display="flex" justifyContent="space-between">
                <Typography
                  sx={{
                    color: w.color,
                    fontWeight: 500,
                    fontSize: { xs: '1.2rem', lg: '1.1rem', xl: '1.5rem' },
                    lineHeight: '32px',
                  }}
                  variant="h5"
                >
                  {beta
                    ? // @ts-ignore
                      formatNumber(portfolioStatisticsData?.indexFunds?.totalReturn, i18n.language, 2)
                    : w.value.replace(/٬/g, ',')}
                </Typography>
                <Typography
                  sx={{
                    color: COLORS.MAIN_DARK,
                    fontWeight: 400,
                    fontSize: {
                      xs: '0.875rem',
                      lg: '0.7rem',
                      xl: '0.875rem',
                    },
                    lineHeight: '24px',
                    minHeight: '24px',
                  }}
                  variant="body2"
                >
                  {w.currency && t(`currency.${w.currency}`)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Tooltip>
      ))}
    </Grid>
  );
};

export default WidgetsV2;
