import { createSlice } from '@reduxjs/toolkit';

const getProductsSlice = createSlice({
  name: 'getProductsSlice',
  initialState: { products: [] },
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
  },
});

export const { setProducts } = getProductsSlice.actions;

export default getProductsSlice.reducer;
