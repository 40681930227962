import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ContactForm from './ContactForm';
import './popup-form.css';

interface PopupFormProps {
  showEmailUsPopup: boolean;
  handleShowEmailUsPopup: () => void;
}

const PopupForm: React.FC<PopupFormProps> = ({ showEmailUsPopup, handleShowEmailUsPopup }) => (
  <Fade in={showEmailUsPopup}>
    <Box
      className="email-form"
      sx={{
        margin: { xs: 'auto', md: 'unset' },
        left: { xs: 0, md: 'unset' },
        right: { xs: 0, md: 0 },
      }}
    >
      <Box
        sx={{
          width: '100%',
          marginBottom: 2,
          textAlign: 'right',
        }}
      >
        <IconButton onClick={handleShowEmailUsPopup}>
          <CloseIcon id="ContactPopupCloseIcon" />
        </IconButton>
      </Box>
      <Grid container display="flex" justifyContent="center" alignItems="center">
        <ContactForm showEmailUsPopup={showEmailUsPopup} handleShowEmailUsPopup={handleShowEmailUsPopup} />
      </Grid>
    </Box>
  </Fade>
);

export default PopupForm;
