import Chip, { chipClasses } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const CustomChip = styled(Chip)({
  [`&.${chipClasses.root}`]: {
    [`& .${chipClasses.label}`]: {
      whiteSpace: 'pre-wrap',
    },
  },
});
