import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Tooltip } from '@mui/material';
import { madkhoulBankInfoSelector, statementSelector } from '../../../store_deprecated/selectors/walletSelectors';
import { getUserIndexFundsSelector } from '../../../store_deprecated/selectors/indexFundSelectors';
import COLORS from '../../../constants/colors';
import {
  getPortfolioStatisticsSelector,
  getSubscribedPortfolioSelector,
} from '../../../store_deprecated/selectors/portfolioSelectors';
import {
  SubscribedPortfolio,
  SubscribedPortfolioStatus,
  SubscriptionStatus,
} from '../../../store_deprecated/types/portfolioTypes';
import { round2Format } from '../../../utils/number';
import { getAlpacaDividendsSelector } from '../../../store_deprecated/selectors/alpacaSelectors';
import { ProductPositions } from '../../../utils/ProductPositions';

type WidgetItem = {
  title: string;
  value: string;
  tooltip: string | number;
  currency: string;
  color: string;
};

const Widgets: React.FC<{ subscribedProducts: any }> = ({ subscribedProducts }) => {
  const { t, i18n } = useTranslation();
  const [widgets, setWidgets] = useState<WidgetItem[]>([
    {
      title: 'totalIncomeWidgetTitle',
      value: '0K',
      tooltip: '0',
      currency: 'SAR',
      color: COLORS.SECONDARY_GREEN,
    },
    {
      title: 'totalCashWidgetTitle',
      value: '0K',
      tooltip: '0',
      currency: 'SAR',
      color: COLORS.SECONDARY_GREEN,
    },
    {
      title: 'totalMarketValueWidgetTitle',
      value: '0K',
      tooltip: '0',
      currency: 'SAR',
      color: COLORS.SECONDARY_GREEN,
    },
    {
      title: 'dividendsPayoutWidgetTitle',
      value: '0K',
      tooltip: '0',
      currency: 'SAR',
      color: COLORS.ORANGE,
    },
    {
      title: 'madaCardPaymentsWidgetTitle',
      value: '0K',
      tooltip: '0',
      currency: 'SAR',
      color: COLORS.ORANGE,
    },
  ]);

  const subscribedPortfolio = useSelector(getSubscribedPortfolioSelector);
  const { latest: data } = useSelector(getPortfolioStatisticsSelector);
  const { dividends } = useSelector(getAlpacaDividendsSelector);
  const { statementDelta } = useSelector(statementSelector);
  // const userIndexFunds = useSelector(getUserIndexFundsSelector);
  const bankInfo = useSelector(madkhoulBankInfoSelector);

  const subscribedPortfolios = subscribedProducts?.filter(
    (sp: any) => sp.status.name === SubscribedPortfolioStatus.ACCEPTED,
  );

  useEffect(() => {
    let overallMarketValue = 0;

    subscribedPortfolios?.forEach((sp: any) => {
      const userIndexFunds = sp.subscribedPortfolioAssociatedIndexFund;

      const cashValue = sp.cacheAllocationValue;

      const portfolioPositions = new ProductPositions({
        realTime: true,
        portfolio: {
          ...sp,
          associatedIndexFunds: sp.subscribedPortfolioAssociatedIndexFund,
        },
        userIndexFunds,
        // @ts-ignore
        portfolioStatisticsData: data,
      });

      const values = portfolioPositions.calculateTotalValues(portfolioPositions.calculatePositions());

      const totalMarketValue = values.totalMarketValue + cashValue + dividends;

      overallMarketValue = (overallMarketValue || 0) + totalMarketValue;

      return overallMarketValue;
    });

    let userPortfolios: SubscribedPortfolio['userPortfolios'] = [];

    if (subscribedPortfolio?.status?.name === SubscribedPortfolioStatus.ACCEPTED) {
      userPortfolios = subscribedPortfolio.userPortfolios || [];
    }

    // OLD cash value and total market value calculations

    // const cashValue = userPortfolios.reduce((res, up) => {
    //   const uniqStatuses = [...new Set(up?.userPortfolioRequests?.map((upr) => upr.status.name))];

    //   if (uniqStatuses.length === 1 && uniqStatuses[0] === SubscriptionStatus.APPROVED) {
    //     return res + up.cacheAllocationValue;
    //   }

    //   return res;
    // }, 0);

    // const totalMarketValue = userIndexFunds.reduce(
    //   (res, item) => res + item.indexFund.netAssetValuePerUnit * (item.numOfUnits || 0) + cashValue + dividends,
    //   cashValue,
    // );

    setWidgets([
      {
        ...widgets[0],
        value: round2Format((bankInfo?.userBankDetails?.amount || 0) + overallMarketValue, i18n.language),
        tooltip: round2Format((bankInfo?.userBankDetails?.amount || 0) + overallMarketValue, i18n.language),
      },
      {
        ...widgets[1],
        value: round2Format(bankInfo?.userBankDetails?.amount || 0, i18n.language, true),
        tooltip: round2Format(bankInfo?.userBankDetails?.amount || 0, i18n.language, true),
      },
      {
        ...widgets[2],
        value: round2Format(overallMarketValue || 0, i18n.language),
        tooltip: round2Format(overallMarketValue || 0, i18n.language),
      },
      {
        ...widgets[3],
        value: round2Format(dividends || 0, i18n.language),
        tooltip: round2Format(dividends || 0, i18n.language),
      },
      {
        ...widgets[4],
        value: round2Format(statementDelta || 0, i18n.language),
        tooltip: round2Format(statementDelta || 0, i18n.language),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankInfo, data, subscribedPortfolio, subscribedProducts, dividends, statementDelta, i18n.language]);

  return (
    <Grid item container columnSpacing={{ xs: 3, xl: 6 }} rowSpacing={0} justifyContent="space-between">
      {widgets.map((w) => (
        <Tooltip title={w.tooltip} followCursor key={w.title}>
          <Grid
            item
            sx={{
              mb: { xs: '12px', lg: 'unset' },
              width: { xs: '100%', md: '100%', lg: '20%' },
            }}
          >
            <Grid
              container
              flexDirection="column"
              component={Paper}
              elevation={0}
              sx={{
                p: { xs: '10px', xl: '20px' },
                boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
                borderRadius: '12px',
                justifyContent: 'center',
                height: '100px',
              }}
            >
              <Grid item>
                <Typography
                  sx={{
                    color: COLORS.LIGHT_GRAY,
                    fontWeight: 400,
                    fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
                    lineHeight: '24px',
                  }}
                  variant="body2"
                >
                  {t(w.title)}
                </Typography>
              </Grid>
              <Grid item flexDirection="row" display="flex" justifyContent="space-between" alignItems="center">
                <Typography
                  sx={{
                    color: COLORS.SECONDARY_GREEN,
                    fontWeight: 500,
                    fontSize: 'clamp(1rem,1vw + 1rem,1.5rem)',
                    lineHeight: '32px',
                  }}
                  variant="h5"
                >
                  {w.value.replace(/٬/g, ',')}
                </Typography>
                <Typography
                  sx={{
                    color: COLORS.MAIN_DARK,
                    fontWeight: 400,
                    fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
                    lineHeight: '24px',
                  }}
                  variant="body2"
                >
                  {t(`currency.${w.currency}`)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Tooltip>
      ))}
    </Grid>
  );
};

export default Widgets;
