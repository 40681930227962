import { IndexFund, UserIndexFund } from '../store_deprecated/types/indexFundTypes';
import {
  PortfolioStatisticsData,
  RecommendedPortfolio,
  SubscribedPortfolio,
} from '../store_deprecated/types/portfolioTypes';

interface PortfolioPositionsCommonData {
  portfolio: SubscribedPortfolio | RecommendedPortfolio;
  userIndexFunds: UserIndexFund[];
}

interface PortfolioPositionsRealTimeData extends PortfolioPositionsCommonData {
  realTime: true;
}

interface PortfolioPositionsStatisticsData extends PortfolioPositionsCommonData {
  realTime: false;
  portfolioStatisticsData: PortfolioStatisticsData | null;
}

type PortfolioPositionsData = PortfolioPositionsRealTimeData | PortfolioPositionsStatisticsData;

export type PortfolioPositionSimpleRowDataItem = {
  indexFund: IndexFund;
  id: number;
  allocation: number;
  units: number;
  todayNav: number;
  costBase: number;
  costBaseValue: number;
  marketValue: number;
  totalSarReturn: number;
};

export type PortfolioPositionRowDataItem = PortfolioPositionSimpleRowDataItem & {
  totalReturn: number;
};

export class PortfolioPositions {
  #realTime: boolean;
  #portfolio: SubscribedPortfolio | RecommendedPortfolio;
  #userIndexFunds: UserIndexFund[];
  #portfolioStatisticsData: PortfolioStatisticsData | null;

  constructor(data: PortfolioPositionsData) {
    this.#realTime = data.realTime;
    this.#portfolio = data.portfolio;
    this.#userIndexFunds = data.userIndexFunds;
    this.#portfolioStatisticsData = 'portfolioStatisticsData' in data ? data.portfolioStatisticsData : null;
  }

  public calculatePositions() {
    if (this.#realTime) {
      return this.#generatePositionsWithTotalReturn(this.#generateSimplePositions());
    }

    return this.#generatePositionsFromStatistics();
  }

  public calculateTotalValues(items: PortfolioPositionRowDataItem[]) {
    return items.reduce(
      (res, item) => {
        res.totalSarReturn += item.totalSarReturn;
        res.totalCostBase += item.costBaseValue;
        res.totalMarketValue += item.marketValue;
        res.totalReturn += item.totalReturn;

        return res;
      },
      { totalSarReturn: 0, totalReturn: 0, totalCostBase: 0, totalMarketValue: 0 },
    );
  }

  #generateSimplePositions() {
    return (
      (this.#portfolio?.associatedIndexFunds || []).map<PortfolioPositionSimpleRowDataItem>((paif) => {
        const userIndexFund = this.#userIndexFunds.find((uif) => uif.indexFundId === paif.indexFundId) || {
          numOfUnits: 0,
        };
        const marketValue = userIndexFund.numOfUnits * paif.indexFund.netAssetValuePerUnit;
        const costBase = paif?.costBase || 0;
        const costBaseValue = userIndexFund.numOfUnits * (paif?.costBase || 0);
        const totalSarReturn = marketValue - costBaseValue;

        return {
          id: paif.id,
          indexFund: paif.indexFund,
          allocation: paif.weight,
          units: userIndexFund.numOfUnits,
          todayNav: paif.indexFund.netAssetValuePerUnit,
          costBase,
          costBaseValue,
          marketValue,
          totalSarReturn,
        };
      }) || []
    );
  }

  #generatePositionsWithTotalReturn(positions: PortfolioPositionSimpleRowDataItem[]): PortfolioPositionRowDataItem[] {
    const totalCostBase = positions.reduce((res, item) => res + item.costBaseValue, 0);

    return positions.map((position) => ({
      ...position,
      totalReturn:
        (position.totalSarReturn / position.costBaseValue) * ((position.costBaseValue / totalCostBase) * 100),
    }));
  }

  #generatePositionsFromStatistics() {
    return (
      this.#portfolio?.associatedIndexFunds.map<PortfolioPositionRowDataItem>((item) => {
        const statistics = this.#portfolioStatisticsData?.indexFunds?.calculationTable.find(
          (ctm) => ctm.indexFundId === item.indexFund.id,
        ) || {
          costBase: 0,
          unitsEndOfDay: 0,
          navEndOfDay: 0,
          totalReturn: 0,
          totalSarReturn: 0,
          mvStartOfDay: 0,
          mvEndOfDay: 0,
          fundAddition: 0,
        };
        const dailyCompound = this.#portfolioStatisticsData?.indexFunds?.dailyCompounding?.find(
          (dc) => dc.indexFundId === item.indexFund.id,
        ) || { totalReturn: 0 };
        const marketValue = statistics.mvEndOfDay;
        const costBase = statistics.costBase || 0;
        const costBaseValue = statistics.unitsEndOfDay * (statistics.costBase || 0);
        const totalSarReturn = statistics.totalSarReturn || 0;
        const { totalReturn } = dailyCompound;

        return {
          indexFund: item.indexFund,
          id: item.id,
          allocation: item.weight,
          units: statistics.unitsEndOfDay,
          todayNav: statistics.navEndOfDay,
          costBase,
          costBaseValue,
          marketValue,
          totalReturn,
          totalSarReturn,
        };
      }) || []
    );
  }
}
