import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCheckUserAuthQuery } from '../../redux/features/auth/auth.apiSlice';
import { setUserProfile } from '../../redux/features/auth/auth.slice';
import { useAppDispatch } from '../../redux/hooks';
import { authSelector } from '../../redux/features/auth/auth.selectors';
import { locations } from '../../routes/locations';

export function useAutoLogin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLoggedIn } = useSelector(authSelector);

  const { data: userProfile } = useCheckUserAuthQuery(undefined, { skip: isLoggedIn === false });

  useEffect(() => {
    if (isLoggedIn === false) {
      sessionStorage.setItem('lastPage', pathname);

      navigate(locations.login(), {
        replace: true,
        state: { from: pathname },
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (userProfile) {
      dispatch(setUserProfile(userProfile));
    }
  }, [userProfile]);
}
