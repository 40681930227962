/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import COLORS from '../../../constants/colors';

interface CountDownProps {
  initialMinutes?: number;
  initialSeconds?: number;
  otpSendTime: number;
  setOtpSendTime: (v: number) => void;
  resendOTP: () => void;
}

const CountDown = (props: CountDownProps) => {
  const { initialMinutes = 1, initialSeconds = 0, otpSendTime, setOtpSendTime, resendOTP } = props;
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [enableResend, setEnableResend] = useState(false);
  const { t } = useTranslation('auth', { useSuspense: true });

  useEffect(() => {
    if (!otpSendTime) {
      return;
    }

    const myInterval = setInterval(() => {
      const newSeconds = initialSeconds + initialMinutes * 60 - Math.floor((Date.now() - otpSendTime) / 1000);
      const newMinutes = Math.floor(newSeconds / 60);

      setSeconds(newSeconds);
      setMinutes(newMinutes);

      if (newSeconds <= 0) {
        clearInterval(myInterval);
        setEnableResend(true);
        setSeconds(0);
        setMinutes(0);
        setOtpSendTime(0);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [otpSendTime]);

  const CustomButton = styled(Button)({
    '&:hover': {
      backgroundColor: 'white',
    },
  });

  const sendOTP = async () => {
    setMinutes(initialMinutes);
    setSeconds(initialSeconds);
    setEnableResend(false);
    await resendOTP();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        mb: 6,
      }}
    >
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          {`${minutes}`.padStart(2, '0')}:{`${seconds}`.padStart(2, '0')}
        </Typography>
      </Box>
      <CustomButton disabled={!enableResend} onClick={sendOTP}>
        <Typography
          variant="bodyMediumB"
          sx={{
            textTransform: 'capitalize',
            color: enableResend ? COLORS.PRIMARY_BLUE : COLORS.LIGHT_GRAY,
            fontWeight: 400,
          }}
        >
          {t('resendOTP')}
        </Typography>
      </CustomButton>
    </Box>
  );
};

export default CountDown;
