import COLORS from '../constants/colors';

export const TABLE_COLORS = [
  COLORS.CYAN,
  COLORS.PRIMARY_BLUE,
  COLORS.DARK_BLUE,
  COLORS.X_LIGHT_GREEN,
  COLORS.LIGHT_GREEN,
  COLORS.SECONDARY_GREEN,
];

export const getSaveChartColor = (itemsLength: number, index: number) => {
  if (itemsLength - index === 1 && itemsLength > 1) {
    const prevColor = getSaveChartColor(itemsLength, index - 1);
    const firstColor = getSaveChartColor(itemsLength, 0);
    const leftColors: string[] = TABLE_COLORS.filter((color) => color !== prevColor && color !== firstColor);

    return leftColors.pop()!;
  }

  return TABLE_COLORS[index % TABLE_COLORS.length];
};
