import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HomeView from '../../components/User/Home/HomeView';
import SURVEY_STATUS from '../../constants/SurveyStatus';
import { useTypedDispatch } from '../../redux/store';
import { fetchAnsweredQuestion, fetchConfig, fetchCurrentQuestion } from '../../store_deprecated/actions/surveyActions';
import {
  getConfigRequestIsFailed,
  getConfigRequestIsLoading,
  getQuestion,
} from '../../store_deprecated/selectors/surveySelectors';
import { GlobalState } from '../../store_deprecated/types';
import { AnsweredQuestionPayload, ConfigData, QuestionPayload } from '../../store_deprecated/types/surveyTypes';

const Loader = React.lazy(() => import('../../shared/Loader'));
const Error = React.lazy(() => import('../../shared/Error'));

const Home: React.FC = () => {
  const dispatch = useTypedDispatch();

  const [config, setConfig] = useState<ConfigData>({ surveyStatus: '' });
  const configRequestIsLoading = useSelector(getConfigRequestIsLoading);
  const configRequestIsFailed = useSelector(getConfigRequestIsFailed);
  const question = useSelector((state: GlobalState) =>
    getQuestion(state, config?.currentSurvey?.nextQuestionOrder || -1),
  );

  const fetchAnswer = async (payload: AnsweredQuestionPayload) => {
    await dispatch(fetchAnsweredQuestion(payload));
  };

  const fetchQuestion = async (payload: QuestionPayload) => {
    const questionId = await dispatch(fetchCurrentQuestion(payload));
    // @ts-ignore
    fetchAnswer({ surveyResultId: payload.surveyResultId, questionId });
  };

  useEffect(() => {
    const prefetchData = async () => {
      const configData: ConfigData = (await dispatch(fetchConfig())) as any;

      setConfig(configData);
    };

    prefetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (config.surveyStatus === SURVEY_STATUS.PENDING) {
      // @ts-ignore
      const { id, nextQuestionOrder, surveyResultId } = config.currentSurvey;

      if (!question) {
        const payload = { surveyId: id, surveyResultId, order: nextQuestionOrder };
        fetchQuestion(payload);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  if (configRequestIsLoading) return <Loader />;

  if (configRequestIsFailed) return <Error />;

  return <HomeView surveyStatus={config.surveyStatus} />;
};

export default Home;
