export const arNumbers = '٠١٢٣٤٥٦٧٨٩٫٬';
export const enNumbers = '0123456789.,';

export const en2Ar = (s: string) => s.replace(/\d/g, (d: string) => arNumbers[+d]);

export const ar2En = (s: string) =>
  s
    .replace(/[٠-۹]/g, (d) => enNumbers[arNumbers.indexOf(d)])
    // eslint-disable-next-line no-useless-escape
    .replace(/\,/g, '');

const getFormatter = (language: string, options: Intl.NumberFormatOptions) =>
  Intl.NumberFormat(language === 'ar' ? 'ar-SA' : 'en-US', options);

function getRoundCustom(fractions: number, withFormat: false): (value: number) => number;
function getRoundCustom(
  fractions: number,
  withFormat: true,
): (value: number, language?: string, roundDown?: boolean) => string;
function getRoundCustom(
  fractions: number,
  withFormat: boolean,
): (value: number, language?: string, roundDown?: boolean) => number | string {
  return (value: number, language = 'en', roundDown = false) => {
    const roundFn = roundDown ? Math.floor : Math.round;
    let resValue = roundFn((value + Number.EPSILON) * fractions) / fractions;

    // eslint-disable-next-line no-compare-neg-zero
    if (resValue === -0) {
      resValue = 0;
    }

    if (!withFormat) {
      return resValue;
    }

    const fractionDigits = `${fractions}`.split('').filter((d) => d === '0').length;
    const formatter = getFormatter(language, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });

    return formatter.format(resValue);
  };
}

export const round2 = getRoundCustom(100, false);
export const round4 = getRoundCustom(10000, false);

export const round2Format = getRoundCustom(100, true);
export const round4Format = getRoundCustom(10000, true);

export const formatNumber = (value: number, language = 'en', minimumFractionDigits = 0) =>
  (value || value === 0) &&
  value
    .toLocaleString(language === 'ar' ? 'ar-SA' : 'en-US', {
      minimumFractionDigits,
      maximumFractionDigits: 2,
    })
    .replace(/٬/g, ',');

export const moneyFormatter = (num: number) => {
  if (num > 999 && num < 1000000) {
    return `${(num / 1000).toFixed(2)}K`; // convert to K for number from > 1000 < 1 million
  }
  if (num > 1000000) {
    return `${(num / 1000000).toFixed(2)}M`; // convert to M for number from > 1 million
  }
  if (num < 900) {
    return num.toFixed(2); // if value < 1000, nothing to do
  }
};
