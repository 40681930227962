import * as yup from 'yup';
import { recurringPaymentFormFields } from '../fields/recurringPayment';

export const recurringPaymentFormSchema = yup.object({
  [recurringPaymentFormFields.amount]: yup.number().required('missingField').min(1, 'Min amount is 1 SAR'),
  [recurringPaymentFormFields.frequencyUnit]: yup.string().required('missingField'),
  [recurringPaymentFormFields.endDateType]: yup.string().required('missingField'),
  [recurringPaymentFormFields.startDate]: yup.date().required('missingField'),
  [recurringPaymentFormFields.endDate]: yup.date().nullable().optional(),
});
