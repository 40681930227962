export * from './profileData';
export * from './changePassword';
export * from './nafazData';

export enum addBankAccountFormFields {
  bankType = 'bankType',
  bankName = 'bankName',
  fullName = 'fullName',
  accountNumber = 'accountNumber',
  ibanNumber = 'ibanNumber',
  swiftCode = 'swiftCode',
}

export enum resetPasswordMobileFormFields {
  mobile = 'mobile',
}

export enum resetPasswordEmailFormFields {
  email = 'email',
}

export enum resetPasswordNewFormFields {
  newPassword = 'newPassword',
  confirmNewPassword = 'confirmNewPassword',
}

export enum redemptionFormFields {
  amount = 'amount',
  reason = 'reason',
  redeemAll = 'redeemAll',
}
