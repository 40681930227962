import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import { PortfolioAllStatisticsItemData } from '../../../store_deprecated/types/portfolioTypes';
import { getSaveChartColor } from '../../../utils/charts';
import { round2 } from '../../../utils/number';

Chart.register(zoomPlugin);

type Dataset = {
  label: string;
  data: (number | undefined)[];
  borderColor: string;
};

interface LineChartProps {
  statistics: PortfolioAllStatisticsItemData[];
}

const mobileDateFormat = 'D/M/YY';
const webDateFormat = 'M/D/YY';

const formatChartDate = (date: string, matchMobileView: boolean, shiftDays = 0) =>
  moment(date, 'M/DD/YYYY')
    .locale('en')
    .add(shiftDays, 'days')
    .format(matchMobileView ? mobileDateFormat : webDateFormat);

const LineChart = ({ statistics }: LineChartProps) => {
  const { t, i18n } = useTranslation();
  const matchMobileView = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [options, setOptions] = useState<ChartOptions<'line'>>({
    responsive: true,
    elements: {
      line: {
        tension: 0.4,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        // align: "end",
        // anchor: "end",
        // offset: 0,
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          /* @ts-ignore */
          label: ({ dataset, formattedValue }) => `${dataset.label}: ${formattedValue}%`,
        },
      },
      zoom: {
        zoom: {
          pinch: {
            enabled: true,
          },
          wheel: {
            enabled: true,
          },
          drag: {
            enabled: true,
          },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
    },
  });

  const [data, setData] = useState({
    labels: [] as string[],
    datasets: [] as Dataset[],
  });

  useEffect(() => {
    const newData = {
      ...data,
      labels: statistics.map((item) => formatChartDate(item.date, matchMobileView)),
      datasets: [
        {
          ...data.datasets[0],
          pointStyle: false,
          borderColor: getSaveChartColor(1, 0),
          data: [100, ...statistics.map((item) => round2(item.performanceReturn))],
          label: t('portfolioPositions.totalReturn'),
        },
      ],
    };

    setData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistics, i18n.language]);

  useEffect(() => {
    setOptions({
      ...options,
      plugins: {
        ...options.plugins,
        tooltip: {
          ...options.plugins!.tooltip,
          rtl: i18n.language === 'ar',
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    Chart.getChart('line-chart')?.resetZoom();
  }, [statistics]);

  useEffect(() => {
    const currentData = data.datasets[0]?.data;

    if (currentData?.length) {
      /* @ts-ignore */
      const maxValue = Math.max(...currentData);
      setOptions({
        ...options,
        scales: {
          x: {
            ticks: {
              font: {
                size: 10,
              },
            },
          },

          y: {
            max: maxValue + maxValue / 5,
            min: 0,
            type: 'logarithmic',
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <Line id="line-chart" data={data} plugins={[ChartDataLabels]} options={options} />;
};

export default LineChart;
