import React from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Header from '../shared/Header/Header';
import Footer from '../shared/Footer';
import PublicRoute from '../routes/PublicRoute';
import packageJson from '../../package.json';
import COLORS from '../constants/colors';

export default function PublicPageLayout() {
  return (
    <PublicRoute>
      <>
        <Box
          sx={{
            display: 'none',
            '@media (max-width: 1050px)': {
              display: 'block',
            },
          }}
        >
          <Header />
        </Box>
        <Box
          sx={{
            minHeight: 'calc(100vh - 260px)',
          }}
        >
          <Outlet />
        </Box>
        <Box
          sx={{
            display: 'none',
            '@media (max-width: 1050px)': {
              display: 'block',
            },
          }}
        >
          <Footer backgroundColor={COLORS.WHITE} />
        </Box>
        <Box display="none" className="app-version">
          <Typography variant="body2">Version: {packageJson.version}</Typography>
        </Box>
      </>
    </PublicRoute>
  );
}
