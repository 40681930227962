export const LOGOUT = 'LOGOUT';
export const CLEAR_AUTH = 'CLEAR_AUTH';
export const GET_PROFILE_DATA_REQUEST = 'GET_PROFILE_DATA_REQUEST';
export const GET_PROFILE_DATA_SUCCESS = 'GET_PROFILE_DATA_SUCCESS';
export const GET_PROFILE_DATA_FAIL = 'GET_PROFILE_DATA_FAIL';
export const UPDATE_PROFILE_DATA_REQUEST = 'UPDATE_PROFILE_DATA_REQUEST';
export const UPDATE_PROFILE_DATA_SUCCESS = 'UPDATE_PROFILE_DATA_SUCCESS';
export const UPDATE_PROFILE_DATA_FAIL = 'UPDATE_PROFILE_DATA_FAIL';

export enum KycStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum KycType {
  CITIZEN = 'Citizen',
  RESIDENT = 'Resident',
}

export enum KycGender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export interface KycTypeOption {
  id: number;
  name: KycType;
}

export interface KycStatusOption {
  id: number;
  name: KycStatus;
}

export interface KycGenderOption {
  id: number;
  name: KycGender;
}

export type UserAddress = {
  id: number;
  streetName: string;
  city: string;
  address2: string;
  address1: string;
  additionalNumber: string;
  district: string;
  unitNumber: string;
  isPrimaryAddress: boolean;
  buildingNumber: string;
  postCode: string;
  locationCoordinates: string;
  createdAt: Date;
  updatedAt: Date;
};

export type Kyc = {
  id: number;
  nationalId: string;
  fullNameEn: string;
  fullNameAr: string;
  firstNameEn: string;
  firstNameAr: string;
  fatherNameAr: string;
  fatherNameEn: string;
  grandFatherNameAr: string;
  grandFatherNameEn: string;
  familyNameEn: string;
  familyNameAr: string;
  nationalityEn: string;
  nationalityAr: string;
  issueLocationAr: string;
  issueLocationEn: string;
  assuranceLevel: string;
  language: string;
  type: KycTypeOption;
  status: KycStatusOption;
  email: string;
  phone: string;
  address: UserAddress;
  birthDate: Date;
  gender: KycGenderOption;
  idIssueDate: Date;
  idExpiryDate: Date;
  iqamaExpiryDate: Date;
  maritalStatus?: string;
  educationLevel?: string;
  companyName?: string;
  jobTitle?: string;
  employerAddress?: string;
  country?: string;
  fiveYearsFinancialSector?: boolean;
  fiveYearsFinancialSectorDescription?: string;
  boardDirectorsMember?: boolean;
  boardDirectorsMemberDescription?: string;
  individualWithHighProfilePoliticalRole?: boolean;
  individualWithHighProfilePoliticalRoleDescription?: string;
  notBeneficialOwner?: boolean;
  notBeneficialOwnerDescription?: string;
  confirmDate: Date | string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type ProfileDataInfo = Kyc;

export type ProfileData = {
  data: ProfileDataInfo | null;
  isLoading: boolean;
  isUpdateLoading: boolean;
  isErrorExist: boolean;
};

export type UpdateProfileDataPayload = Partial<Omit<Kyc, 'address'>> & {
  emailOtp?: string;
  phoneOtp?: string;
};

export enum UploadEntity {
  nationalId = 'nationalId',
  selfie = 'selfie',
  orderFile = 'orderFile',
  other = 'other',
}

export interface UploadDocumentPayload {
  formData: FormData;
  entity: UploadEntity;
}

export interface RequestGetProfileData {
  type: typeof GET_PROFILE_DATA_REQUEST;
}

export interface RequestGetProfileDataSuccess {
  type: typeof GET_PROFILE_DATA_SUCCESS;
  payload: ProfileDataInfo;
}

export interface RequestGetProfileDataFail {
  type: typeof GET_PROFILE_DATA_FAIL;
}

export interface RequestUpdateProfileData {
  type: typeof UPDATE_PROFILE_DATA_REQUEST;
}

export interface RequestUpdateProfileDataSuccess {
  type: typeof UPDATE_PROFILE_DATA_SUCCESS;
  payload: ProfileDataInfo;
}

export interface RequestUpdateProfileDataFail {
  type: typeof UPDATE_PROFILE_DATA_FAIL;
}

export interface Logout {
  type: typeof LOGOUT;
}

export interface ClearAuth {
  type: typeof CLEAR_AUTH;
}

export type AuthAction =
  | RequestGetProfileData
  | RequestGetProfileDataSuccess
  | RequestGetProfileDataFail
  | RequestUpdateProfileData
  | RequestUpdateProfileDataSuccess
  | RequestUpdateProfileDataFail
  | Logout
  | ClearAuth;

export type AuthState = Readonly<{
  profileData: ProfileData;
}>;
