import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import COLORS from '../../../constants/colors';
import MButton from '../../../shared/MButton';

interface ChangePasswordSuccessfulScreenProps {
  openSuccessfulDialog: boolean;
  onSuccessButtonClick: () => void;
}

const ChangePasswordSuccessfulScreen = ({
  openSuccessfulDialog,
  onSuccessButtonClick,
}: ChangePasswordSuccessfulScreenProps) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  return (
    <Dialog open={openSuccessfulDialog} onClose={onSuccessButtonClick} disableScrollLock>
      <Box
        sx={{
          padding: { xs: '24px 24px 0', md: '48px 48px 0' },
          maxWidth: { xs: '100%', md: '650px' },
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <img src="/images/icons/mail-sent.svg" alt="mail sent" />
        <Typography
          variant={isEnglish ? 'h6' : 'cairoButtonR'}
          color={COLORS.SECONDARY_GREEN}
          paragraph
          textAlign="center"
          sx={{ marginTop: { xs: 2, md: 8 }, marginBottom: { xs: 2, md: 8 } }}
        >
          {t('changePasswordDialog.successfulScreenTitle')}
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          maxWidth: { xs: '100%', md: '650px' },
          margin: 'auto',
          mb: '12px',
          mx: { xs: '24px', md: 'auto' },
        }}
      >
        <MButton
          id="RequestInvestSuccessDoneButton"
          variant="text"
          onClick={onSuccessButtonClick}
          fullWidth
          buttonProps={{
            sx: {
              height: { xs: '50px', md: 60 },
            },
          }}
        >
          {t('done')}
        </MButton>
      </Box>
    </Dialog>
  );
};

export default ChangePasswordSuccessfulScreen;
