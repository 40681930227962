const COLORS = {
  // PRIMARY_BLUE: '#2482BE',
  PRIMARY_BLUE: '#0061A7',
  SECONDARY_GREEN: '#7EA256',
  SECONDARY_LIGHT_GREEN: 'rgba(126, 163, 86, 0.12)',
  MAIN_DARK: '#111827',
  DARK_GREY: '#374151',
  CYAN: '#66C2FE',
  MINT: '#96D9C9',
  TURQUOISE: '#1CAF8C',
  PURPLE: '#8A51C3',
  ACCENT_CYAN: '#69C9DD',
  X_LIGHT_CYAN: '#F4FBFC',
  DARK_BLUE: '#0061A7',
  X_DARK_BLUE: '#0061A7',
  LIGHT_GREEN: '#A9CB85',
  LIGHT_GRAY: '#9CA3AF',
  X_LIGHT_GRAY: '#D1D5DB',
  X_LIGHT_GREEN: '#DBE9CC',
  XX_LIGHT_GREEN: '#E5E7EB',
  LINEAR_GRADIENT: 'linear-gradient(270deg, #2482BE 0, #69C9DD 108.75%)',
  LINEAR_GRADIENT_DARK: 'linear-gradient(270deg, #0061A7 0%, #22A2FF 108.75%);',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  TRANSPARENT: 'transparent',
  XLIGHT_GRAY: '#D1D5DB',
  ORANGE: '#EA9515',
  DARK_ORANGE: '#BF6900',
  RED: '#EF4444',
  RED_WITH_OPACITY: 'rgba(239, 68, 68, 0.6)',
  PLACEHOLDER: '#000000DE',
  GOLD: '#FFD700',
  LIGHT_BACKGROUND: '#f3f4f6',
  GREEN: '#85DE22',
  PRIMARY_LIGHT_GREEN: '#7EA3561F',
  PRIMARY_LIGHT_GRAY: '#F3F4F6',
};

export default COLORS;
