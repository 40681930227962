import React, { ReactElement } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import COLORS from '../constants/colors';

interface BreadcrumbProps {
  breadcrumbs: ReactElement[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumbs }) => (
  <Stack spacing={2}>
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{ color: COLORS.LIGHT_GRAY }} />}>
      {breadcrumbs}
    </Breadcrumbs>
  </Stack>
);

export default Breadcrumb;
