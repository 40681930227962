import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { userPreferenceService } from '../../../api';
import { SaveUserPreferenceRequest } from '../../../types/userPreference';

export const fetchUserPreferenceByType = createAsyncThunk(
  'user-preference/fetch-all',
  (type: string, { rejectWithValue }) =>
    userPreferenceService.fetchUserPreferenceByType(type).catch((error) => rejectWithValue(error)),
);

export const fetchRecommendedPortfolios = createAsyncThunk(
  'user-preference/recommended/portfolio',
  (_, { rejectWithValue }) =>
    userPreferenceService.fetchRecommendedPortfolios().catch((error) => rejectWithValue(error)),
);

export const saveUserPreference = createAsyncThunk(
  'user-preference/save',
  (data: SaveUserPreferenceRequest, { rejectWithValue }) =>
    userPreferenceService.saveUserPreference(data).catch((error) => rejectWithValue(error)),
);

export const deleteUserPreference = createAsyncThunk('user-preference/delete', (type: string, { rejectWithValue }) =>
  userPreferenceService.deleteUserPreference(type).catch((error) => rejectWithValue(error)),
);

export const postRecommendedPortfolios = createAsyncThunk(
  'user-preference/recommended/portfolio//',
  (data: any, { rejectWithValue }) =>
    userPreferenceService.postRecommendedPortfolios(data).catch((error) => rejectWithValue(error)),
);

export const deleteRecommendedPortfolio = createAsyncThunk(
  'user-preference/recommended/portfolio/',
  (id: number, { rejectWithValue }) =>
    userPreferenceService.deleteRecommendedPortfolio(id).catch((error) => rejectWithValue(error)),
);

export const cleanUserPreferences = createAction('user-preference/clean');
