import React from 'react';
import { InlineWidget } from 'react-calendly';
import Box from '@mui/material/Box';

const AskForAdvice: React.FC = () => (
  <Box>
    <InlineWidget url="https://calendly.com/madkhol-team/meet-with-me?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=033b77&text_color=ffffff&primary_color=ffffff" />
  </Box>
);

export default AskForAdvice;
