import {
  AlpacaAction,
  AlpacaState,
  CLEAR_ALPACA,
  GET_ALPACA_ACTIVITIES_FAIL,
  GET_ALPACA_ACTIVITIES_REQUEST,
  GET_ALPACA_ACTIVITIES_SUCCESS,
  GET_ALPACA_DIVIDENDS_FAIL,
  GET_ALPACA_DIVIDENDS_REQUEST,
  GET_ALPACA_DIVIDENDS_SUCCESS,
} from '../types/alpacaTypes';
import { LOGOUT } from '../types/authTypes';

const INITIAL_STATE: AlpacaState = {
  activities: {
    data: [],
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
  dividends: {
    dividends: 0,
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
};

/**
 * TODO: We must migrate to `@reduxjs/toolkit`!
 * @deprecated
 */
const alpacaReducer = (state: AlpacaState = INITIAL_STATE, action: AlpacaAction = { type: LOGOUT }): AlpacaState => {
  switch (action.type) {
    case GET_ALPACA_ACTIVITIES_REQUEST:
      return {
        ...state,
        activities: {
          ...state.activities,
          isLoading: true,
        },
      };

    case GET_ALPACA_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          data: action.payload,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case GET_ALPACA_ACTIVITIES_FAIL:
      return {
        ...state,
        activities: {
          ...state.activities,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case GET_ALPACA_DIVIDENDS_REQUEST:
      return {
        ...state,
        dividends: {
          ...state.dividends,
          isLoading: true,
        },
      };

    case GET_ALPACA_DIVIDENDS_SUCCESS:
      return {
        ...state,
        dividends: {
          ...state.dividends,
          dividends: action.payload,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case GET_ALPACA_DIVIDENDS_FAIL:
      return {
        ...state,
        dividends: {
          ...state.dividends,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case CLEAR_ALPACA:
      return { ...INITIAL_STATE };

    case LOGOUT:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default alpacaReducer;
