import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import COLORS from '../../../constants/colors';

interface SendingEmailScreensProps {
  isLoading: boolean;
  isErrorExist: boolean;
  successSubmission: boolean;
}

const SendingEmailScreens: React.FC<SendingEmailScreensProps> = ({ isLoading, isErrorExist, successSubmission }) => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  return (
    <Box>
      {isLoading && <CircularProgress color="primary" />}
      {!isLoading && !isErrorExist && successSubmission && (
        <Box sx={{ textAlign: 'center' }}>
          <img src="/images/mail-sent.svg" alt="mail sent" />
          <Typography
            variant={isEnglish ? 'h4' : 'h3'}
            color={COLORS.MAIN_DARK}
            paragraph
            textAlign="center"
            sx={{ marginTop: 3 }}
          >
            {t('contactUs.emailSentSuccess')}
          </Typography>
        </Box>
      )}
      {!isLoading && isErrorExist && !successSubmission && (
        <Box sx={{ textAlign: 'center' }}>
          <img src="/images/mail-not-sent.svg" alt="mail sent" />
          <Typography
            variant={isEnglish ? 'h4' : 'h3'}
            color={COLORS.MAIN_DARK}
            paragraph
            textAlign="center"
            sx={{ marginTop: 3 }}
          >
            {t('contactUs.emailSentFailTitle')}
          </Typography>
          <Typography
            variant="bodyLarge"
            color={COLORS.MAIN_DARK}
            paragraph
            textAlign="center"
            sx={{
              marginTop: 3,
              color: COLORS.LIGHT_GRAY,
              textTransform: 'initial',
            }}
          >
            {t('contactUs.emailSentFailSubtitle')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SendingEmailScreens;
