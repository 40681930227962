import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import COLORS from '../../../constants/colors';

interface PasswordFieldProps {
  id?: string;
  name?: string;
  autoComplete?: string;
  label?: string;
  isArabic: boolean;
  password: string;
  autoFocus?: boolean;
  handleChangePassword: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleMouseDownPassword?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const PasswordField: React.FC<PasswordFieldProps> = (props) => {
  const {
    id = 'password',
    name = 'password',
    autoComplete = 'password',
    label = 'signUpFormPasswordLabel',
    autoFocus = true,
    isArabic,
    password,
    handleChangePassword,
    handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
  } = props;
  const { t } = useTranslation('auth', { useSuspense: true });
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl sx={{ width: '100%' }} variant="outlined">
      <Typography
        variant={isArabic ? 'cairoM' : 'bodyMedium'}
        color={COLORS.MAIN_DARK}
        textAlign="justify"
        paragraph
        sx={{ marginBottom: 1 }}
      >
        {t(label)}
      </Typography>
      <InputBase
        required
        fullWidth
        id={id}
        name={name}
        type={showPassword ? 'text' : 'password'}
        value={password}
        autoComplete={autoComplete}
        placeholder={t('signUpFormPasswordPlaceholder')}
        autoFocus={autoFocus}
        onChange={handleChangePassword}
        sx={{
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: COLORS.XLIGHT_GRAY,
          borderRadius: '8px',
          height: '100%',
          '&:hover': {
            borderColor: 'black',
          },
          '& .MuiInputBase-root': {
            borderRadius: '8px',
            '& fieldset': { borderColor: COLORS.XLIGHT_GRAY },
          },
          '& .MuiFormHelperText-root': {
            fontFamily: isArabic ? 'Noto Kufi Arabic' : 'Montserrat',
          },
        }}
        inputProps={{
          style: {
            padding: 13,
            borderRadius: '8px',
            fontFamily: isArabic ? 'Noto Kufi Arabic' : 'Montserrat',
            fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
            direction: 'ltr',
            textAlign: isArabic ? 'right' : 'left',
          },
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((prev) => !prev)}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              sx={{ mr: '1px' }}
            >
              {showPassword ? <img src="/images/auth/eye.svg" alt="" /> : <img src="/images/auth/eye-off.svg" alt="" />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default PasswordField;
