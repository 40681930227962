/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import React, { memo, useState, useEffect, useCallback, CSSProperties } from 'react';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import SingleInput from './SingleOTPInput';

export interface OTPInputProps {
  length: number;
  isArabic: boolean;
  onChangeOTP: (otp: string) => any;
  autoFocus?: boolean;
  clearOTP?: boolean;
  isNumberInput?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
  inputStyle?: CSSProperties;
  inputClassName?: string;
}

export function OTPInputComponent(props: OTPInputProps) {
  const {
    length,
    isNumberInput,
    autoFocus,
    disabled,
    onChangeOTP,
    inputClassName,
    clearOTP,
    inputStyle,
    isArabic,
    ...rest
  } = props;

  const ac = new AbortController();
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(''));
  const matchMobileView = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (clearOTP) setOTPValues(Array<string>(length).fill(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearOTP]);

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp: string[]) => {
      const otpValue = otp.join('');
      onChangeOTP(otpValue);
    },
    [onChangeOTP],
  );

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (str: string) => {
      const changedValue = str;

      if (!isNumberInput || !changedValue) {
        return changedValue;
      }

      return Number(changedValue) >= 0 ? changedValue : '';
    },
    [isNumberInput],
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || '';
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues],
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length],
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput],
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue],
  );

  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
            e.preventDefault();
          }

          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues],
  );

  const fillValues = (values: string[]) => {
    if (values) {
      let nextFocusIndex = 0;
      const updatedOTPValues = [...otpValues];
      updatedOTPValues.forEach((val, index) => {
        if (index >= activeInput) {
          const changedValue = getRightValue(values.shift() || val);
          if (changedValue) {
            updatedOTPValues[index] = changedValue;
            nextFocusIndex = index;
          }
        }
      });
      setOTPValues(updatedOTPValues);
      setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      handleOtpChange(updatedOTPValues);
    }
  };

  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, length - activeInput)
        .split('');

      return fillValues(pastedData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeInput, getRightValue, length, otpValues],
  );

  const onOneTimeCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pastedData = e.target.value
      .trim()
      .slice(0, length - activeInput)
      .split('');

    return fillValues(pastedData);
  };

  useEffect(() => {
    if ('OTPCredential' in window) {
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        })
        .then((otp) => {
          if (otp) {
            fillValues(otp?.code.split(''));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div {...rest}>
      <Grid
        container
        spacing={matchMobileView ? 4 : 8}
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: isArabic ? 'center' : '',
          flexDirection: isArabic ? 'row-reverse' : 'row',
          flexWrap: 'nowrap',
        }}
      >
        <input
          hidden
          type="text"
          name="token"
          id="token"
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="one-time-code"
          onChange={onOneTimeCodeChange}
          onPaste={handleOnPaste}
        />
        {Array(length)
          .fill('')
          .map((_, index) => (
            <Grid item xs key={index} display="flex" flexDirection="row" flexWrap="nowrap">
              <SingleInput
                key={`SingleInput-${index}`}
                type={isNumberInput ? 'number' : 'text'}
                inputMode="numeric"
                focus={activeInput === index}
                value={otpValues[index]}
                autoFocus={autoFocus}
                onFocus={handleOnFocus(index)}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                onBlur={onBlur}
                onPaste={handleOnPaste}
                style={inputStyle}
                className={inputClassName}
                disabled={disabled}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

const OTPInput = memo(OTPInputComponent);
export default OTPInput;
