import { LOGOUT } from '../types/authTypes';
import {
  BANK_INFO_FAIL,
  BANK_INFO_REQUEST,
  BANK_INFO_SUCCESS,
  BANKS_FAIL,
  BANKS_REQUEST,
  BANKS_SUCCESS,
  CLEAR_EDIT_BANK_INFO,
  CLEAR_WALLET,
  CREATE_MASTERCARD_SESSION_FAIL,
  CREATE_MASTERCARD_SESSION_REQUEST,
  CREATE_MASTERCARD_SESSION_SUCCESS,
  DELETE_BANK_INFO_FAIL,
  DELETE_BANK_INFO_REQUEST,
  DELETE_BANK_INFO_SUCCESS,
  DO_WITHDRAW_FAIL,
  DO_WITHDRAW_REQUEST,
  DO_WITHDRAW_SUCCESS,
  EDIT_BANK_INFO_FAIL,
  EDIT_BANK_INFO_REQUEST,
  EDIT_BANK_INFO_SUCCESS,
  FETCH_ANB_MASTERCARD_CONFIG_FAIL,
  FETCH_ANB_MASTERCARD_CONFIG_REQUEST,
  FETCH_ANB_MASTERCARD_CONFIG_SUCCESS,
  GET_NET_WITHDRAW_FAIL,
  GET_NET_WITHDRAW_REQUEST,
  GET_NET_WITHDRAW_SUCCESS,
  GET_STATEMENT_DELTA_FAIL,
  GET_STATEMENT_DELTA_REQUEST,
  GET_STATEMENT_DELTA_SUCCESS,
  SAVE_BANK_INFO_FAIL,
  SAVE_BANK_INFO_REQUEST,
  SAVE_BANK_INFO_SUCCESS,
  SET_PRIMARY_BANK_ACCOUNT_FAIL,
  SET_PRIMARY_BANK_ACCOUNT_REQUEST,
  SET_PRIMARY_BANK_ACCOUNT_SUCCESS,
  SUBSCRIPTIONS_FAIL,
  SUBSCRIPTIONS_REQUEST,
  SUBSCRIPTIONS_SUCCESS,
  WalletAction,
  WalletState,
} from '../types/walletTypes';

const INITIAL_STATE: WalletState = {
  bankInfo: {
    data: [],
    isLoading: false,
    isErrorExist: false,
  },
  editBankInfo: {
    data: null,
    isLoading: false,
    isErrorExist: false,
  },
  anbMastercardConfigInfo: {
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
  depositInfo: {
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
  withdrawInfo: {
    netWithdraw: 0,
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
  statementInfo: {
    statementDelta: 0,
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
  banks: {
    banks: [],
    isLoading: false,
    isErrorExist: false,
  },
  subscriptions: {
    subscriptions: [],
    isLoading: false,
    isErrorExist: false,
  },
  createdSubscription: {
    subscription: null,
    isLoading: false,
    isErrorExist: false,
  },
};

/**
 * TODO: We must migrate to `@reduxjs/toolkit`!
 * @deprecated
 */
const walletReducer = (state: WalletState = INITIAL_STATE, action: WalletAction = { type: LOGOUT }): WalletState => {
  switch (action.type) {
    case BANK_INFO_REQUEST:
      return {
        ...state,
        bankInfo: {
          ...state.bankInfo,
          isLoading: true,
        },
      };

    case BANK_INFO_SUCCESS:
      return {
        ...state,
        bankInfo: {
          ...state.bankInfo,
          data: action.payload,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case BANK_INFO_FAIL:
      return {
        ...state,
        bankInfo: {
          ...state.bankInfo,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case BANKS_REQUEST:
      return {
        ...state,
        banks: {
          ...state.banks,
          isLoading: true,
        },
      };

    case BANKS_SUCCESS:
      return {
        ...state,
        banks: {
          ...state.banks,
          banks: action.payload,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case BANKS_FAIL:
      return {
        ...state,
        banks: {
          ...state.banks,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case SAVE_BANK_INFO_REQUEST:
      return {
        ...state,
        editBankInfo: {
          ...state.editBankInfo,
          isLoading: true,
        },
      };

    case SAVE_BANK_INFO_SUCCESS:
      return {
        ...state,
        editBankInfo: {
          ...state.editBankInfo,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case SAVE_BANK_INFO_FAIL:
      return {
        ...state,
        editBankInfo: {
          ...state.editBankInfo,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case DELETE_BANK_INFO_REQUEST:
      return {
        ...state,
        bankInfo: {
          ...state.bankInfo,
          isLoading: true,
        },
      };

    case DELETE_BANK_INFO_SUCCESS:
      return {
        ...state,
        bankInfo: {
          ...state.bankInfo,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case DELETE_BANK_INFO_FAIL:
      return {
        ...state,
        bankInfo: {
          ...state.bankInfo,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case SET_PRIMARY_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        bankInfo: {
          ...state.bankInfo,
          isLoading: true,
        },
      };

    case SET_PRIMARY_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        bankInfo: {
          ...state.bankInfo,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case SET_PRIMARY_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        bankInfo: {
          ...state.bankInfo,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case EDIT_BANK_INFO_REQUEST:
      return {
        ...state,
        editBankInfo: {
          ...state.editBankInfo,
          isLoading: true,
        },
      };

    case EDIT_BANK_INFO_SUCCESS:
      return {
        ...state,
        editBankInfo: {
          ...state.editBankInfo,
          data: action.payload,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case EDIT_BANK_INFO_FAIL:
      return {
        ...state,
        editBankInfo: {
          ...state.editBankInfo,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case DO_WITHDRAW_REQUEST:
    case GET_NET_WITHDRAW_REQUEST:
      return {
        ...state,
        withdrawInfo: {
          ...state.withdrawInfo,
          isLoading: true,
        },
      };

    case DO_WITHDRAW_SUCCESS:
      return {
        ...state,
        withdrawInfo: {
          ...state.withdrawInfo,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case DO_WITHDRAW_FAIL:
    case GET_NET_WITHDRAW_FAIL:
      return {
        ...state,
        withdrawInfo: {
          ...state.withdrawInfo,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case GET_NET_WITHDRAW_SUCCESS:
      return {
        ...state,
        withdrawInfo: {
          ...state.withdrawInfo,
          netWithdraw: action.payload,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case CREATE_MASTERCARD_SESSION_REQUEST:
      return {
        ...state,
        depositInfo: {
          ...state.depositInfo,
          isLoading: true,
          isErrorExist: false,
        },
      };

    case CREATE_MASTERCARD_SESSION_FAIL:
      return {
        ...state,
        depositInfo: {
          ...state.depositInfo,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case CREATE_MASTERCARD_SESSION_SUCCESS:
      return {
        ...state,
        depositInfo: {
          ...state.depositInfo,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case FETCH_ANB_MASTERCARD_CONFIG_REQUEST:
      return {
        ...state,
        anbMastercardConfigInfo: {
          ...state.anbMastercardConfigInfo,
          isLoading: true,
          isErrorExist: false,
        },
      };

    case FETCH_ANB_MASTERCARD_CONFIG_FAIL:
      return {
        ...state,
        anbMastercardConfigInfo: {
          ...state.anbMastercardConfigInfo,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case FETCH_ANB_MASTERCARD_CONFIG_SUCCESS:
      return {
        ...state,
        anbMastercardConfigInfo: {
          ...state.anbMastercardConfigInfo,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case CLEAR_EDIT_BANK_INFO:
      return {
        ...state,
        editBankInfo: INITIAL_STATE.editBankInfo,
      };

    case SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          isLoading: true,
        },
      };

    case SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          subscriptions: action.payload,
          isLoading: false,
          isErrorExist: false,
        },
      };

    case SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case GET_STATEMENT_DELTA_REQUEST:
      return {
        ...state,
        statementInfo: {
          ...state.statementInfo,
          isLoading: true,
        },
      };
    case GET_STATEMENT_DELTA_SUCCESS:
      return {
        ...state,
        statementInfo: {
          ...state.statementInfo,
          statementDelta: action.payload,
          isLoading: false,
          isErrorExist: false,
        },
      };
    case GET_STATEMENT_DELTA_FAIL:
      return {
        ...state,
        statementInfo: {
          ...state.statementInfo,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case CLEAR_WALLET:
      return { ...INITIAL_STATE };

    case LOGOUT:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default walletReducer;
