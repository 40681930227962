import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import CenterContent from './CenterContent';

const CircularIndeterminate: React.FC = () => (
  <CenterContent>
    <CircularProgress />
  </CenterContent>
);

export default CircularIndeterminate;
