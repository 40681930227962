import * as APIs from '../../constants/APIs';
import { UserAgreementName } from '../../store_deprecated/types/userAgreementTypes';
import { ApiService } from '../http';

const HTTP = new ApiService();

export async function getUserSignedAgreement(name: UserAgreementName) {
  try {
    return await HTTP.get(`${APIs.GET_USER_SIGNED_AGREEMENT}/${name}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function doSignUserAgreement(name: UserAgreementName) {
  try {
    return await HTTP.post(`${APIs.SIGN_USER_AGREEMENT}`, { name });
  } catch (error) {
    HTTP.handleError(error);
  }
}
