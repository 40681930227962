import { LOGOUT } from '../types/authTypes';
import {
  CLEAR_INDEX_FUND,
  GET_FUND_CHART_DATA_FAIL,
  GET_FUND_CHART_DATA_REQUEST,
  GET_FUND_CHART_DATA_SUCCESS,
  GET_INDEX_FUND_FAIL,
  GET_INDEX_FUND_REQUEST,
  GET_INDEX_FUND_SUCCESS,
  GET_USER_INDEX_FUND_FAIL,
  GET_USER_INDEX_FUND_REQUEST,
  GET_USER_INDEX_FUND_SUCCESS,
  IndexFundAction,
  IndexFundState,
} from '../types/indexFundTypes';

const INITIAL_STATE: IndexFundState = {
  indexFund: {
    data: null,
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
  fundChartData: {
    data: [],
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
  userIndexFunds: {
    data: [],
    error: '',
    isLoading: false,
    isErrorExist: false,
  },
};

/**
 * TODO: We must migrate to `@reduxjs/toolkit`!
 * @deprecated
 */
const indexFundReducer = (
  state: IndexFundState = INITIAL_STATE,
  action: IndexFundAction = { type: LOGOUT },
): IndexFundState => {
  switch (action.type) {
    case GET_INDEX_FUND_REQUEST:
      return {
        ...state,
        indexFund: {
          ...state.indexFund,
          isLoading: true,
        },
      };

    case GET_INDEX_FUND_FAIL:
      return {
        ...state,
        indexFund: {
          ...state.indexFund,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case GET_INDEX_FUND_SUCCESS:
      return {
        ...state,
        indexFund: {
          data: action.payload,
          error: '',
          isLoading: false,
          isErrorExist: false,
        },
      };

    case GET_USER_INDEX_FUND_REQUEST:
      return {
        ...state,
        userIndexFunds: {
          ...state.userIndexFunds,
          isLoading: true,
        },
      };

    case GET_USER_INDEX_FUND_FAIL:
      return {
        ...state,
        userIndexFunds: {
          ...state.userIndexFunds,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case GET_USER_INDEX_FUND_SUCCESS:
      return {
        ...state,
        userIndexFunds: {
          data: action.payload,
          error: '',
          isLoading: false,
          isErrorExist: false,
        },
      };

    case GET_FUND_CHART_DATA_REQUEST:
      return {
        ...state,
        fundChartData: {
          ...state.fundChartData,
          isLoading: true,
        },
      };

    case GET_FUND_CHART_DATA_FAIL:
      return {
        ...state,
        fundChartData: {
          ...state.fundChartData,
          error: action.payload,
          isLoading: false,
          isErrorExist: true,
        },
      };

    case GET_FUND_CHART_DATA_SUCCESS:
      return {
        ...state,
        fundChartData: {
          data: action.payload,
          error: '',
          isLoading: false,
          isErrorExist: false,
        },
      };

    case CLEAR_INDEX_FUND:
      return { ...INITIAL_STATE };

    case LOGOUT:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default indexFundReducer;
