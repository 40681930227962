import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import COLORS from '../../../constants/colors';
import CenterContent from '../../../shared/CenterContent';
import Breadcrumb from '../../../shared/Breadcrumb';
import ProgressBar from '../../../shared/ProgressBar';
import { locations } from '../../../routes/locations';
import { QuestionData, SurveyData } from '../../../store_deprecated/types/surveyTypes';
import MButton from '../../../shared/MButton';

interface SurveyViewProps {
  currentStepIndex: number;
  survey: SurveyData;
  question: QuestionData;
  isFirstStep?: boolean;
  handleBack: () => void;
  handleNext: () => void;
  handleSkip: () => void;
  handleChooseAnotherProduct?: () => void;
  isLastStep: () => boolean;
  isValidAnswer: () => boolean;
  renderQuestion: () => JSX.Element;
  showSkip: boolean;
}

const SurveyView: React.FC<SurveyViewProps> = (props) => {
  const {
    currentStepIndex,
    survey,
    question,
    isFirstStep,
    handleBack,
    handleNext,
    handleSkip,
    isLastStep,
    isValidAnswer,
    renderQuestion,
    handleChooseAnotherProduct,
    showSkip,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      onClick={() => navigate(locations.home())}
      sx={{ color: COLORS.LIGHT_GRAY, cursor: 'pointer' }}
    >
      {t('breadcrumb.home')}
    </Link>,
    <Typography key="2" color="text.primary">
      {t('breadcrumb.riskAssessmentSurvey')}
    </Typography>,
  ];

  return (
    <CenterContent>
      <Box
        sx={{
          marginX: { xs: '24px', md: '160px' },
          width: '100%',
          height: '100%',
          minHeight: 'inherit',
          display: 'flex',
          flexDirection: 'column',
          pb: '24px',
        }}
      >
        <Grid container sx={{ py: '24px' }} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Breadcrumb breadcrumbs={breadcrumbs} />
          </Grid>

          <Grid item alignItems="center" sx={{ display: { xs: 'none', md: 'block' } }}>
            <MButton
              variant="text"
              onClick={handleChooseAnotherProduct}
              buttonProps={{
                sx: {
                  m: 0,
                  mr: { xs: 'unset', md: showSkip ? '16px' : 0 },
                  p: 0,
                  backgroundColor: COLORS.TRANSPARENT,
                  '&:hover': {
                    background: COLORS.TRANSPARENT,
                  },
                  boxShadow: 'none',
                },
              }}
              typographyProps={{
                variant: 'body2',
                sx: {
                  textTransform: 'capitalize',
                  color: COLORS.LIGHT_GRAY,
                },
              }}
            >
              {t('products.chooseAnotherProduct')}
            </MButton>
            {/* {showSkip && (
              <MButton
                variant="text"
                onClick={handleSkip}
                buttonProps={{
                  sx: {
                    p: 0,
                    m: 0,
                    '&:hover': {
                      background: COLORS.TRANSPARENT,
                    },
                    boxShadow: 'none',
                  },
                }}
                typographyProps={{
                  variant: 'body2',
                  sx: {
                    textTransform: 'capitalize',
                    color: COLORS.LIGHT_GRAY,
                  },
                }}
              >
                {t('skipSurvey')}
              </MButton>
            )} */}
          </Grid>
        </Grid>
        <Box sx={{ marginBottom: 3 }}>
          <ProgressBar value={((currentStepIndex + 1) / survey.totalQuestions) * 100} />
        </Box>
        <Box
          sx={{
            // minHeight: "72vh",
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
            borderRadius: '24px',
            textAlign: 'center',
            backgroundColor: 'white',
          }}
        >
          <Box
            sx={{
              my: { xs: '24px', md: '40px' },
              mx: { xs: '24px', md: 'unset' },
              width: '100%',
            }}
          >
            <Typography
              variant="h1"
              gutterBottom
              sx={{
                fontSize: 'clamp(1rem, 0.7vw + 1rem, 2.5rem)',
                fontWeight: 500,
                marginBottom: { xs: 12, md: 5 },
                lineHeight: { xs: '28px', md: '48px' },
              }}
              id={`QuestionText${currentStepIndex + 1}`}
            >
              {isArabic ? question.textAr : question.textEn}
            </Typography>
            {renderQuestion()}
          </Box>
        </Box>
        <Grid
          container
          spacing={2}
          justifyContent={{ xs: 'center', md: 'space-between' }}
          display={{ xs: 'grid', md: 'flex' }}
          textAlign={{ xs: 'center', md: 'unset' }}
          mt={2}
          sx={{
            gridTemplateColumns: { xs: '1fr', md: 'unset' },
            // gridTemplateRows: { xs: "repeat(4, 1fr)", md: "unset" },
          }}
        >
          <Grid item sx={{ gridRow: { xs: '3', md: 'unset' } }}>
            <MButton
              variant="outlined"
              onClick={handleBack}
              id="BackButton"
              buttonProps={{
                sx: {
                  background: COLORS.TRANSPARENT,
                  border: `1px solid ${COLORS.X_DARK_BLUE}`,
                  width: { xs: '100%', md: 230 },
                  height: { xs: '50px', md: 60 },
                  '&:hover': {
                    backgroundColor: '#f7f9fa !important',
                    boxShadow: 'none',
                  },
                },
              }}
            >
              {t('back')}
            </MButton>
          </Grid>
          {/* MUST NOT BE DELETED, THIS IS NEEDED FOR AUTOMATION SCRIPT */}
          <div id="CurrentQuestion" hidden>
            {`${currentStepIndex + 1}/${survey.totalQuestions}`}
          </div>
          {/* <Grid
            item
            sx={{
              textAlign: "center",
              padding: 0,
              gridRow: { xs: "1", md: "unset" },
            }}
          >
            {survey.totalQuestions !== -1 && (
              <Typography
                sx={{ color: COLORS.LIGHT_GRAY }}
                id="CurrentQuestion"
              >
                {`${currentStepIndex + 1}/${survey.totalQuestions}`}
              </Typography>
            )}
          </Grid> */}
          <Grid item sx={{ gridRow: { xs: '2', md: 'unset' } }}>
            <MButton
              variant="contained"
              onClick={handleNext}
              id={isLastStep() ? 'EndSurvey' : 'NextButton'}
              disabled={!isValidAnswer()}
              buttonProps={{
                sx: {
                  width: { xs: '100%', md: 230 },
                  height: { xs: '50px', md: 60 },
                  boxShadow: 'none',
                  backgroundColor: COLORS?.X_DARK_BLUE,
                },
              }}
            >
              {isLastStep() ? t('endSurvey') : t('next')}
            </MButton>
          </Grid>
          <Grid
            item
            alignItems="center"
            sx={{
              display: { xs: 'grid', md: 'none' },
              gridRow: { xs: '4', md: 'unset' },
            }}
          >
            <MButton
              variant="text"
              onClick={handleChooseAnotherProduct}
              buttonProps={{
                sx: {
                  m: 0,
                  mr: { xs: 'unset', md: '16px' },
                  my: { xs: 3, md: 'unset' },
                  p: 0,
                  mt: { xs: 3, md: 'unset' },
                  '&:hover': {
                    background: COLORS.TRANSPARENT,
                  },
                  boxShadow: 'none',
                },
              }}
              typographyProps={{
                variant: 'body2',
                sx: {
                  textTransform: 'capitalize',
                  color: COLORS.LIGHT_GRAY,
                },
              }}
            >
              {t('products.chooseAnotherProduct')}
            </MButton>
            {/* {showSkip && (
              <MButton
                variant="text"
                onClick={handleSkip}
                buttonProps={{
                  sx: {
                    p: 0,
                    m: 0,
                    mt: { xs: 3, md: 'unset' },
                    '&:hover': {
                      background: COLORS.TRANSPARENT,
                    },
                    boxShadow: 'none',
                  },
                }}
                typographyProps={{
                  variant: 'body2',
                  sx: {
                    textTransform: 'capitalize',
                    color: COLORS.LIGHT_GRAY,
                  },
                }}
              >
                {t('skipSurvey')}
              </MButton>
            )} */}
          </Grid>
        </Grid>
      </Box>
    </CenterContent>
  );
};

export default SurveyView;
