import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import COLORS from '../../../constants/colors';
import GoalDetailsChart from './GoalDetailsChart';
import { PrettoSlider } from '../../../shared/CustomSlider';
import MButton from '../../../shared/MButton';

const GoalDetailsTab: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      component="div"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      rowSpacing="64px"
    >
      <Grid
        item
        sx={{ width: '100%', maxWidth: '716px', mt: '64px' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <GoalDetailsChart />
      </Grid>
      <Grid item container md={6} xs={12} flexDirection="column">
        <Grid item display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            sx={{
              color: COLORS.LIGHT_GRAY,
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
            }}
          >
            {t('goalDetails.balance')}
          </Typography>
          <Typography
            sx={{
              color: COLORS.LIGHT_GRAY,
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
            }}
          >
            {t('goalDetails.target')}
          </Typography>
        </Grid>
        <Grid item sx={{ mt: '4px' }} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="bodyMediumB" sx={{ color: COLORS.MAIN_DARK }}>
            540 SAR
          </Typography>
          <Typography variant="bodyMediumB" sx={{ color: COLORS.MAIN_DARK }}>
            200,000 SAR
          </Typography>
        </Grid>
        <Grid item display="flex" flexDirection="column" alignItems="center" sx={{ mt: '12px' }}>
          <PrettoSlider
            className="hide-thumb"
            valueLabelDisplay="off"
            aria-label="pretto slider"
            value={27}
            min={0}
            max={100}
          />
        </Grid>
        <Grid item sx={{ mt: '32px' }} display="flex" justifyContent="center" alignItems="center">
          <MButton
            variant="contained"
            onClick={() => alert('Coming soon.')}
            buttonProps={{
              sx: {
                backgroundColor: COLORS.X_DARK_BLUE,
                '&:hover': {
                  backgroundColor: COLORS.X_DARK_BLUE,
                },
              },
            }}
          >
            {t('redeem')}
          </MButton>
        </Grid>
      </Grid>
      <Grid item sx={{ width: '100%' }}>
        <Typography
          variant="bodyLarge"
          sx={{
            color: COLORS.DARK_GREY,
            fontWeight: 400,
            fontSize: { xs: '12px', md: '16px' },
            lineHeight: '28px',
          }}
        >
          {t('portfolioPositions.hint')}
        </Typography>
      </Grid>

      <Accordion
        elevation={0}
        disableGutters
        sx={{
          width: '100%',
          padding: '32px 24px',
          mt: '64px',
          borderRadius: '24px!important',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="education-goal-details-panel-content"
          id="education-goal-details-panel-header"
        >
          <Typography variant="h5" sx={{ color: COLORS.MAIN_DARK }}>
            {t('educationView.goalDetails.title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container flexDirection="column" rowSpacing="32px">
            <Grid item display="flex" flexDirection="column">
              <Typography variant="bodyMedium" sx={{ mb: '8px', color: COLORS.LIGHT_GRAY }}>
                {t('educationView.goalDetails.goalType')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ color: COLORS.MAIN_DARK }}>
                Buy a house
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="column">
              <Typography variant="bodyMedium" sx={{ mb: '8px', color: COLORS.LIGHT_GRAY }}>
                {t('educationView.goalDetails.goalDetails.profile')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ color: COLORS.MAIN_DARK }}>
                Moderately Conservative
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="column">
              <Typography variant="bodyMedium" sx={{ mb: '8px', color: COLORS.LIGHT_GRAY }}>
                {t('educationView.goalDetails.goalExpectedDate')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ color: COLORS.MAIN_DARK }}>
                20-10-2023
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="column">
              <Typography variant="bodyMedium" sx={{ mb: '8px', color: COLORS.LIGHT_GRAY }}>
                {t('educationView.goalDetails.monthlyInvestmentAmount')}
              </Typography>
              <Typography variant="bodyLargeM" sx={{ color: COLORS.MAIN_DARK }}>
                5,000 $
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default GoalDetailsTab;
