import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import COLORS from '../../constants/colors';
import useWebsiteRedirect from '../../helpers/hooks/useWebsiteRedirect';

interface DesktopViewProps {
  isArabic: boolean;
  isLoggedIn: boolean;
  language: string;
  logoutUser: () => void;
  handleChangeLanguage: () => void;
}

const DesktopView: React.FC<DesktopViewProps> = ({
  isArabic,
  isLoggedIn,
  language,
  logoutUser,
  handleChangeLanguage,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { gotToDashaboard } = useWebsiteRedirect();

  return (
    <Box
      sx={{
        width: '100%',
        display: { xs: 'none', lg: 'flex' },
        alignItems: 'center',
      }}
    >
      <Grid container display="flex" justifyContent="space-between" alignItems="center">
        <Grid
          item
          sx={{
            width: 153,
            mr: 2,
            cursor: 'pointer',
            display: { xs: 'none', sm: 'flex' },
          }}
          onClick={() => navigate('/')}
        >
          <img
            style={{
              width: '100%',
            }}
            onClick={gotToDashaboard}
            src={isArabic ? '/images/logo-with-text-ar.png' : '/images/logo-with-text-en.png'}
            alt="madkhol logo"
          />
        </Grid>

        <Grid item>
          <Button
            onClick={handleChangeLanguage}
            sx={{
              '&:hover': {
                backgroundColor: COLORS.TRANSPARENT,
              },
            }}
          >
            <Typography variant={isArabic ? 'bodySmall' : 'cairoM'} color="black" sx={{ textTransform: 'capitalize' }}>
              {language}
            </Typography>
          </Button>
          {isLoggedIn && (
            <Button
              onClick={logoutUser}
              sx={{
                ml: '32px',
                '&:hover': {
                  backgroundColor: COLORS.TRANSPARENT,
                },
              }}
            >
              <Typography
                variant={isArabic ? 'bodySmall' : 'cairoM'}
                sx={{ textTransform: 'capitalize', fontWeight: 500 }}
                color={COLORS.PRIMARY_BLUE}
              >
                {t('logout')}
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DesktopView;
