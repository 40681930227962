import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { locations } from '../../../routes/locations';
import { ReduxState, useTypedDispatch } from '../../../redux/store';
import { getSurveyResult } from '../../../store_deprecated/selectors/surveySelectors';
import { getAvailablePortfoliosSelector } from '../../../store_deprecated/selectors/portfolioSelectors';
import { fetchLastSurveyResult } from '../../../store_deprecated/actions/surveyActions';
import { findRecommendedPortfolioByProduct } from '../../../store_deprecated/actions/portfolioActions';
import { fetchAttachedDocuments } from '../../../store_deprecated/actions/docsActions';
import MButton from '../../../shared/MButton';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { userPreferenceByTypeSelector } from '../../../redux/modules/userPreference/userPreference.selectors';
import { UserPreferenceTypeEnum } from '../../../types/userPreference';
import { fetchUserPreferenceByType } from '../../../redux/modules/userPreference/userPreference.actions';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import COLORS from '../../../constants/colors';

const RiskAssessmentScore = React.lazy(() => import('./RiskAssessmentScore'));
const RecommendedPortfolios = React.lazy(() => import('./RecommendedPortfolios'));
const ProductApprove = React.lazy(() => import('../../../pages/user/ProductApprove'));
const Expect2030 = React.lazy(() => import('./Expect2030'));

const mapPageToStep: Record<string, number> = {
  riskAssessmentScore: 0,
  recommendedPortfolios: 1,
  expect2030: 2,
};

const mapStepToPage: Record<number, string> = {
  0: 'riskAssessmentScore',
  1: 'recommendedPortfolios',
  2: 'expect2030',
};

const RiskSurveyView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { state: routerState } = useLocation();

  const { user } = useSelector(authSelector);
  const dispatch = useTypedDispatch();
  const [search, setSearch] = useSearchParams();
  const [step, setStep] = useState(mapPageToStep[search.get('page') as string] || 0);

  const surveyResult = useSelector(getSurveyResult);
  const availablePortfolios = useSelector(getAvailablePortfoliosSelector);
  const { userPreference: productTypePreference, fetchStatus: userPreferenceFetchStatus } = useSelector(
    (state: ReduxState) => userPreferenceByTypeSelector(state, UserPreferenceTypeEnum.PRODUCT_TYPE),
  );

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleSkip = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    if (routerState?.from) navigate(routerState?.from);
    else setStep(step - 1);
  };

  const retakeSurvey = () => {
    navigate(locations.survey());
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return <RiskAssessmentScore />;

      case 1:
        return <ProductApprove handleBack={handleBack} listType={routerState?.type} />;
      //  return <RecommendedPortfolios />;

      case 2:
        return <Expect2030 />;

      default:
        break;
    }
  };

  const renderCTA = () => {
    switch (step) {
      case 0:
        return 'next';

      case 1:
        return 'expect2030';

      default:
        return '';
    }
  };

  useEffect(() => {
    if (!surveyResult?.category?.id) {
      dispatch(fetchLastSurveyResult());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userPreferenceFetchStatus === fetchStatuses.pending) {
      return;
    }

    if (!productTypePreference && userPreferenceFetchStatus === fetchStatuses.idle) {
      dispatch(fetchUserPreferenceByType(UserPreferenceTypeEnum.PRODUCT_TYPE));
    }
  }, [productTypePreference, userPreferenceFetchStatus]);

  useEffect(() => {
    if (productTypePreference) {
      const product = JSON.parse(productTypePreference);

      const fetchRecommendedPortfolio = async (productTypeId: number, category?: string) => {
        await dispatch(findRecommendedPortfolioByProduct({ productTypeId, category }));
      };

      const category =
        product.scoreDependent && surveyResult?.category?.id ? surveyResult?.category?.nameEn : undefined;

      fetchRecommendedPortfolio(product.id, category);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyResult?.category, productTypePreference]);

  useEffect(() => {
    if (!routerState?.from) {
      setSearch({ page: mapStepToPage[step] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const renderRetakeSurveyButton = () => (
    <MButton
      variant="outlined"
      onClick={retakeSurvey}
      id="RetakeSurveyButton"
      fullWidth
      typographyProps={{
        sx: { background: COLORS.WHITE, color: COLORS.X_DARK_BLUE },
      }}
      buttonProps={{
        sx: {
          width: { xs: '100%', md: 'fit-content' },
          height: { xs: '50px', md: 60 },
          borderRadius: '80px',
          border: `1px solid ${COLORS.PRIMARY_BLUE}`,
          mb: { xs: '24px', md: '0' },
          boxShadow: 'none',
          '&:before': {
            background: COLORS.WHITE,
          },
        },
      }}
    >
      {t('retakeSurvey')}
    </MButton>
  );

  return (
    <Box
      sx={{
        marginLeft: { xs: 6, lg: 'auto' },
        marginRight: { xs: 6, lg: 'auto' },
        maxWidth: '1200px',
      }}
    >
      {renderStep()}
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row-reverse' },
          gap: { xs: '12px', md: '0' },
          justifyContent: 'space-between',
          maxWidth: '1440px',
          margin: 'auto',
          my: '24px',
        }}
      >
        {/* TODO: expect2030 is not included in MVP */}
        {[0].includes(step) && (
          <Box
            sx={{
              // mt: step === 0 ? '24px' : '16px',
              // mb: '16px',
              width: { xs: '100%', md: '220px' },
            }}
          >
            <MButton
              variant={step === 1 ? 'text' : 'contained'}
              fullWidth
              onClick={handleNext}
              buttonProps={{
                sx: {
                  width: { xs: '100%' },
                  height: { xs: '50px', md: 60 },
                  borderRadius: '80px',
                  boxShadow: 'none',
                  backgroundColor: COLORS.X_DARK_BLUE,
                  backgroundImage: 'none',
                },
              }}
            >
              {t(renderCTA())}
            </MButton>
          </Box>
        )}
        {step === 0 && renderRetakeSurveyButton()}
      </Box>
    </Box>
  );
};

export default RiskSurveyView;
