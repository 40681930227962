import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import COLORS from '../../../constants/colors';
import { locations } from '../../../routes/locations';

const SurveyResultsSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSurveyResultView = () => {
    navigate(locations.riskSurvey());
  };

  return (
    <Grid
      item
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ mt: '32px', p: '24px', borderRadius: '12px' }}
      component={Paper}
      elevation={0}
    >
      <Typography variant="bodyMediumB" sx={{ color: COLORS.MAIN_DARK }}>
        {t('userProfile.surveyResults')}
      </Typography>
      <Button
        variant="text"
        onClick={onSurveyResultView}
        id="ViewSurveyResultsButton"
        startIcon={<VisibilityOutlinedIcon />}
        sx={{ textTransform: 'capitalize', color: COLORS.PRIMARY_BLUE }}
      >
        <Typography
          variant="bodySmall"
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
          }}
        >
          {t('view')}
        </Typography>
      </Button>
    </Grid>
  );
};

export default SurveyResultsSection;
