const PasswordValidationStatus = {
  SHORT_PASSWORD: 'The minimum limit is 8 characters.',
  AT_LEAST_UPPERCASE_CHAR:
    'Must have a combination of capital & small letters, digits, and at least 1 special character for each Example: @.',
  COMBINATION_SEQUENCE_OR_CONSECUTIVE_PATTERN:
    'Sequential or consecutive characters are not allowed. Example: abc or 123.',
  PASSWORD_AND_CONFIRM_PASSWORD_DOES_MATCH: 'Password and confirm password does not match',
};

export default PasswordValidationStatus;
