// Define any related types such as ProductType, ProductFile, PortfolioAssociatedIndexFund

import { ProductWithDummy } from '../../../../types/product';

// Function to generate a random ID
function generateRandomId(): number {
  return Math.floor(Math.random() * 1000000) + 1; // Random number between 1 and 1000000
}

// Function to generate a unique name
function generateUniqueName(): string {
  return `Product_${Math.floor(Math.random() * 1000000)}`; // Unique name with random number suffix
}

// Dummy data generator function
function generateDummyProduct(): ProductWithDummy {
  const key = generateRandomId();
  return {
    id: generateRandomId(),
    nameEn: generateUniqueName(),
    nameAr: generateUniqueName(),
    descriptionEn: `Description of Product ${key}`,
    descriptionAr: `${key}وصف المنتج `,
    minimumPortfolioInvestment: 1000,
    minimumAddFundInvestment: 100,
    fee: 0.05,
    scoreDependent: false,
    isCustom: false,
    disabled: false,
    files: [],
    standartDeviation: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
    isDummy: true,
  };
}

// Create an array with four dummy Product items
export default function getDummyProducts(howMany = 4): ProductWithDummy[] {
  return Array.from({ length: howMany }, () => generateDummyProduct());
}
