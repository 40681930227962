import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { getAlpacaActivities, getAlpacaDividends } from '../../helpers/api_deprecated/alpaca';
import {
  AlpacaActivity,
  AlpacaActivityRequestParams,
  CLEAR_ALPACA,
  ClearAlpaca,
  GET_ALPACA_ACTIVITIES_FAIL,
  GET_ALPACA_ACTIVITIES_REQUEST,
  GET_ALPACA_ACTIVITIES_SUCCESS,
  RequestAlpacaActivities,
  RequestAlpacaActivitiesFail,
  RequestAlpacaActivitiesSuccess,
  GET_ALPACA_DIVIDENDS_FAIL,
  GET_ALPACA_DIVIDENDS_REQUEST,
  GET_ALPACA_DIVIDENDS_SUCCESS,
  RequestAlpacaDividends,
  RequestAlpacaDividendsFail,
  RequestAlpacaDividendsSuccess,
} from '../types/alpacaTypes';

export const requestAlpacaActivities = (): RequestAlpacaActivities => ({
  type: GET_ALPACA_ACTIVITIES_REQUEST,
});

export const requestAlpacaActivitiesSuccess = (payload: AlpacaActivity[]): RequestAlpacaActivitiesSuccess => ({
  type: GET_ALPACA_ACTIVITIES_SUCCESS,
  payload,
});

export const requestAlpacaActivitiesFail = (payload: string): RequestAlpacaActivitiesFail => ({
  type: GET_ALPACA_ACTIVITIES_FAIL,
  payload,
});

export const requestAlpacaDividends = (): RequestAlpacaDividends => ({
  type: GET_ALPACA_DIVIDENDS_REQUEST,
});

export const requestAlpacaDividendsSuccess = (payload: number): RequestAlpacaDividendsSuccess => ({
  type: GET_ALPACA_DIVIDENDS_SUCCESS,
  payload,
});

export const requestAlpacaDividendsFail = (payload: string): RequestAlpacaDividendsFail => ({
  type: GET_ALPACA_DIVIDENDS_FAIL,
  payload,
});

export const clearAlpaca = (): ClearAlpaca => ({
  type: CLEAR_ALPACA,
});

export const findAlpacaActivities =
  (params: AlpacaActivityRequestParams): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestAlpacaActivities());
    try {
      const response: any = await getAlpacaActivities(params);

      dispatch(requestAlpacaActivitiesSuccess(response.data));

      return response.data;
    } catch (error: any) {
      dispatch(requestAlpacaActivitiesFail(error.message));
    }
  };

export const findAlpacaDividends =
  (): ThunkAction<any, any, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch(requestAlpacaDividends());
    try {
      const response: any = await getAlpacaDividends();

      dispatch(requestAlpacaDividendsSuccess(response.data?.dividends));

      return response.data;
    } catch (error: any) {
      dispatch(requestAlpacaDividendsFail(error.message));
    }
  };
