import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Buffer } from 'buffer';
import {
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useVerifyOTPMutation,
} from '../../../redux/features/auth/auth.apiSlice';
import CenterContent from '../../../shared/CenterContent';
import { locations } from '../../../routes/locations';
import { RestorePasswordRequest } from '../../../types/auth';
import ResetPasswordMobileForm from './ResetPasswordMobileForm';
import ResetPasswordEmailForm from './ResetPasswordEmailForm';
import ResetPasswordOtpForm from './ResetPasswordOtpForm';
import ResetPasswordNewForm from './ResetPasswordNewForm';
import ResetModal from '../../../shared/PasswordResetModal';

const Loader = React.lazy(() => import('../../../shared/Loader'));

type ResetType = 'mobile' | 'email';

const ResetPasswordView = () => {
  const { t, i18n } = useTranslation('auth', { useSuspense: true });
  const { t: tApiError } = useTranslation('apiError');
  const navigate = useNavigate();
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [otp, setOtp] = useState('');
  const [loginPassed, setLoginPassed] = useState(false);
  const [passwordReset, setPasswordReset] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [otpPassed, setOtpPassed] = useState(false);
  const [restoreType, setRestoreType] = useState<ResetType>(
    (localStorage.getItem('loginType') as ResetType) || 'mobile',
  );
  const [showLoader, setShowLoader] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [userToken, setUserToken] = useState('');
  const [apiErrData, setApiErrData] = useState<[string, object]>(['', {}]);
  const isMobile = restoreType === 'mobile';
  const isEnglish = i18n.language === 'en';

  const [forgotPassword] = useForgotPasswordMutation();
  const [verifyOTP] = useVerifyOTPMutation();
  const [resetPassword] = useResetPasswordMutation();

  const toggleRestoreType = () => {
    const nextRestoreType = restoreType === 'mobile' ? 'email' : 'mobile';

    setRestoreType(nextRestoreType);
  };

  const resetError = () => {
    setErrMsg('');
    setApiErrData(['', {}]);
  };

  const forgetPassword = async (value = isMobile ? mobile : email) => {
    resetError();
    setShowLoader(true);
    try {
      await forgotPassword(isMobile ? { mobileNumber: value } : { email: value }).unwrap();

      if (!loginPassed) {
        setLoginPassed(true);
      }
    } catch (error: any) {
      if (i18n.exists(`apiError:${error?.data?.message}`)) {
        setApiErrData([error.data.message, { ...(error.data?.data || {}) }]);
      }
      setErrMsg(isMobile ? 'loginMobileNumberError' : 'loginEmailNumberError');
    }
    setShowLoader(false);
  };

  const submitLogin = async (value: string) => {
    if (isMobile) {
      setMobile(value);
    } else {
      setEmail(value);
    }
    forgetPassword(value);
  };

  const resendOTP = async () => {
    setOtp('');
    await forgetPassword();
  };

  const handleClose = () => {
    setPasswordReset(false);
    if (type === 'success') {
      navigate(locations.login());
    } else {
      setLoginPassed(false);
      setOtpPassed(false);
    }
  };

  const submitOtp = async (value: string) => {
    setOtp(value);
    resetError();
    setShowLoader(true);

    const payload = isMobile ? { mobileNumber: mobile, otpCode: value } : { email, otpCode: value };

    try {
      const otpVerifyResponse = await verifyOTP(payload).unwrap();

      if (otpVerifyResponse.hasOwnProperty('otpToken')) {
        setUserToken(otpVerifyResponse.otpToken);
        setOtpPassed(true);
      }
    } catch (error: any) {
      if (i18n.exists(`apiError:${error?.data?.data}`)) {
        setApiErrData([error.data.data, { ...(error.data?.data || {}) }]);
      } else if (i18n.exists(`apiError:${error?.data?.message}`)) {
        setApiErrData([error.data.message, { ...(error.data?.data || {}) }]);
      }

      setMobile('');
      setEmail('');
      setOtp('');

      if (error?.data?.data === 'INVALID_OTP_CODE') {
        if (isMobile) {
          setMobile(payload?.mobileNumber || '');
        } else {
          setEmail(payload?.email || '');
        }
      } else {
        setLoginPassed(false);
      }
    }

    setShowLoader(false);
  };

  const handleSuccess = () => {
    setPasswordReset(true);
    setType('success');
  };

  const handleFail = () => {
    setPasswordReset(true);
    setType('fail');
  };

  const submitResetPassword = async (newPassword: string) => {
    resetError();
    setMobile('');
    setEmail('');
    setOtp('');
    setShowLoader(true);
    const payload: RestorePasswordRequest = {
      token: userToken,
      password: Buffer.from(newPassword).toString('base64'),
      ...(isMobile ? { mobileNumber: mobile } : { email }),
    };

    try {
      await resetPassword(payload).unwrap();
      handleSuccess();
    } catch (error: any) {
      if (i18n.exists(`apiError:${error?.data?.data}`)) {
        setApiErrData([error.data.data, { ...(error.data?.data || {}) }]);
      } else if (i18n.exists(`apiError:${error?.data?.message}`)) {
        setApiErrData([error.data.message, { ...(error.data?.data || {}) }]);
      }
      if (error?.data?.message === 'PASSWORD_MATCH_OLD') {
        if (isMobile && 'mobileNumber' in payload) {
          setMobile(payload?.mobileNumber || '');
        } else if ('email' in payload) {
          setEmail(payload?.email || '');
        }
      } else {
        handleFail();
      }
    }

    setShowLoader(false);
  };

  const renderForm = () => {
    if (!loginPassed && isMobile) {
      return (
        <ResetPasswordMobileForm
          toggleRestoreType={toggleRestoreType}
          onSubmit={submitLogin}
          mobile={mobile}
          errorMessage={apiErrData[0] ? tApiError(...apiErrData) : t(errMsg)}
        />
      );
    }

    if (!loginPassed && !isMobile) {
      return (
        <ResetPasswordEmailForm
          toggleRestoreType={toggleRestoreType}
          onSubmit={submitLogin}
          email={email}
          errorMessage={apiErrData[0] ? tApiError(...apiErrData) : t(errMsg)}
        />
      );
    }

    if (!otpPassed) {
      return (
        <ResetPasswordOtpForm
          onResendOTP={resendOTP}
          onSubmit={submitOtp}
          errorMessage={apiErrData[0] ? tApiError(...apiErrData) : t(errMsg)}
        />
      );
    }

    return (
      <ResetPasswordNewForm
        onSubmit={submitResetPassword}
        onCancel={() => navigate(locations.login())}
        errorMessage={apiErrData[0] ? tApiError(...apiErrData) : t(errMsg)}
      />
    );
  };

  if (showLoader) {
    return <Loader />;
  }

  return (
    <CenterContent>
      <Box sx={{ position: 'absolute', top: '15%', right: '0', zIndex: -1 }}>
        <img src={!isEnglish ? '/images/logo-frame-ar.svg' : '/images/logo-frame-en.svg'} alt="madkhol logo frame" />
      </Box>
      {renderForm()}
      <ResetModal open={passwordReset} handleClose={handleClose} type={type} onDoneClick={handleClose} />
    </CenterContent>
  );
};

export default ResetPasswordView;
