export enum nafazDataFormFields {
  maritalStatus = 'maritalStatus',
  educationLevel = 'educationLevel',
  companyName = 'companyName',
  employerAddress = 'employerAddress',
  fiveYearsFinancialSector = 'fiveYearsFinancialSector',
  fiveYearsFinancialSectorDescription = 'fiveYearsFinancialSectorDescription',
  boardDirectorsMember = 'boardDirectorsMember',
  boardDirectorsMemberDescription = 'boardDirectorsMemberDescription',
  individualWithHighProfilePoliticalRole = 'individualWithHighProfilePoliticalRole',
  individualWithHighProfilePoliticalRoleDescription = 'individualWithHighProfilePoliticalRoleDescription',
  notBeneficialOwner = 'notBeneficialOwner',
  notBeneficialOwnerDescription = 'notBeneficialOwnerDescription',
}
